import { Badge, Card, Subheading, TextStyle, Text } from "@shopify/polaris";
import styled from "@emotion/styled";
import { Withdrawal } from "app/models/withdrawal";
import { formatMoneyNoCurrency } from "app/lib/money";
import { formatDateStr, getDateTime } from "app/utils/helpers";

interface Props {
  payout: Withdrawal;
}

const getPayoutTotal = (payout: Withdrawal) => {
  const sum = Number(payout.amount) + Number(payout.fee);
  return sum;
};

export default function WithdrawalDetails(props: Props) {
  const { payout } = props;

  const total = getPayoutTotal(payout);
  // const profit = Number(payout.fee) - Number(payout.actual_fee);

  return (
    <Card>
      <Card.Header title={<Subheading>Withdrawal Details</Subheading>} />
      <WithdrawalsDetailsEL>
        <Card.Section>
          <div>
            <Text variant="bodyMd" as="p">
              Withdrawal type
            </Text>
            <p>
              <Badge children="Busha" />
            </p>
          </div>
          <div>
            <Text variant="bodyMd" as="p">
              Date initiated
            </Text>
            <p>{`${formatDateStr(payout.created_at)} at ${getDateTime(
              payout.created_at
            )}`}</p>
          </div>
          <div>
            <Text variant="bodyMd" as="p">
              Network
            </Text>
            <p>{payout.network}</p>
          </div>
        </Card.Section>
        <Card.Section>
          <div>
            <Text variant="bodyMd" as="p">
              Amount
            </Text>
            <p>{`${formatMoneyNoCurrency(
              Number(payout.amount),
              payout.category === "fiat" ? "fiat" : "crypto"
            )} ${payout.currency}`}</p>
          </div>
          <div>
            <Text variant="bodyMd" as="p">
              Fees
            </Text>
            <p>{`${formatMoneyNoCurrency(
              Number(payout.actual_fee),
              payout.category === "fiat" ? "fiat" : "crypto"
            )} ${payout.currency}`}</p>
          </div>
          <div>
            <TextStyle variation="strong">Total</TextStyle>
            <TextStyle variation="strong">{`${formatMoneyNoCurrency(
              total,
              payout.category === "fiat" ? "fiat" : "crypto"
            )} ${payout.currency}`}</TextStyle>
          </div>
        </Card.Section>
        <Card.Section>
          <Confirmation>
            <TextStyle variation="positive">Confirmation</TextStyle>
            <TextStyle variation="positive">{`${payout.confirmations} / ${payout.required_confirmations}`}</TextStyle>
          </Confirmation>
        </Card.Section>
      </WithdrawalsDetailsEL>
    </Card>
  );
}

const WithdrawalsDetailsEL = styled.div`
  margin-top: 16px;

  .Polaris-Card__Section {
    div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }
    div:last-child {
      margin-bottom: 0px;
    }
  }
`;

const Confirmation = styled.div`
  display: flex;
  justify-content: space-between;
`;
