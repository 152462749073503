import styled from "@emotion/styled";
import {
  Badge,
  Card,
  Icon,
  IconSource,
  Layout,
  Link,
  Stack,
  Text,
} from "@shopify/polaris";
import { ExternalSmallMinor } from "@shopify/polaris-icons";

import useModalState from "app/hooks/useModalState";
import UpdateCountForm from "./UpdateCountForm";
import { SinglePayment } from "app/models/payment";
import { capitalizeStr, formatDateStr } from "app/utils/helpers";
import MerchantDetails from "./MerchantDetails";
import { CUSTOMER_APP_URL } from "app/constants/variables";
import PaymentStatus from "./PaymentStatus";
import Feedback from "./Feedback";
import { formatMoney } from "app/lib/money";

interface Props {
  payment: SinglePayment;
}

export default function Details(props: Props) {
  const { payment } = props;

  const { isOpen, closeModal } = useModalState();

  const resourcePreviewLink = `${CUSTOMER_APP_URL}/${payment.type}s/${payment.resource_id}`;

  return (
    <Layout>
      <Layout.Section>
        <Card>
          <Card.Section title="PAYMENT DETAILS">
            <DataRow>
              <Text as="p" variant="bodyMd">
                Payment type
              </Text>
              <Badge>{payment.type ? capitalizeStr(payment.type) : "N/A"}</Badge>
            </DataRow>

            <DataRow>
              <Text as="p" variant="bodyMd">
                Date paid
              </Text>
              <Text as="p" variant="bodyMd">
                {formatDateStr(payment.created_at, "MMMM D, YYYY [at] hh:mma")}
              </Text>
            </DataRow>

            <DataRow>
              <Text as="p" variant="bodyMd">
                Network
              </Text>
              <Text as="p" variant="bodyMd">
                {payment.network}
              </Text>
            </DataRow>

            <DataRow>
              <Text as="p" variant="bodyMd">
                Fee
              </Text>
              <Text as="p" variant="bodyMd">
                {payment.fees}
              </Text>
            </DataRow>

            <DataRow>
              <Text as="p" variant="bodyMd">
                Amount
              </Text>
              <Text as="p" variant="bodyMd">
                {formatMoney(Number(payment.amount), "digital", payment.currency)}
              </Text>
            </DataRow>
          </Card.Section>

          <Card.Section>
            <Confirmation>
              <Text variant="bodyMd" as="p" color="success">
                Confirmation
              </Text>
              <Text
                variant="bodyMd"
                as="p"
                color="success"
              >{`${payment.confirmation} / ${payment.required_confirmations}`}</Text>
            </Confirmation>
          </Card.Section>
        </Card>
        <Card>
          <Card.Section
            title={
              <Text as="h2" fontWeight="semibold" variant="headingSm">
                {payment.type ? payment.type.toLocaleUpperCase() + " DETAILS" : null}
              </Text>
            }
          >
            <DataRow>
              <Text as="p" variant="bodyMd">
                Link
              </Text>
              <Text as="p" variant="bodyMd">
                <Link url={resourcePreviewLink.toString()} external removeUnderline>
                  <Stack spacing="extraTight">
                    <p>Preview</p>
                    <Icon source={ExternalSmallMinor as IconSource} color="interactive" />
                  </Stack>
                </Link>
              </Text>
            </DataRow>

            <DataRow>
              <Text as="p" variant="bodyMd">
                Reference
              </Text>
              <Text as="p" variant="bodyMd">
                <Link url={`/${payment.type}s/${payment.resource_id}`} removeUnderline>
                  {payment.reference}
                </Link>
              </Text>
            </DataRow>

            <DataRow>
              <Text as="p" variant="bodyMd">
                Date created
              </Text>
              <Text as="p" variant="bodyMd">
                {payment.created_at
                  ? formatDateStr(payment.created_at, "MMMM D, YYYY [at] hh:mma")
                  : "N/A"}
              </Text>
            </DataRow>
          </Card.Section>
        </Card>
      </Layout.Section>

      <Layout.Section secondary>
        <Card>
          <Card.Section title="CUSTOMER">
            {payment.customer.name || payment.customer.email ? (
              <div style={{ marginTop: 16 }}>
                <Text as="p" variant="bodyMd">
                  {payment.customer.name}
                </Text>
                <Text as="p" variant="bodyMd">
                  {payment.customer.email}
                </Text>
              </div>
            ) : (
              "N/A"
            )}
          </Card.Section>
        </Card>

        <PaymentStatus payment={payment} />

        <Card>
          <Card.Section title="BUSINESS">
            <Link url={`/businesses/${payment?.business_id}`} removeUnderline>
              <p style={{ marginTop: 16 }}>{payment?.business_name}</p>
            </Link>
          </Card.Section>
        </Card>

        <MerchantDetails payment={payment} />

        <Feedback payment={payment} />
      </Layout.Section>

      <UpdateCountForm open={isOpen} onClose={closeModal} payment={payment} />
    </Layout>
  );
}

export const DataRow = styled.div`
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
`;

const Confirmation = styled.div`
  display: flex;
  justify-content: space-between;
`;
