import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import TeamsService from "app/api/teams";

export default function useGetRoles() {
  const { data, status, error, refetch, isFetching, isLoading } = useQuery(
    ["roles"],
    () => TeamsService.getRoles()
  );

  return {
    roles: data?.data,
    status,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
    isFetching,
    isLoading,
  };
}
