import styled from "@emotion/styled";
import { Card, Layout, Link, TextStyle } from "@shopify/polaris";

import BusinessDetails from "./BusinessDetails";
import useModalState from "app/hooks/useModalState";
import UpdateCountForm from "./UpdateCountForm";
import { formatDateStr } from "app/utils/helpers";
import PaymentLinkTransaction from "app/models/transaction";

interface Props {
  transaction: PaymentLinkTransaction;
}

export default function Details(props: Props) {
  const { transaction } = props;

  const { isOpen, closeModal, openModal } = useModalState();

  return (
    <Layout>
      <Layout.Section>
        <Card>
          <Card.Section title="PAYMENT DETAILS">
            <DataRow>
              <TextStyle variation="subdued">Product Name</TextStyle>
              <TextStyle variation="subdued"></TextStyle>
            </DataRow>
            <DataRow>
              <TextStyle variation="subdued">Type</TextStyle>
              <TextStyle variation="subdued">{transaction.type}</TextStyle>
            </DataRow>
            <DataRow>
              <TextStyle variation="subdued">Reference</TextStyle>
              <Link removeUnderline url={`/payments/`}>
                {transaction.reference}
              </Link>
            </DataRow>
            <DataRow>
              <TextStyle variation="subdued">Amount</TextStyle>
              <TextStyle variation="subdued"></TextStyle>
            </DataRow>
            <DataRow>
              <TextStyle variation="subdued">Date</TextStyle>
              <TextStyle variation="subdued">
                {formatDateStr(transaction.created_at, "MMMM D, YYYY [at] hh:mma")}
              </TextStyle>
            </DataRow>
            <DataRow>
              <TextStyle variation="subdued">Payment method</TextStyle>
              <TextStyle variation="subdued"></TextStyle>
            </DataRow>

            <DataRow>
              <TextStyle variation="subdued">Confirmation</TextStyle>
              <Link onClick={openModal} removeUnderline>
                0/9
              </Link>
            </DataRow>
          </Card.Section>
        </Card>
      </Layout.Section>
      <Layout.Section secondary>
        <BusinessDetails transaction={transaction} />
      </Layout.Section>
      <UpdateCountForm open={isOpen} onClose={closeModal} transaction={transaction} />
    </Layout>
  );
}

export const DataRow = styled.div`
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
`;
