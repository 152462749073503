import * as React from "react";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Badge,
  Button,
  Card,
  Layout,
  Link,
  Loading,
  Page,
  Stack,
  Text,
  TextStyle,
} from "@shopify/polaris";

import LoaderContainer from "app/components/LoaderContainer";
import Snackbar from "app/components/Snackbar";
import { formatDateStr } from "app/utils/helpers";

import { AccountsSection } from "./components/AccountsSection";
import Compliance from "./components/Compliance";
import PaymentLinkInvoices from "./components/PaymentLinkInvoices";
import PaymentWithdrawals from "./components/PaymentWithdrawals";
import SupportEmailModal from "./components/SupportEmailModal";
import AdminActivitiesModal from "./components/AdminActivitiesModal";
import EditProfileModal from "./components/EditBusinessProfile";

import { BusinessType } from "app/models/business";

import useGetMerchant from "app/hooks/useGetMerchant";
import useModalState from "app/hooks/useModalState";
import useGetBusinessTypes from "app/hooks/useGetBusinessTypes";

import useTogglePaymentStatus from "./hooks/useTogglePaymentStatus";
import useToggleWithdrawalStatus from "./hooks/useToggleWithdrawalStatus";
import useSuspendBusiness from "./hooks/useSuspendBusiness";
import useGetBusiness from "./hooks/useGetBusiness";
import ToggleStatusModal from "app/components/ToggleStatusModal";
import RecoverBusinessModal from "./components/RecoverBusinessModal";
import Feedback from "./components/Feedback";
import BusinessLimits from "./components/BusinessLimits";
import TeamMembersPreview from "./components/TeamMembersPreview";
import BankAccounts from "./components/BankAccounts";
import Settlement from "./components/Settlement";
import Owners from "./components/Owners";
import BusinessInformation from "./components/BusinessInformation";

export default function BusinessDetails() {
  const navigate = useNavigate();

  const { businessId = "" } = useParams();

  const { business, isFetching, isLoading, error, refetch } = useGetBusiness(businessId);

  const { isToggling: isPaymentToggling, togglePaymentStatus } =
    useTogglePaymentStatus(businessId);

  const { isToggling: isWithdrawalToggling, toggleWithdrawalStatus } =
    useToggleWithdrawalStatus(businessId);

  const { isToggling: isSuspensionToggling, toggleSuspension } =
    useSuspendBusiness(businessId);

  const goBack = () => navigate("/businesses");

  const {
    isOpen: isDisableWithdrawalOpen,
    openModal: openWithdrawalModal,
    closeModal: closeWithdrawalModal,
  } = useModalState(false);

  const {
    isOpen: isDisablePaymentOpen,
    openModal: openPaymentModal,
    closeModal: closePaymentModal,
  } = useModalState(false);

  const {
    isOpen: isSuspendOpen,
    openModal: openSuspendModal,
    closeModal: closeSuspendModal,
  } = useModalState(false);

  const {
    isOpen: isSupportEmailOpen,
    openModal: openSupportEmailModal,
    closeModal: closeSupportEmailtModal,
  } = useModalState(false);

  const {
    isOpen: isAdminActvitiesOpen,
    openModal: openAdminActivities,
    closeModal: closeAdminActivitiesModal,
  } = useModalState(false);

  const {
    isOpen: isEditProfileModalOpen,
    openModal: openEditProfileModal,
    closeModal: closeEditProfileModal,
  } = useModalState(false);

  const { merchant } = useGetMerchant(String(business?.user_id));

  const { businessTypes } = useGetBusinessTypes();

  const isPaymentEnabled = !!business?.payments;
  const isWithdrawalsEnabled = !!business?.withdrawals;
  const isSuspended = !!business?.suspended;
  const isBusinessClosed = business ? business.status === "closed" : false;

  const isQueryResolved = !isLoading && !error && Boolean(business);

  return (
    <>
      {isFetching && <Loading />}

      <Page
        title={isQueryResolved ? business?.name : "..."}
        titleMetadata={
          isQueryResolved ? (
            <Tags>
              <StatusBadge>
                <span className="dot"></span>
                <p className="text">
                  {business && businessTypes
                    ? businessTypes!![business.business_type_id as keyof BusinessType]
                        ?.name
                    : ""}
                </p>
              </StatusBadge>

              <Badge status={business?.verified ? "success" : "critical"}>
                {business?.verified ? "Verified" : "Unverified"}
              </Badge>
            </Tags>
          ) : (
            "..."
          )
        }
        subtitle={
          isQueryResolved
            ? `Created at ${formatDateStr(
                String(business?.created_at),
                "MMMM D, YYYY [at] hh:mma"
              )}`
            : "..."
        }
        breadcrumbs={[{ onAction: goBack }]}
        primaryAction={
          isQueryResolved &&
          (isBusinessClosed ? (
            <RecoverBusinessModal businessId={business?.id ?? ""} />
          ) : (
            <Button
              onClick={openSuspendModal}
              destructive={!isSuspended}
              outline={!isSuspended}
            >
              {isSuspended ? "Activate Business" : "Suspend Business"}
            </Button>
          ))
        }
        actionGroups={[
          {
            title: "More Actions",
            disabled: isBusinessClosed,
            actions: [
              {
                content: "Change Email",
                accessibilityLabel: "Change Email",
                onAction: openSupportEmailModal,
              },
              {
                content: isPaymentEnabled ? "Disable Payments" : "Enable Payments",
                accessibilityLabel: isPaymentEnabled
                  ? "Disable Payments"
                  : "Enable Payments",
                onAction: openPaymentModal,
              },
              {
                content: isWithdrawalsEnabled
                  ? "Disable Withdrawals"
                  : "Enable  Withdrawals",
                accessibilityLabel: isWithdrawalsEnabled
                  ? "Disable Withdrawals"
                  : "Enable  Withdrawals",
                onAction: openWithdrawalModal,
              },
            ],
          },
        ]}
      >
        <LoaderContainer error={error} onRetryClicked={refetch} loading={isLoading}>
          <SectionWrapper>
            {!isPaymentEnabled && (
              <Snackbar
                text={
                  <TextStyle>
                    Payments have been currently disabled for this business
                  </TextStyle>
                }
              ></Snackbar>
            )}

            {!isWithdrawalsEnabled && (
              <Snackbar
                text={
                  <TextStyle>
                    Withdrawals have been currently disabled for this business
                  </TextStyle>
                }
              ></Snackbar>
            )}
          </SectionWrapper>

          <Layout>
            <Layout.Section>
              <SectionWrapper>
                {business && <AccountsSection business={business} />}
              </SectionWrapper>

              <SectionWrapper>
                <PaymentLinkInvoices />
              </SectionWrapper>

              <PaymentWithdrawals />
            </Layout.Section>

            <Layout.Section secondary>
              <SectionWrapper>
                <Card
                  title={
                    <Stack alignment="center" distribution="equalSpacing">
                      <Text as="h3" variant="headingXs">
                        MERCHANT
                      </Text>
                      <Avatar
                        size="small"
                        initials={`${merchant?.profile.first_name[0]}${merchant?.profile.last_name[0]}`}
                      />
                    </Stack>
                  }
                  sectioned
                >
                  {merchant && (
                    <Link removeUnderline url={`/merchants/${merchant.id}`}>
                      {merchant.profile.first_name} {merchant.profile.last_name}
                    </Link>
                  )}
                </Card>
              </SectionWrapper>

              <SectionWrapper>
                <Owners businessId={businessId} />
              </SectionWrapper>

              {business && (
                <SectionWrapper>
                  <BusinessInformation business={business} />
                </SectionWrapper>
              )}

              <SectionWrapper>
                <Compliance businessId={businessId} />
              </SectionWrapper>

              <SectionWrapper>
                {business && <TeamMembersPreview business={business} />}
              </SectionWrapper>

              <SectionWrapper>
                <Card
                  title={
                    <Text as="p" variant="bodySm" fontWeight="semibold">
                      BUSINESS PROFILE
                    </Text>
                  }
                  actions={[
                    {
                      content: "Edit",
                      onAction: openEditProfileModal,
                    },
                  ]}
                >
                  <Card.Section>
                    <Stack vertical spacing="extraTight">
                      <Text as="p" variant="bodyMd" fontWeight="medium">
                        Account Email Address
                      </Text>

                      <Text as="p" variant="bodyMd" breakWord color="subdued">
                        {merchant?.email}
                      </Text>
                    </Stack>
                  </Card.Section>

                  <Card.Section>
                    <Stack vertical spacing="extraTight">
                      <Text as="p" variant="bodyMd" fontWeight="medium">
                        Support Email Address
                      </Text>

                      <Text as="p" variant="bodyMd" breakWord color="subdued">
                        {business?.support_email}
                      </Text>
                    </Stack>
                  </Card.Section>

                  <Card.Section>
                    <Stack vertical spacing="extraTight">
                      <Text as="p" variant="bodyMd" fontWeight="medium">
                        Store Currency
                      </Text>

                      <Text as="p" variant="bodyMd" breakWord color="subdued">
                        {business?.store_currency}
                      </Text>
                    </Stack>
                  </Card.Section>
                </Card>
              </SectionWrapper>
              <SectionWrapper>
                <BusinessLimits businessId={businessId} />
              </SectionWrapper>
              <SectionWrapper>
                <Settlement businessId={businessId} />
              </SectionWrapper>
              <SectionWrapper>
                <BankAccounts businessId={businessId} />
              </SectionWrapper>

              <Feedback />
              <Card
                title={
                  <Text as="p" variant="bodySm" fontWeight="semibold">
                    ADMIN ACTIVITIES & COMMENTS
                  </Text>
                }
                actions={[
                  {
                    content: `View all`,
                    onAction: openAdminActivities,
                  },
                ]}
              >
                <Card.Section>
                  <Stack vertical spacing="extraTight">
                    <Text as="p" variant="bodySm">
                      Withdrawal Disabled
                    </Text>

                    <Text as="p" variant="bodyMd" breakWord color="subdued">
                      High risk payout associated with account...
                    </Text>
                  </Stack>
                </Card.Section>

                <Card.Section>
                  <Stack vertical spacing="extraTight">
                    <Text as="p" variant="bodySm">
                      Business Activated
                    </Text>

                    <Text as="p" variant="bodyMd" breakWord color="subdued">
                      N/A
                    </Text>
                  </Stack>
                </Card.Section>
              </Card>
            </Layout.Section>
          </Layout>
        </LoaderContainer>

        <ToggleStatusModal
          destructive={isPaymentEnabled}
          open={isDisablePaymentOpen}
          title={isPaymentEnabled ? "Disable Payments" : "Enable Payments"}
          onClose={closePaymentModal}
          loading={isPaymentToggling}
          onConfirm={togglePaymentStatus}
          confirmText={isPaymentEnabled ? "Disable" : "Enable"}
          text={
            isPaymentEnabled
              ? "Disabling payments will deactivate payments for this business"
              : "Enabling payments will activate payments for this business"
          }
        />

        <ToggleStatusModal
          title={isWithdrawalsEnabled ? "Disable Withdrawals" : "Enable Withdrawals"}
          open={isDisableWithdrawalOpen}
          onClose={closeWithdrawalModal}
          loading={isWithdrawalToggling}
          onConfirm={toggleWithdrawalStatus}
          destructive={isWithdrawalsEnabled}
          confirmText={isWithdrawalsEnabled ? "Disable" : "Enable"}
          text={
            isWithdrawalsEnabled
              ? `Disabling withdrawals will deactivate withdrawals for this business`
              : `Enabling withdrawals will activate withdrawals for this business`
          }
        />

        <ToggleStatusModal
          title={isSuspended ? "Activate Business" : "Suspend Business"}
          open={isSuspendOpen}
          onClose={closeSuspendModal}
          loading={isSuspensionToggling}
          onConfirm={toggleSuspension}
          destructive={!isSuspended}
          confirmText={isSuspended ? "Activate" : "Suspend"}
          text={
            !isSuspended
              ? `Suspending this business will deactivate payments and withdrawals for this business`
              : `Activating this business will activate withdrawals & payments for this business`
          }
        />

        <SupportEmailModal
          open={isSupportEmailOpen}
          onClose={closeSupportEmailtModal}
          onConfirm={() => null}
          confirmText="Save Changes"
        />

        <AdminActivitiesModal
          open={isAdminActvitiesOpen}
          onClose={closeAdminActivitiesModal}
        />

        {business && merchant && (
          <EditProfileModal
            values={{
              account_email: merchant.email,
              support_email: business.support_email,
              local_currency: business.local_currency,
            }}
            open={isEditProfileModalOpen}
            onClose={closeEditProfileModal}
          />
        )}
      </Page>
    </>
  );
}

export const SectionWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const Tags = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StatusBadge = styled.div`
  height: 20px;
  background: #e4e5e7;
  border-radius: 10px;
  padding-inline: 8px;
  display: flex;
  align-items: center;
  gap: 4px;

  .dot {
    width: 10px;
    height: 10px;
    background: #6d7175;
    border-radius: 50%;
    display: inline-block;
  }

  .text {
    font-size: 0.875rem;
  }
`;
