export const SIGN_OUT_PATH = "signout";

export const TOKEN_STORAGE_KEY = "tokens";

export const NOT_AVAILABLE_STRING = "N/A";

export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const CUSTOMER_APP_URL = process.env.REACT_APP_CUSTOMER_APP_URL;
export const COMMERCE_URL = process.env.REACT_APP_COMMERCE_URL;

export const ATLAS_URL = process.env.REACT_APP_ATLAS_URL ?? "http://localhost:4321";