import styled from "@emotion/styled";
import { Button, InlineError, Stack } from "@shopify/polaris";
// import { AlertMinor } from "@shopify/polaris-icons";
import useGetMissingPaymentsCount from "../hooks/useGetMissingPaymentsCount";

import useResyncPayment from "../hooks/useResyncPayment";

const PaymentActionButtons = () => {
  return (
    <Stack>
      <ExportButton />
    </Stack>
  );
};

const ExportButton = () => {
  const { isLoading, refetchMissingPayment, data } = useGetMissingPaymentsCount();
  const { isLoading: isLoadingResyncButton, resyncPayouts } = useResyncPayment();

  return (
    <Stack>
      {data && data.missing_deposits > 0 && (
        <InlineText>
          <InlineError message={`${data.missing_deposits} missing`} fieldID="myFieldID" />
        </InlineText>
      )}
      <Button
        loading={isLoadingResyncButton}
        disabled={isLoading}
        onClick={resyncPayouts}
      >
        Resync
      </Button>
      <Button
        primary
        onClick={refetchMissingPayment}
        loading={isLoading}
        disabled={isLoading}
      >
        Find missing Payments
      </Button>
    </Stack>
  );
};

const InlineText = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px 0px;
`;

export default PaymentActionButtons;
