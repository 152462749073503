import styled from "@emotion/styled";
import { IndexTable, Link, Badge } from "@shopify/polaris";
import { Withdrawals } from "app/models/withdrawal";
import {
  capitalizeStr,
  formatDateStr,
  getDateTime,
  mapStatusToBadge,
} from "app/utils/helpers";

interface Props {
  withdrawals?: Withdrawals[];
}

export default function WithdrawalsList(props: Props) {
  const { withdrawals } = props;

  return (
    <>
      {withdrawals?.map((withdrawal, index) => {
        return (
          <IndexTable.Row key={index} id={index.toString()} position={index}>
            <IndexTable.Cell>
              <CellPadding>
                <Link url={`/withdrawals/${withdrawal.id}`} removeUnderline>
                  {withdrawal.reference}
                </Link>
              </CellPadding>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <CellPadding>
                {`${formatDateStr(withdrawal.created_at)} at ${getDateTime(
                  withdrawal.created_at
                )}`}
              </CellPadding>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <CellPadding>{withdrawal.customer}</CellPadding>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <CellPadding>
                <Badge
                  status={mapStatusToBadge(withdrawal.status)}
                  children={capitalizeStr(withdrawal.status)}
                />
              </CellPadding>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <CellPadding>
                <Badge children="Busha" />
              </CellPadding>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <CellPadding>{withdrawal.network}</CellPadding>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <CellPadding>
                {withdrawal.amount} {withdrawal.currency}
              </CellPadding>
            </IndexTable.Cell>
          </IndexTable.Row>
        );
      })}
    </>
  );
}

const CellPadding = styled.div`
  padding: 13px 0px;
`;
