import { Card, Modal } from "@shopify/polaris";
import useHaltDeletion from "../hooks/useHaltDeletion";
import { useParams } from "react-router-dom";

interface Props {
  open: boolean;
  onClose: () => void;
}
export default function HaltDeletionModal(props: Props) {
  const { open, onClose } = props;

  const { merchantId } = useParams<{ merchantId: string }>();

  const { haltDeletion, loading } = useHaltDeletion();

  const handleHaltDeletion = () => {
    haltDeletion(
      {
        merchantId: String(merchantId),
      },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };

  return (
    <Modal
      title="Stop account Deletion process?"
      open={open}
      onClose={onClose}
      primaryAction={{
        content: "Stop Deletion",
        onAction: handleHaltDeletion,
        loading: loading,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <Card.Section>
          <p>This account will not be deleted. Are you sure you want to continue?</p>
        </Card.Section>
      </Modal.Section>
    </Modal>
  );
}
