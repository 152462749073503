const countries: { name: string; currency: string; id: string }[] = [
  {
    name: "Nigeria",
    currency: "NGN",
    id: "NG",
  },
  {
    name: "United States",
    currency: "USD",
    id: "US",
  },
];

export default countries;
