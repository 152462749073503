import styled from "@emotion/styled";
import { Badge, Card, IndexTable, Link, Text } from "@shopify/polaris";

import TableWrapper from "app/components/TableWrapper";
import { TransactionsItem } from "app/models/transaction";
import { capitalizeStr, formatDateStr, mapStatusToBadge } from "app/utils/helpers";

interface Props {
  transactions: TransactionsItem[];
}

export default function TransactionsList({ transactions }: Props) {
  return (
    <Card.Section fullWidth>
      <TableWrapper>
        <IndexTable
          selectable={false}
          resourceName={{ plural: "transactions", singular: "transaction" }}
          itemCount={transactions.length}
          headings={[
            { title: "Ref Number" },
            { title: "Date" },
            { title: "Business Name" },
            { title: "Status" },
            { title: "Category" },
            { title: "Amount" },
            { title: "New Balance" },
          ]}
        >
          {transactions.map((transaction, index) => {
            return (
              <IndexTable.Row id={transaction.id} key={transaction.id} position={index}>
                <IndexTable.Cell>
                  <CellPadding>
                    <Link removeUnderline url={`/transactions/${transaction.id}`}>
                      {transaction.reference}
                    </Link>
                  </CellPadding>
                </IndexTable.Cell>

                <IndexTable.Cell>
                  {formatDateStr(transaction.created_at!, "MMMM DD, YYYY [at] hh:mm a")}
                </IndexTable.Cell>

                <IndexTable.Cell>
                  <Link removeUnderline url={`/businesses/${transaction.business_id}`}>
                    {transaction.business_name}
                  </Link>
                </IndexTable.Cell>

                <IndexTable.Cell>
                  <Badge status={mapStatusToBadge(transaction.status)}>
                    {capitalizeStr(transaction.status)}
                  </Badge>
                </IndexTable.Cell>

                <IndexTable.Cell>{capitalizeStr(transaction.category)}</IndexTable.Cell>

                <IndexTable.Cell>
                  <Text
                    as="p"
                    variant="bodyMd"
                    color={transaction.is_credit ? "success" : "critical"}
                  >
                    {transaction.is_credit ? "+" : "-"} {transaction.amount}{" "}
                    {transaction.currency}
                  </Text>
                </IndexTable.Cell>

                <IndexTable.Cell>
                  <CellPadding>
                    {transaction.new_balance} {transaction.currency}
                  </CellPadding>
                </IndexTable.Cell>
              </IndexTable.Row>
            );
          })}
        </IndexTable>
      </TableWrapper>
    </Card.Section>
  );
}

const CellPadding = styled.div`
  padding: 13px 0px;
`;
