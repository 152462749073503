import styled from "@emotion/styled";
import { Stack, Card, Text, Divider } from "@shopify/polaris";
import LoaderContainer from "app/components/LoaderContainer";
import { TransactionsItem } from "app/models/transaction";
import { capitalizeStr } from "app/utils/helpers";
import useGetCurrency from "app/views/CurrencyDetails/hooks/useGetCurrency";

interface Props {
  transaction: TransactionsItem;
}

export default function TransactionDetails({ transaction }: Props) {
  const { currency, isLoading, error, refetch } = useGetCurrency(
    true,
    transaction.currency
  );

  return (
    <Card>
      <LoaderContainer small loading={isLoading} onRetryClicked={refetch} error={error}>
        <Card.Section title="TRANSACTION DETAILS">
          <TransactionsDetailsWrapper>
            <Stack alignment="center" distribution="equalSpacing">
              <Text as="p" variant="bodyMd">
                Payment type
              </Text>

              <Text as="p" variant="bodyMd">
                {transaction.is_credit ? "Credit" : "Debit"}
              </Text>
            </Stack>

            <Stack alignment="center" distribution="equalSpacing">
              <Text as="p" variant="bodyMd">
                Category
              </Text>
              <Text as="p" variant="bodyMd" fontWeight="medium">
                {capitalizeStr(transaction.category.split("_").join(" "))}
              </Text>
            </Stack>

            <Divider />

            <Stack alignment="center" distribution="equalSpacing">
              <Text as="p" variant="bodyMd">
                Currency type
              </Text>

              <Text as="p" variant="bodyMd" fontWeight="medium">
                {currency?.is_fiat ? "Fiat" : "Crypto"}
              </Text>
            </Stack>
            <Stack alignment="center" distribution="equalSpacing">
              <Text as="p" variant="bodyMd">
                Currency
              </Text>

              <Text as="p" variant="bodyMd">
                {transaction.currency}
              </Text>
            </Stack>
          </TransactionsDetailsWrapper>
        </Card.Section>
      </LoaderContainer>
    </Card>
  );
}

const TransactionsDetailsWrapper = styled.div`
  margin-top: 16px;
  display: grid;
  gap: 16px;
`;
