import * as React from "react";
import { Modal, Stack, TextField, TextStyle } from "@shopify/polaris";
import { QRCodeSVG as QRCode } from "qrcode.react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import useInitiateTwoFA from "../hooks/useInitiateTwoFA";
import LoaderContainer from "app/components/LoaderContainer";

interface Props {
  open: boolean;
  onClose: () => void;
  loading: boolean;
  onSubmit: (code: string) => void;
}
export default function TwoFAModal(props: Props) {
  const { open, onClose, onSubmit, loading } = props;

  const { refetch, initiating2FA, initiate2FAError, initiate2FAResponse } =
    useInitiateTwoFA();

  const form = useForm({
    defaultValues: {
      code: "",
    },
    resolver: yupResolver(
      Yup.object().shape({
        code: Yup.string().required("Code is required"),
      })
    ),
  });

  return (
    <Modal
      loading={initiating2FA}
      open={open}
      onClose={onClose}
      title="Enable app authentication"
      primaryAction={{
        content: "Enable",
        onAction: form.handleSubmit((v) => onSubmit(v.code)),
        loading,
        disabled: !!initiate2FAError,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <LoaderContainer
          modal
          error={initiate2FAError || undefined}
          onRetryClicked={refetch}
        >
          {initiate2FAResponse && (
            <Stack vertical spacing="loose">
              <Stack wrap={false} spacing="extraLoose">
                <Stack.Item fill>
                  <Stack vertical>
                    <Stack>
                      <p>
                        <TextStyle variation="strong">
                          1. CONFIGURE THE AUTHENTIFICATION APP
                        </TextStyle>
                      </p>
                      <p>
                        Open the authenticator app on your mobile device and
                        scan the QR Code.
                      </p>
                    </Stack>
                    <Stack vertical spacing="tight">
                      <p>
                        Can't scan the QR Code? Configure your app with this
                        key:{" "}
                      </p>
                      <p>
                        <TextStyle variation="strong">
                          {initiate2FAResponse.secret}
                        </TextStyle>
                      </p>
                    </Stack>
                  </Stack>
                </Stack.Item>
                <QRCode value={initiate2FAResponse.qr} size={127} />
              </Stack>
              {/* <Stack vertical>
            <Stack vertical spacing="extraTight">
              <p>
                <TextStyle variation="strong">
                  2. ENTER YOUR CURRENT PASSWORD
                </TextStyle>
              </p>

              <p>Enter the password you currently use to log in</p>
            </Stack>
            <TextField autoComplete="" label="Current password" onChange={() => {}} />
          </Stack> */}
              <Stack vertical>
                <p>
                  <TextStyle variation="strong">
                    2. ENTER THE SIX-DIGIT CODE GENERATED BY THE APP
                  </TextStyle>
                </p>
                <Controller
                  name="code"
                  control={form.control}
                  render={({ field, formState: { errors } }) => (
                    <TextField
                      autoComplete=""
                      autoFocus
                      label="Authentication code"
                      name="code"
                      value={field.value}
                      onChange={(v) => form.setValue("code", v)}
                      error={errors.code?.message}
                    />
                  )}
                />
              </Stack>
            </Stack>
          )}
        </LoaderContainer>
      </Modal.Section>
    </Modal>
  );
}
