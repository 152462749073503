import * as React from "react";
import {
  Button,
  Card,
  DatePicker,
  Popover,
  Select,
  Stack,
  TextField,
  Range,
  IconSource,
} from "@shopify/polaris";
import { CalendarMinor } from "@shopify/polaris-icons";
import styled from "@emotion/styled";
import { formatDateStr } from "app/utils/helpers";
import dateOptions from "app/utils/dateOptions";

interface Props {
  onDateChange: (dateString: string) => void;
  value?: string;
  onReset: () => void;
}
export default function GenericDate(props: Props) {
  const { onDateChange, value, onReset } = props;

  const [popoverActive, setPopoverActive] = React.useState(false);

  const [selected, setSelected] = React.useState("");

  const [{ month, year }, setDate] = React.useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });

  const [selectedDates, setSelectedDates] = React.useState<Range>();

  const dateValue = value?.split("|");
  const parsedDateValue = !dateValue
    ? "Select Date"
    : dateValue?.length > 1
    ? `${formatDateStr(dateValue[0])} | ${formatDateStr(dateValue[1])}`
    : dateOptions.find(option => option.value === dateValue[0])?.label || "Select Date";

  const dateRange =
    dateValue && dateValue.length > 1
      ? { start: dateValue[0], end: dateValue[1] }
      : { start: null, end: null };

  const handleSelectChange = (value: string) => {
    setSelected(value);
    setSelectedDates(undefined);
    onDateChange(value);
    setPopoverActive(false);
  };

  const handleDateRangeChange = (date: Range) => {
    if (date.end && date.start && date.end !== date.start) {
      const rangeString = `${formatDateStr(
        date.start.toDateString(),
        "YYYY-MM-DD"
      )} 00:00|${formatDateStr(date.end.toDateString(), "YYYY-MM-DD")} 00:00`;

      onDateChange(rangeString);
      setPopoverActive(false);
    }
    setSelected("");
    setSelectedDates(date);
  };

  const resetDate = () => {
    setSelected("");
    setSelectedDates(undefined);
    onReset();
    setPopoverActive(false);
  };

  return (
    <Popover
      fluidContent
      active={popoverActive}
      activator={
        <Button
          onClick={() => {
            setPopoverActive(popoverActive => !popoverActive);
          }}
          icon={CalendarMinor as IconSource}
        >
          {parsedDateValue}
        </Button>
      }
      onClose={() => {
        setPopoverActive(popoverActive => !popoverActive);
      }}
      autofocusTarget="none"
      preferredAlignment="left"
    >
      <Container>
        <Select
          label="Date range"
          options={dateOptions}
          onChange={handleSelectChange}
          value={selected}
        />

        <Stack alignment="center" distribution="fillEvenly" wrap={false}>
          <TextField
            label="Starting"
            onChange={() => {}}
            autoComplete="off"
            value={dateRange.start ? formatDateStr(dateRange.start) : ""}
            readOnly
          />
          <TextField
            label="Ending"
            onChange={() => {}}
            autoComplete="off"
            value={dateRange.end ? formatDateStr(dateRange.end) : ""}
            readOnly
          />
        </Stack>

        <Card sectioned>
          <DatePicker
            month={month}
            year={year}
            onChange={handleDateRangeChange}
            onMonthChange={(month: number, year: number) => {
              setDate({ month, year });
            }}
            selected={selectedDates}
            disableDatesAfter={new Date()}
            disableDatesBefore={new Date("2022")}
            multiMonth
            allowRange
          />
        </Card>
      </Container>

      {value && (
        <ButtonWrapper>
          <Button primary onClick={resetDate}>
            Reset
          </Button>
        </ButtonWrapper>
      )}
    </Popover>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 9px;
  width: 610px;
  background-color: var(--p-surface);
  padding: 10px;
`;

const ButtonWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: flex-end;
`;
