import styled from "@emotion/styled";
import { Avatar, Card, Link, Stack, Text } from "@shopify/polaris";
import useGetPaymentAccountDetails from "../hooks/useGetAccountDetails";
import { TradeDetails } from "app/models/connect";
import LoaderContainer from "app/components/LoaderContainer";
import { capitalizeStr } from "app/utils/helpers";

interface Props {
  trade: TradeDetails;
}

export default function MerchantDetails(props: Props) {
  const { trade } = props;
  const { account, isLoading, error, refetch } = useGetPaymentAccountDetails({
    accountId: trade.trade.merchant_payment_account_id,
  });

  let merchantData = trade.trade.Profiles.find(i => i.merchant === true);

  return (
    <Card>
      {merchantData && (
        <Card.Section
          title={
            <Stack distribution="equalSpacing" alignment="center">
              <Text variant="headingXs" as="h4">
                MERCHANT
              </Text>
              <Avatar initials={capitalizeStr(merchantData.username[0])} size="small" />
            </Stack>
          }
        >
          <Link removeUnderline url={`/merchants/${trade.trade.merchant_id}`}>
            <InnerText>{merchantData.username}</InnerText>
          </Link>
          <InnerText>
            {" "}
            {merchantData.first_name} {merchantData.last_name}
          </InnerText>
          <InnerText>{merchantData.email}</InnerText>
        </Card.Section>
      )}

      <Card.Section
        title={
          <Text as="h3" variant="headingXs">
            {trade.trade.type === "deposit" ? "RECEIVING ACCOUNT" : "PAYMENT ACCOUNT"}
          </Text>
        }
      >
        <LoaderContainer
          small
          loading={isLoading}
          onRetryClicked={refetch}
          error={error}
          spinnerSize="small"
        >
          {account && (
            <>
              <InnerText>{account.fields.bank_name}</InnerText>
              <InnerText>{account.fields.account_name}</InnerText>
              <InnerText>{account.fields.account_number}</InnerText>
            </>
          )}
        </LoaderContainer>
      </Card.Section>
    </Card>
  );
}

const InnerText = styled.p`
  margin-top: 4px;
`;
