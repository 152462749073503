import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import BusinessesService from "app/api/businesses";
import { AddBusinessTypePayload } from "app/api/businesses/types";
import parseError from "app/lib/parseError";
import { useToast } from "app/providers/ToastProvider";

export default function useAddBusinessType() {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (payload: AddBusinessTypePayload) =>
      BusinessesService.addBusinessType(payload),
    {
      onError: (error: AxiosError<{}>) => {
        const errorMessage = parseError(error) as string;
        showToast(errorMessage || "", "error");
      },
      onSuccess: res => {
        queryClient.invalidateQueries(["countries"]);
        const message = res.data.message || "";
        showToast(message, "success");
      },
    }
  );

  return {
    addBusinessType: mutate,
    isLoading,
  };
}
