import * as React from "react";
import { Modal, Select } from "@shopify/polaris";
import useGetRoles from "../hooks/useGetRoles";
import { capitalizeStr } from "app/utils/helpers";
import useEditTeamMembersRole from "../hooks/useEditTeamMembersRole";

interface Props {
  open: boolean;
  businessId: string;
  onClose: () => void;
  reset: () => void;
  member_ids: string[];
}

export default function EditRoleModal(props: Props) {
  const { open, onClose, businessId, member_ids, reset } = props;
  const { roles } = useGetRoles();

  const { isLoading: editTeamMembersLoading, editTeamMembersRole } =
    useEditTeamMembersRole();

  const [selected, setSelected] = React.useState("");

  const rolesOptions = React.useMemo(
    () =>
      roles?.data
        .filter(role => role.name !== "owner")
        .map(role => ({
          label: capitalizeStr(role.name),
          value: role.id,
        })),
    [roles?.data]
  );

  const isDisabled = selected.length === 0;

  const emptySelected = () => {
    setSelected("");
    reset();
    onClose();
  };

  const handleRole = () => {
    editTeamMembersRole(
      {
        businessId: businessId,
        payload: {
          member_ids: member_ids,
          role_id: selected,
        },
      },
      {
        onSuccess: emptySelected,
      }
    );
  };

  const handleSelectChange = (value: string) => {
    setSelected(value);
  };

  return (
    <Modal
      title="Edit Team Member role?"
      open={open}
      onClose={onClose}
      primaryAction={{
        content: "Edit role",
        disabled: isDisabled,
        onAction: handleRole,
        loading: editTeamMembersLoading,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <Select
          label="Roles"
          name="roles"
          placeholder="Choose a role"
          value={selected}
          options={rolesOptions}
          onChange={handleSelectChange}
        />
      </Modal.Section>
    </Modal>
  );
}
