import * as React from "react";
import {
  Button,
  Card,
  Form,
  FormLayout,
  Heading,
  Layout,
  Modal,
  TextField,
} from "@shopify/polaris";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import useUpdatePassword from "../hooks/useUpdatePassword";
import { useToast } from "app/providers/ToastProvider";

export default function PasswordSection() {
  const showToast = useToast();

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleModal = () => setIsOpen((v) => !v);

  const { loading, error, updatePassword, response } = useUpdatePassword();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      new_password: "",
      old_password: "",
      password_confirmation: "",
    },
    resolver: yupResolver(
      Yup.object().shape({
        new_password: Yup.string()
          .required("Password is required")
          .min(8, "Password must be at least 8 characters")
          .max(30, "Password must be at most 30 characters"),
        old_password: Yup.string().required("Old password is required"),
        password_confirmation: Yup.string()
          .required("Please confirm password")
          .oneOf([Yup.ref("new_password")], "Passwords do not match"),
      })
    ),
  });

  React.useEffect(() => {
    if (response) {
      showToast(response, "success");
      reset();
      toggleModal();
    }
    if (error) {
      showToast(error, "error");
    }
  }, [response, error, showToast, reset]);

  return (
    <Layout.AnnotatedSection title="Password">
      <Card
        sectioned
        title={
          <Heading>
            <p>You have never changed your password</p>
          </Heading>
        }
      >
        <Modal
          activator={
            <Button onClick={toggleModal}>Change your Password</Button>
          }
          title="Change password"
          open={isOpen}
          onClose={toggleModal}
          primaryAction={{
            content: "Change password",
            onAction: handleSubmit(updatePassword),
            loading,
          }}
        >
          <Modal.Section>
            <Form onSubmit={() => {}}>
              <FormLayout>
                <Controller
                  name="old_password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete=""
                      type="password"
                      name="old_password"
                      label="Current password"
                      value={field.value}
                      onChange={(v) => setValue("old_password", v)}
                      error={errors.old_password?.message}
                    />
                  )}
                />
                <Controller
                  name="new_password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete=""
                      type="password"
                      name="new_password"
                      label="New password"
                      value={field.value}
                      onChange={(v) => setValue("new_password", v)}
                      error={errors.new_password?.message}
                    />
                  )}
                />
                <Controller
                  name="password_confirmation"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete=""
                      type="password"
                      name="password_confirmation"
                      label="Confirm new password"
                      value={field.value}
                      onChange={(v) => setValue("password_confirmation", v)}
                      error={errors.password_confirmation?.message}
                    />
                  )}
                />
              </FormLayout>
            </Form>
          </Modal.Section>
        </Modal>
      </Card>
    </Layout.AnnotatedSection>
  );
}
