import { Badge, BadgeProps, Card, IndexTable, Link, Text } from "@shopify/polaris";

import TableWrapper, { CellPadding } from "app/components/TableWrapper";
import { paymentStatusMap } from "app/lib/mappers";
import { formatMoney } from "app/lib/money";
import { GrootDeposit } from "app/models/payment";
import { capitalizeStr, formatDateStr } from "app/utils/helpers";

interface Props {
  transactions: GrootDeposit[];
  loading: boolean;
}

export default function PaymentLinksPayments(props: Props) {
  const { transactions, loading } = props;

  return (
    <Card>
      <Card.Section
        title={
          <div style={{ marginBottom: 12 }}>
            <Text as="h3" variant="headingMd">
              Payment Link Transactions
            </Text>
          </div>
        }
      >
        <TableWrapper>
          <IndexTable
            selectable={false}
            resourceName={{
              plural: "payment links",
              singular: "payment link",
            }}
            loading={loading}
            itemCount={transactions.length}
            headings={[
              { title: "Ref Number" },
              { title: "Date" },
              { title: "Status" },
              { title: "Amount" },
            ]}
          >
            {transactions.map((transaction, index) => (
              <IndexTable.Row id={transaction.id} key={transaction.id} position={index}>
                <IndexTable.Cell>
                  <CellPadding>
                    <Link removeUnderline url={`/payments/${transaction.id}`}>
                      {transaction.reference}
                    </Link>
                  </CellPadding>
                </IndexTable.Cell>

                <IndexTable.Cell>
                  {formatDateStr(transaction.created_at, "MMMM D, YYYY [at] hh:mma")}
                </IndexTable.Cell>

                <IndexTable.Cell>
                  <Badge
                    status={paymentStatusMap[transaction.status] as BadgeProps["status"]}
                  >
                    {capitalizeStr(transaction.status) ?? "N/A"}
                  </Badge>
                </IndexTable.Cell>

                <IndexTable.Cell>
                  {transaction.amount
                    ? formatMoney(Number(transaction.amount), "", transaction.currency)
                    : "No Payment"}
                </IndexTable.Cell>
              </IndexTable.Row>
            ))}
          </IndexTable>
        </TableWrapper>
      </Card.Section>
    </Card>
  );
}
