import { Button, IconSource, Stack } from "@shopify/polaris";
import useResyncGrootPayouts from "../hooks/useResyncWithdrawals";
import { ExportMinor } from "@shopify/polaris-icons";

const WithdrawalActionButtons = () => {
  return (
    <Stack>
      <ExportButton />
    </Stack>
  );
};

const ExportButton = () => {
  const { isLoading, resyncPayouts } = useResyncGrootPayouts();

  return (
    <Button
      icon={ExportMinor as IconSource}
      loading={isLoading}
      disabled={isLoading}
      onClick={resyncPayouts}
    >
      Export
    </Button>
  );
};

export default WithdrawalActionButtons;
