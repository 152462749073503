import * as React from "react";
import { Card, Layout } from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";

import ConfirmModal from "./ConfirmModal";
import Profile from "app/models/profile";
import useUpdateUserAccess from "../hooks/useUpdateUserAccess";
import { useToast } from "app/providers/ToastProvider";

interface Props {
  profile: Profile;
}
export default function AccessSection(props: Props) {
  const { profile } = props;

  const [deactivateModalIsOpen, setDeactivateModalIsOpen] =
    React.useState(false);
  const [deleteModalIsOpen, setdeleteModalIsOpen] = React.useState(false);

  const { id } = useParams<{ id: string }>();

  const showToast = useToast();

  const navigate = useNavigate();

  const {
    toggleUserActiveStatus,
    isLoading,
    data,
    error,
    //
    deleteUser,
    deletingUser,
    deleteUserResponse,
    deleteUserError,
  } = useUpdateUserAccess(String(id));

  React.useEffect(() => {
    if (data) {
      setDeactivateModalIsOpen(false);
      showToast(data.message || "", "success");
    }
    if (error) {
      showToast(error, "error");
    }
    if (deleteUserResponse) {
      setdeleteModalIsOpen(false);
      showToast(deleteUserResponse.message || "", "success");
      navigate("/users");
    }
    if (deleteUserError) {
      showToast(deleteUserError, "error");
    }
  }, [data, showToast, error, deleteUserResponse, deleteUserError, navigate]);

  return (
    <Layout.AnnotatedSection title="Manage Access">
      <Card
        footerActionAlignment="left"
        primaryFooterAction={
          profile.active
            ? {
                content: "Deactivate user account",
                destructive: true,
                onAction: () => setDeactivateModalIsOpen(true),
              }
            : undefined
        }
        secondaryFooterActions={
          !profile.active
            ? [
                {
                  content: "Activate user account",
                  outline: true,
                  onAction: () => toggleUserActiveStatus("activate"),
                  loading: isLoading,
                },
              ]
            : undefined
        }
      >
        <Card.Section title="Deactivate user account">
          <p>
            Deactivated user accounts will no longer have access to your this
            platform. You can re-activate staff accounts at any time.
          </p>
        </Card.Section>
      </Card>
      <Card
        footerActionAlignment="left"
        primaryFooterAction={{
          content: "Delete user account",
          destructive: true,
          onAction: () => setdeleteModalIsOpen(true),
        }}
      >
        <Card.Section title="Delete user account">
          <p>
            Deleted user accounts will be permanently removed from your store.
            This action cannot be reversed.
          </p>
        </Card.Section>
      </Card>
      <ConfirmModal
        open={deactivateModalIsOpen}
        onClose={() => setDeactivateModalIsOpen(false)}
        loading={isLoading}
        onConfirm={() => toggleUserActiveStatus("deactivate")}
        confirmText="Deactivate account"
        text={`Are you sure you want to deactivate user account for ${profile.first_name}?`}
      />
      <ConfirmModal
        open={deleteModalIsOpen}
        onClose={() => setdeleteModalIsOpen(false)}
        onConfirm={deleteUser}
        loading={deletingUser}
        confirmText="Delete account"
        text={`Are you sure you want to delete user account for ${profile.first_name}? This action cannot be reversed.`}
      />
    </Layout.AnnotatedSection>
  );
}
