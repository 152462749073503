import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import CurrencyService from "app/api/currencies";
import { AddCurrencyPayload } from "app/api/currencies/types";
import { useToast } from "app/providers/ToastProvider";
import parseError from "app/lib/parseError";

interface Props {
  isAddMode: boolean;
}
export default function useCurrencyAction({ isAddMode }: Props) {
  const params = useParams<{ code: string }>();
  const showToast = useToast();
  const queryClient = useQueryClient();

  const handleError = (error: AxiosError<{}>) => {
    const errorMessage = parseError(error) as string;

    showToast(errorMessage || "", "error");
  };

  const { isLoading: isAddingCurrency, mutate: addCurrencyMutate } = useMutation(
    (d: AddCurrencyPayload) => CurrencyService.addCurrency(d),
    {
      onSuccess: res => {
        queryClient.invalidateQueries(["currencies"]);
        const message = res.data.message;
        showToast(message || "", "success");
      },
      onError: handleError,
    }
  );

  const { isLoading: isUpdatingCurrency, mutate: updateCurrencyMutate } = useMutation(
    (d: AddCurrencyPayload) => CurrencyService.updateCurrency(d.code, d),
    {
      onSuccess: res => {
        queryClient.invalidateQueries(["currency", params.code]);
        const message = res.data.message;
        showToast(message || "", "success");
      },
      onError: handleError,
    }
  );

  const onSubmit = isAddMode ? addCurrencyMutate : updateCurrencyMutate;

  return {
    formIsLoading: isAddingCurrency || isUpdatingCurrency,
    onSubmit,
  };
}
