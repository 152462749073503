import * as React from "react";
import { ContextualSaveBar, Page } from "@shopify/polaris";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { css, Global } from "@emotion/react";

import useGoBack from "app/hooks/useGoBack";
import CountryForm from "./components/CountrySettingsForm";
import { AddCountryPayload } from "app/api/countries/type";
import useAddCountry from "./hooks/useAddCountry";
import useGetCountryDetails from "./hooks/useGetCountryDetails";
import LoaderContainer from "app/components/LoaderContainer";
import useGetCurrencies from "app/hooks/useGetCurrencies";

const defaultValues: AddCountryPayload = {
  code: "",
  currency: "",
  name: "",
  calling_code: "",
};

export default function CountrySettingsDetails() {
  const goBack = useGoBack();
  const params = useParams();

  const currenciesQuery = useGetCurrencies({ status: "online" });
  const countryQuery = useGetCountryDetails(params.code as string);
  const { addCountry, addingCountryInProgress } = useAddCountry();

  const formMethods = useForm<AddCountryPayload>({
    defaultValues,
  });

  const { reset, formState, handleSubmit } = formMethods;
  const isAddMode = params.code === "add";
  const { country } = countryQuery;
  const currencies = currenciesQuery.data?.currency ?? [];

  React.useEffect(() => {
    if (country) {
      reset({ ...country, code: country.id });
    }
  }, [country, reset]);

  const saveCountry = (values: AddCountryPayload) => {
    addCountry(values, {
      onSuccess() {
        reset(defaultValues);
      },
    });
  };

  const handleRefetch = () => {
    if (countryQuery.error) {
      countryQuery.refetch();
    }

    if (currenciesQuery.error) {
      currenciesQuery.refetch();
    }
  };

  return (
    <Page
      title={`${isAddMode ? "Add" : "Edit"} Country`}
      breadcrumbs={[{ onAction: goBack }]}
    >
      {formState.isDirty && (
        <>
          <BaseStyles />
          <ContextualSaveBar
            message="Unsaved Country"
            saveAction={{
              content: isAddMode ? "Add" : "Save",
              onAction: handleSubmit(saveCountry),
              loading: addingCountryInProgress,
            }}
            discardAction={{
              onAction: () =>
                reset(isAddMode ? defaultValues : { ...country, code: country?.id }),
            }}
          />
        </>
      )}

      <FormProvider {...formMethods}>
        {params.code === "add" ? (
          <LoaderContainer
            loading={currenciesQuery.isLoading}
            error={currenciesQuery.error}
            onRetryClicked={currenciesQuery.refetch}
          >
            <CountryForm isAddMode={isAddMode} currencies={currencies} />
          </LoaderContainer>
        ) : (
          <LoaderContainer
            style={{ minHeight: "unset" }}
            loading={countryQuery.isLoading || currenciesQuery.isLoading}
            error={countryQuery.error || currenciesQuery.error}
            onRetryClicked={handleRefetch}
          >
            <CountryForm isAddMode={isAddMode} currencies={currencies} />
          </LoaderContainer>
        )}
      </FormProvider>
    </Page>
  );
}

const BaseStyles = () => {
  return (
    <Global
      styles={css`
        .Polaris-Frame__ContextualSaveBar {
          z-index: 1000;
        }
      `}
    />
  );
};
