import { useParams } from "react-router-dom";
import {
  Card,
  IndexTable,
  Pagination,
  Select,
  Stack,
  Text,
} from "@shopify/polaris";

import LoaderContainer from "app/components/LoaderContainer";
import { formatDateStr } from "app/utils/helpers";
import { CellPadding, TableWrapper } from "app/styles";
import useGetMerchantSessions from "app/views/MerchantActivities/hooks/useGetMerchantsSessions";

export function WebSessions() {
  const { merchantId } = useParams<{ merchantId: string }>();

  const {
    data,
    isLoading,
    isFetching,
    refetch,
    error,
    gotoNextPage,
    gotoPrevPage,
  } = useGetMerchantSessions(String(merchantId));

  return (
    <>
      <Card.Section>
        <Stack vertical>
          <Stack distribution="equalSpacing" alignment="center">
            <Stack spacing="extraLoose" alignment="center">
              Showing {data?.paginator?.current_entries_size || 0} Devices
            </Stack>

            <Select
              labelInline
              label="Sort by"
              name="sort"
              options={[
                { label: "Newest", value: "desc" },
                { label: "Oldest", value: "asc" },
              ]}
            />
          </Stack>
        </Stack>
      </Card.Section>

      <LoaderContainer
        modal
        loading={isLoading || isFetching}
        onRetryClicked={refetch}
        error={error}
      >
        <TableWrapper>
          <IndexTable
            headings={[
              { title: "Date" },
              { title: "Device" },
              { title: "IP Address" },
              { title: "Location" },
            ]}
            itemCount={data?.paginator?.current_entries_size || 0}
            selectable={false}
            emptyState={
              <Text as="p" alignment="center" variant="bodySm">
                No Devices
              </Text>
            }
          >
            {data?.data.map((app, index) => {
              return (
                <IndexTable.Row
                  key={index}
                  id={index.toString()}
                  position={index}
                >
                  <IndexTable.Cell>
                    <CellPadding>
                      {formatDateStr(
                        app.created_at,
                        "MMMM D, YYYY [at] hh:mma"
                      )}
                    </CellPadding>
                  </IndexTable.Cell>

                  <IndexTable.Cell>
                    <CellPadding>{app.device_name}</CellPadding>
                  </IndexTable.Cell>

                  <IndexTable.Cell>
                    <CellPadding>{app.ip}</CellPadding>
                  </IndexTable.Cell>

                  <IndexTable.Cell>
                    <CellPadding>{app.location}</CellPadding>
                  </IndexTable.Cell>
                </IndexTable.Row>
              );
            })}
          </IndexTable>
        </TableWrapper>

        {!!data?.paginator.total_entries_size && (
          <Card.Section>
            <Stack distribution="center">
              <Pagination
                label={
                  <p>
                    {data.paginator.page} /{data.paginator.total_pages}
                  </p>
                }
                hasNext={data.paginator.total_pages > data.paginator.page}
                onNext={gotoNextPage}
                hasPrevious={data.paginator.page > 1}
                onPrevious={gotoPrevPage}
              />
            </Stack>
          </Card.Section>
        )}
      </LoaderContainer>
    </>
  );
}
