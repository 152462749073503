import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import PaymentAccountService from "app/api/paymentAccount";

interface Props {
  accountId: string;
  enabled?: boolean;
}
export default function useGetPaymentAccountDetails({ accountId, enabled }: Props) {
  const { data, isLoading, error, refetch } = useQuery(
    ["payment-account", accountId],
    () => PaymentAccountService.getPaymentAccountDetails(accountId),
    { enabled }
  );

  return {
    account: data?.data.data,
    isLoading,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
  };
}
