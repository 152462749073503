import * as React from "react";
import { Page, Layout, Loading } from "@shopify/polaris";
import { formatDateStr, getDateTime } from "app/utils/helpers";
import useGoBack from "app/hooks/useGoBack";
import WithdrawalDetails from "./components/WithdrawalDetails";
import useWithdrawalDetails from "./hooks/useWIthdrawalsDetails";
import LoaderContainer from "app/components/LoaderContainer";
import useModalState from "app/hooks/useModalState";
import UpdatePayoutStatusesModal, {
  PayoutModalAction,
} from "./components/UpdatePayoutStatusesModal";
import WithdrawalMerchant from "./components/WithdrawalMerchant";
import WithdrawalActions from "./components/WithdrawalActions";
import WithdrawalStatus from "./components/WithdrawalStatus";
import WithdrawalBusiness from "./components/WithdrawalBusiness";

export default function WithdrawalsDetails() {
  const goBack = useGoBack();

  const { data, isFetching, isLoading, error, refetch } =
    useWithdrawalDetails();

  const { isOpen, openModal, closeModal } = useModalState();
  const [modalAction, setModalAction] = React.useState<PayoutModalAction>();

  const handleActionButtonsOnclick = (action: PayoutModalAction) => {
    setModalAction(action);
    openModal();
  };

  return (
    <Page
      breadcrumbs={[{ onAction: goBack }]}
      title={data ? data.reference : undefined}
      subtitle={
        data
          ? `${formatDateStr(data.created_at)} at ${getDateTime(
              data.created_at
            )}`
          : undefined
      }
      compactTitle
      primaryAction={
        <WithdrawalActions
          data={data}
          onActionClicked={handleActionButtonsOnclick}
        />
      }
    >
      {isFetching && <Loading />}
      <LoaderContainer
        page
        loading={isLoading}
        onRetryClicked={refetch}
        error={error}
      >
        {data ? (
          <Layout>
            <Layout.Section>
              <WithdrawalDetails payout={data} />
            </Layout.Section>
            <Layout.Section secondary>
              <WithdrawalStatus withdrawal={data} />
              <WithdrawalBusiness withdrawal={data} />
              <WithdrawalMerchant withdrawal={data} />
            </Layout.Section>
          </Layout>
        ) : (
          <></>
        )}
        <UpdatePayoutStatusesModal
          isOpen={isOpen}
          closeModal={closeModal}
          action={modalAction}
        />
      </LoaderContainer>
    </Page>
  );
}
