import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import { useToast } from "app/providers/ToastProvider";
import BankAccountService from "app/api/paymentAccount";

export default function useSetPrimaryBankAccount() {
  const queryClient = useQueryClient();
  const showToast = useToast();

  const { mutate, isLoading } = useMutation(BankAccountService.setPrimaryPaymentAccount, {
    onError: (error: AxiosError<{}>) => {
      const errorMessage = parseError(error) as string;

      showToast(errorMessage || "", "error");
    },
    onSuccess(response) {
      showToast(response.data.message || "", "success");
      return queryClient.invalidateQueries(["bank-accounts"]);
    },
  });

  return { setPrimaryPaymentAccount: mutate, isLoading };
}
