import { Card, Page } from "@shopify/polaris";
import LoaderContainer from "app/components/LoaderContainer";
import BusinessTypesFilters from "./components/BusinessTypesFilters";
import BusinessTypesList from "./components/BusinessTypesList";
import useGetBusinessTypes from "app/hooks/useGetBusinessTypes";
import useQueryParams from "app/hooks/useQueryParams";
import { ListBusinessTypesQuery } from "app/api/businesses/types";
import useGetCountries from "app/hooks/useGetCountries";

export default function BusinessSettings() {
  const { queryParams, updateQueryParams } =
    useQueryParams<ListBusinessTypesQuery>({});

  const { businessTypes, error, isLoading, refetch } =
    useGetBusinessTypes(queryParams);

  const countriesQuery = useGetCountries();

  const businessTypesData = businessTypes ? Object.values(businessTypes) : [];
  const countries = countriesQuery.countries?.data ?? [];

  return (
    <Page
      title="Business Types"
      primaryAction={{
        content: "Add Business Type",
        url: "/settings/businesses/create",
      }}
    >
      <Card>
        <BusinessTypesFilters
          countries={countries}
          query={queryParams}
          updateQuery={updateQueryParams}
        />

        <LoaderContainer
          style={{ minHeight: "unset" }}
          loading={isLoading}
          error={error}
          onRetryClicked={refetch}
        >
          <BusinessTypesList businessTypes={businessTypesData} />
        </LoaderContainer>
      </Card>
    </Page>
  );
}
