import { useQuery } from "@tanstack/react-query";
import PaymentsService from "app/api/payments";
import parseError from "app/lib/parseError";
import { AxiosError } from "axios";

export default function useGetPaymentDetails(reference?: string) {
  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["paymentDetails", reference],
    () => PaymentsService.getPaymentDetails(reference ?? ""),
    {
      enabled: reference === undefined ? false : true,
    }
  );

  return {
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    isLoading,
    refetch,
    isFetching,
  };
}
