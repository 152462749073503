import * as React from "react";
import { ChoiceList, FilterInterface, Filters, Select, Stack } from "@shopify/polaris";
import debounce from "lodash.debounce";

import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import { GetDocumentsQuery } from "app/api/compliance/types";
import LoaderContainer from "app/components/LoaderContainer";
import { ListBusinessesQuery } from "app/api/businesses/types";
import useGetCountries from "app/hooks/useGetCountries";
import useGetBusinessTypes from "app/hooks/useGetBusinessTypes";

interface Props {
  query: ListBusinessesQuery;
  businessesOnDisplay?: number;
  setQuery: (q: ListBusinessesQuery) => void;
}
export default function BusinessesFilter(props: Props) {
  const { query, setQuery, businessesOnDisplay } = props;

  const [queryString, setQueryString] = React.useState(query.name);

  const { countries, ...countriesQuery } = useGetCountries();
  const { businessTypes, ...businessTypesQuery } = useGetBusinessTypes();

  const businessTypesOptions = Object.entries(businessTypes ?? {}).map(([key, value]) => {
    const name = value.name.substring(0, value.name.indexOf("("));

    return {
      label: `${name} (${value.country_id})`,
      value: key,
    };
  });

  const debouncedQueryUpdate = React.useMemo(
    () => debounce(searchString => setQuery({ name: searchString, page: 1 }), 1000),
    [setQuery]
  );

  useUpdatedEffect(() => {
    debouncedQueryUpdate(queryString);
  }, [queryString]);

  const filters: FilterInterface[] = [
    {
      label: "Country",
      key: "country",
      filter: (
        <ChoiceList
          title="Country"
          name="country"
          titleHidden
          choices={(countries?.data ?? []).map(country => ({
            label: country.name,
            value: country.id,
          }))}
          selected={[]}
        />
      ),
      shortcut: true,
    },
    {
      label: "Status",
      key: "compliance_status",
      filter: (
        <ChoiceList
          title="Status"
          name="status"
          titleHidden
          choices={statusTypes}
          selected={[query.compliance_status || ""]}
          onChange={t =>
            setQuery({ compliance_status: t[0] as typeof query.compliance_status })
          }
        />
      ),
      shortcut: true,
    },
    {
      label: "Business Type",
      key: "type",
      filter: (
        <ChoiceList
          title="Business type"
          titleHidden
          selected={[query.type || ""]}
          choices={businessTypesOptions}
          onChange={t => setQuery({ type: t[0] })}
        />
      ),
      shortcut: true,
    },
  ];

  const removeFilter = (filter: keyof GetDocumentsQuery) => {
    setQuery({ ...query, [filter]: undefined });
  };

  const appliedFilters = Object.keys(query)
    .filter(
      key =>
        ["country", "compliance_status", "type"].includes(key) &&
        query[key as keyof ListBusinessesQuery] !== undefined
    )
    .map(key => ({
      key,
      label: `${key}: ${query[key as keyof ListBusinessesQuery]}`,
      onRemove: removeFilter,
    }));

  const handleRefetch = () => {
    if (businessTypesQuery.error) {
      businessTypesQuery.refetch();
    }

    if (countriesQuery.error) {
      countriesQuery.refetch();
    }
  };

  return (
    <LoaderContainer
      style={{ minHeight: "unset" }}
      error={countriesQuery.error || businessTypesQuery.error}
      onRetryClicked={handleRefetch}
    >
      <Stack vertical>
        <Filters
          filters={filters}
          appliedFilters={appliedFilters}
          queryValue={queryString}
          onQueryChange={setQueryString}
          onQueryClear={() => setQueryString("")}
          queryPlaceholder="Search by business name"
          onClearAll={() =>
            setQuery({
              compliance_status: undefined,
              name: undefined,
              sort: undefined,
              type: undefined,
              page: 1,
            })
          }
          focused
          hideTags
        />
        <Stack distribution="equalSpacing" alignment="center">
          <Stack spacing="extraLoose" alignment="center">
            {Boolean(businessesOnDisplay) && (
              <p>
                Showing {businessesOnDisplay} Business
                {Number(businessesOnDisplay) > 1 ? "es" : ""}
              </p>
            )}
          </Stack>
          <Select
            labelInline
            label="Sort by"
            name="sort"
            value={query.sort}
            options={[
              { label: "Newest", value: "desc" },
              { label: "Oldest", value: "asc" },
            ]}
            onChange={(s: "asc" | "desc") => {
              setQuery({ sort: s });
            }}
          />
        </Stack>
      </Stack>
    </LoaderContainer>
  );
}

const statusTypes = [
  {
    label: "Verified",
    value: "verified",
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
  {
    label: "Unverified",
    value: "unverified",
  },
];
