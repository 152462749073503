import styled from "@emotion/styled";
import { Card, Stack, Subheading, Link } from "@shopify/polaris";
import { Withdrawal } from "app/models/withdrawal";

interface Props {
  withdrawal: Withdrawal;
}

export default function WithdrawalBusiness(props: Props) {
  const { withdrawal } = props;
  return (
    <Card>
      <Card.Section
        title={
          <Stack distribution="equalSpacing" alignment="center">
            <Subheading>business</Subheading>
          </Stack>
        }
      >
        <Space>
          <Link removeUnderline url={`/merchants/${withdrawal.sender_id}`}>
            {withdrawal.sender_name}
          </Link>
        </Space>
      </Card.Section>
    </Card>
  );
}

const Space = styled.div`
  margin-top: 8px;
`;
