import { Doc } from "app/models/compliance";
import request from "../request";
import { GenericResponse } from "../types";
import {
  AddDocTypePayload,
  GetBusinessOwnersResponse,
  GetDocumentTypesQuery,
  GetDocumentTypesResponse,
  GetDocumentsQuery,
  GetDocumentsResponse,
  UpdateDocPayload,
  UpdateDocTypePayload,
} from "./types";

export const getDocuments = (params?: GetDocumentsQuery) => {
  return request.get<GetDocumentsResponse>(`/v1/documents`, {
    params,
  });
};

export const getDocument = (id: string) => {
  return request.get<Doc>(`/v1/documents/${id}`);
};

export const updateDocumentStatus = (id: string, payload: UpdateDocPayload) => {
  return request.put<GenericResponse<Doc>>(`/v1/documents/${id}`, payload);
};

async function getDocumentTypes(params: GetDocumentTypesQuery = {}) {
  return request.get<GetDocumentTypesResponse>("/v1/businesses/types/requirements", {
    params,
  });
}

async function addDocumentType(payload: AddDocTypePayload) {
  const { business_type_id, ...input } = payload;

  return request.post<GenericResponse<Doc>>(
    `/v1/businesses/types/${business_type_id}/requirements`,
    [input]
  );
}

async function updateDocumentType(docTypeId: string, payload: UpdateDocTypePayload) {
  return request.put(
    `/v1/businesses/types/${docTypeId}/requirements/:doc_type_id`,
    payload
  );
}

async function getBusinessOwners(businessId: string) {
  return request.get<GetBusinessOwnersResponse>(`/v1/businesses/${businessId}/owners`);
}

const ComplianceService = {
  addDocumentType,
  getDocuments,
  getDocument,
  getDocumentTypes,
  updateDocumentStatus,
  updateDocumentType,
  getBusinessOwners,
};

export default ComplianceService;
