import { useMutation, useQueryClient } from "@tanstack/react-query";
import InvoiceService from "app/api/invoices";
import parseError from "app/lib/parseError";
import { useToast } from "app/providers/ToastProvider";
import { AxiosError } from "axios";

export default function useVoidInvoice() {
  const queryClient = useQueryClient();
  const showToast = useToast();

  const { mutate, isLoading } = useMutation(
    (invoiceId: string) => InvoiceService.voidInvoice(invoiceId),
    {
      onSuccess: (res, invoiceId) => {
        const message = res.data.message;

        showToast(message || "", "success");
        queryClient.invalidateQueries(["invoices", invoiceId]);
      },
      onError: (error: AxiosError<{}>) => {
        const errorMessage = parseError(error) as string;

        showToast(errorMessage || "", "error");
      },
    }
  );

  return { voidInvoice: mutate, isLoading };
}
