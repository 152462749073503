import { Modal } from "@shopify/polaris";
import { TimelineContainer } from "app/styles/TimelineContainer";
import { formatDateStr } from "app/utils/helpers";

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function AdminActivitiesModal({ open, onClose }: Props) {
  return (
    <Modal onClose={onClose} open={open} title="Admin Activities">
      <Modal.Section>
        <TimelineContainer>
          <div className="timeline">
            {Array(1)
              .fill("")
              .map((dateString, idx) => {
                return (
                  <div key={idx.toString()}>
                    <h3 className="date-header">{dateString}</h3>

                    <div className="stepper">
                      {Array(6)
                        .fill("")
                        .map((row, index) => {
                          return (
                            <div key={index.toString()}>
                              <div className="row">
                                <div className="dot-container">
                                  <div
                                    className={`dot ${index === 0 ? "active" : ""}`}
                                  ></div>
                                </div>

                                <div className="status" style={{ height: 60 }}>
                                  Michael Adereyi set withdrawal status to disabled
                                  because: High risk payout associated with account
                                </div>
                                <div className="time">
                                  {formatDateStr(row.created_at, "h:mm a")}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
          </div>
        </TimelineContainer>
      </Modal.Section>
    </Modal>
  );
}
