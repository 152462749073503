import * as React from "react";
import {
  Card,
  DatePicker,
  Form,
  FormLayout,
  Modal,
  Select,
  Stack,
  TextField,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useToast } from "app/providers/ToastProvider";
import { UpdateMerchantProfilePayload } from "app/api/merchants/type";
import useUpdateMerchantProfile from "../hooks/useUpdateMerchantProfile";
import { formatDateStr, parseDOB } from "app/utils/helpers";

interface Props {
  open: boolean;
  onClose: () => void;
  formValues: UpdateMerchantProfilePayload;
}

const genderOptions = [
  { label: "Select Gender", value: "" },
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Rather not say", value: "non-binary" },
];

export default function EditMerchantModal(props: Props) {
  const { open, onClose, formValues } = props;

  const { merchantId } = useParams<{ merchantId: string }>();

  const { error, loading, response, updateProfile } = useUpdateMerchantProfile(
    String(merchantId)
  );
  const [{ month, year }, setDate] = React.useState(() => {
    if (formValues.dob) {
      const dobDate = parseDOB(formValues.dob);
      return { month: dobDate.getMonth(), year: dobDate.getFullYear() };
    }
    return { month: 1, year: 1999 };
  });

  const showToast = useToast();

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<UpdateMerchantProfilePayload>({
    defaultValues: {
      firstname: "",
      lastname: "",
      gender: "",
      dob: "",
    },

    resolver: yupResolver(
      Yup.object().shape({
        firstname: Yup.string().required("First name is required"),
        lastname: Yup.string().required("Last name is required"),
        gender: Yup.string().required("Gender is required"),
        dob: Yup.string(),
      })
    ),
  });

  const onCancel = () => {
    reset();
    setTimeout(() => {
      onClose();
    }, 300);
  };

  React.useEffect(() => {
    reset({
      ...formValues,
      dob: formValues.dob ? parseDOB(formValues.dob).toString() : new Date().toString(),
    });
  }, [formValues, reset]);

  React.useEffect(() => {
    if (response) {
      showToast(response, "success");
      onClose();
      reset();
    }
  }, [response, showToast, onClose, reset]);

  React.useEffect(() => {
    if (error) {
      showToast(error, "error");
    }
  }, [error, showToast]);

  const handler = (values: UpdateMerchantProfilePayload) => {
    updateProfile({
      ...values,
      dob: formatDateStr(values.dob, "DD/MM/YYYY"),
    });
  };

  return (
    <Modal
      title="Edit Merchant Profile"
      open={open}
      onClose={onCancel}
      primaryAction={{
        content: "Save Changes",
        onAction: handleSubmit(handler),
        loading,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onCancel,
        },
      ]}
    >
      <Modal.Section>
        <Card subdued>
          <Card.Section>
            <Form onSubmit={() => null}>
              <FormLayout>
                <FormLayout.Group>
                  <Controller
                    name="firstname"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete=""
                        label="First name"
                        name="first_name"
                        value={field.value}
                        onChange={v => setValue("firstname", v)}
                        error={errors.firstname?.message}
                      />
                    )}
                  />
                  <Controller
                    name="lastname"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete=""
                        label="Last name"
                        name="last_name"
                        value={field.value}
                        onChange={v => setValue("lastname", v)}
                        error={errors.lastname?.message}
                      />
                    )}
                  />

                  <Controller
                    name="gender"
                    control={control}
                    render={({ field }) => (
                      <Select
                        label="Gender"
                        options={genderOptions}
                        name="gender"
                        value={field.value}
                        onChange={v => setValue("gender", v)}
                        error={errors.gender?.message}
                      />
                    )}
                  />
                </FormLayout.Group>

                <Controller
                  name="dob"
                  control={control}
                  render={({ field }) => (
                    <Stack vertical spacing="baseTight">
                      <label>Date of Birth</label>
                      <Card sectioned>
                        <DatePicker
                          id="dob"
                          month={month}
                          year={year}
                          onMonthChange={(month, year) => setDate({ month, year })}
                          selected={
                            field.value
                              ? {
                                  start: new Date(field.value),
                                  end: new Date(field.value),
                                }
                              : { start: new Date(), end: new Date() }
                          }
                          onChange={date => setValue("dob", date.start.toString())}
                        />
                      </Card>
                    </Stack>
                  )}
                />
              </FormLayout>
            </Form>
          </Card.Section>
        </Card>
      </Modal.Section>
    </Modal>
  );
}
