import * as React from "react";
import {
  AppliedFilterInterface,
  Card,
  ChoiceList,
  Filters,
} from "@shopify/polaris";
import { MerchantBusinessesQuery } from "app/api/merchants/type";
import debounce from "lodash.debounce";
import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import useListBusinessTypes from "app/views/Businesses/hooks/useListBusinessTypes";

interface Props {
  query: MerchantBusinessesQuery;
  updateQuery: (q: MerchantBusinessesQuery) => void;
}
export default function BusinessFilter(props: Props) {
  const { query, updateQuery } = props;

  const [queryValue, setQueryValue] = React.useState("");

  const { businessTypes } = useListBusinessTypes();

  const businessTypesOptions = React.useMemo(
    () =>
      businessTypes?.data.map((business) => ({
        label: business.name,
        value: business.id,
      })),
    [businessTypes?.data]
  );

  const debouncedSearch = React.useMemo(
    () =>
      debounce(
        (searchString) => updateQuery({ q: searchString, page: 1 }),
        1000
      ),
    [updateQuery]
  );

  useUpdatedEffect(() => {
    debouncedSearch(queryValue);
  }, [queryValue]);

  const filters = [
    {
      key: "type",
      label: "Business Type",
      filter: (
        <ChoiceList
          title="Business Type"
          selected={[query.type || ""]}
          choices={businessTypesOptions!}
          titleHidden
          onChange={(t) => {
            updateQuery({ type: t[0] });
          }}
        />
      ),
      shortcut: true,
    },
  ];

  const removeFilter = (filter: keyof MerchantBusinessesQuery) => {
    updateQuery({ ...query, [filter]: undefined });
  };

  const appliedFilters: AppliedFilterInterface[] = Object.keys(query)
    .filter(
      (key) =>
        !["q", "limit", "page"].includes(key) &&
        query[key as keyof MerchantBusinessesQuery] !== undefined
    )
    .map((key) => {
      return {
        key,
        label: `${key === "type" ? "Business type" : key}: ${
          key === "type"
            ? businessTypes!.data.find(
                (businessType) => businessType.id === query.type
              )?.name
            : query[key as string as keyof MerchantBusinessesQuery]
        }`,
        onRemove: removeFilter,
      };
    });

  return (
    <Card.Section>
      <Filters
        queryValue={queryValue}
        queryPlaceholder="Business name"
        filters={filters}
        appliedFilters={appliedFilters}
        onQueryChange={setQueryValue}
        onQueryClear={() => setQueryValue("")}
        onClearAll={() => {}}
      />
    </Card.Section>
  );
}
