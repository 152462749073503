import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import { useToast } from "app/providers/ToastProvider";
import BusinessesService from "app/api/businesses";

export default function useSuspendBusiness(businessId: string) {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const { isLoading: isToggling, mutate: toggleSuspension } = useMutation(
    () => BusinessesService.toggleSuspension(businessId),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["business"]);
        showToast(res.data.message || "", "success");
      },
      onError: (error: AxiosError<{}>) => {
        const errorMessage = parseError(error) as string;

        showToast(errorMessage || "", "error");
      },
    }
  );

  return {
    isToggling,
    toggleSuspension,
  };
}
