import Team, { TeamActivity } from "app/models/team";
import {
  EditTeamMemberRolesPayload,
  GetTeamsQuery,
  RemoveTeamMembersPayload,
  RolesQuery,
  RolesResponse,
  SuspendTeamMembersPayload,
} from "./types";

import request from "../request";
import { GenericCursorPaginatedResponse, GenericPaginatedResponse } from "../types";

const getTeamMembers = async (businessId: string, params?: GetTeamsQuery) => {
  return request.get<GenericPaginatedResponse<Team>>(
    `/v1/businesses/${businessId}/teams`,
    {
      params,
    }
  );
};

const getRoles = async (params?: RolesQuery) => {
  return request.get<RolesResponse>(`/v1/businesses/roles`, {
    params,
  });
};

const suspendTeamMembers = ({ businessId, payload }: SuspendTeamMembersPayload) => {
  return request.put(`/v1/businesses/${businessId}/teams/suspend`, payload);
};

const editTeamMembersRole = ({ businessId, payload }: EditTeamMemberRolesPayload) => {
  return request.put(`/v1/businesses/${businessId}/teams/role`, payload);
};

const removeTeamMembers = ({ businessId, member_ids }: RemoveTeamMembersPayload) => {
  return request.delete(`/v1/businesses/${businessId}/teams`, {
    data: { member_ids },
  });
};

const getTeamActivities = async (businessId: string, params?: GetTeamsQuery) => {
  return request.get<GenericCursorPaginatedResponse<TeamActivity>>(
    `/v1/businesses/${businessId}/teams/logs`,
    {
      params,
    }
  );
};

const TeamsService = {
  suspendTeamMembers,
  removeTeamMembers,
  editTeamMembersRole,
  getTeamMembers,
  getRoles,
  getTeamActivities,
};

export default TeamsService;
