import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import UserService from "app/api/users";

export default function useUpdateUserAccess(id: string) {
  const queryClient = useQueryClient();

  const { isLoading, data, error, mutate } = useMutation(
    (mode: "activate" | "deactivate") =>
      mode === "activate"
        ? UserService.activateUser(id)
        : UserService.deactivateUser(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user", id]);
      },
    }
  );

  const toggleUserActiveStatus = (mode: "activate" | "deactivate") => {
    mutate(mode);
  };

  const {
    isLoading: deletingUser,
    data: deleteUserResponse,
    error: deleteUserError,
    mutate: deleteUserMutate,
  } = useMutation(["user", id], () => UserService.deleteUser(id));

  const deleteUser = () => {
    deleteUserMutate();
  };

  return {
    isLoading,
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    toggleUserActiveStatus,
    //
    deleteUser,
    deletingUser,
    deleteUserResponse: deleteUserResponse?.data,
    deleteUserError: deleteUserError
      ? (parseError(deleteUserError as AxiosError<{}>) as string)
      : undefined,
  };
}
