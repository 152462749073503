import { Card, Link, TextStyle } from "@shopify/polaris";

import PaymentLinkTransaction from "app/models/transaction";

interface Props {
  transaction: PaymentLinkTransaction;
}

export default function BusinessDetails(props: Props) {
  const { transaction } = props;

  return (
    <>
      <Card>
        <Card.Section title="Business" actions={[{ content: "View" }]}>
          <Link removeUnderline url={`/businesses/${transaction.business_id}`}>
            {transaction.business_name}
          </Link>
        </Card.Section>
      </Card>

      <Card>
        <Card.Section title="Customer">
          <TextStyle variation="subdued">
            {(
              transaction.customer.customer_name ||
              transaction.customer.customer_email ||
              "n/a"
            )?.toUpperCase()}
          </TextStyle>
        </Card.Section>
      </Card>
    </>
  );
}
