import * as React from "react";
import { Card, FilterInterface, Filters, Select, Stack, Text } from "@shopify/polaris";
import debounce from "lodash.debounce";

import { GetAuditLogsQuery } from "app/api/auditLog/types";
import useUpdatedEffect from "app/hooks/useUpdatedEffect";

interface Props {
  query: GetAuditLogsQuery;
  setQuery: (q: GetAuditLogsQuery) => void;
  numOfLogs: number;
}
export default function AuditLogFilter(props: Props) {
  const { numOfLogs, query, setQuery } = props;

  const [queryString, setQueryString] = React.useState(query.q);

  const debouncedQueryUpdate = React.useMemo(
    () =>
      debounce((searchString: string) => setQuery({ ...query, q: searchString }), 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setQuery]
  );

  useUpdatedEffect(() => {
    if (!queryString) return;

    debouncedQueryUpdate(queryString);
  }, [queryString]);

  const filters: FilterInterface[] = [];

  return (
    <>
      <Filters
        queryPlaceholder="Search by name"
        filters={filters}
        queryValue={queryString}
        onQueryChange={setQueryString}
        onQueryClear={() => {
          setQueryString("");
          setQuery({ ...query, q: undefined });
        }}
        onClearAll={() =>
          setQuery({
            page: 1,
            q: undefined,
            limit: undefined,
          })
        }
      />

      <Card.Section fullWidth>
        <Stack distribution="equalSpacing" alignment="center">
          <Text as="p" variant="bodyMd">
            Showing {numOfLogs} Logs
          </Text>
          <Select
            labelInline
            label="Sort by"
            name="sort"
            value={query.date_created}
            options={[
              { label: "Newest", value: "desc" },
              { label: "Oldest", value: "asc" },
            ]}
            onChange={(s: "asc" | "desc") => {
              setQuery({ date_created: s });
            }}
          />
        </Stack>
      </Card.Section>
    </>
  );
}
