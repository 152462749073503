import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import RolesService from "app/api/roles";
import { UpdatePermission } from "app/api/roles/types";
import parseError from "app/lib/parseError";
import { useToast } from "app/providers/ToastProvider";

export default function useEditPermission() {
  const queryClient = useQueryClient();
  const showToast = useToast();

  const { mutate, isLoading, data, error } = useMutation(RolesService.updatePermission, {
    onSuccess(data) {
      showToast(data.data.message || "Permission Updated Successfully", "success");

      return Promise.all([
        queryClient.invalidateQueries(["permission"]),
        queryClient.invalidateQueries(["role_permissions"]),
      ]);
    },
    onError: (error: AxiosError<{}>) => {
      const errorMessage = parseError(error) as string;
      showToast(errorMessage, "error");
    },
  });

  const editPermission = (payload: UpdatePermission) => {
    mutate(payload);
  };

  return {
    editPermission,
    isEditing: isLoading,
    responseData: data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : null,
  };
}
