import * as React from "react";
import GenericDropdownFilter from "app/components/GenericDropdownFilter";
import { LegacyCard, Stack, Text } from "@shopify/polaris";
import LoaderContainer from "app/components/LoaderContainer";
import useGetPaymentsAnalytics from "app/hooks/useGetPaymentAnalytics";
import { formatMoney } from "app/lib/money";

export default function PaymentsAsset() {
  const [value, setValue] = React.useState<string>("BTC");

  function changehandler(selected: string[]) {
    setValue(selected[0]);
  }

  const { paymentAnalytics, isLoading, error, refetch } = useGetPaymentsAnalytics({
    display_currency: value,
  });

  const paymentAssetsOptions = React.useMemo(
    () =>
      paymentAnalytics?.balances.map(payment => ({
        label: payment.currency,
        value: payment.currency,
      })),
    [paymentAnalytics]
  );

  const amount = paymentAnalytics?.total_amount ?? "";
  const currency = paymentAnalytics?.display_currency;

  return (
    <LegacyCard
      title={
        isLoading ? (
          ""
        ) : (
          <Stack distribution="equalSpacing" alignment="center">
            <div style={{ position: "relative", borderBottom: "dashed 1px" }}>
              <Text as={"h4"} variant={"headingMd"}>
                Payments asset
              </Text>
            </div>

            <GenericDropdownFilter
              options={paymentAssetsOptions!}
              selected={[value]}
              onChange={changehandler}
              size="slim"
            />
          </Stack>
        )
      }
    >
      <LoaderContainer
        small
        loading={isLoading}
        onRetryClicked={refetch}
        error={error}
        spinnerSize="small"
      >
        <LegacyCard.Section>
          <Text variant="headingXl" as="h2">
            {amount ? `${currency} ${formatMoney(amount)}` : ""}
          </Text>
        </LegacyCard.Section>
      </LoaderContainer>
    </LegacyCard>
  );
}
