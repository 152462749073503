import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { FormMode } from "app/hooks/useFormMode";
import BookService from "app/api/books";
import { useToast } from "app/providers/ToastProvider";
import parseError from "app/lib/parseError";
import { AddBookPayload } from "app/api/books/types";

export default function useBookAction(formMode: FormMode) {
  const params = useParams<{ id: string }>();

  const showToast = useToast();

  const handleError = (error: AxiosError<{}>) => {
    const errorMessage = parseError(error) as string;

    showToast(errorMessage || "", "error");
  };

  const { isLoading: isAddingBook, mutate: addBookMutate } = useMutation(
    (d: AddBookPayload) => BookService.addBook(d),
    {
      onSuccess: (res) => {
        const message = res.data.message;
        showToast(message || "", "success");
      },
      onError: handleError,
    }
  );

  const queryClient = useQueryClient();
  const { isLoading: isUpdatingBook, mutate: updateBookMutate } = useMutation(
    (d: AddBookPayload) => BookService.updateBook(params.id as string, d),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["book", params.id]);
        const message = res.data.message;
        showToast(message || "", "success");
      },
      onError: handleError,
    }
  );

  const onSubmit = (d: AddBookPayload) => {
    if (formMode === "add") {
      addBookMutate(d);
    }
    if (formMode === "edit") {
      updateBookMutate(d);
    }
  };

  return {
    formIsLoading: isAddingBook || isUpdatingBook,
    onSubmit,
  };
}
