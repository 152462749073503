import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import MerchantService from "app/api/merchants";
import { ListMerchantsActivitiesQuery } from "app/api/merchants/type";

export default function useGetMerchantSessions(merchantId: string) {
  const [query, updateQuery] = React.useState<ListMerchantsActivitiesQuery>({
    page: 1,
    limit: 10,
  });

  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["merchant_activities", merchantId],
    () => MerchantService.getMerchantSessions(merchantId),
    {
      enabled: Boolean(merchantId),
    }
  );

  const gotoNextPage = () => {
    if (data) {
      updateQuery({ ...query, page: data.data.paginator.page + 1 });
    }
  };

  const gotoPrevPage = () => {
    if (data) {
      updateQuery({ ...query, page: data.data.paginator.page - 1 });
    }
  };

  return {
    isLoading,
    isFetching,
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
    gotoNextPage,
    gotoPrevPage,
    updateQuery,
    query,
  };
}
