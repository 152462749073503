import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import MerchantService from "app/api/merchants";
import parseError from "app/lib/parseError";

export default function useGetMerchant(merchantId: string) {
  const { data, isLoading, isFetching, error, refetch } = useQuery(
    ["merchant", merchantId],
    () => MerchantService.getMerchant(merchantId),
    {
      enabled: Boolean(merchantId),
    }
  );

  return {
    merchant: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    isLoading,
    isFetching,
    refetch,
  };
}
