import { Badge, Inline, LegacyCard, Stack, Text } from "@shopify/polaris";

import BankAccountsModal from "./BankAccountsModal";
import useListBankAccounts from "./hooks/useListBankAccounts";
import LoaderContainer from "app/components/LoaderContainer";

interface Props {
  businessId: string;
}
export default function BankAccounts(props: Props) {
  const { businessId } = props;

  const { bankAccounts, isLoading, error, refetch } = useListBankAccounts(businessId);

  // The primary bank account is not guaranteed to be first in the response
  const primaryBankAccount = (bankAccounts ?? []).find(bank => bank.is_default === true);

  const bankName = primaryBankAccount?.fields.find(
    b => b.display_name === "Account Name"
  )?.value;

  const accountNumber = primaryBankAccount?.fields.find(
    b => b.display_name === "Account Number"
  )?.value;
  const accountName = primaryBankAccount?.fields.find(
    b => b.display_name === "Bank Name"
  )?.value;

  return (
    <LegacyCard
      title={
        <Stack distribution="equalSpacing" alignment="center">
          <Text as="p" variant="bodySm" fontWeight="semibold">
            BANK ACCOUNTS
          </Text>
          {bankAccounts?.length && <BankAccountsModal numOfBanks={bankAccounts} />}
        </Stack>
      }
    >
      <LoaderContainer
        small
        loading={isLoading}
        onRetryClicked={refetch}
        error={error}
        spinnerSize="small"
      >
        {primaryBankAccount ? (
          <LegacyCard.Section>
            <Stack spacing="tight" vertical>
              <Inline align="space-between">
                <Text as="p" variant="bodyMd">
                  {bankName}
                </Text>
                <Badge>Primary</Badge>
              </Inline>

              <Text as="p" variant="bodyMd">
                {accountNumber}
              </Text>
              <Text as="p" variant="bodyMd">
                {accountName}
              </Text>
            </Stack>
          </LegacyCard.Section>
        ) : (
          <LegacyCard.Section>
            <Text as="p" variant="bodyMd">
              No bank account added
            </Text>
          </LegacyCard.Section>
        )}
      </LoaderContainer>
    </LegacyCard>
  );
}
