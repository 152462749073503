const dateOptions = [
  {
    label: "",
    value: "",
    disabled: true,
  },
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Yesterday",
    value: "yesterday",
  },
  {
    label: "This week",
    value: "this_week",
  },
  {
    label: "This month",
    value: "this_month",
  },
  {
    label: "Last 30 days",
    value: "last_30_days",
  },
  {
    label: "Last 3 months",
    value: "last_3_months",
  },
];

export default dateOptions;
