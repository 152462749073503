import * as React from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  Form,
  FormLayout,
  Modal,
  TextField,
  Select,
} from "@shopify/polaris";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import useChangeEmail from "../hooks/useChangeEmail";

interface FormVals {
  account_email: string;
  support_email: string;
  local_currency: string;
}

interface Props {
  open: boolean;
  values: FormVals;
  onClose: () => void;
}

export default function EditBusinessProfileModal(props: Props) {
  const { id } = useParams<{ id: string }>();

  const { values, open, onClose } = props;

  const { changeEmail, isLoading } = useChangeEmail();

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, isDirty },
  } = useForm<FormVals>({
    mode: "onChange",
    defaultValues: {
      account_email: "",
      support_email: "",
      local_currency: "",
    },
    resolver: yupResolver(
      Yup.object().shape({
        account_email: Yup.string().required("First name is required"),
        support_email: Yup.string().required("Last name is required"),
        local_currency: Yup.string().required("Role is required"),
      })
    ),
  });

  React.useEffect(() => {
    reset({
      account_email: values?.account_email,
      support_email: values?.support_email,
      local_currency: values?.local_currency,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const closeModal = () =>
    setTimeout(() => {
      onClose();
    }, 500);

  const handleChangeEmail = (value: FormVals) => {
    changeEmail(
      { businessId: String(id), payload: { email: value.support_email } },
      {
        onSuccess: onCancel,
      }
    );
  };

  const onCancel = () => {
    closeModal();
  };

  return (
    <Modal
      title="Edit Business Profile"
      open={open}
      onClose={onCancel}
      primaryAction={{
        content: "Save changes",
        onAction: handleSubmit(handleChangeEmail),
        loading: isLoading,
        disabled: !isDirty,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onCancel,
        },
      ]}
    >
      <Modal.Section>
        <Card subdued>
          <Card.Section>
            <Form onSubmit={() => null}>
              <FormLayout>
                <Controller
                  name="account_email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      disabled
                      autoComplete=""
                      label="Account Email Address"
                      name="account_email"
                      value={field.value}
                      onChange={(v) => setValue("account_email", v)}
                      error={errors.account_email?.message}
                    />
                  )}
                />
                <Controller
                  name="support_email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete=""
                      label="Support Email Address"
                      name="support_email"
                      value={field.value}
                      onChange={(v) =>
                        setValue("support_email", v, { shouldDirty: true })
                      }
                      error={errors.support_email?.message}
                    />
                  )}
                />

                <Controller
                  name="local_currency"
                  control={control}
                  render={({ field }) => (
                    <Select
                      disabled
                      label="Local Currency"
                      options={[{ label: "NGN", value: "NGN" }]}
                      name="local_currency"
                      value={field.value}
                      onChange={(v) => setValue("local_currency", v)}
                      error={errors.local_currency?.message}
                    />
                  )}
                />
              </FormLayout>
            </Form>
          </Card.Section>
        </Card>
      </Modal.Section>
    </Modal>
  );
}
