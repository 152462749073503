import * as React from "react";
import { Layout, Page } from "@shopify/polaris";

import LoaderContainer from "app/components/LoaderContainer";

import BasicDetailsSection from "./components/BasicDetailsSection";
import DevicesSection from "./components/DevicesSection";
import PasswordSection from "./components/PasswordSection";
import SkeletonLoader from "./components/SkeletonLoader";
import TwoFASection from "./components/TwoFASection";
import useGetProfile from "app/hooks/useGetProfile";

export default function Profile() {
  const { isLoading, error, refetch } = useGetProfile();

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <Page title="Profile" divider>
      <LoaderContainer page error={error || undefined} onRetryClicked={refetch}>
        <Layout>
          <BasicDetailsSection />
          <PasswordSection />
          <TwoFASection />
          <DevicesSection />
        </Layout>
      </LoaderContainer>
    </Page>
  );
}
