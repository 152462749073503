import * as React from "react";
import {
  Avatar,
  Card,
  ContextualSaveBar,
  FormLayout,
  Layout,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import useUpdateProfile from "../hooks/useUpdateProfile";
import { useProfile } from "app/providers/ProfileProvider";
import { formatDateStr } from "app/utils/helpers";
import { UpdateProfilePayload } from "app/api/profile/types";
import { useToast } from "app/providers/ToastProvider";

export default function BasicDetailsSection() {
  const profile = useProfile();

  const showToast = useToast();

  const { loading, error, updateProfile, response, variables } =
    useUpdateProfile();

  const {
    handleSubmit,
    control,
    setValue,
    reset,

    formState: { errors, isDirty },
  } = useForm<UpdateProfilePayload>({
    defaultValues: {
      first_name: profile ? profile.first_name : "",
      last_name: profile ? profile.last_name : "",
    },
    resolver: yupResolver(
      Yup.object().shape({
        first_name: Yup.string().required("First name is required"),
        last_name: Yup.string().required("Last name is required"),
      })
    ),
  });

  React.useEffect(() => {
    if (response && variables) {
      showToast(response, "success");

      reset({
        first_name: variables!.first_name,
        last_name: variables!.last_name,
      });
    }
  }, [response, showToast, variables, reset]);

  React.useEffect(() => {
    if (error) {
      showToast(error, "error");
    }
  }, [error, showToast]);

  if (!profile) return null;

  return (
    <>
      {isDirty && (
        <ContextualSaveBar
          message="Unsaved changes"
          saveAction={{
            onAction: handleSubmit(updateProfile),
            loading,
          }}
          discardAction={{
            onAction: () => reset(),
          }}
        />
      )}
      <Layout.AnnotatedSection title="Details">
        <Card>
          <Card.Section>
            <Stack alignment="center" spacing="tight">
              <Avatar
                initials={`${profile.first_name[0]}${profile.last_name[0]}`}
                size="small"
              />
              <p>{`${profile.first_name} ${profile.last_name}`}</p>
              <p>
                ( Last login was{" "}
                {formatDateStr(profile.last_login, "MMMM DD, YYYY [at] hh:mma")}
                )
              </p>
            </Stack>
          </Card.Section>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group
                helpText={
                  <p>
                    Use your first and last name as they appear on your
                    government-issued ID.
                  </p>
                }
              >
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete=""
                      label="First name"
                      name="first_name"
                      value={field.value}
                      onChange={(v) =>
                        setValue("first_name", v, { shouldDirty: true })
                      }
                      error={errors.first_name?.message}
                    />
                  )}
                />

                <Controller
                  name="last_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete=""
                      label="Last name"
                      name="last_name"
                      value={field.value}
                      onChange={(v) =>
                        setValue("last_name", v, { shouldDirty: true })
                      }
                      error={errors.last_name?.message}
                    />
                  )}
                />
              </FormLayout.Group>
              <div>
                <p>Email</p>
                <p>
                  <TextStyle variation="subdued">{profile.email}</TextStyle>
                </p>
              </div>
            </FormLayout>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </>
  );
}
