import * as React from "react";
import { Card, Pagination, Stack } from "@shopify/polaris";
import { useParams } from "react-router-dom";

import BusinessList from "./BusinessList";
import LoaderContainer from "app/components/LoaderContainer";
import useGetMerchantBusiness from "../hooks/useGetMerchantBusiness";
import { MerchantBusinessesQuery } from "app/api/merchants/type";
import BusinessFilter from "./BusinessFilter";

export default function Merchant() {
  const params = useParams<{ merchantId: string }>();
  const [queryParams, updateQueryParams] =
    React.useState<MerchantBusinessesQuery>({ page: 1 });

  const businessResponse = useGetMerchantBusiness(
    params.merchantId ?? "",
    queryParams
  );

  const businesses = businessResponse.businesses;

  return (
    <Card title="Businesses">
      <BusinessFilter query={queryParams} updateQuery={updateQueryParams} />

      <LoaderContainer
        style={{ minHeight: "unset" }}
        loading={businessResponse.isLoading}
        error={businessResponse.error}
        onRetryClicked={businessResponse.refetch}
      >
        {businessResponse.businesses && (
          <BusinessList data={businessResponse.businesses.data} />
        )}
      </LoaderContainer>

      <Card.Section>
        <Stack distribution="center">
          {businesses && (
            <Pagination
              label={`${queryParams.page ?? 1} / ${
                businesses.pagination.total_pages
              }`}
              onPrevious={() => {
                updateQueryParams({
                  ...queryParams,
                  page: Number(queryParams.page ?? 1) - 1,
                });
              }}
              onNext={() => {
                updateQueryParams({
                  ...queryParams,
                  page: Number(queryParams.page ?? 1) + 1,
                });
              }}
              hasNext={
                businesses.pagination.total_pages > businesses.pagination.page
              }
              hasPrevious={Boolean(businesses.pagination.page > 1)}
            />
          )}
        </Stack>
      </Card.Section>
    </Card>
  );
}
