import * as React from "react";
import { Badge, Page } from "@shopify/polaris";
import { useParams } from "react-router-dom";

import LoaderContainer from "app/components/LoaderContainer";
import useGoBack from "app/hooks/useGoBack";
import Details from "./components/Details";
import useGetTransaction from "./hooks/useGetTransaction";
import { formatDateStr } from "app/utils/helpers";
import PaymentLinkTransaction from "app/models/transaction";

export default function PaymentLinkTransactionDetails() {
  const goBack = useGoBack();

  const { id } = useParams();

  const { isLoading, data, error, refetch } = useGetTransaction({
    charge_id: String(id),
  });

  const transaction = React.useMemo(() => data?.data[0], [data?.data]);

  return (
    <Page
      breadcrumbs={[{ onAction: goBack }]}
      title={isLoading ? "..." : transaction?.reference}
      subtitle={
        isLoading
          ? "..."
          : formatDateStr(String(transaction?.created_at), "MMMM D, YYYY [at] hh:mma")
      }
      primaryAction={{ content: `Disable`, disabled: true }}
      titleMetadata={
        <>
          <Badge status="success">{transaction?.timeline[0].status}</Badge>
          <Badge status="critical">{transaction?.timeline[0].context}</Badge>
        </>
      }
    >
      <LoaderContainer modal loading={isLoading} error={error} onRetryClicked={refetch}>
        {data && <Details transaction={transaction as PaymentLinkTransaction} />}
      </LoaderContainer>
    </Page>
  );
}
