import { Controller, useFormContext } from "react-hook-form";
import { Card, FormLayout, Stack, TextField } from "@shopify/polaris";

import { UpdatePermission } from "app/api/roles/types";

export default function EditPermissionsForm() {
  const { control } = useFormContext<UpdatePermission["payload"]>();

  return (
    <Card>
      <Card.Section>
        <Stack distribution="fill" vertical>
          <FormLayout>
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <TextField
                  name="description"
                  label="Description"
                  value={field.value}
                  onChange={val => field.onChange(val)}
                  autoComplete="off"
                />
              )}
            />
          </FormLayout>
        </Stack>
      </Card.Section>
    </Card>
  );
}
