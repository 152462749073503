import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import PaymentLinksServices from "app/api/paymentLink";

export default function useGetPaymentLink(PaymentLinkId: string, enabled?: boolean) {
  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["payment-link", PaymentLinkId],
    () => PaymentLinksServices.getPaymentLink(PaymentLinkId),
    { enabled }
  );

  return {
    isLoading,
    isFetching,
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
  };
}
