import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import BusinessesService from "app/api/businesses";
import { ListBusinessTypesQuery } from "app/api/businesses/types";
import { groupBy } from "app/utils/helpers";

export default function useGetBusinessTypes(query?: ListBusinessTypesQuery) {
  const { data, status, error, refetch, isFetching, isLoading } = useQuery(
    ["businessTypes", query],
    () => BusinessesService.listBusinessTypes(query),
    {
      select: response => {
        const data = response.data.data;
        return groupBy(data, "id");
      },
      // caches the data for 24 hours
      staleTime: 86_400_000,
    }
  );

  return {
    businessTypes: data,
    status,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
    isFetching,
    isLoading,
  };
}
