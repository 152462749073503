import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useParams } from "react-router-dom";

import parseError from "app/lib/parseError";
import BookService from "app/api/books";
import { FormMode } from "app/hooks/useFormMode";

export default function useGetBook(formMode: FormMode) {
  const params = useParams<{ id: string }>();

  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["book", params.id],
    () => BookService.getBook(params.id as string),
    { enabled: formMode === "edit" }
  );

  return {
    isLoading,
    isFetching,
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
  };
}
