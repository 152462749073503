import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import ProfileServices from "app/api/profile";
import parseError from "app/lib/parseError";

export default function useTwoFA() {
  const queryClient = useQueryClient();

  const activateTwoFAMutation = useMutation(ProfileServices.activate2FA, {
    onSuccess: () => {
      queryClient.invalidateQueries(["profile"]);
    },
  });
  const activateTwoFA = (code: string) => {
    activateTwoFAMutation.mutate({ code });
  };

  const deactivateTwoFAMutation = useMutation(ProfileServices.deActivate2FA, {
    onSuccess: () => {
      queryClient.invalidateQueries(["profile"]);
    },
  });
  const deactivateTwoFA = () => {
    deactivateTwoFAMutation.mutate();
  };

  return {
    activateTwoFA,
    activatingTwoFA: activateTwoFAMutation.isLoading,
    activateTwoFAResponse: activateTwoFAMutation.data?.data.message,
    activateTwoFAError: activateTwoFAMutation.error
      ? (parseError(activateTwoFAMutation.error as AxiosError<{}>) as string)
      : null,
    //
    deactivateTwoFA,
    deactivatingTwoFA: deactivateTwoFAMutation.isLoading,
    deactivateTwoFAResponse: deactivateTwoFAMutation.data?.data.message,
    deactivateTwoFAError: deactivateTwoFAMutation.error
      ? (parseError(deactivateTwoFAMutation.error as AxiosError<{}>) as string)
      : null,
  };
}
