import { Card, Page, Pagination, Stack } from "@shopify/polaris";

import LoaderContainer from "app/components/LoaderContainer";
import MerchantsFilter from "./components/MerchantsFilter";
import { MerchantsList } from "./components/MerchantsList";
import useListMerchants from "./hooks/useListMerchants";

export default function Merchants() {
  const {
    merchants,
    isLoading,
    isFetching,
    error,
    refetch,
    query,
    setQuery,
    gotoNextPage,
    gotoPrevPage,
  } = useListMerchants();

  const merchantsData = merchants?.customers ?? [];

  return (
    <Page title="All Merchants">
      <Card>
        <MerchantsFilter
          query={query}
          totalMerchants={Number(merchants?.pagination.total_entries_size ?? 0)}
          merchantsOnDisplay={merchantsData.length}
          updateQuery={setQuery}
        />

        <LoaderContainer
          style={{ minHeight: "unset" }}
          loading={isLoading || isFetching}
          error={error}
          onRetryClicked={refetch}
        >
          <MerchantsList data={merchantsData} />
        </LoaderContainer>

        <Card.Section>
          <Stack distribution="center">
            {merchants && (
              <Pagination
                label={`${query.page ?? 1} / ${
                  merchants.pagination.total_pages
                }`}
                onPrevious={gotoPrevPage}
                onNext={gotoNextPage}
                hasNext={
                  merchants.pagination.total_pages > merchants.pagination.page
                }
                hasPrevious={merchants.pagination.page > 1}
              />
            )}
          </Stack>
        </Card.Section>
      </Card>
    </Page>
  );
}
