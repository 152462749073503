import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import {
  Badge,
  Card,
  Icon,
  IconSource,
  Layout,
  Link,
  Page,
  Stack,
  Text,
} from "@shopify/polaris";
import { ExternalSmallMinor } from "@shopify/polaris-icons";

import LoaderContainer from "app/components/LoaderContainer";
import { CUSTOMER_APP_URL } from "app/constants/variables";
import useGetMerchant from "app/hooks/useGetMerchant";
import useGoBack from "app/hooks/useGoBack";
import { formatMoney } from "app/lib/money";
import { capitalizeStr, formatDateStr, mapInvoiceStatusToBadge } from "app/utils/helpers";
import useGetInvoice from "./hooks/useGetInvoice";
import VoidInvoiceModal from "./components/VoidInvoiceModal";
import useGetPayments from "app/hooks/useGetPayments";

export default function InvoiceDetails() {
  const goBack = useGoBack();
  const params = useParams<{ invoiceId: string }>();

  const invoiceResponse = useGetInvoice({
    invoiceId: String(params.invoiceId),
  });

  const merchantResponse = useGetMerchant(invoiceResponse.invoice?.user_id ?? "");

  const paymentResponse = useGetPayments({
    params: { resource_id: String(params.invoiceId), type: "invoice" },
  });

  const paymentId =
    paymentResponse.payments && paymentResponse.payments.data.length > 0
      ? paymentResponse.payments.data[0].id
      : "";

  const invoice = invoiceResponse.invoice;
  const merchantData = merchantResponse.merchant;

  const invoiceCreationDate = invoice?.created_at
    ? `${formatDateStr(invoice.created_at, "MMMM DD, YYYY [at] hh:mm a")}`
    : "";

  const invoiceDueDate = invoice?.due_date
    ? `${formatDateStr(invoice.due_date, "MMMM DD, YYYY [at] hh:mm a")}`
    : "";

  const invoicePreviewLink = new URL(
    `${CUSTOMER_APP_URL}/invoices/${invoice?.id}?mode=live`
  );

  return (
    <LoaderContainer
      style={{ minHeight: "unset" }}
      loading={invoiceResponse.isLoading}
      error={invoiceResponse.error}
      onRetryClicked={invoiceResponse.refetch}
    >
      <Page
        title={invoice?.reference}
        subtitle={invoiceCreationDate}
        titleMetadata={
          invoice?.status && (
            <Badge status={mapInvoiceStatusToBadge(invoice?.status)}>
              {capitalizeStr(invoice.status)}
            </Badge>
          )
        }
        breadcrumbs={[{ onAction: goBack }]}
        primaryAction={invoice ? <VoidInvoiceModal invoice={invoice} /> : null}
      >
        <Layout>
          <Layout.Section>
            <Card>
              <Card.Section title="INVOICE DETAILS">
                <InvoiceDetailsWrapper>
                  <Stack alignment="center" distribution="equalSpacing">
                    <Text as="p" variant="bodyMd">
                      Invoice Name
                    </Text>

                    <Text as="p" variant="bodyMd" breakWord>
                      {invoice?.name || "N/A"}
                    </Text>
                  </Stack>

                  <Stack alignment="center" distribution="equalSpacing">
                    <Text as="p" variant="bodyMd">
                      Invoice Link
                    </Text>
                    <Link url={invoicePreviewLink.toString()} external removeUnderline>
                      <Stack spacing="extraTight">
                        <p>Preview</p>
                        <Icon
                          source={ExternalSmallMinor as IconSource}
                          color="interactive"
                        />
                      </Stack>
                    </Link>
                  </Stack>

                  <Stack alignment="center" distribution="equalSpacing">
                    <Text as="p" variant="bodyMd">
                      Payment Reference
                    </Text>

                    {Boolean(paymentId) ? (
                      <Link url={`/payments/${paymentId}`} removeUnderline>
                        {invoice?.reference}
                      </Link>
                    ) : (
                      <Text as="p" variant="bodyMd">
                        {invoice?.reference}
                      </Text>
                    )}
                  </Stack>

                  <Stack alignment="center" distribution="equalSpacing">
                    <Text as="p" variant="bodyMd">
                      Amount
                    </Text>

                    <Text as="p" variant="bodyMd">
                      {formatMoney(
                        Number(invoice?.local_amount),
                        "",
                        invoice?.local_currency
                      )}
                    </Text>
                  </Stack>

                  <Stack alignment="center" distribution="equalSpacing">
                    <Text as="p" variant="bodyMd">
                      Due Date
                    </Text>

                    <Text
                      as="p"
                      variant="bodyMd"
                      color={invoice?.status === "EXPIRED" ? "critical" : undefined}
                    >
                      {invoiceDueDate}
                    </Text>
                  </Stack>

                  <Stack alignment="center" distribution="equalSpacing">
                    <Text as="p" variant="bodyMd">
                      Type
                    </Text>
                    <Badge>Invoice</Badge>
                  </Stack>
                </InvoiceDetailsWrapper>
              </Card.Section>
            </Card>
          </Layout.Section>

          <Layout.Section secondary>
            <Card>
              <Card.Section title="CUSTOMER">
                <div style={{ marginTop: 14 }}>
                  <Stack alignment="center" distribution="equalSpacing">
                    <Text as="p" variant="bodyMd">
                      {invoice?.customer_name}
                    </Text>
                    <Text as="p" variant="bodyMd">
                      {invoice?.customer_email}
                    </Text>
                  </Stack>
                </div>
              </Card.Section>
            </Card>

            <Card>
              <Card.Section title="DESCRIPTION">
                <div style={{ marginTop: 14 }}>
                  <Text as="p" variant="bodyMd" breakWord>
                    {invoice?.description || "N/A"}
                  </Text>
                </div>
              </Card.Section>
            </Card>

            <Card>
              <Card.Section title="BUSINESS">
                <div style={{ marginTop: 14 }}>
                  <Link url={`/businesses/${invoice?.business_id}`} removeUnderline>
                    <Text as="p" variant="bodyMd">
                      {invoice?.business_name}
                    </Text>
                  </Link>
                </div>
              </Card.Section>
            </Card>

            <Card>
              <LoaderContainer
                style={{ minHeight: "unset" }}
                loading={merchantResponse.isLoading}
                error={merchantResponse.error}
                onRetryClicked={merchantResponse.refetch}
              >
                {merchantData && (
                  <Card.Section title="MERCHANT">
                    <div style={{ marginTop: 14 }}>
                      <Link url={`/merchants/${merchantData.id}`} removeUnderline>
                        <Text as="p" variant="bodyMd">
                          {merchantData.profile.first_name}{" "}
                          {merchantData.profile.last_name}
                        </Text>
                      </Link>
                    </div>
                  </Card.Section>
                )}
              </LoaderContainer>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </LoaderContainer>
  );
}

const InvoiceDetailsWrapper = styled.div`
  margin-top: 16px;
  display: grid;
  gap: 16px;
`;
