import { useParams, useSearchParams } from "react-router-dom";
import styled from "@emotion/styled";
import { Badge, Card, IndexTable, Layout, Link, Text } from "@shopify/polaris";

import BusinessDetails from "./BusinessDetails";
import TransactionsList from "./TransactionsList";
import PaymentLink from "app/models/paymentLink";
import useGetTransactions from "app/views/PaymentLinkDetails/hooks/useGetTransactions";
import LoaderContainer from "app/components/LoaderContainer";
import { ReactComponent as PreviewIcon } from "app/assets/icons/preview.svg";
import { CUSTOMER_APP_URL } from "app/constants/variables";
import { DonationAccounts } from "./DonationAccounts";
import usePaymentLinkAnalytics from "app/hooks/useGetPaymentLinkAnalytics";
import { TableWrapper } from "./styles";

interface Props {
  paymentLink: PaymentLink;
}

export default function Details(props: Props) {
  const { paymentLink } = props;

  const [searchParams] = useSearchParams();

  const { id } = useParams();

  const { data, isLoading, refetch, error, isFetching } = useGetTransactions({
    resource_id: String(id),
    business_id: String(searchParams.get("business_id")),
  });

  const transactions = data?.groot_deposits;

  const {
    data: paymentLinkAnalyticsdata,
    isLoading: paymentLinkAnalyticsIsLoading,
    refetch: paymentLinkAnalyticsRefetch,
    error: paymentLinkAnalyticsError,
  } = usePaymentLinkAnalytics({ id: String(id) });

  const donationPreviewLink = new URL(
    `${CUSTOMER_APP_URL}/payment_links/${paymentLink.id}?mode=live`
  );

  return (
    <>
      <Layout>
        <Layout.Section>
          <Card>
            <Card.Section title="DONATION DETAILS">
              <DataRow>
                <Text as="p" variant="bodyMd">
                  Donation Name
                </Text>
                <Text as="p" variant="bodyMd">
                  {paymentLink.name}
                </Text>
              </DataRow>

              <DataRow>
                <Text as="p" variant="bodyMd">
                  Donation Link
                </Text>
                <Link removeUnderline url={donationPreviewLink.toString()}>
                  Preview
                  <span style={{ paddingLeft: 8 }}>
                    <PreviewIcon />
                  </span>
                </Link>
              </DataRow>

              <DataRow>
                <Text as="p" variant="bodyMd">
                  Type
                </Text>
                <Badge>
                  {paymentLink.payment_link_type === "fixed_price"
                    ? "Payment Link"
                    : "Donation"}
                </Badge>
              </DataRow>
            </Card.Section>
          </Card>

          <CardWrapper>
            <LoaderContainer
              loading={paymentLinkAnalyticsIsLoading}
              error={paymentLinkAnalyticsError}
              onRetryClicked={paymentLinkAnalyticsRefetch}
              modal
            >
              {paymentLinkAnalyticsdata && (
                <DonationAccounts paymentLink={paymentLinkAnalyticsdata.data} />
              )}
            </LoaderContainer>
          </CardWrapper>

          <LoaderContainer
            loading={isLoading}
            error={error}
            onRetryClicked={refetch}
            modal
          >
            <CardWrapper>
              <Card title="DONATION PAYMENTS">
                <Card.Section fullWidth>
                  <TableWrapper>
                    <IndexTable
                      selectable={false}
                      resourceName={{
                        plural: "donations",
                        singular: "donation",
                      }}
                      loading={isFetching}
                      itemCount={data?.pagination.total_entries_size || 0}
                      headings={[
                        { title: "Ref Number" },
                        { title: "Date" },
                        { title: "Status" },
                        { title: "Amount" },
                      ]}
                    >
                      {transactions && <TransactionsList transactions={transactions} />}
                    </IndexTable>
                  </TableWrapper>
                </Card.Section>
              </Card>
            </CardWrapper>
          </LoaderContainer>
        </Layout.Section>
        <Layout.Section secondary>
          <BusinessDetails paymentLink={paymentLink} />
        </Layout.Section>
      </Layout>
    </>
  );
}

export const LayoutMargin = styled.section`
  margin-top: 13px;
`;

export const DataRow = styled.div`
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
`;

export const CardWrapper = styled.div`
  margin-top: 16px;
`;
