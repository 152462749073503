import * as React from "react";
import { ChoiceList, FilterInterface, Filters, Stack } from "@shopify/polaris";
import { GetTeamsQuery } from "app/api/teams/types";
import debounce from "lodash.debounce";
import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import useGetRoles from "../hooks/useGetRoles";
import { capitalizeStr } from "app/utils/helpers";

const statuses = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Suspended",
    value: "suspended",
  },
  {
    label: "Pending",
    value: "pending",
  },
];

interface Props {
  query: GetTeamsQuery;
  setQuery: (q: GetTeamsQuery) => void;
}

export default function TeamMembersFilter(props: Props) {
  const { query, setQuery } = props;

  const [queryString, setQueryString] = React.useState(query.email);

  const { roles } = useGetRoles();

  const rolesOptions = React.useMemo(
    () =>
      roles?.data.map(role => ({
        label: capitalizeStr(role.description),
        value: role.id,
      })),
    [roles?.data]
  );

  const debouncedQueryUpdate = React.useMemo(
    () =>
      debounce(
        searchString =>
          setQuery({ email: searchString.length ? searchString : undefined, page: 1 }),
        1000
      ),
    [setQuery]
  );

  useUpdatedEffect(() => {
    debouncedQueryUpdate(queryString);
  }, [queryString]);

  const filters: FilterInterface[] = [
    {
      label: "Role",
      key: "role_id",
      filter: (
        <ChoiceList
          title="Role"
          selected={[query.role_id || ""]}
          choices={rolesOptions!}
          onChange={t => setQuery({ role_id: t[0] })}
          titleHidden
        />
      ),
      shortcut: true,
    },
    {
      label: "Status",
      key: "status",
      filter: (
        <ChoiceList
          title="Status"
          selected={[query.status || ""]}
          choices={statuses}
          onChange={t => setQuery({ status: t[0] })}
          titleHidden
        />
      ),
      shortcut: true,
    },
  ];

  const removeFilter = React.useCallback(
    (filter: string) => {
      setQuery({ ...query, [filter]: undefined });
    },
    [setQuery, query]
  );

  const appliedFilters = Object.keys(query)
    .filter(
      key =>
        !["email", "limit", "sort"].includes(key) &&
        query[key as keyof GetTeamsQuery] !== undefined
    )
    .map(key => ({
      key,
      label: `${key === "role" ? "Role" : key}: ${
        key === "role"
          ? roles!.data.find(role => role.id === query.role_id)?.name
          : query[key as string as keyof GetTeamsQuery]
      }`,
      onRemove: removeFilter,
    }));

  return (
    <Stack vertical>
      <Filters
        filters={filters}
        appliedFilters={appliedFilters}
        focused
        queryValue={queryString}
        onQueryChange={setQueryString}
        onQueryClear={() => {
          setQueryString("");
          setQuery({ ...query, email: undefined });
        }}
        queryPlaceholder="Search by team member email"
        onClearAll={() =>
          setQuery({
            role_id: undefined,
            status: undefined,
            email: undefined,
            limit: undefined,
            sort: undefined,
          })
        }
        hideTags
      />
    </Stack>
  );
}
