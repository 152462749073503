import request from "../request"
import { GetAuditLogsResponse, GetAuditLogsQuery } from "./types"

const getLogs = async (params?: GetAuditLogsQuery) => {
  return request.get<GetAuditLogsResponse>("/v1/me/audit-log", { params })
}


const AuditLogService = {
    getLogs,
}

export default AuditLogService;