import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import PaymentAccountService from "app/api/paymentAccount";

export default function useListPaymentAccounts(businessId: string) {
  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["payment-accounts", businessId],
    () => PaymentAccountService.ListPaymentAccounts(businessId)
  );

  return {
    isLoading,
    isFetching,
    paymentAccounts: data?.data.payment_accounts,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
  };
}
