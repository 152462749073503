import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import BusinessesService from "app/api/businesses";
import { ListBusinessesQuery } from "app/api/businesses/types";

export default function useListBusinesses(queryParams?: ListBusinessesQuery) {
  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["businesses", queryParams],
    () => BusinessesService.getBusinesses(queryParams),
    {
      staleTime: 1000 * 60 * 10, // Cache for 10 minutes
    }
  );

  return {
    isLoading,
    isFetching,
    businessesData: data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
  };
}
