import { Badge, Card, IndexTable, Link } from "@shopify/polaris";
import styled from "@emotion/styled";

import Business, { BusinessType } from "app/models/business";
import { capitalizeStr, formatDateStr } from "app/utils/helpers";
import TableWrapper from "app/components/TableWrapper";
import { mapBusinessStatusToBadge } from "app/views/Businesses/helpers";

interface Props {
  data: Array<Business & { business_type: BusinessType }>;
}

export default function BusinessList(props: Props) {
  const { data } = props;

  return (
    <Card.Section fullWidth>
      <TableWrapper>
        <IndexTable
          resourceName={{
            singular: "business",
            plural: "businesses",
          }}
          itemCount={data.length}
          headings={[
            { title: "Business Name" },
            { title: "Business Type" },
            { title: "Creation Date" },
            { title: "Status" },
          ]}
          selectable={false}
        >
          {data.map((business, index) => {
            return (
              <IndexTable.Row id={business.id} key={business.id} position={index}>
                <IndexTable.Cell>
                  <CellPadding>
                    <Link url={`/businesses/${business.id}`} removeUnderline>
                      {business.name}
                    </Link>
                  </CellPadding>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <Badge>{business.business_type.name}</Badge>
                </IndexTable.Cell>
                <IndexTable.Cell>{formatDateStr(business.created_at)}</IndexTable.Cell>
                <IndexTable.Cell>
                  <Badge status={mapBusinessStatusToBadge(business.status)}>
                    {capitalizeStr(business.status)}
                  </Badge>
                </IndexTable.Cell>
              </IndexTable.Row>
            );
          })}
        </IndexTable>
      </TableWrapper>
    </Card.Section>
  );
}

const CellPadding = styled.div`
  padding: 13px 0px;
`;
