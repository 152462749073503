import { Modal, TextContainer } from "@shopify/polaris";

interface Props {
  open: boolean;
  isToggling: boolean;
  onClose: () => void;
  onAction: () => void;
}

export default function TogglePermissionModal(props: Props) {
  const { open, isToggling, onClose, onAction } = props;

  return (
    <div style={{ height: "500px" }}>
      <Modal
        open={open}
        onClose={onClose}
        title="Make change to category"
        primaryAction={{
          content: "Yes, continue",
          onAction: onAction,
          loading: isToggling,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: onClose,
            disabled: isToggling,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>
              You are about to make a change to this category. Do you want to continue?
            </p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </div>
  );
}
