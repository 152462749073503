import * as React from "react";

import { TOKEN_STORAGE_KEY } from "app/constants/variables";
import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import storage from "app/lib/storage";
import { DispatchAction } from "./types";

export type TokenContextType = {
  access_token?: string;
};

const INITIAL_STATE: TokenContextType = {};

export const TokenContext = React.createContext<TokenContextType>({});
TokenContext.displayName = "TokenContext";

function initToken(initialState: TokenContextType) {
  return { ...initialState, ...storage.get(TOKEN_STORAGE_KEY) };
}

export const TokenActions = {
  setTokens: "SET_TOKENS",
};

function reducer(
  state: TokenContextType,
  action: DispatchAction<TokenContextType>
) {
  switch (action.type) {
    case TokenActions.setTokens:
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
}

export const TokenDispatchContext = React.createContext<
  React.Dispatch<DispatchAction<TokenContextType>>
>(() => {});
TokenDispatchContext.displayName = "TokenDispatchContext";

export default function TokenProvider(props: React.PropsWithChildren<{}>) {
  const [tokens, dispatch] = React.useReducer(
    reducer,
    INITIAL_STATE,
    initToken
  );

  useUpdatedEffect(() => {
    storage.set(TOKEN_STORAGE_KEY, tokens);
  }, [tokens]);

  return (
    <TokenContext.Provider value={tokens}>
      <TokenDispatchContext.Provider value={dispatch}>
        {props.children}
      </TokenDispatchContext.Provider>
    </TokenContext.Provider>
  );
}

export function useAuthTokens() {
  const authTokens = React.useContext(TokenContext);

  return authTokens;
}

export function useAuthTokensDispatch() {
  const authTokensDispatch = React.useContext(TokenDispatchContext);

  return authTokensDispatch;
}
