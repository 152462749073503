import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import useQueryParams from "app/hooks/useQueryParams";
import PaymentLinksServices from "app/api/paymentLink";
import { GetPaymentLinksQuery } from "app/api/paymentLink/types";

export default function useGetPaymentLinks(type?: "donation" | "fixed_price" | "") {
  const { queryParams, updateQueryParams } = useQueryParams<GetPaymentLinksQuery>({
    page: 1,
    limit: 10,
    type: type || "fixed_price",
    sort: "desc",
  });

  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["payment-links", queryParams, type],
    () => PaymentLinksServices.getPaymentLinks(queryParams)
  );

  const gotoNextPage = () => {
    if (data) {
      updateQueryParams("page", data.data.pagination.page + 1);
    }
  };

  const gotoPrevPage = () => {
    if (data) {
      updateQueryParams("page", data.data.pagination.page - 1);
    }
  };

  return {
    isLoading,
    isFetching,
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
    gotoNextPage,
    gotoPrevPage,
    query: queryParams,
    setQuery: updateQueryParams,
  };
}
