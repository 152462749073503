import * as React from "react";
import { ChoiceList, FilterInterface, Filters } from "@shopify/polaris";
import debounce from "lodash.debounce";

import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import { ListBusinessesQuery } from "app/api/businesses/types";
import useListBusinessTypes from "../hooks/useListBusinessTypes";
import { BusinessStatus } from "app/models/business";

const statusTypes = [
  {
    label: "Verified",
    value: "verified",
  },
  {
    label: "Unverified",
    value: "unverified",
  },
  {
    label: "Closed",
    value: "closed",
  },
];

interface Props {
  query: ListBusinessesQuery;
  setQuery: (q: ListBusinessesQuery) => void;
}

export default function BusinessesFilter(props: Props) {
  const { query, setQuery } = props;

  const [queryString, setQueryString] = React.useState(query.name);

  const { businessTypes } = useListBusinessTypes();

  const businessTypesOptions = React.useMemo(
    () =>
      businessTypes?.data.map(business => ({
        label: business.name,
        value: business.id,
      })),
    [businessTypes?.data]
  );

  const debouncedQueryUpdate = React.useMemo(
    () =>
      debounce(
        searchString =>
          setQuery({ name: searchString.length ? searchString : undefined, page: 1 }),
        1000
      ),
    [setQuery]
  );

  useUpdatedEffect(() => {
    debouncedQueryUpdate(queryString);
  }, [queryString]);

  const filters: FilterInterface[] = [
    {
      label: "Business Type",
      key: "type",
      filter: (
        <ChoiceList
          title="Business Type"
          selected={[query.type || ""]}
          choices={businessTypesOptions!}
          onChange={t => setQuery({ type: t[0] })}
          titleHidden
        />
      ),
      shortcut: true,
    },
    {
      label: "Status",
      key: "status",
      filter: (
        <ChoiceList
          title="Status"
          titleHidden
          selected={[query.status || ""]}
          choices={statusTypes}
          onChange={t => setQuery({ status: t[0] as BusinessStatus })}
        />
      ),
      shortcut: true,
    },
  ];

  const removeFilter = React.useCallback(
    (filter: string) => {
      setQuery({ ...query, [filter]: undefined });
    },
    [setQuery, query]
  );

  const appliedFilters = Object.keys(query)
    .filter(
      key =>
        !["q", "limit", "page", "sort"].includes(key) &&
        query[key as keyof ListBusinessesQuery] !== undefined
    )
    .map(key => ({
      key,
      label: `${key === "type" ? "Business type" : key}: ${
        key === "type"
          ? businessTypes!.data.find(businessType => businessType.id === query.type)?.name
          : query[key as string as keyof ListBusinessesQuery]
      }`,
      onRemove: removeFilter,
    }));

  return (
    <Filters
      filters={filters}
      appliedFilters={appliedFilters}
      focused
      queryValue={queryString}
      onQueryChange={setQueryString}
      onQueryClear={() => {
        setQueryString("");
        setQuery({ ...query, name: undefined });
      }}
      queryPlaceholder="Search by business name"
      onClearAll={() =>
        setQuery({
          name: undefined,
          sort: undefined,
          page: 1,
          limit: undefined,
        })
      }
      hideTags
    />
  );
}
