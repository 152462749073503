import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";

import countries from "app/constants/countries";
import { formatMoneyNoCurrency } from "app/lib/money";
import { BadgeProps } from "@shopify/polaris";
import { Invoice } from "app/models/invoice";

dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

/**
 * Wrap an async operation with an error handler
 */
export const asyncWrap = (promise: Promise<any>) =>
  promise.then((result: any) => [null, result]).catch((err: Error) => [err]);

/**
 * Converts the first letter of the string to uppercase
 */
export const capitalizeStr = (str: string) => {
  if (typeof str !== "string") return "";

  return str.charAt(0).toLocaleUpperCase() + str.slice(1).toLocaleLowerCase();
};

/**
 * Get file size of a File object in kilobytes
 */
export const getFileSizeInKB = (size: number) => parseInt(String(size / 1024));

/**
 * Decode string
 */
export const encodeString = (str: string) =>
  btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) =>
      String.fromCharCode(parseInt(p1, 16))
    )
  );

/**
 * Decode string
 */
export const decodeString = (str: string) =>
  decodeURIComponent(
    Array.prototype.map
      .call(atob(str), c => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );

/**
 * Get english possessive nouns
 */
export const getPossessiveNoun = (noun: string) => {
  if (noun.endsWith("s")) {
    return `${noun}'`;
  }
  return `${noun}'s`;
};

/**
 * Get scale for text
 */
export function scaleBetween(
  unscaledNum: number,
  minAllowed: number,
  maxAllowed: number,
  min: number,
  max: number
) {
  return ((maxAllowed - minAllowed) * (unscaledNum - min)) / (max - min) + minAllowed;
}

/**
 * Get bank slug string from bank name string
 */
export function getBankSlug(name: string) {
  return name.split(" ").join("-").toLowerCase();
}

/**
 * Format  string to a `{MMM} {dd}, {yyyy}` format
 * @param dateStr
 * @param format (optional defaults to MMM DD, YYYY)
 */
export function formatDateStr(dateStr: string | number, format?: string) {
  return dayjs(dayjs(dateStr)).format(format || "MMM DD, YYYY");
}

/**
 * Pasre a  string
 * @param dateStr
 * @param format
 * @returns dayjs.Dayjs
 */
export function parseDate(dateStr: string, format?: dayjs.OptionType | undefined) {
  return dayjs(dateStr, format);
}

export function parseDOB(dateStr: string) {
  return parseDate(dateStr, "DD/MM/YYYY").toDate();
}

/**
 * Get time string form a date string
 */
export function getDateTime(dateStr: string, timeFormat: "12h" | "24h" = "24h") {
  const format = timeFormat === "12h" ? "hh:mma" : "HH:mm";
  return dayjs(new Date(dateStr)).format(format);
}

/**
 * Format the transaction amount which comes in the format of '{CURRENCY} {AMOUNT}`
 */
export function formatTransactionAmountStr(
  str: string,
  type: "debit" | "credit" = "credit"
) {
  const [currency, amount] = str.split(" ");
  const formattedAmount = formatMoneyNoCurrency(Number(amount), undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 8,
  });
  const sign = type === "credit" ? "" : "-";

  return `${sign}${formattedAmount} ${currency}`;
}

/**
 * Get Card Expiry date
 */
export function getCardExpiryDate(card: any | null) {
  return card?.exp_year && card?.exp_month
    ? new Date(
        Number(card.exp_year),
        Number(card.exp_month),
        new Date(Number(card.exp_year), Number(card?.exp_month)).getDate()
      )
    : new Date();
}

/**
 * Get Card Expiry distance
 */
export function getCardExpiryDistance(cardExpiryDate: Date, compareDate: Date) {
  return dayjs(cardExpiryDate).from(compareDate, true);
}

export function getCountryCurrency(countryId: string) {
  return (
    countries.find(country => country.id === countryId)?.currency ?? countries[0].currency
  );
}

/**
 * get relative time from now
 */

export function getRelativeTime(time: Date) {
  return dayjs(time).fromNow();
}

type TimestampFormat = {
  format?: "unix";
};
export function getStartOfMonth({ format }: TimestampFormat = {}) {
  return format === "unix"
    ? dayjs().startOf("month").unix()
    : dayjs().startOf("month").valueOf();
}

export function getNow({ format }: TimestampFormat = {}) {
  return format === "unix" ? dayjs().unix() : dayjs().valueOf();
}

export function getThreeMonthsAgo({ format }: TimestampFormat = {}) {
  return format === "unix"
    ? dayjs().subtract(3, "month").unix()
    : dayjs().subtract(3, "month").valueOf();
}

export function getSixMonthsAgo({ format }: TimestampFormat = {}) {
  return format === "unix"
    ? dayjs().subtract(6, "month").unix()
    : dayjs().subtract(6, "month").valueOf();
}

export function downloadCsv(data: any) {
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");

  a.setAttribute("href", url);
  a.setAttribute("download", "report.csv");

  a.click();
}

interface TruncateArgs {
  text: string;
  startChars?: number;
  endChars?: number;
  maxLength?: number;
}
export function truncateTextMidSection({
  text,
  endChars = 5,
  startChars = 6,
  maxLength = 12,
}: TruncateArgs) {
  if (text.length <= maxLength) return text;

  let start = text.substring(0, startChars);
  let end = text.substring(text.length - endChars, text.length);

  while (start.length + end.length < maxLength) {
    start = start + "...";
  }

  return start + end;
}

export function mapStatusToBadge(status: string) {
  const statusMap: { [key: string]: BadgeProps["status"] } = {
    new: "info",
    completed: "success",
    pending: "warning",
    resolved: "info",
    unresolved: "warning",
    accepted: "success",
    verified: "success",
    unverified: "warning",
    expired: "success",
    cancelled: "warning",
    rejected: "critical",
    open: "success",
    deleted: "critical",
  };

  return statusMap[String(status).toLocaleLowerCase()];
}

export function mapConnectStatusToBadge(status: string) {
  const statusMap: { [key: string]: BadgeProps["status"] } = {
    completed: "success",
    pending: "warning",
    disputed: "info",
    initialized: "attention",
    paid: "success",
    cancelled: "critical",
  };

  return statusMap[String(status).toLocaleLowerCase()];
}

export function mapMerchantStatusToBadge(status: string) {
  const statusMap: { [key: string]: BadgeProps["status"] } = {
    active: "success",
    inactive: "attention",
    deleted: "critical",
    pending_deletion: "warning",
  };

  return statusMap[String(status).toLocaleLowerCase()];
}

export function mapInvoiceStatusToBadge(status: Invoice["status"]) {
  const statusMap: Record<string, BadgeProps["status"]> = {
    COMPLETED: "success",
    DELETED: "warning",
    EXPIRED: "critical",
    NEW: "info",
    RESOLVED: "attention",
    VIEWED: "info",
  };

  return statusMap[status as typeof status];
}

export function mapStatusToBadgeProgress(status: string) {
  const statusMap: { [key: string]: BadgeProps["progress"] } = {
    // Charges
    new: "incomplete",
    completed: "complete",
    pending: "partiallyComplete",
    resolved: "partiallyComplete",
    unresolved: "partiallyComplete",
    expired: "incomplete",
    cancelled: "incomplete",
  };
  return statusMap[status];
}

export function groupBy<T extends Record<string, any>>(list: T[], key: keyof T) {
  return list.reduce((group, item) => {
    const itemKey = item[key];

    group[itemKey] = item;

    return group;
  }, {} as Record<keyof T, T>);
}

export function prependZero(x: number) {
  if (x < 10) return "0" + x;
  else return x;
}
