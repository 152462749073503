import { Avatar, Card, Link, Stack, Text } from "@shopify/polaris";
import LoaderContainer from "app/components/LoaderContainer";

import { capitalizeStr } from "app/utils/helpers";
import useGetBusinessOwners from "app/views/ComplianceDetails/hooks/useGetBusinessOwners";

interface Props {
  businessId: string;
}
export default function Owners(props: Props) {
  const { businessId } = props;

  const { owners, error, refetch, isLoading } = useGetBusinessOwners(businessId);

  return (
    <LoaderContainer small loading={isLoading} onRetryClicked={refetch} error={error}>
      {owners && (
        <Card
          title={
            <Stack alignment="center" distribution="equalSpacing">
              <Text as="h3" variant="headingXs">
                OWNERS
              </Text>

              <Link url={`/compliance/owners/${businessId}`} removeUnderline>
                View all
              </Link>
            </Stack>
          }
          sectioned
        >
          <Stack vertical>
            {owners.map(owner => (
              <Stack key={owner.id} spacing="extraTight" vertical>
                <Text as="p" variant="bodyMd" fontWeight="medium">
                  {capitalizeStr(owner.role)}
                </Text>

                <Stack alignment="center" distribution="equalSpacing">
                  <Link url={`/compliance/owners/${businessId}`} removeUnderline>
                    {owner.first_name} {owner.last_name}
                  </Link>

                  <Avatar
                    size="small"
                    initials={`${capitalizeStr(owner.first_name[0])}${capitalizeStr(
                      owner.last_name[0]
                    )}`}
                  />
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Card>
      )}
    </LoaderContainer>
  );
}
