import * as React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import {
  Button,
  DisplayText,
  Spinner,
  SpinnerProps,
  Stack,
  TextStyle,
} from "@shopify/polaris";

interface LoaderConatainerBoxProps {
  page?: boolean;
  modal?: boolean;
  childrenTop: boolean;
  small?: boolean;
}
const LoaderConatainerBox = styled.div<LoaderConatainerBoxProps>`
  min-height: calc(100vh - 5.6rem);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.page &&
    css`
      min-height: calc(100vh - 14.8rem);
    `};

  ${props =>
    props.modal &&
    css`
      min-height: 200px;
    `};

  ${props =>
    props.small &&
    css`
      min-height: 50px;
    `};

  ${props =>
    props.childrenTop &&
    css`
      align-items: flex-start;
    `};

  .children {
    flex-grow: 1;
    width: 100%;
  }
`;

type BaseProp = React.HTMLAttributes<HTMLDivElement> &
  Omit<LoaderConatainerBoxProps, "childrenTop">;
interface Props extends BaseProp {
  loading?: boolean;
  error?: string;
  onRetryClicked?: () => void;
  spinnerSize?: SpinnerProps["size"];
}
export default function LoaderContainer(props: React.PropsWithChildren<Props>) {
  const { loading, error, onRetryClicked, spinnerSize, ...rest } = props;

  return (
    <LoaderConatainerBox childrenTop={!(loading || !!error)} {...rest}>
      {loading ? <Spinner size={spinnerSize} /> : null}

      {error && !loading ? (
        <Stack vertical distribution="center" alignment="center">
          <DisplayText size={rest.small ? "small" : undefined}>
            <TextStyle variation="negative">{error}</TextStyle>
          </DisplayText>
          <Button onClick={onRetryClicked} primary size={rest.small ? "slim" : undefined}>
            Retry
          </Button>
        </Stack>
      ) : null}

      {!loading && !error ? <div className="children">{props.children}</div> : null}
    </LoaderConatainerBox>
  );
}
