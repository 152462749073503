import { Badge, Page, Stack } from "@shopify/polaris";
import { useParams } from "react-router-dom";

import useGoBack from "app/hooks/useGoBack";
import useGetConnectTrade from "./hooks/useGetConnectTrade";
import LoaderContainer from "app/components/LoaderContainer";
import Details from "./components/Details";
import { capitalizeStr, formatDateStr, mapConnectStatusToBadge } from "app/utils/helpers";

export default function ConnectDetails() {
  const goBack = useGoBack();
  const { id } = useParams();

  const { isLoading, connectTrade, error, refetch } = useGetConnectTrade(String(id));

  const tradeCreationDate = connectTrade
    ? `${formatDateStr(connectTrade.trade.created_at, "MMMM DD, YYYY [at] hh:mm a")}`
    : "";

  return (
    <LoaderContainer loading={isLoading} onRetryClicked={refetch} error={error}>
      {connectTrade && (
        <Page
          breadcrumbs={[{ onAction: goBack }]}
          title={connectTrade.trade.reference}
          subtitle={tradeCreationDate}
          titleMetadata={
            <Stack spacing="extraTight">
              <Badge status={mapConnectStatusToBadge(connectTrade.trade.status)}>
                {capitalizeStr(connectTrade.trade.status)}
              </Badge>
              <Badge>{capitalizeStr(connectTrade.trade.type)}</Badge>
            </Stack>
          }
        >
          <Details trade={connectTrade} />
        </Page>
      )}
    </LoaderContainer>
  );
}
