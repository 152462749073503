import { Card, Layout, Stack, Text } from "@shopify/polaris";
import BusinessDetails from "./BusinessDetails";
import MerchantDetails from "./MerchantDetails";
import { TradeDetails } from "app/models/connect";
import ConnectTimeline from "./ConnectTimeline";
import { formatMoney } from "app/lib/money";
import { capitalizeStr } from "app/utils/helpers";

interface Props {
  trade: TradeDetails;
}

export default function Details(props: Props) {
  const { trade } = props;

  let Deposit = trade.trade.type === "deposit";

  return (
    <>
      <Layout>
        <Layout.Section>
          <Card>
            <Card.Section title="Trade Details">
              <Stack spacing="loose" vertical>
                <Stack distribution="equalSpacing">
                  <Text as="p" variant="bodyMd">
                    {capitalizeStr(trade.trade.type)}
                  </Text>
                  <Text as="p" variant="bodyMd">
                    {formatMoney(trade.trade.user_amount, "", trade.trade.currency)}
                  </Text>
                </Stack>

                <Stack distribution="equalSpacing">
                  <Text as="p" variant="bodyMd">
                    Fees
                  </Text>

                  <Text as="p" variant="bodyMd">
                    {formatMoney(trade.trade.fee, "", trade.trade.currency)}
                  </Text>
                </Stack>

                <Stack distribution="equalSpacing">
                  <Text as="p" variant="bodyMd">
                    {Deposit
                      ? "Merchant will receive in Bank / Business will transfer"
                      : "Business will receive in Bank / Merchant will transfer"}
                  </Text>

                  <Text as="p" variant="bodyMd">
                    {formatMoney(trade.trade.user_amount, "", trade.trade.currency)}
                  </Text>
                </Stack>

                <Stack distribution="equalSpacing">
                  <Text as="p" variant="bodyMd">
                    {Deposit
                      ? "Business wallet will be credited / Merchant will be debited"
                      : "Business wallet will be debited / Merchant will be credited"}
                  </Text>

                  <Text as="p" variant="bodyMd">
                    {formatMoney(trade.trade.merchant_amount, "", trade.trade.currency)}
                  </Text>
                </Stack>
              </Stack>
            </Card.Section>
          </Card>
          <ConnectTimeline event={trade.trade} />
        </Layout.Section>
        <Layout.Section secondary>
          <BusinessDetails trade={trade} />
          <MerchantDetails trade={trade} />
        </Layout.Section>
      </Layout>
    </>
  );
}
