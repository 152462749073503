import * as React from "react";
import { Badge, Card, IndexTable, Link, Text } from "@shopify/polaris";
import { useParams } from "react-router-dom";

import LoaderContainer from "app/components/LoaderContainer";
import useGetPaymentLinks from "app/hooks/useGetPaymentLinks";
import { formatDateStr } from "app/utils/helpers";
import { CellPadding, TableWrapper } from "app/styles";

export function Donations() {
  const { id } = useParams();

  const { data, isLoading, refetch, error, setQuery } = useGetPaymentLinks();

  React.useEffect(() => {
    if (id) {
      setQuery({ business_id: id, type: "donation", limit: 5 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Card>
      <LoaderContainer modal loading={isLoading} onRetryClicked={refetch} error={error}>
        <Card.Section fullWidth>
          <TableWrapper>
            <IndexTable
              headings={[
                { title: "Ref. Number" },
                { title: "Donation name" },
                { title: "Date created" },
                { title: "Status" },
              ]}
              itemCount={data?.pagination?.current_entries_size || 0}
              selectable={false}
              emptyState={
                <Text as="p" alignment="center" variant="bodySm">
                  This business is yet to create a donation.
                </Text>
              }
            >
              {data?.data.map((paymentLink, index) => {
                return (
                  <IndexTable.Row key={index} id={index.toString()} position={index}>
                    <IndexTable.Cell>
                      <Link removeUnderline url={`/donations/${paymentLink.id}`}>
                        <CellPadding>DBE5GG</CellPadding>
                      </Link>
                    </IndexTable.Cell>

                    <IndexTable.Cell>{paymentLink.name}</IndexTable.Cell>
                    <IndexTable.Cell>
                      {formatDateStr(paymentLink.created_at, "MMMM D, YYYY [at] hh:mma")}
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Badge status={paymentLink.deleted_at ? "critical" : "success"}>
                        {paymentLink.deleted_at ? "Inactive" : "Active"}
                      </Badge>
                    </IndexTable.Cell>
                  </IndexTable.Row>
                );
              })}
            </IndexTable>
          </TableWrapper>
        </Card.Section>
      </LoaderContainer>
    </Card>
  );
}
