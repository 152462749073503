import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import InvoiceService from "app/api/invoices";
import parseError from "app/lib/parseError";

interface Props {
  invoiceId: string;
  enabled?: boolean;
}
export default function useGetInvoice({ invoiceId, enabled }: Props) {
  const { data, isLoading, error, refetch } = useQuery(
    ["invoices", invoiceId],
    () => InvoiceService.getInvoice(invoiceId),
    { enabled }
  );

  return {
    invoice: data?.data.data,
    isLoading,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
  };
}
