import styled from "@emotion/styled";
import { Avatar, Card, Link, Stack, Text } from "@shopify/polaris";
import { TradeDetails } from "app/models/connect";
import useGetPaymentAccountDetails from "../hooks/useGetAccountDetails";
import LoaderContainer from "app/components/LoaderContainer";
import { capitalizeStr } from "app/utils/helpers";

interface Props {
  trade: TradeDetails;
}

export default function BusinessDetails(props: Props) {
  const { trade } = props;
  const { account, isLoading, error, refetch } = useGetPaymentAccountDetails({
    accountId: trade.trade.user_payment_account_id,
  });

  let businessData = trade.business;

  return (
    <Card>
      {businessData && (
        <Card.Section
          title={
            <Stack distribution="equalSpacing" alignment="center">
              <Text variant="headingXs" as="h4">
                BUSINESS
              </Text>
              <Avatar initials={capitalizeStr(businessData.name[0])} size="small" />
            </Stack>
          }
        >
          <Link removeUnderline url={`/businesses/${trade.business.id}`}>
            <InnerText>{businessData.name}</InnerText>
          </Link>

          <InnerText>{businessData.support_email}</InnerText>
        </Card.Section>
      )}

      <Card.Section
        title={
          <Text as="h3" variant="headingXs">
            {trade.trade.type === "deposit" ? "PAYMENT ACCOUNT" : "RECEIVING ACCOUNT"}
          </Text>
        }
      >
        <LoaderContainer
          small
          loading={isLoading}
          onRetryClicked={refetch}
          error={error}
          spinnerSize="small"
        >
          {account && (
            <>
              <InnerText>{account.fields.bank_name}</InnerText>
              <InnerText>{account.fields.account_name}</InnerText>
              <InnerText>{account.fields.account_number}</InnerText>
            </>
          )}
        </LoaderContainer>
      </Card.Section>
    </Card>
  );
}

const InnerText = styled.p`
  margin-top: 4px;
`;
