import { Card, Page } from "@shopify/polaris";

import useGoBack from "app/hooks/useGoBack";
import DevicesTabs from "./components/DevicesTabs";

export default function MerchantDevices() {
  const goBack = useGoBack();

  return (
    <Page title="Account Devices" breadcrumbs={[{ onAction: goBack }]}>
      <Card>
        <DevicesTabs />
      </Card>
    </Page>
  );
}
