import styled from "@emotion/styled";
import { Badge, IndexTable, Link } from "@shopify/polaris";
import { formatMoney } from "app/lib/money";

import PaymentLink from "app/models/paymentLink";
import { formatDateStr } from "app/utils/helpers";

interface Props {
  paymentLinks: PaymentLink[];
}
export default function PaymentLinksList(props: Props) {
  const { paymentLinks } = props;

  return (
    <>
      {paymentLinks.map((paymentLink, i) => {
        return (
          <IndexTable.Row id={paymentLink.id} key={paymentLink.id} position={i}>
            <IndexTable.Cell>
              <CellPadding>
                <Link
                  removeUnderline
                  url={`/payment-links/${paymentLink.id}?business_id=${paymentLink.business_id}`}
                >
                  CHK-{paymentLink.id.slice(0, 6)}
                </Link>
              </CellPadding>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <CellPadding>{paymentLink.name}</CellPadding>
            </IndexTable.Cell>
            <IndexTable.Cell>
              {formatDateStr(paymentLink.created_at, "MMMM D, YYYY [at] hh:mma")}
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Link removeUnderline url={`/businesses/${paymentLink.business_id}`}>
                {paymentLink.business_name}
              </Link>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <CellPadding>
                <Badge status={paymentLink.deleted_at ? "critical" : "success"}>
                  {paymentLink.deleted_at ? "Inactive" : "Active"}
                </Badge>
              </CellPadding>
            </IndexTable.Cell>
            <IndexTable.Cell>
              {paymentLink.local_amount
                ? formatMoney(
                    Number(paymentLink.local_amount),
                    "",
                    paymentLink.local_currency
                  )
                : " 0.00"}
            </IndexTable.Cell>
          </IndexTable.Row>
        );
      })}
    </>
  );
}

const CellPadding = styled.div`
  padding: 13px 0px;
`;
