import { useInfiniteQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import TeamsService from "app/api/teams";
import { GetTeamsQuery } from "app/api/teams/types";

export default function useGetTeamActivities(businessId: string, params: GetTeamsQuery) {
  const fetchItemsWithCursor = ({ pageParam = undefined }) =>
    TeamsService.getTeamActivities(businessId, {
      ...params,
      cursor: pageParam,
    });

  const {
    isLoading,
    error,
    isFetching,
    data,
    hasNextPage,
    hasPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    refetch,
  } = useInfiniteQuery(
    ["teamActivities", ...Object.values(params), businessId],
    fetchItemsWithCursor,
    {
      keepPreviousData: true,
      getNextPageParam: lastPage => lastPage.data.next_cursor,
      getPreviousPageParam: firstPage => firstPage.data.next_cursor,
    }
  );

  return {
    hasNextPage,
    hasPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isLoading,
    isFetching,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    data: data?.pages,
    refetch,
    fetchNextPage,
    fetchPreviousPage,
  };
}
