import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import PaymentsService from "app/api/payments";
import { useToast } from "app/providers/ToastProvider";

export default function useGetMissingPaymentsCount() {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const {
    isLoading,
    mutate: refetchMissingPayment,
    data,
  } = useMutation(() => PaymentsService.getMissingPaymentsCount(), {
    onSuccess: res => {
      queryClient.invalidateQueries(["payments"]);
      showToast(res.data.message || "", "success");
    },
    onError: error => {
      const errorMessage = parseError(error as AxiosError<{}>);
      showToast(errorMessage as string, "error");
    },
  });

  return {
    isLoading,
    refetchMissingPayment,
    data: data?.data,
  };
}
