import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import AnalyticsService from "app/api/analytics";
import { GetAnalyticsParams } from "app/api/analytics/types";

export default function useGetInvoiceAnalytics(params?: GetAnalyticsParams) {
  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["invoice_analytics", params],
    () => AnalyticsService.getInvoiceAnalytics(params),

    {
      select(response) {
        const { data } = response.data;

        return data;
      },
    }
  );

  return {
    isLoading,
    isFetching,
    invoiceAnalytics: data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
  };
}
