import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import { GetPaymentsQuery } from "app/api/payments/types";
import PaymentsService from "app/api/payments";

interface Props {
  params?: GetPaymentsQuery;
}
export default function useGetPayments({ params = {} }: Props) {
  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["payments", params],
    () => PaymentsService.getPayments(params),
    {
      select(response) {
        const { groot_deposits, ...rest } = response.data;
        rest.data = groot_deposits;
        return rest;
      },
    }
  );

  return {
    isLoading,
    isFetching,
    payments: data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
  };
}
