import styled from "@emotion/styled";
import { Card, Stack, Subheading, Avatar, Link } from "@shopify/polaris";
import { Withdrawal } from "app/models/withdrawal";

interface Props {
  withdrawal: Withdrawal;
}

function getInitials(name: string) {
  const nameChunk = String(name).split(" ");
  return `${nameChunk[0][0]}${nameChunk[1][0]}`;
}

export default function WithdrawalMerchant(props: Props) {
  const { withdrawal } = props;

  return (
    <Card>
      <Card.Section
        title={
          <Stack distribution="equalSpacing" alignment="center">
            <Subheading>MERCHANT</Subheading>
            <Avatar
              initials={getInitials(withdrawal.sender_name)}
              size="extraSmall"
            />
          </Stack>
        }
      >
        <Space>
          <Link removeUnderline url={`/merchants/${withdrawal.sender_id}`}>
            {withdrawal.sender_name}
          </Link>
        </Space>
      </Card.Section>
    </Card>
  );
}
const Space = styled.div`
  margin-top: 8px;
`;
