import * as React from "react";
import {
  Icon,
  IconSource,
  Listbox,
  Popover,
  TextField,
} from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import styled from "@emotion/styled";
import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import debounce from "lodash.debounce";
import { useSearchParams } from "react-router-dom";

interface Option {
  label: string;
  value: string;
}

interface Props {
  options: Option[];
  placeholder?: string;
}
export default function SearchFilter(props: Props) {
  const { options, placeholder } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const [popoverActive, setPopoverActive] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState<Option>();
  const [inputValue, setInputValue] = React.useState("");

  const updateQueryParams = React.useCallback(
    (params: { searchOption?: string; searchTerm: string }) => {
      const { searchOption, searchTerm } = params;

      // Prevents url update if search option is selected and search term is not defined
      if (!searchTerm && searchOption && searchParams.has(searchOption)) {
        searchParams.delete(searchOption);
        setSearchParams(searchParams);
        return;
      }

      // Update url only if search term and search term are defined
      if (searchOption && !searchTerm) return;

      const optionsKeys = options.map((option) => option.value);
      const keyInUrl = optionsKeys.find((key) => searchParams.has(key));

      if (keyInUrl) {
        searchParams.delete(keyInUrl);
      }

      if (searchOption) {
        searchParams.set(searchOption, searchTerm);
      }

      setSearchParams(searchParams);
    },
    [options, searchParams, setSearchParams]
  );

  const debouncedQueryUpdate = React.useMemo(
    () =>
      debounce(
        (searchString) =>
          updateQueryParams({
            searchOption: selectedOption?.value,
            searchTerm: searchString,
          }),
        1000
      ),
    [selectedOption?.value, updateQueryParams]
  );

  useUpdatedEffect(() => {
    debouncedQueryUpdate(inputValue);
  }, [inputValue]);

  return (
    <div className="">
      <Popover
        active={popoverActive}
        activator={
          <TextField
            autoComplete="off"
            onChange={(val) => setInputValue(val)}
            label=""
            labelHidden
            onFocus={() => setPopoverActive(true)}
            value={inputValue}
            placeholder={placeholder ?? "Search reference or customer details"}
            prefix={<Icon source={SearchMinor as IconSource} />}
          />
        }
        preferInputActivator={false}
        onClose={() => setPopoverActive((prev) => !prev)}
        fullWidth
        fullHeight
      >
        <Padding>
          <Listbox
            onSelect={(value) => {
              const option = options.find((opt) => opt.value === value);

              if (option) {
                setSelectedOption(option);
                updateQueryParams({
                  searchOption: option.value,
                  searchTerm: inputValue,
                });
              }
            }}
          >
            {options.map((option) => (
              <Listbox.Option
                key={option.value}
                value={option.value}
                selected={selectedOption?.value === option.value}
                accessibilityLabel={option.label}
              >
                {option.label}
              </Listbox.Option>
            ))}
          </Listbox>
        </Padding>
      </Popover>
    </div>
  );
}

const Padding = styled.div`
  padding-block: 8px;
`;
