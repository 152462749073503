import * as React from "react";
import {
  Card,
  Form,
  FormLayout,
  Modal,
  TextField,
  Select,
} from "@shopify/polaris";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { CreateUserPayload } from "app/api/users/types";
import roles from "app/constants/roles";
import useCreateUser from "../hooks/useCreateUser";
import { useToast } from "app/providers/ToastProvider";

interface Props {
  open: boolean;
  onClose: () => void;
}
export default function AddUserModal(props: Props) {
  const { open, onClose } = props;

  const { createUser, error, loading, response } = useCreateUser();

  const showToast = useToast();

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<CreateUserPayload>({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      role: roles[0].value,
    },

    resolver: yupResolver(
      Yup.object().shape({
        first_name: Yup.string().required("First name is required"),
        last_name: Yup.string().required("Last name is required"),
        email: Yup.string()
          .required("Email is required")
          .email("Email is not valid"),
        role: Yup.string().required("Role is required"),
      })
    ),
  });

  const onCancel = () => {
    reset();
    onClose();
  };

  React.useEffect(() => {
    if (response) {
      showToast(response, "success");
      onClose();
      reset();
    }
  }, [response, showToast, onClose, reset]);

  React.useEffect(() => {
    if (error) {
      showToast(error, "error");
    }
  }, [error, showToast]);

  return (
    <Modal
      title="Add new user"
      open={open}
      onClose={onCancel}
      primaryAction={{
        content: "Add user",
        onAction: handleSubmit(createUser),
        loading,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onCancel,
        },
      ]}
    >
      <Modal.Section>
        <Card subdued>
          <Card.Section>
            <p>
              Give staff access to this platform by sending them an invitation
            </p>
          </Card.Section>
          <Card.Section>
            <Form onSubmit={handleSubmit(createUser)}>
              <FormLayout>
                <FormLayout.Group>
                  <Controller
                    name="first_name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete=""
                        label="First name"
                        name="first_name"
                        value={field.value}
                        onChange={(v) => setValue("first_name", v)}
                        error={errors.first_name?.message}
                      />
                    )}
                  />
                  <Controller
                    name="last_name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete=""
                        label="Last name"
                        name="last_name"
                        value={field.value}
                        onChange={(v) => setValue("last_name", v)}
                        error={errors.last_name?.message}
                      />
                    )}
                  />
                </FormLayout.Group>

                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete=""
                      label="Email"
                      name="email"
                      value={field.value}
                      onChange={(v) => setValue("email", v)}
                      error={errors.email?.message}
                    />
                  )}
                />
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <Select
                      label="Role"
                      options={roles}
                      name="role"
                      value={field.value}
                      onChange={(v) => setValue("role", v)}
                      error={errors.role?.message}
                    />
                  )}
                />
              </FormLayout>
            </Form>
          </Card.Section>
        </Card>
      </Modal.Section>
    </Modal>
  );
}
