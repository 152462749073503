import request from "../request";
import { BaseResponse } from "../types";
import { ListSettlementsResponse, UpdateSettlementPayload } from "./types";

const listSettlements = (businessId: string) => {
  return request.get<ListSettlementsResponse>(`/v1/businesses/${businessId}/settlements`);
};

const updateSettlement = ({
  businessId,
  settlementId,
  ...payload
}: UpdateSettlementPayload) => {
  return request.put<BaseResponse>(
    `/v1/businesses/${businessId}/settlements/${settlementId}`,
    payload
  );
};

const SettlementService = {
  listSettlements,
  updateSettlement,
};

export default SettlementService;
