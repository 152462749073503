import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import MerchantService from "app/api/merchants";
import { UpdateMerchantAddressPayload } from "app/api/merchants/type";

export default function useUpdateMerchantAddress(merchantId: string) {
  const queryClient = useQueryClient();

  const { mutate, isLoading, data, error } = useMutation(
    MerchantService.updateMerchantAddress,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["merchant"]);
      },
    }
  );
  const updateAddress = (payload: UpdateMerchantAddressPayload) => {
    mutate({
      merchantId,
      payload,
    });
  };

  return {
    updateAddress,
    loading: isLoading,
    response: data?.data.message,
    error: error ? (parseError(error as AxiosError<{}>) as string) : null,
  };
}
