import { Card, Stack, Text } from "@shopify/polaris";
import Settlement from "app/models/settlement";
import { capitalizeStr } from "app/utils/helpers";

interface Props {
  settlement: Settlement;
}
export default function SettlementDetails(props: Props) {
  const { settlement } = props;

  return (
    <>
      <Card.Section>
        <Stack spacing="tight" vertical>
          <Text as="p" variant="bodyMd" fontWeight="medium">
            Status
          </Text>

          <Text as="p" color="subdued" variant="bodyMd">
            {settlement.disabled_at ? "Disabled" : "Enabled"}
          </Text>
        </Stack>
      </Card.Section>
      <Card.Section>
        <Stack spacing="tight" vertical>
          <Text as="p" variant="bodyMd" fontWeight="medium">
            Payout account
          </Text>

          <Stack spacing="tight" vertical>
            <Text as="p" color="subdued" variant="bodyMd">
              {settlement.payment_account.fields.account_name}
            </Text>
            <Text as="p" color="subdued" variant="bodyMd">
              {settlement.payment_account.fields.account_number}
            </Text>
            <Text as="p" color="subdued" variant="bodyMd">
              {settlement.payment_account.fields.bank_name}
            </Text>
          </Stack>
        </Stack>
      </Card.Section>
      <Card.Section>
        <Stack spacing="tight" vertical>
          <Text as="p" variant="bodyMd" fontWeight="medium">
            Payout percentage
          </Text>

          <Text as="p" color="subdued" variant="bodyMd">
            {settlement.percentage}%
          </Text>
        </Stack>
      </Card.Section>
      <Card.Section>
        <Stack spacing="tight" vertical>
          <Text as="p" variant="bodyMd" fontWeight="medium">
            Frequency
          </Text>

          <Text as="p" color="subdued" variant="bodyMd">
            {capitalizeStr(settlement.frequency)}
          </Text>
        </Stack>
      </Card.Section>
    </>
  );
}
