import styled from "@emotion/styled";
import {
  DisplayText,
  FormLayout,
  Stack,
  TextStyle,
  TextField,
  Button,
} from "@shopify/polaris";
import { Link } from "react-router-dom";

interface Props {
  onSubmit: () => void;
}
export default function ForgotPasswordForm(props: Props) {
  return (
    <Stack vertical spacing="loose">
      <Stack vertical spacing="tight">
        <DisplayText element="h2" size="medium">
          <TextStyle variation="strong">Reset Password</TextStyle>
        </DisplayText>
        <p>
          <TextStyle variation="subdued">
            Enter the email associated with your account <br /> and you'll get
            instructions on how to reset <br /> your password
          </TextStyle>
        </p>
      </Stack>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          props.onSubmit();
        }}
      >
        <FormLayout>
          <TextField
            autoComplete=""
            labelHidden
            label="Email"
            placeholder="Enter email"
            value=""
            onChange={() => {}}
          />
          <Button submit primary>
            Reset
          </Button>
        </FormLayout>
        <Footer>
          <p>
            Remembered your password?{" "}
            <Link to="/login">
              <TextStyle variation="subdued">Go Home</TextStyle>
            </Link>
          </p>
        </Footer>
      </Form>
    </Stack>
  );
}

const Form = styled.form`
  .Polaris-Button--plain > .Polaris-Button__Content {
    color: var(--p-text-subdued);
  }
`;

const Footer = styled.div`
  margin-top: 45px;
  a {
    text-decoration: none;
  }
`;
