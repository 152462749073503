import { Modal, Stack, Text } from "@shopify/polaris";

import useSetPrimaryBankAccount from "./hooks/useSetPrimaryBankAccount";
import useRemoveBankAccount from "./hooks/useRemoveBankAccount";
import ManageBankAccountDropdown from "./ManageBankAccountDropdown";
import { PaymentAccount } from "app/models/paymentAccount";

interface Props {
  numOfBanks: PaymentAccount[];
}

export default function BankAccountsList(props: Props) {
  const { numOfBanks } = props;

  const { setPrimaryPaymentAccount } = useSetPrimaryBankAccount();
  const { removePaymentAccount } = useRemoveBankAccount();

  return (
    <>
      {numOfBanks.map(bank => {
        const accountName = bank.fields.find(
          b => b.display_name === "Account Name"
        )?.value;

        const accountNumber = bank.fields.find(
          b => b.display_name === "Account Number"
        )?.value;

        const bankName = bank.fields.find(b => b.display_name === "Bank Name")?.value;

        return (
          <Modal.Section key={bank.id}>
            <Stack spacing="loose">
              <Stack.Item fill>
                <Text as="p" variant="bodyMd" fontWeight="semibold">
                  {accountName}
                </Text>
                <Text as="p" variant="bodyMd">
                  {accountNumber}
                </Text>
                <Text as="p" variant="bodyMd">
                  {bankName}
                </Text>
              </Stack.Item>
              <Stack.Item>
                <ManageBankAccountDropdown
                  onRemove={callback =>
                    removePaymentAccount(
                      {
                        businessId: bank.business_id,
                        paymentAccountId: bank.id,
                      },
                      { onSuccess: callback }
                    )
                  }
                  onSetPrimary={callback =>
                    setPrimaryPaymentAccount(
                      {
                        businessId: bank.business_id,
                        paymentAccountId: bank.id,
                      },
                      { onSuccess: callback }
                    )
                  }
                  active={bank.is_default}
                />
              </Stack.Item>
            </Stack>
          </Modal.Section>
        );
      })}
    </>
  );
}
