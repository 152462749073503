import request from "../request";
import {
  AddCurrencyPayload,
  GetCurrenciesQuery,
  GetCurrenciesResponse,
} from "./types";
import Currency from "app/models/currency";

import { GenericResponse } from "../types";

const getCurrencies = async (params?: GetCurrenciesQuery) => {
  return request.get<GetCurrenciesResponse>("/v1/businesses/currencies", {
    params,
  });
};

const getCurrency = async (code: string) => {
  return request.get<Currency>(`/v1/businesses/currencies/${code}`);
};

const addCurrency = async (data: AddCurrencyPayload) => {
  return request.post<GenericResponse<Currency>>(
    "/v1/businesses/currencies",
    data
  );
};

const updateCurrency = async (code: string, data: AddCurrencyPayload) => {
  return request.put<GenericResponse<Currency>>(
    `/v1/businesses/currencies/${code}`,
    data
  );
};

const generateCurrencyDetails = async (codeAssetName: string) => {
  return request.get<Currency>(
    `/v1/businesses/currencies/${codeAssetName}/fetch`
  );
};

const toggleCurrencyStatus = async (code: string, status: string) => {
  return request.put<GenericResponse<Currency>>(
    `/v1/businesses/currencies/${code}/status/${status}`
  );
};

const toggleCurrencyDepositStatus = async (code: string) => {
  return request.put<GenericResponse<Currency>>(
    `/v1/businesses/currencies/${code}/deposit`
  );
};

const toggleCurrencyPayoutStatus = async (code: string) => {
  return request.put<GenericResponse<Currency>>(
    `/v1/businesses/currencies/${code}/payout`
  );
};

const toggleCurrencyVIPStatus = async (code: string) => {
  return request.put<GenericResponse<Currency>>(`/v1/currencies/${code}/vip`);
};

const CurrencyService = {
  getCurrencies,
  getCurrency,
  addCurrency,
  updateCurrency,
  generateCurrencyDetails,
  toggleCurrencyStatus,
  toggleCurrencyDepositStatus,
  toggleCurrencyPayoutStatus,
  toggleCurrencyVIPStatus,
};

export default CurrencyService;
