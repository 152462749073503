import * as React from "react";
import { ChoiceList, FilterInterface, Filters } from "@shopify/polaris";
import debounce from "lodash.debounce";

import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import { GetCurrenciesQuery } from "app/api/currencies/types";
import { CurrencyType } from "app/models/currency";
import currencyTypes from "app/constants/currencyTypes";

interface Props {
  query: GetCurrenciesQuery;
  setQuery: (q: GetCurrenciesQuery) => void;
}

export default function CurrenciesFilter(props: Props) {
  const { query, setQuery } = props;

  const [queryString, setQueryString] = React.useState(query.q);

  const debouncedQueryUpdate = React.useMemo(
    () =>
      debounce((searchString) => setQuery({ q: searchString, page: 1 }), 1000),
    [setQuery]
  );

  useUpdatedEffect(() => {
    debouncedQueryUpdate(queryString);
  }, [queryString]);

  const filters: FilterInterface[] = [
    {
      label: "Type",
      key: "type",
      filter: (
        <ChoiceList
          title="Status"
          name="status"
          titleHidden
          choices={currencyTypes}
          selected={[query.type || ""]}
          onChange={(t) => setQuery({ type: t[0] as CurrencyType })}
        />
      ),
      shortcut: true,
    },
  ];

  const removeFilter = React.useCallback(
    (filter: string) => {
      setQuery({ ...query, [filter]: undefined });
    },
    [setQuery, query]
  );

  const appliedFilters = filters
    .filter(
      (filter) => query[filter.key as keyof GetCurrenciesQuery] !== undefined
    )
    .map((filter) => ({
      ...filter,
      label: `${filter.label}: ${
        query[filter.key as keyof GetCurrenciesQuery]
      }`,
      onRemove: removeFilter,
    }));

  return (
    <Filters
      filters={filters}
      appliedFilters={appliedFilters}
      focused
      queryValue={queryString}
      onQueryChange={setQueryString}
      onQueryClear={() => setQueryString("")}
      queryPlaceholder="Search by name"
      onClearAll={() =>
        setQuery({
          type: undefined,
          q: undefined,
          sort: undefined,
          status: undefined,
          page: 1,
          limit: undefined,
        })
      }
    />
  );
}
