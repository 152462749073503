import { Layout, Loading, Page } from "@shopify/polaris";
import LoaderContainer from "app/components/LoaderContainer";
import useGoBack from "app/hooks/useGoBack";
import { useParams } from "react-router-dom";
import AccessSection from "./components/AccessSection";

import AccountInfoSection from "./components/AccountInfoSection";
import PermissionSection from "./components/PermissionSection";
import useGetUser from "./hooks/useGetUser";
import useSendUserInvite from "./hooks/useSendUserInvite";

export default function UserDetails() {
  const goBack = useGoBack();

  const { id } = useParams();

  const {
    data: profile,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useGetUser(String(id));

  const { sendUserInvite, sendingUserInvite } = useSendUserInvite(String(id));

  return (
    <Page
      title={profile ? `${profile.first_name} ${profile.last_name}` : "..."}
      divider
      breadcrumbs={[{ onAction: goBack }]}
      primaryAction={
        profile && !profile.verified
          ? {
              content: "Send Invite",
              onAction: sendUserInvite,
              loading: sendingUserInvite,
            }
          : undefined
      }
    >
      {isFetching && <Loading />}
      <LoaderContainer
        loading={isLoading}
        error={error}
        onRetryClicked={refetch}
      >
        {profile && (
          <Layout>
            <AccountInfoSection profile={profile} />
            <PermissionSection profile={profile} />
            <AccessSection profile={profile} />
          </Layout>
        )}
      </LoaderContainer>
    </Page>
  );
}
