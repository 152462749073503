import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import MerchantService from "app/api/merchants";
import { ListMerchantsQuery } from "app/api/merchants/type";
import parseError from "app/lib/parseError";
import useQueryParams from "app/hooks/useQueryParams";

export default function useListMerchants() {
  const { queryParams, updateQueryParams } = useQueryParams<ListMerchantsQuery>({
    sort: "desc",
    page: 1,
    limit: 10,
  });

  const { data, isLoading, isFetching, error, refetch } = useQuery(
    ["merchants", queryParams],
    () => MerchantService.listMerchants(queryParams)
  );

  const gotoNextPage = () => {
    if (data) {
      updateQueryParams("page", data.data.pagination.page + 1);
    }
  };

  const gotoPrevPage = () => {
    if (data) {
      updateQueryParams("page", data.data.pagination.page - 1);
    }
  };

  return {
    merchants: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    isLoading,
    isFetching,
    refetch,
    gotoNextPage,
    gotoPrevPage,
    setQuery: updateQueryParams,
    query: queryParams,
  };
}
