import { Card, IndexTable, Page, Pagination, Select, Stack } from "@shopify/polaris";

import LoaderContainer from "app/components/LoaderContainer";
import GenericDate from "app/components/GenericDate";
import PaymentLinksFilter from "./components/PaymentLinksFilter";
import PaymentLinksList from "./components/PaymentLinksList";
import useGetPaymentLinks from "app/hooks/useGetPaymentLinks";

export default function PaymentLinks() {
  const { data, isLoading, error, refetch, gotoNextPage, gotoPrevPage, query, setQuery } =
    useGetPaymentLinks("fixed_price");

  return (
    <Page title="Payment Links">
      <Card>
        <>
          <Card.Section>
            <Stack vertical>
              <PaymentLinksFilter query={query} setQuery={setQuery} />

              <Stack distribution="equalSpacing" alignment="center">
                <Stack spacing="extraLoose" alignment="center">
                  <GenericDate
                    onDateChange={value => {
                      setQuery({ date: value });
                    }}
                    onReset={() => setQuery({ date: "" })}
                    value={query.date}
                  />

                  <p>
                    Showing {data?.pagination.current_entries_size ?? 0} payment links
                  </p>
                </Stack>

                <Select
                  labelInline
                  label="Sort by"
                  name="sort"
                  value={query.sort}
                  options={[
                    { label: "Newest", value: "desc" },
                    { label: "Oldest", value: "asc" },
                  ]}
                  onChange={s => setQuery("sort", s)}
                />
              </Stack>
            </Stack>
          </Card.Section>

          <LoaderContainer
            page
            loading={isLoading}
            error={error}
            onRetryClicked={refetch}
          >
            {data && (
              <IndexTable
                selectable={false}
                resourceName={{ plural: "payment links", singular: "payment link" }}
                itemCount={data.data.length}
                headings={[
                  { title: "Ref Number" },
                  { title: "Product Name" },
                  { title: "Creation Date" },
                  { title: "Business" },
                  { title: "Status" },
                  { title: "Amount" },
                ]}
              >
                <PaymentLinksList paymentLinks={data.data} />
              </IndexTable>
            )}
          </LoaderContainer>

          {data && (
            <Card.Section>
              <Stack distribution="center">
                <Pagination
                  label={
                    <p>
                      {data.pagination.page} /{data.pagination.total_pages}
                    </p>
                  }
                  hasNext={data.pagination.total_pages > data.pagination.page}
                  onNext={gotoNextPage}
                  hasPrevious={data.pagination.page > 1}
                  onPrevious={gotoPrevPage}
                />
              </Stack>
            </Card.Section>
          )}
        </>
      </Card>
    </Page>
  );
}
