import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import TransactionsServices from "app/api/transactions";
import { GetPaymentLinkTransactionsQuery } from "app/api/transactions/types";

export default function useGetTransaction(params: GetPaymentLinkTransactionsQuery) {
  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["paymentLink-transactions", params],
    () => TransactionsServices.getPaymentLinkTransaction(params)
  );

  return {
    isLoading,
    isFetching,
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
  };
}
