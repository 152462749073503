import AnalyticsCard from "./AnalyticsCard";
import useGetPayments from "app/hooks/useGetPayments";

export default function TotalPayments() {
  const { payments, isLoading, error, refetch } = useGetPayments({});

  const paymentsData = payments?.pagination.total_entries_size ?? "";

  return (
    <AnalyticsCard
      title="Total payments"
      viewLink="/payments"
      amount={paymentsData}
      loading={isLoading}
      error={error}
      refetch={refetch}
    />
  );
}
