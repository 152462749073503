import request from "../request";
import {
  AddBookPayload,
  GenerateBookFromCurrencyPayload,
  GetBookExchangesResponse,
  GetBooksQuery,
  GetBooksResponse,
} from "./types";
import { GenericResponse } from "../types";
import Book from "app/models/book";

const getBooks = async (params?: GetBooksQuery) => {
  return request.get<GetBooksResponse>("/v1/books", {
    params,
  });
};

const getBook = async (id: string) => {
  return request.get<Book>(`/v1/books/${id}`);
};

const addBook = async (data: AddBookPayload) => {
  return request.post<GenericResponse<Book>>("/v1/books", data);
};

const updateBook = async (id: string, data: AddBookPayload) => {
  return request.put<GenericResponse<Book>>(`/v1/books/${id}`, data);
};

const getExchanges = async () => {
  return request.get<GetBookExchangesResponse>("/v1/books/exchange");
};

const getOrderExchanges = async () => {
  return request.get<GetBookExchangesResponse>("/v1/books/order-exchange");
};

const generateBookFromCurrency = async ({
  asset,
  ...payload
}: GenerateBookFromCurrencyPayload) => {
  return request.post<GenericResponse<Book>>(
    `/v1/books/${asset}/generate`,
    payload
  );
};

const BookService = {
  getBooks,
  getBook,
  addBook,
  updateBook,
  getExchanges,
  getOrderExchanges,
  generateBookFromCurrency,
};

export default BookService;
