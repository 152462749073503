import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import ProfileServices from "app/api/profile";
import parseError from "app/lib/parseError";

export default function useInitiateTwoFA() {
  const initiate2FAQuery = useQuery(["two-fa"], ProfileServices.initiate2FA);

  return {
    initiating2FA: initiate2FAQuery.isLoading,
    initiate2FAResponse: initiate2FAQuery.data?.data,
    initiate2FAError: initiate2FAQuery.error
      ? (parseError(initiate2FAQuery.error as AxiosError<{}>) as string)
      : null,
    refetch: initiate2FAQuery.refetch,
  };
}
