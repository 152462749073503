import { BadgeProps } from "@shopify/polaris";

export function mapTeamStatus(status: string) {
  const statusMap: { [key: string]: BadgeProps["status"] } = {
    pending: "warning",
    active: "success",
    suspended: "critical",
  };

  return statusMap[String(status).toLocaleLowerCase()];
}
