import * as React from "react";
import { Avatar, Card, Inline, Link, Stack, Text } from "@shopify/polaris";

import Business from "app/models/business";
import useGetTeamMembers from "../hooks/useGetTeamMembers";
import { capitalizeStr } from "app/utils/helpers";
import LoaderContainer from "app/components/LoaderContainer";
import useGetTeamActivities from "app/views/TeamActivities/hooks/useGetTeamActivities";

interface Props {
  business: Business;
}
export default function TeamMembersPreview(props: Props) {
  const { business } = props;

  const { data, isLoading, error, refetch } = useGetTeamMembers(business.id, {
    limit: 5,
  });

  const {
    data: activityData,
    isLoading: loadingActivities,
    refetch: refetchActivities,
    error: activitiesError,
  } = useGetTeamActivities(business.id, {});

  const currentActivityData = React.useMemo(
    () => (activityData ? activityData.flatMap(page => page.data.data) : []),
    [activityData]
  );

  const theLastActivity = currentActivityData.length - 1;

  const teamsData = data?.data;

  return (
    <Card>
      <Card.Section
        title={
          <div style={{ marginBottom: 18 }}>
            <Inline align="space-between">
              <Text as="h3" variant="headingXs">
                TEAM MEMBERS
              </Text>
              <Link url={`/businesses/${business.id}/team`} removeUnderline>
                View
              </Link>
            </Inline>
          </div>
        }
      >
        <LoaderContainer
          small
          loading={isLoading}
          onRetryClicked={refetch}
          error={error}
          spinnerSize="small"
        >
          {teamsData ? (
            <Inline align="start">
              {teamsData.map((data, index) => (
                <Avatar
                  size="small"
                  key={index}
                  initials={`${capitalizeStr(data.name[0])}${capitalizeStr(
                    data.email[0]
                  )}`}
                />
              ))}
            </Inline>
          ) : (
            "N/A"
          )}
        </LoaderContainer>
      </Card.Section>
      <Card.Section
        title={
          <div style={{ marginBottom: 18 }}>
            <Inline align="space-between">
              <Text as="h3" variant="headingXs">
                TEAM ACTIVITIES
              </Text>
              <Link url={`/businesses/${business.id}/team-activities`} removeUnderline>
                View logs
              </Link>
            </Inline>
          </div>
        }
      >
        <LoaderContainer
          small
          loading={loadingActivities}
          onRetryClicked={refetchActivities}
          error={activitiesError}
          spinnerSize="small"
        >
          {currentActivityData.length > 0 ? (
            <Stack vertical spacing="extraTight">
              <Text as="p" variant="bodyMd" fontWeight="medium">
                {currentActivityData[theLastActivity].name}
              </Text>
              <Text as="p" variant="bodyMd" breakWord color="subdued">
                {currentActivityData[theLastActivity].description}
              </Text>
            </Stack>
          ) : (
            "N/A"
          )}
        </LoaderContainer>
      </Card.Section>
    </Card>
  );
}
