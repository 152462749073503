import request from "../request";
import {
  GetPaymentLinkTransactionsQuery,
  GetPaymentLinkTransactionsResponse,
  GetTransactionsQuery,
  GetTransactionResponse,
  GetTransactionsResponse,
} from "./types";

const getTransactions = async (params?: GetTransactionsQuery) => {
  return request.get<GetTransactionsResponse>("/v1/businesses/transactions", {
    params,
  });
};

const getTransaction = async (transactionId: string) => {
  return request.get<GetTransactionResponse>(
    `/v1/businesses/transactions/${transactionId}`
  );
};

const getPaymentLinkTransactions = async (params?: GetPaymentLinkTransactionsQuery) => {
  return request.get<GetPaymentLinkTransactionsResponse>("/v1/businesses/charges", {
    params,
  });
};

const getPaymentLinkTransaction = async (params?: GetPaymentLinkTransactionsQuery) => {
  return request.get<GetPaymentLinkTransactionsResponse>("/v1/businesses/charges", {
    params,
  });
};

const TransactionsServices = {
  getTransactions,
  getTransaction,
  getPaymentLinkTransactions,
  getPaymentLinkTransaction,
};

export default TransactionsServices;
