import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import ComplianceService from "app/api/compliance";
import { AddDocTypePayload } from "app/api/compliance/types";
import parseError from "app/lib/parseError";
import { useToast } from "app/providers/ToastProvider";

export default function useAddDocumentType() {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (payload: AddDocTypePayload) => ComplianceService.addDocumentType(payload),
    {
      onError: (error: AxiosError<{}>) => {
        const errorMessage = parseError(error) as string;
        showToast(errorMessage || "", "error");
      },
      onSuccess: res => {
        queryClient.invalidateQueries(["document-types"]);
        const message = res.data.message || "";
        showToast(message, "success");
      },
    }
  );

  return { addDocumentType: mutate, isLoading };
}
