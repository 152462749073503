import styled from "@emotion/styled";
import { Badge, IndexTable, Link } from "@shopify/polaris";

import PaymentLink from "app/models/paymentLink";
import { formatDateStr } from "app/utils/helpers";

interface Props {
  paymentLinks: PaymentLink[];
}
export default function DonationsList(props: Props) {
  const { paymentLinks } = props;

  return (
    <>
      {paymentLinks.map((paymentLink, i) => {
        return (
          <IndexTable.Row id={paymentLink.id} key={paymentLink.id} position={i}>
            <IndexTable.Cell>
              <CellPadding>
                <Link
                  removeUnderline
                  url={`/donations/${paymentLink.id}?business_id=${paymentLink.business_id}`}
                >
                  DON-{paymentLink.id.slice(0, 6)}
                </Link>
              </CellPadding>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <CellPadding>{paymentLink.name}</CellPadding>
            </IndexTable.Cell>
            <IndexTable.Cell>
              {formatDateStr(paymentLink.created_at, "MMMM D, YYYY [at] hh:mma")}
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Link removeUnderline url={`/businesses/${paymentLink.business_id}`}>
                {paymentLink.business_name}
              </Link>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <CellPadding>
                <Badge status={paymentLink.active ? "success" : "critical"}>
                  {paymentLink.active ? "Active" : "Inactive"}
                </Badge>
              </CellPadding>
            </IndexTable.Cell>
          </IndexTable.Row>
        );
      })}
    </>
  );
}

const CellPadding = styled.div`
  padding: 13px 0px;
`;
