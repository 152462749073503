import * as React from "react";
import { Link } from "react-router-dom";

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  url?: string;
  external?: boolean;
}

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;

export default function AppLink(props: Props) {
  const { url = "", external, children, ...rest } = props;
  // react-router only supports links to pages it can handle itself. It does not
  // support arbirary links, so anything that is not a path-based link should
  // use a reglar old `a` tag
  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    rest.target = "_blank";
    rest.rel = "noopener noreferrer";
    return (
      <a href={url} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <Link to={url} {...rest}>
      {children}
    </Link>
  );
}
