import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import UserService from "app/api/users";
import { CreateUserPayload } from "app/api/users/types";

export default function useCreateUser() {
  const queryClient = useQueryClient();

  const createUserMutation = useMutation(UserService.createUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
    },
  });
  const createUser = (data: CreateUserPayload) => {
    createUserMutation.mutate(data);
  };

  return {
    createUser,
    loading: createUserMutation.isLoading,
    response: createUserMutation.data?.data.message,
    error: createUserMutation.error
      ? (parseError(createUserMutation.error as AxiosError<{}>) as string)
      : null,
  };
}
