import styled from "@emotion/styled";
import { Badge, Card, IndexTable, Link } from "@shopify/polaris";

import TableWrapper from "app/components/TableWrapper";
import Country from "app/models/country";
import { capitalizeStr, formatDateStr } from "app/utils/helpers";
import { mapCountryOnlineStatusToBadge } from "app/views/Currencies/helpers";

interface Props {
  countries: Country[];
}
export default function CountriesList(props: Props) {
  const { countries } = props;

  return (
    <Card.Section fullWidth>
      <TableWrapper>
        <IndexTable
          headings={[
            { title: "Name" },
            { title: "Country ID" },
            { title: "Currency" },
            { title: "Status" },
            { title: "Date Created" },
          ]}
          itemCount={countries.length}
          selectable={false}
        >
          {countries.map((country, index) => {
            return (
              <IndexTable.Row key={country.id} id={country.id} position={index}>
                <CellPadding>
                  <IndexTable.Cell>
                    <Link
                      url={`/settings/countries/${country.id}`}
                      removeUnderline
                    >
                      {country.name}
                    </Link>
                  </IndexTable.Cell>
                </CellPadding>

                <IndexTable.Cell>{country.id}</IndexTable.Cell>
                <IndexTable.Cell>{country.currency}</IndexTable.Cell>
                <IndexTable.Cell>
                  <Badge
                    status={mapCountryOnlineStatusToBadge(
                      String(country.can_register) as "true" | "false"
                    )}
                  >
                    {capitalizeStr(String(country.can_register))}
                  </Badge>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  {formatDateStr(country.created_at, "MMMM DD, YYYY")}
                </IndexTable.Cell>
              </IndexTable.Row>
            );
          })}
        </IndexTable>
      </TableWrapper>
    </Card.Section>
  );
}

const CellPadding = styled.div`
  padding: 13px 0px;
`;
