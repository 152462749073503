import AuthPageWrapper from "app/components/AuthPageWrapper";
import LoaderContainer from "app/components/LoaderContainer";
import InviteForm from "./components/InviteForm";
import useInvite from "./hooks/useInvite";

export default function Invite() {
  const {
    isRequesting,
    refetch,
    requestError,
    onSubmit,
    isAccepting,
    invite,
    acceptanceError,
  } = useInvite();
  return (
    <LoaderContainer
      loading={isRequesting}
      onRetryClicked={refetch}
      error={requestError}
    >
      <AuthPageWrapper>
        {invite && (
          <InviteForm
            onSubmit={onSubmit}
            loading={isAccepting}
            invite={invite}
            error={acceptanceError}
          />
        )}
      </AuthPageWrapper>
    </LoaderContainer>
  );
}
