import { Global, css } from "@emotion/react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Page, ContextualSaveBar } from "@shopify/polaris";

import useGoBack from "app/hooks/useGoBack";
import { capitalizeStr } from "app/utils/helpers";
import { UpdatePermission } from "app/api/roles/types";
import useGetPermission from "./hooks/useGetPermission";
import LoaderContainer from "app/components/LoaderContainer";
import useUpdatedEffect from "app/hooks/useUpdatedEffect";

import useEditPermission from "./hooks/useEditPermission";
import EditPermissionsForm from "./components/EditPermissionsForm";

export default function RoleManagementDetails() {
  const goBack = useGoBack();
  const params = useParams<{ category: string }>();

  const { isLoading, error, refetch, permission } = useGetPermission(
    params.category ?? ""
  );

  const { editPermission, isEditing } = useEditPermission();

  const form = useForm<UpdatePermission["payload"]>({
    defaultValues: { description: "" },
  });

  const { formState, handleSubmit, reset } = form;

  useUpdatedEffect(() => {
    if (permission) {
      reset({ description: permission.description });
    }
  }, [permission]);

  const handleEditPermission = (value: UpdatePermission["payload"]) => {
    editPermission({ payload: value, permissionId: params.category ?? "" });
  };

  return (
    <Page title={capitalizeStr(params.category!)} breadcrumbs={[{ onAction: goBack }]}>
      {formState.isDirty && (
        <>
          <BaseStyles />

          <ContextualSaveBar
            message="Unsaved description"
            saveAction={{
              content: "Save",
              onAction: handleSubmit(handleEditPermission),
              loading: isEditing,
            }}
            discardAction={{
              onAction: () => reset({ description: permission?.description }),
            }}
          />
        </>
      )}

      <FormProvider {...form}>
        <LoaderContainer modal loading={isLoading} error={error} onRetryClicked={refetch}>
          <EditPermissionsForm />
        </LoaderContainer>
      </FormProvider>
    </Page>
  );
}

const BaseStyles = () => {
  return (
    <Global
      styles={css`
        .Polaris-Frame__ContextualSaveBar {
          z-index: 1000;
        }
      `}
    />
  );
};
