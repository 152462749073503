import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import MerchantService from "app/api/merchants";
import parseError from "app/lib/parseError";
import useGetBusinessTypes from "app/hooks/useGetBusinessTypes";
import { BusinessType } from "app/models/business";
import { MerchantBusinessesQuery } from "app/api/merchants/type";

export default function useGetMerchantBusiness(
  merchantId: string,
  params?: MerchantBusinessesQuery
) {
  const businessTypeResponse = useGetBusinessTypes();

  const { data, isLoading, isFetching, error, refetch } = useQuery(
    ["merchants", "business", merchantId, params],
    () => MerchantService.getMerchantBusiness(merchantId, params),
    {
      enabled:
        Boolean(merchantId) && Boolean(businessTypeResponse.businessTypes),

      select: response => {
        const businessTypes = businessTypeResponse.businessTypes;
        const businesses = response.data.data.map(business => {
          return {
            ...business,
            business_type:
              businessTypes![business.business_type_id as keyof BusinessType],
          };
        });

        const result = { ...response.data, data: businesses };
        return result;
      },
    }
  );

  return {
    businesses: data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    isLoading,
    isFetching,
    refetch,
  };
}
