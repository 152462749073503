import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import CountryService from "app/api/countries";
import parseError from "app/lib/parseError";

export default function useGetCountries() {
  const { data, error, refetch, isLoading } = useQuery(
    ["countries"],
    () => CountryService.getCountries(),
    {
      staleTime: 86_400_000, // caches the data for 24 hours,
    }
  );

  return {
    countries: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
    isLoading,
  };
}
