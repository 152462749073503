import AnalyticsCard from "./AnalyticsCard";
import useListBusinesses from "app/hooks/useListBusinesses";

export default function TotalBusinesses() {
  const { businessesData, isLoading, error, refetch } = useListBusinesses();

  const businessData = businessesData?.pagination.total_entries_size ?? "";

  return (
    <AnalyticsCard
      title="Total businesses"
      viewLink="/businesses"
      amount={businessData}
      loading={isLoading}
      error={error}
      refetch={refetch}
    />
  );
}
