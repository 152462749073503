import styled from "@emotion/styled";
import { Card, Frame } from "@shopify/polaris";

import { ReactComponent as BushaIcon } from "app/assets/images/logo.svg";
import ToastProvider from "app/providers/ToastProvider";

export default function AuthPageWrapper(props: React.PropsWithChildren<{}>) {
  return (
    <Frame>
      <ToastProvider>
        <Container>
          <Card>
            <BushaIcon />
            {props.children}
          </Card>
        </Container>
      </ToastProvider>
    </Frame>
  );
}

const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .Polaris-Card {
    width: 440px;
    max-width: 100%;
    padding: 64px 40px;

    > svg {
      margin-bottom: 30px;
    }
  }
`;
