import * as React from "react";
import styled from "@emotion/styled";
import { Badge, Card, Layout, Link, Stack, Text } from "@shopify/polaris";

import BusinessDetails from "./BusinessDetails";
import PaymentLink from "app/models/paymentLink";
import { formatMoney } from "app/lib/money";
import useGetTransactions from "../hooks/useGetTransactions";
import { useParams, useSearchParams } from "react-router-dom";
import LoaderContainer from "app/components/LoaderContainer";
import { ReactComponent as PreviewIcon } from "app/assets/icons/preview.svg";
import { CUSTOMER_APP_URL } from "app/constants/variables";
import PaymentLinksPayments from "./PaymentLinksPayments";

interface Props {
  paymentLink: PaymentLink;
}
export default function Details(props: Props) {
  const { paymentLink } = props;

  const [searchParams] = useSearchParams();
  const { id } = useParams();

  const { data, isLoading, refetch, error, isFetching } = useGetTransactions({
    resource_id: String(id),
    business_id: String(searchParams.get("business_id")),
  });

  const transactions = data?.groot_deposits;

  const paymentLinkPreviewLink = `${CUSTOMER_APP_URL}/${paymentLink.id}`;

  const numOfCompletedPayments = React.useMemo(
    () => transactions?.filter(tx => tx.status === "completed").length ?? 0,
    [transactions]
  );

  const totalPaymentsProcessed = React.useMemo(() => {
    return (
      transactions
        ?.filter(tx => tx.status === "completed")
        .reduce((sum, tx) => {
          return (sum += Number(tx.amount));
        }, 0) ?? 0
    );
  }, [transactions]);

  return (
    <Layout>
      <Layout.Section>
        <GridWrapper>
          <Stack distribution="fillEvenly">
            <Card
              title={
                <Text as="h3" variant="headingSm" fontWeight="regular">
                  Number of payments
                </Text>
              }
              sectioned
            >
              <Text as="p" fontWeight="medium" variant="headingLg">
                {numOfCompletedPayments}
              </Text>
            </Card>

            <Card
              title={
                <Text as="h3" variant="headingSm" fontWeight="regular">
                  Total payments processed
                </Text>
              }
              sectioned
            >
              <Text as="p" fontWeight="medium" variant="headingLg">
                {formatMoney(totalPaymentsProcessed, "", paymentLink.local_currency)}
              </Text>
            </Card>
          </Stack>
        </GridWrapper>

        <GridWrapper>
          <Card>
            <Card.Section title="PAYMENT LINK DETAILS">
              <DataRow>
                <Text as="p" variant="bodyMd">
                  Product Name
                </Text>
                <Text as="p" variant="bodyMd" breakWord>
                  {paymentLink.name}
                </Text>
              </DataRow>

              <DataRow>
                <Text as="p" variant="bodyMd">
                  Payment Link
                </Text>

                <Link removeUnderline url={paymentLinkPreviewLink}>
                  Preview
                  <span style={{ paddingLeft: 8 }}>
                    <PreviewIcon />
                  </span>
                </Link>
              </DataRow>

              <DataRow>
                <Text as="p" variant="bodyMd">
                  Amount
                </Text>

                <Text as="p" variant="bodyMd">
                  {paymentLink.local_amount
                    ? formatMoney(
                        Number(paymentLink.local_amount),
                        "",
                        paymentLink.local_currency
                      )
                    : "N/A"}
                </Text>
              </DataRow>

              <DataRow>
                <Text as="p" variant="bodyMd">
                  Type
                </Text>

                <Badge>
                  {paymentLink.payment_link_type === "fixed_price"
                    ? "Payment Link"
                    : "Donation"}
                </Badge>
              </DataRow>
            </Card.Section>
          </Card>
        </GridWrapper>

        <LoaderContainer loading={isLoading} error={error} onRetryClicked={refetch} modal>
          {transactions && (
            <PaymentLinksPayments transactions={transactions} loading={isFetching} />
          )}
        </LoaderContainer>
      </Layout.Section>

      <Layout.Section secondary>
        <BusinessDetails paymentLink={paymentLink} />
      </Layout.Section>
    </Layout>
  );
}

const DataRow = styled.div`
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
`;

const GridWrapper = styled.div`
  margin-bottom: 14px;
`;
