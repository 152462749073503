import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import { useToast } from "app/providers/ToastProvider";
import BusinessesService from "app/api/businesses";

export default function useChangeEmail() {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(BusinessesService.changeEmail, {
    onSuccess: (res) => {
      showToast(res.data.message || "", "success");
      queryClient.invalidateQueries(["business"]);
    },
    onError: (error: AxiosError<{}>) => {
      const errorMessage = parseError(error) as string;

      showToast(errorMessage || "", "error");
    },
  });

  return {
    isLoading,
    changeEmail: mutate,
  };
}
