import * as React from "react";
import styled from "@emotion/styled";
import { ReactComponent as DangerIcon } from "app/assets/icons/danger.svg";

interface Props {
  text: string | React.ReactNode;
}

export default function Snackbar(props: Props) {
  return (
    <SnackBarContainer>
      <DangerIcon />
      {props.text}
    </SnackBarContainer>
  );
}

export const SnackBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
  height: 52px;
  background: #fff4f4;
  border: 1px solid #e0b3b2;
  border-radius: 8px;
  margin: 5px 0;
`;
