import { MerchantDetails } from "app/models/merchant";
import request from "../request";
import {
  GetMerchantActivitiesResponse,
  GetMerchantBusinessResponse,
  GetMerchantConfirmedDevicesResponse,
  GetMerchantSessionsResponse,
  ListMerchantsQuery,
  ListMerchantsResponse,
  ListMerchantTiersResponse,
  MerchantBusinessesQuery,
  UpdateMerchantAddressPayload,
  UpdateMerchantProfilePayload,
} from "./type";

const listMerchants = async (params: ListMerchantsQuery = {}) => {
  return request.get<ListMerchantsResponse>("/v1/customers", {
    params: {
      app: "pug",
      ...params,
    },
  });
};

const listMerchantTiers = async () => {
  return request.get<ListMerchantTiersResponse>("/v1/businesses/merchant/tiers");
};

const getMerchant = async (merchantId: string) => {
  return request.get<MerchantDetails>(`/v1/customers/${merchantId}`, {
    params: {
      app: "pug",
    },
  });
};

const getMerchantBusiness = (merchantId: string, params?: MerchantBusinessesQuery) => {
  return request.get<GetMerchantBusinessResponse>(`/v1/businesses`, {
    params: { user_id: merchantId, ...params },
  });
};

const getMerchantActivities = (merchantId: string) => {
  return request.get<GetMerchantActivitiesResponse>(
    `/v1/customers/${merchantId}/activities`
  );
};

const getMerchantMobileApps = (merchantId: string) => {
  return request.get<GetMerchantActivitiesResponse>(
    `/v1/customers/${merchantId}/applications`
  );
};

const getMerchantSessions = (merchantId: string) => {
  return request.get<GetMerchantSessionsResponse>(`/v1/customers/${merchantId}/sessions`);
};

const enableMerchantsBusinesses = (merchantId: string) => {
  return request.patch(`/v1/businesses/merchant/${merchantId}/enable`);
};

const disableMerchantsBusinesses = (merchantId: string) => {
  return request.patch(`/v1/businesses/merchant/${merchantId}/disable`);
};

const getMerchantConfirmedDevices = (merchantId: string) => {
  return request.get<GetMerchantConfirmedDevicesResponse>(
    `/v1/customers/${merchantId}/devices`
  );
};

const updateMerchantProfile = ({
  merchantId,
  payload,
}: {
  merchantId: string;
  payload: UpdateMerchantProfilePayload;
}) => {
  return request.put(`/v1/customers/${merchantId}/profile`, payload);
};

const updateMerchantAddress = ({
  merchantId,
  payload,
}: {
  merchantId: string;
  payload: UpdateMerchantAddressPayload;
}) => {
  return request.put(`/v1/customers/${merchantId}/address`, payload);
};

const updateMerchantLevel = ({
  merchantId,
  tierId,
}: {
  merchantId: string;
  tierId: string;
}) => {
  return request.put(`/v1/businesses/merchant/${merchantId}/tier/${tierId}`);
};

const haltDeletion = ({ merchantId }: { merchantId: string }) => {
  return request.put(`/v1/customers/${merchantId}/halt_deletion`);
};

const MerchantService = {
  listMerchants,
  getMerchant,
  getMerchantBusiness,
  getMerchantMobileApps,
  updateMerchantProfile,
  updateMerchantAddress,
  getMerchantActivities,
  getMerchantConfirmedDevices,
  getMerchantSessions,
  listMerchantTiers,
  updateMerchantLevel,
  enableMerchantsBusinesses,
  disableMerchantsBusinesses,
  haltDeletion,
};

export default MerchantService;
