import * as React from "react";
import { IndexTable, Card, Text, Link, Checkbox } from "@shopify/polaris";

import TableWrapper, { CellPadding } from "app/components/TableWrapper";
import useModalState from "app/hooks/useModalState";
import { TogglePermissionPayload } from "app/api/roles/types";
import { Roles } from "app/models/team";

import TogglePermissionModal from "./TogglePermissionModal";
import { Permission, RoleEnum } from "../utils";
import useTogglePermission from "../hooks/useTogglePermission";

const getModifiedArray = (list: string[], itemToCheck: string) => {
  if (list.includes(itemToCheck)) {
    return list.filter(item => item !== itemToCheck);
  }
  const newList = [...list, itemToCheck];
  return newList;
};

interface Props {
  permissions: Permission[];
  roles: Roles[];
}

export default function PermissionsList(props: Props) {
  const { permissions, roles } = props;

  const { isOpen, closeModal, setIsOpen } = useModalState(false);
  const [payload, setPayload] = React.useState<TogglePermissionPayload>();

  const { togglePermission, isToggling } = useTogglePermission();

  const onToggle = (roleName: string, action: string) => {
    setIsOpen(true);
    const role = roles!.find(r => r.name === roleName);

    if (role) {
      const { id, name, description, permissions } = role;
      const newPermissions = getModifiedArray(permissions, action);
      setPayload(() => ({ id, name, description, permissions: newPermissions }));
    }
  };

  const onPermissionsToggle = () => {
    if (payload) {
      togglePermission(payload, {
        onSuccess: () => {
          setPayload(undefined);
          closeModal();
        },
      });
    }
  };

  return (
    <div>
      <Card.Section fullWidth>
        <TableWrapper>
          <IndexTable
            itemCount={permissions.length}
            headings={[
              { title: "Category" },
              { title: "Permission" },
              { title: "Owner" },
              { title: "Admin" },
              { title: "Support" },
              { title: "Developer" },
            ]}
            selectable={false}
          >
            {permissions.map((item, index) => (
              <IndexTable.Row id={index.toString()} key={index} position={index}>
                <IndexTable.Cell>
                  <CellPadding>
                    <Text variant="bodyMd" fontWeight="regular" as="p">
                      {item.category}
                    </Text>
                  </CellPadding>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <Link url={`/settings/roles/${item.id}`} removeUnderline>
                    {item.description}
                  </Link>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <Checkbox
                    label=""
                    checked={item.owner}
                    onChange={() => onToggle(RoleEnum.owner, item.id)}
                    labelHidden
                  />
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <Checkbox
                    label=""
                    checked={item.admin}
                    onChange={() => onToggle(RoleEnum.admin, item.id)}
                    labelHidden
                  />
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <Checkbox
                    label=""
                    checked={item.support}
                    onChange={() => onToggle(RoleEnum.support, item.id)}
                    labelHidden
                  />
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <Checkbox
                    label=""
                    checked={item.developer}
                    onChange={() => onToggle(RoleEnum.dev, item.id)}
                    labelHidden
                  />
                </IndexTable.Cell>
              </IndexTable.Row>
            ))}
          </IndexTable>
        </TableWrapper>
      </Card.Section>

      <TogglePermissionModal
        isToggling={isToggling}
        onClose={closeModal}
        open={isOpen}
        onAction={onPermissionsToggle}
      />
    </div>
  );
}
