import styled from "@emotion/styled";
import { Badge, Card, IndexTable, Link } from "@shopify/polaris";
import TableWrapper from "app/components/TableWrapper";
import useGetBusinessTypes from "app/hooks/useGetBusinessTypes";
import Business, { BusinessType } from "app/models/business";

import { capitalizeStr, formatDateStr } from "app/utils/helpers";
import { mapBusinessStatusToBadge } from "../helpers";

interface Props {
  businesses: Business[];
}

export default function BusinessesList(props: Props) {
  const { businesses } = props;

  const { businessTypes } = useGetBusinessTypes();

  return (
    <Card.Section fullWidth>
      <TableWrapper>
        <IndexTable
          selectable={false}
          resourceName={{ plural: "businesses", singular: "business" }}
          itemCount={businesses.length}
          headings={[
            { title: "Business name" },
            { title: "Business type" },
            { title: "Status" },
            { title: "Creation Date" },
          ]}
        >
          {businesses.map((business, i) => {
            return (
              <IndexTable.Row id={business.id} key={business.id} position={i}>
                <IndexTable.Cell>
                  <CellPadding>
                    <Link removeUnderline url={`/businesses/${business.id}`}>
                      {business.name}
                    </Link>
                  </CellPadding>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <Badge>
                    {businessTypes
                      ? businessTypes!![
                          String(
                            business.business_type_id
                          ) as keyof BusinessType
                        ]?.name
                      : "N/A"}
                  </Badge>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <CellPadding>
                    <Badge status={mapBusinessStatusToBadge(business.status)}>
                      {capitalizeStr(business.status)}
                    </Badge>
                  </CellPadding>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <CellPadding>
                    {formatDateStr(
                      business.created_at,
                      "MMMM D, YYYY [at] hh:mma"
                    )}
                  </CellPadding>
                </IndexTable.Cell>
              </IndexTable.Row>
            );
          })}
        </IndexTable>
      </TableWrapper>
    </Card.Section>
  );
}

const CellPadding = styled.div`
  padding: 13px 0px;
`;
