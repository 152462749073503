import { Card, Form, Modal, TextField } from "@shopify/polaris";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import useChangeEmail from "../hooks/useChangeEmail";

interface Props {
  open: boolean;
  onClose: () => void;
  text?: string;
  onConfirm: () => void;
  confirmText: string;
  title?: string;
}
export default function SupportEmailModal(props: Props) {
  const { open, onClose, title, text, confirmText } = props;

  const { id } = useParams<{ id: string }>();

  const { changeEmail, isLoading } = useChangeEmail();

  const { control, setValue, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: "",
    },
  });

  const handleChangeEmail = (value: { email: string }) => {
    changeEmail(
      { businessId: String(id), payload: value },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };

  return (
    <Modal
      title={title || "Change Support Email"}
      open={open}
      onClose={onClose}
      primaryAction={{
        disabled: isLoading,
        content: confirmText,

        onAction: handleSubmit(handleChangeEmail),
        loading: isLoading,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <Card subdued>
          {text && (
            <Card.Section>
              <p>{text}</p>
            </Card.Section>
          )}
          <Card.Section>
            <Form onSubmit={() => null}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    autoComplete=""
                    label="New Email Address"
                    placeholder="youremail@example.com"
                    value={field.value}
                    onChange={(v) =>
                      setValue("email", v, {
                        shouldDirty: true,
                      })
                    }
                  />
                )}
              />
            </Form>
          </Card.Section>
        </Card>
      </Modal.Section>
    </Modal>
  );
}
