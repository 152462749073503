import * as React from "react";
import { ChoiceList, FilterInterface, Filters } from "@shopify/polaris";
import debounce from "lodash.debounce";

import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import { GetPaymentLinkTransactionsQuery } from "app/api/transactions/types";

interface Props {
  query: GetPaymentLinkTransactionsQuery;
  setQuery: (q: GetPaymentLinkTransactionsQuery) => void;
}

const statusTypes = [
  {
    label: "New",
    value: "new",
  },
  {
    label: "Completed",
    value: "completed",
  },
];

export default function TransactionsFilter(props: Props) {
  const { query, setQuery } = props;

  const [queryString, setQueryString] = React.useState(query.q);

  const debouncedQueryUpdate = React.useMemo(
    () => debounce(searchString => setQuery({ q: searchString, page: 1 }), 1000),
    [setQuery]
  );

  useUpdatedEffect(() => {
    debouncedQueryUpdate(queryString);
  }, [queryString]);

  const filters: FilterInterface[] = [
    {
      label: "Status",
      key: "status",
      filter: (
        <ChoiceList
          title="Completed"
          name="status"
          titleHidden
          choices={statusTypes}
          selected={[query.status || ""]}
          onChange={t => setQuery({ status: t[0] })}
        />
      ),
      shortcut: true,
    },
  ];

  const removeFilter = React.useCallback(
    (filter: string) => {
      setQuery({ ...query, [filter]: undefined });
    },
    [setQuery, query]
  );

  const appliedFilters = filters
    .filter(
      filter => query[filter.key as keyof GetPaymentLinkTransactionsQuery] !== undefined
    )
    .map(filter => ({
      ...filter,
      label: `${filter.label}: ${
        query[filter.key as keyof GetPaymentLinkTransactionsQuery]
      }`,
      onRemove: removeFilter,
    }));

  return (
    <Filters
      filters={filters}
      appliedFilters={appliedFilters}
      focused
      queryValue={queryString}
      onQueryChange={setQueryString}
      onQueryClear={() => setQueryString("")}
      queryPlaceholder="Search ref number"
      onClearAll={() =>
        setQuery({
          q: undefined,
          sort: undefined,
          page: 1,
        })
      }
    />
  );
}
