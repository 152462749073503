import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import PayoutsService from "app/api/withdrawals";
import parseError from "app/lib/parseError";
import { useToast } from "app/providers/ToastProvider";

const useResyncGrootPayouts = () => {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const { isLoading, mutate: resyncPayouts } = useMutation(
    () => PayoutsService.resyncWithdrawals(),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["withdrawals"]);
        showToast(res.data.message || "", "success");
      },
      onError: (error) => {
        const errorMessage = parseError(error as AxiosError<{}>);
        showToast(errorMessage as string, "error");
      },
    }
  );

  return {
    isLoading,
    resyncPayouts,
  };
};

export default useResyncGrootPayouts;
