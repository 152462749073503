import * as React from "react";
import { Card, Tabs } from "@shopify/polaris";
import { Donations } from "./Donations";
import { PaymentLinks } from "./PaymentLinks";
import { Invoices } from "./Invoices";
import { useParams } from "react-router-dom";

const tabsMap: Record<number, React.ReactNode> = {
  0: <PaymentLinks />,
  1: <Donations />,
  2: <Invoices />,
};

const routesMap: Record<number, string> = {
  0: "payment links",
  1: "donations",
  2: "invoices",
};

export default function PaymentLinkInvoices() {
  const [selected, setSelected] = React.useState(0);

  const { id } = useParams();

  const onSelectTab = (n: number) => {
    setSelected(n);
  };

  return (
    <Card
      actions={[
        {
          content: `View all ${routesMap[selected]}`,
          url: `/${
            routesMap[selected] === "payment links"
              ? "payment-links"
              : routesMap[selected]
          }?business_id=${id}`,
        },
      ]}
    >
      <Tabs
        selected={selected}
        onSelect={onSelectTab}
        tabs={[
          {
            id: "payment_links",
            content: "Payment Links",
          },
          {
            id: "donations",
            content: "Donations",
          },
          {
            id: "invoices",
            content: "Invoices",
          },
        ]}
      >
        {tabsMap[selected]}
      </Tabs>
    </Card>
  );
}
