import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useParams } from "react-router-dom";

import parseError from "app/lib/parseError";
import CurrencyService from "app/api/currencies";

export default function useGetCurrency(enabled: boolean, currency?: string) {
  const params = useParams<{ code: string }>();

  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["currency", params.code],
    () => CurrencyService.getCurrency((params.code as string) ?? currency),
    { enabled }
  );

  return {
    isLoading,
    isFetching,
    currency: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
  };
}
