import * as React from "react";
import { Button, Modal, TextField } from "@shopify/polaris";
import styled from "@emotion/styled";
import useModalState from "app/hooks/useModalState";
import BankAccountsList from "./BankAccountsList";
import { PaymentAccount } from "app/models/paymentAccount";

interface Props {
  numOfBanks: PaymentAccount[];
}

export default function BankAccountsModal(props: Props) {
  const { numOfBanks } = props;

  const { isOpen, setIsOpen, toggleIsOpen } = useModalState();
  const [value, setValue] = React.useState("");

  const handleChange = React.useCallback((newValue: string) => setValue(newValue), []);

  return (
    <Modal
      activator={
        <Button onClick={toggleIsOpen} plain>
          View more
        </Button>
      }
      open={isOpen}
      onClose={() => setIsOpen(!isOpen)}
      title="Bank Account"
      small
    >
      <Modal.Section flush>
        <SearchEl>
          <TextField
            label=""
            labelHidden
            value={value}
            onChange={handleChange}
            autoComplete="off"
          />
        </SearchEl>
      </Modal.Section>

      <BankAccountsList numOfBanks={numOfBanks} />
    </Modal>
  );
}

const SearchEl = styled.div`
  padding: 2px 8px;
`;
