import request from "../request";
import { BaseResponse } from "../types";
import {
  GetInvoiceResponse,
  ListInvoicesQuery,
  ListInvoicesResponse,
} from "./types";

const listInvoices = (params: ListInvoicesQuery = {}) => {
  return request.get<ListInvoicesResponse>("/v1/businesses/invoices", {
    params,
  });
};

const getInvoice = (invoiceId: string) => {
  return request.get<GetInvoiceResponse>(
    `/v1/businesses/invoices/${invoiceId}`
  );
};

const voidInvoice = (invoiceId: string) => {
  return request.delete<BaseResponse>(`/v1/businesses/invoices/${invoiceId}`);
};

const InvoiceService = {
  getInvoice,
  listInvoices,
  voidInvoice,
};

export default InvoiceService;
