import { Card, EmptyState, Page, Text } from "@shopify/polaris";

export default function NotFound() {
  return (
    <Page>
      <Card sectioned>
        <EmptyState
          action={{ content: "Go Home", url: "/" }}
          image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
          fullWidth
        >
          <Text as="h1" variant="heading4xl">
            Page Not Found 👀
          </Text>
        </EmptyState>
      </Card>
    </Page>
  );
}
