import * as React from "react";
import {
  Card,
  ContextualSaveBar,
  Layout,
  Select,
  Stack,
} from "@shopify/polaris";
import roles from "app/constants/roles";
import Profile from "app/models/profile";
import useUpdateUser from "../hooks/useUpdateUser";
import { useToast } from "app/providers/ToastProvider";
import { useParams } from "react-router-dom";

interface Props {
  profile: Profile;
}
export default function PermissionSection(props: Props) {
  const { profile } = props;

  const { id } = useParams<{ id: string }>();

  const [role, setRole] = React.useState(profile.role);

  const showToast = useToast();

  const { updateUser, isLoading, error, data, updateUserVariables } =
    useUpdateUser(String(id));

  React.useEffect(() => {
    if (error) {
      showToast(error, "error");
    }
    if (data && data.message) {
      showToast(data.message, "success");

      if (updateUserVariables && updateUserVariables.role) {
        setRole(updateUserVariables.role);
      }
    }
  }, [error, data, showToast, updateUserVariables]);

  return (
    <>
      {role !== profile.role && (
        <ContextualSaveBar
          message="Unsaved changes (Permissions)"
          saveAction={{
            onAction: () => updateUser({ ...profile, role }),
            loading: isLoading,
          }}
          discardAction={{
            onAction: () => {
              setRole(profile.role);
            },
          }}
        />
      )}

      <Layout.AnnotatedSection title="Permissions">
        <Card>
          <Card.Section title="Customize Permissions">
            <Stack vertical>
              <p>Manage users access to features on the platform</p>
              <Select
                label="Role"
                options={roles}
                value={role}
                onChange={setRole}
              />
            </Stack>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </>
  );
}
