import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";

import ProfileServices from "app/api/profile";
import { UpdatePasswordPayload } from "app/api/profile/types";
import parseError from "app/lib/parseError";

export default function useUpdatePassword() {
  const updatePasswordMutation = useMutation(ProfileServices.updatePassword);
  const updatePassword = (data: UpdatePasswordPayload) => {
    updatePasswordMutation.mutate(data);
  };

  return {
    updatePassword,
    loading: updatePasswordMutation.isLoading,
    response: updatePasswordMutation.data?.data.message,
    error: updatePasswordMutation.error
      ? (parseError(updatePasswordMutation.error as AxiosError<{}>) as string)
      : null,
  };
}
