import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import ProfileServices from "app/api/profile";
import { UpdateProfilePayload } from "app/api/profile/types";
import parseError from "app/lib/parseError";

export default function useUpdateProfile() {
  const queryClient = useQueryClient();

  const updateProfileMutation = useMutation(ProfileServices.updateProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries(["profile"]);
    },
  });
  const updateProfile = (data: UpdateProfilePayload) => {
    updateProfileMutation.mutate(data);
  };

  return {
    updateProfile,
    loading: updateProfileMutation.isLoading,
    response: updateProfileMutation.data?.data.message,
    error: updateProfileMutation.error
      ? (parseError(updateProfileMutation.error as AxiosError<{}>) as string)
      : null,
    variables: updateProfileMutation.variables,
  };
}
