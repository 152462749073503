import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  Button,
  Card,
  Checkbox,
  Form,
  FormLayout,
  Layout,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@shopify/polaris";

import { AddCurrencyPayload } from "app/api/currencies/types";
import currencyTypes from "app/constants/currencyTypes";
import BooksSection from "./BooksSection";
import Currency from "app/models/currency";
import useGenerateCurrencyDetails from "../hooks/useGenerateCurrencyDetails";
import useToggleCurrencyStatus from "../hooks/useToggleCurrencyStatus";

interface Props {
  isAddMode: boolean;
  currency?: Currency;
}

export default function CurrencyForm(props: Props) {
  const { isAddMode, currency } = props;

  const { control, setValue, reset, watch } = useFormContext<AddCurrencyPayload>();

  const [name, code] = watch(["name", "code"]);

  const { generateCurrencyDetails, isLoading } = useGenerateCurrencyDetails();

  const {
    isLoading: togglingStatus,
    isTogglingVIPStatus,
    isTogglingDepositStatus,
  } = useToggleCurrencyStatus();

  return (
    <Form onSubmit={() => {}}>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <FormLayout>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    autoComplete="on"
                    label="Name"
                    disabled={!isAddMode}
                    placeholder="eg. Bitcoin"
                    name="name"
                    value={field.value}
                    onChange={field.onChange}
                    error={fieldState.error?.message}
                  />
                )}
              />

              <Stack alignment="trailing">
                <Stack.Item fill>
                  <Controller
                    name="code"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        autoComplete=""
                        label="Code"
                        disabled={!isAddMode}
                        placeholder="BTC"
                        name="code"
                        value={field.value}
                        onChange={field.onChange}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                </Stack.Item>

                {isAddMode && (
                  <Stack.Item>
                    <Tooltip content="Provide currency name and code">
                      <Button
                        primary
                        disabled={!(name && code)}
                        loading={isLoading}
                        onClick={() =>
                          generateCurrencyDetails(`${code}-${name}`.toLowerCase(), {
                            onSuccess(data) {
                              reset(data.data);
                            },
                          })
                        }
                      >
                        Fetch Details
                      </Button>
                    </Tooltip>
                  </Stack.Item>
                )}
              </Stack>

              <Controller
                name="explorer"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    autoComplete=""
                    label="Explorer URL"
                    placeholder="e.g https://www.blockchain.com/btc/tx/"
                    name="explorer"
                    value={field.value}
                    onChange={field.onChange}
                    error={fieldState.error?.message}
                  />
                )}
              />

              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    autoComplete=""
                    multiline={5}
                    label="Description"
                    placeholder="write a short note about the Currency"
                    name="description"
                    value={field.value}
                    onChange={field.onChange}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </FormLayout>
          </Card>

          <Card sectioned>
            <FormLayout>
              <Controller
                name="website"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    autoComplete=""
                    label="Website"
                    placeholder="https://"
                    name="website"
                    value={field.value}
                    onChange={v => field.onChange(v)}
                    error={fieldState.error?.message}
                  />
                )}
              />

              <Controller
                name="whitepaper"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    autoComplete=""
                    label="Whitepaper"
                    name="whitepaper"
                    value={field.value}
                    onChange={field.onChange}
                    error={fieldState.error?.message}
                  />
                )}
              />

              <Controller
                name="address_regex"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    autoComplete=""
                    label="Address Regex"
                    name="address_regex"
                    value={field.value}
                    onChange={field.onChange}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </FormLayout>
          </Card>

          <Card sectioned>
            <FormLayout>
              <Controller
                name="color"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    autoComplete=""
                    prefix="#"
                    label="Color"
                    name="color"
                    value={field.value}
                    onChange={field.onChange}
                    error={fieldState.error?.message}
                  />
                )}
              />

              <FormLayout.Group>
                <Controller
                  name="exponent"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      autoComplete=""
                      type="number"
                      label="Exponent"
                      name="exponent"
                      value={field.value.toString()}
                      onChange={v => field.onChange(Number(v))}
                      error={fieldState.error?.message}
                    />
                  )}
                />

                <Controller
                  name="unit_price_scale"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      autoComplete=""
                      type="number"
                      label="Unit price scale"
                      name="unit_price_scale"
                      value={field.value.toString()}
                      onChange={v => field.onChange(Number(v))}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card>
        </Layout.Section>

        <Layout.Section secondary>
          <Card>
            <Card.Section title="Currency type">
              <Controller
                control={control}
                name="type"
                render={({ field, fieldState }) => (
                  <Select
                    label="Currency Type"
                    labelHidden
                    options={currencyTypes}
                    value={field.value}
                    onChange={field.onChange}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </Card.Section>
          </Card>
          <Card>
            <Card.Section title="Status">
              <Stack vertical spacing="tight">
                <Controller
                  name="status"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Checkbox
                      label="Online"
                      name="status"
                      checked={field.value === "online"}
                      onChange={v => {
                        setValue("status", "online", { shouldDirty: true });
                      }}
                      // disabled={togglingStatus}
                      error={!!fieldState.error?.message}
                    />
                  )}
                />

                <Controller
                  name="status"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Checkbox
                      label="Offline"
                      name="status"
                      checked={field.value === "offline"}
                      onChange={v => {
                        setValue("status", "offline", { shouldDirty: true });
                      }}
                      disabled={togglingStatus}
                      error={!!fieldState.error?.message}
                    />
                  )}
                />
              </Stack>
            </Card.Section>
          </Card>
          <Card>
            <Card.Section title="Currency actions">
              <Stack vertical spacing="tight">
                <Controller
                  control={control}
                  name="vip_payout_status"
                  render={({ field, fieldState }) => (
                    <Checkbox
                      label="VIP payout status"
                      name="vip_payout_status"
                      checked={field.value}
                      onChange={field.onChange}
                      disabled={isTogglingVIPStatus}
                      error={fieldState.error?.message}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="deposit"
                  render={({ field, fieldState }) => (
                    <Checkbox
                      label="Deposit status"
                      name="deposit"
                      checked={field.value}
                      onChange={field.onChange}
                      disabled={isTogglingDepositStatus}
                      error={fieldState.error?.message}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="payout"
                  render={({ field, fieldState }) => (
                    <Checkbox
                      label="Payout status"
                      name="payout"
                      checked={field.value}
                      onChange={field.onChange}
                      disabled={isTogglingDepositStatus}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </Stack>
            </Card.Section>
          </Card>
          {!isAddMode && currency && <BooksSection currency={currency} />}
        </Layout.Section>
      </Layout>
    </Form>
  );
}
