import styled from "@emotion/styled";
import { Button, Modal, TextField } from "@shopify/polaris";
import copyToClipboard from "app/lib/copyToClipBoard";
import { useToast } from "app/providers/ToastProvider";

interface Props {
  open: boolean;
  onClose: () => void;
  currency: string;
}

const address = "1F1tAUXrC3494irioew31XNn4xq";

export default function AddressModal({ currency, open, onClose }: Props) {
  const showToast = useToast();

  const onCopyAddress = () => {
    copyToClipboard(address)
      .then(() => {
        showToast("Address Copied", "success");
      })
      .catch(() => {
        showToast("Could not copy Address", "error");
      });
  };

  return (
    <Modal onClose={onClose} open={open} title={`${currency} Wallet Address`}>
      <Modal.Section>
        <FieldWrapper>
          <div className="field-wrapper">
            <TextField disabled autoComplete="off" label="" labelHidden value={address} />
          </div>
          <Button primary onClick={onCopyAddress}>
            Copy
          </Button>
        </FieldWrapper>
      </Modal.Section>
    </Modal>
  );
}

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  .field-wrapper {
    flex: 1;
  }
`;
