import { Card, Page, Pagination, Select, Stack } from "@shopify/polaris";

import LoaderContainer from "app/components/LoaderContainer";
import BusinessesFilter from "./components/BusinessesFilter";
import useListBusinesses from "../../hooks/useListBusinesses";
import BusinessesList from "./components/BusinessesList";
import useQueryParams from "app/hooks/useQueryParams";
import { ListBusinessesQuery } from "app/api/businesses/types";

export default function Businesses() {
  const { queryParams, updateQueryParams } = useQueryParams<ListBusinessesQuery>({
    page: 1,
    limit: 10,
  });

  const { businessesData, isLoading, error, refetch, isFetching } =
    useListBusinesses(queryParams);

  const businesses = businessesData?.data || [];

  return (
    <Page title="Businesses">
      <Card>
        <Card.Section>
          <Stack vertical>
            <BusinessesFilter query={queryParams} setQuery={updateQueryParams} />

            <Stack distribution="equalSpacing" alignment="center">
              {isLoading ? (
                <p>Loading businesses</p>
              ) : (
                <p>
                  Showing {businessesData?.pagination.current_entries_size || 0}{" "}
                  businesses
                </p>
              )}
              <Select
                labelInline
                label="Sort by"
                name="sort"
                value={queryParams.sort}
                options={[
                  { label: "Newest", value: "desc" },
                  { label: "Oldest", value: "asc" },
                ]}
                onChange={s => updateQueryParams("sort", s)}
              />
            </Stack>
          </Stack>
        </Card.Section>

        <LoaderContainer
          style={{ minHeight: "unset" }}
          loading={isLoading || isFetching}
          error={error}
          onRetryClicked={refetch}
        >
          {businesses && <BusinessesList businesses={businesses} />}
        </LoaderContainer>

        <Card.Section>
          <Stack distribution="center">
            {businessesData && (
              <Pagination
                label={`${queryParams.page ?? 1} / ${
                  businessesData.pagination.total_pages
                }`}
                hasNext={
                  businessesData.pagination.total_pages > businessesData.pagination.page
                }
                onNext={() => {
                  updateQueryParams("page", businessesData.pagination.page + 1);
                }}
                hasPrevious={businessesData.pagination.page > 1}
                onPrevious={() => {
                  updateQueryParams("page", businessesData.pagination.page - 1);
                }}
              />
            )}
          </Stack>
        </Card.Section>
      </Card>
    </Page>
  );
}
