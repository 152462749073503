import * as React from "react";
import styled from "@emotion/styled";
import { Card, Layout, Link, Stack, Text } from "@shopify/polaris";

import LoaderContainer from "app/components/LoaderContainer";
import useGetMerchant from "app/hooks/useGetMerchant";
import Business from "app/models/business";
import { Owner } from "app/models/compliance";
import OwnerDetails from "./OwnerDetails";
import { capitalizeStr } from "app/utils/helpers";

interface Props {
  business: Business;
  owners: Owner[];
}
export default function OwnersSection(props: Props) {
  const { business, owners } = props;

  const [activeOwnerID, setActiveOwnerID] = React.useState(owners ? owners[0]?.id : "");

  const { merchant, isLoading, refetch, error } = useGetMerchant(business.user_id);

  const activeOwner = owners
    ? owners.find(owner => owner.id === activeOwnerID)
    : undefined;

  return (
    <Layout>
      <Layout.Section secondary>
        <Stack vertical>
          <Card
            title={
              <Text as="h3" variant="headingXs">
                REGISTERED OWNERS
              </Text>
            }
          >
            <ListWrapper>
              {owners && owners.length > 0 ? (
                owners.map(owner => {
                  return (
                    <OwnerItem
                      key={owner.id}
                      active={owner.id === activeOwnerID}
                      onClick={() => {
                        setActiveOwnerID(owner.id);
                      }}
                    >
                      <Stack vertical>
                        <Text as="p" variant="bodyMd">
                          {capitalizeStr(owner.role)}
                        </Text>

                        <Link removeUnderline url="">
                          <Text as="p" variant="bodyMd">
                            {capitalizeStr(owner.first_name)}{" "}
                            {capitalizeStr(owner.last_name)}
                          </Text>
                        </Link>
                      </Stack>
                    </OwnerItem>
                  );
                })
              ) : (
                <Card.Section>
                  <Text as="p" variant="bodyMd">
                    No owners submitted
                  </Text>
                </Card.Section>
              )}
            </ListWrapper>
          </Card>
          <Card
            title={
              <Text as="h3" variant="headingXs">
                BUSINESS
              </Text>
            }
            sectioned
          >
            <Link removeUnderline url={`/businesses/${business.id}`}>
              <Text as="p" variant="bodyMd" fontWeight="medium">
                {business.name}
              </Text>
            </Link>
          </Card>

          <LoaderContainer
            loading={isLoading}
            onRetryClicked={refetch}
            error={error}
            small
          >
            {merchant && (
              <Card
                title={
                  <Text as="h3" variant="headingXs">
                    MERCHANT
                  </Text>
                }
                sectioned
              >
                <Link url={`/merchants/${merchant.id}`} removeUnderline>
                  <Text as="p" variant="bodyMd" fontWeight="medium">
                    {merchant.profile.first_name} {merchant.profile.last_name}
                  </Text>
                </Link>
              </Card>
            )}
          </LoaderContainer>
        </Stack>
      </Layout.Section>

      <Layout.Section>
        {activeOwner && <OwnerDetails owner={activeOwner} />}
      </Layout.Section>
    </Layout>
  );
}

const ListWrapper = styled.div`
  padding-top: 16px;
`;

const OwnerItem = styled.div<{ active: boolean }>`
  cursor: pointer;
  padding: 20px;
  transition: 0.3s background-color ease;

  ${props => props.active && `background-color: #f6f6f7;`}

  &:hover {
    background-color: #f6f6f7;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid #e1e3e5;
  }

  &:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;
