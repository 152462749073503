import * as React from "react";
import GenericDropdownFilter from "app/components/GenericDropdownFilter";
import useGetAnalytics from "app/hooks/useGetAnalytics";
import { formatMoney } from "app/lib/money";
import { LegacyCard, Stack, Text } from "@shopify/polaris";
import LoaderContainer from "app/components/LoaderContainer";
import { PaymentLinkType } from "app/hooks/useGetAnalytics";

export default function PaymentsProcessedOn() {
  const [value, setValue] = React.useState<PaymentLinkType>("invoice");

  function changehandler(selected: PaymentLinkType[]) {
    setValue(selected[0]);
  }

  const { data, isLoading, error, refetch } = useGetAnalytics(value);

  const amount = data?.data.total_amount ?? "";
  const currency = data?.data.display_currency;

  return (
    <LegacyCard
      title={
        isLoading ? (
          ""
        ) : (
          <Stack distribution="equalSpacing" alignment="center">
            <div style={{ position: "relative", borderBottom: "dashed 1px" }}>
              <Text as={"h4"} variant={"headingMd"}>
                Payments processed on
              </Text>
            </div>
            <GenericDropdownFilter
              options={[
                { label: "Invoices", value: "invoice" },
                { label: "Payment Links", value: "fixed_price" },
                { label: "Donations", value: "donation" },
              ]}
              selected={value === "fixed_price" ? ["Payment Link"] : [value]}
              onChange={changehandler}
              size="slim"
            />
          </Stack>
        )
      }
    >
      <LoaderContainer
        small
        loading={isLoading}
        onRetryClicked={refetch}
        error={error}
        spinnerSize="small"
      >
        <LegacyCard.Section>
          <Text variant="headingXl" as="h2">
            {amount ? `${currency} ${formatMoney(amount)}` : ""}
          </Text>
        </LegacyCard.Section>
      </LoaderContainer>
    </LegacyCard>
  );
}
