import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import PaymentsService from "app/api/payments";

export default function useGetPayment(paymentId: string) {
  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["payment", { paymentId }],
    () => PaymentsService.getPayment(paymentId)
  );

  return {
    isLoading,
    isFetching,
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
  };
}
