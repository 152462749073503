import * as React from "react";
import { Card, FormLayout, Modal, TextField } from "@shopify/polaris";
import useUpdateConfirmationCount from "../hooks/useUpdateConfirmation";
import { Controller, useForm } from "react-hook-form";
import { SinglePayment } from "app/models/payment";

interface Props {
  open: boolean;
  payment: SinglePayment;
  onClose: () => void;
}
export default function UpdateCountForm(props: Props) {
  const { open, onClose, payment } = props;
  const [step, setStep] = React.useState(1);

  const { updateConfirmationCount, isLoading } = useUpdateConfirmationCount();

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<{ count: number; paymentId: string }>({
    defaultValues: {
      count: payment.confirmations,
      paymentId: payment.id,
    },
    mode: "onChange",
  });

  const onCancel = () => {
    onClose();
  };

  return (
    <Modal
      title="Update Confirmation Count"
      open={open}
      onClose={onCancel}
      primaryAction={
        step === 1
          ? {
              content: "Continue",
              onAction: () => setStep(2),
              loading: isLoading,
            }
          : {
              content: "Update",
              onAction: handleSubmit(updateConfirmationCount as () => void),
              loading: isLoading,
            }
      }
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onCancel,
        },
      ]}
    >
      <Modal.Section>
        <Card subdued>
          <Card.Section>
            {step === 1 && (
              <FormLayout>
                <Controller
                  name="count"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete="on"
                      label="Confirm block"
                      name="count"
                      type="number"
                      connectedRight={<div>{}</div>}
                      value={String(field.value)}
                      onChange={(v) =>
                        setValue("count", Number(v), { shouldDirty: true })
                      }
                      error={errors.count?.message}
                    />
                  )}
                />
              </FormLayout>
            )}

            {step === 2 && (
              <p>
                You are about to update this confirmation count. Do you wish to
                continue?
              </p>
            )}
          </Card.Section>
        </Card>
      </Modal.Section>
    </Modal>
  );
}
