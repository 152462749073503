import { Card, Stack, Text } from "@shopify/polaris";

import useGetBusinessLimits from "../hooks/useGetBusinessLimits";
import { formatMoney } from "app/lib/money";
import LoaderContainer from "app/components/LoaderContainer";

interface Props {
  businessId: string;
}
export default function BusinessLimits(props: Props) {
  const { businessId } = props;

  const { businessLimits, isLoading, refetch, error } = useGetBusinessLimits(businessId);

  const payout = businessLimits?.data
    ? businessLimits.data.find(b => b.action === "crypto_payout")
    : undefined;

  const deposit = businessLimits?.data
    ? businessLimits.data.find(b => b.action === "crypto_deposit")
    : undefined;

  return (
    <LoaderContainer
      style={{ minHeight: "unset", width: "unset" }}
      loading={isLoading}
      onRetryClicked={refetch}
      error={error}
    >
      <Card
        title={
          <Text as="p" variant="bodySm" fontWeight="semibold">
            BUSINESS CRYPTO LIMITS
          </Text>
        }
      >
        <Card.Section>
          <Stack vertical spacing="extraTight">
            <Text as="p" variant="bodyMd" fontWeight="medium">
              Payment
            </Text>

            <Text as="p" variant="bodyMd" breakWord color="subdued">
              {deposit?.value === "-1"
                ? "Unlimited"
                : formatMoney(
                    Number(deposit?.value ?? 0),
                    "fiat",
                    deposit?.currency ?? ""
                  )}
            </Text>
          </Stack>
        </Card.Section>

        <Card.Section>
          <Stack vertical spacing="extraTight">
            <Text as="p" variant="bodyMd" fontWeight="medium">
              Withdrawals
            </Text>

            <Text as="p" variant="bodyMd" breakWord color="subdued">
              {formatMoney(Number(payout?.value ?? 0), "fiat", payout?.currency ?? "")}
            </Text>
          </Stack>
        </Card.Section>
      </Card>
    </LoaderContainer>
  );
}
