import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import MerchantService from "app/api/merchants";

export default function useListMerchantTiers() {
  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["merchant_tiers"],
    () => MerchantService.listMerchantTiers()
  );

  return {
    isLoadingTiers: isLoading,
    isFetching,
    tiers: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
  };
}
