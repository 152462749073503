import {
  Card,
  Page,
  Pagination,
  ResourceList,
  Stack,
  EmptyState,
} from "@shopify/polaris";
import { renderAuditLog } from "./components/AuditLog";
import useGetAuditLogs from "./hooks/useGetAuditLog";
import LoaderContainer from "app/components/LoaderContainer";
import AuditLogFilter from "./components/AuditLogFilter";

export default function AuditLogs() {
  const { isLoading, isFetching, data, error, refetch, query, setQuery } =
    useGetAuditLogs();

  return (
    <>
      <Page title="Audit Logs">
        <LoaderContainer page loading={isLoading} error={error} onRetryClicked={refetch}>
          {data && (
            <Card>
              <ResourceList
                loading={isFetching}
                onSelectionChange={ids => {
                  console.log(ids);
                }}
                filterControl={
                  <AuditLogFilter
                    query={query}
                    setQuery={setQuery}
                    numOfLogs={data.audits.length ?? 0}
                  />
                }
                resourceName={{ singular: "Log", plural: "Logs" }}
                showHeader={false}
                items={data?.audits || []}
                renderItem={renderAuditLog}
                emptyState={
                  <EmptyState image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png">
                    <p>No audit logs</p>
                  </EmptyState>
                }
              />
              {data.audits && data.audits.length === 0 && (
                <>
                  <Card.Section flush />
                  <Card.Section>
                    <Stack distribution="center">
                      <Pagination hasNext={!!data.next_page} />
                    </Stack>
                  </Card.Section>
                </>
              )}
            </Card>
          )}
        </LoaderContainer>
      </Page>
    </>
  );
}
