import * as React from "react";
import { css, Global } from "@emotion/react";
import { useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { ContextualSaveBar, Page } from "@shopify/polaris";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import LoaderContainer from "app/components/LoaderContainer";
import useGoBack from "app/hooks/useGoBack";
import { AddCurrencyPayload } from "app/api/currencies/types";
import useCurrencyAction from "./hooks/useCurrencyActions";
import useGetCurrency from "./hooks/useGetCurrency";
import CurrencyForm from "./components/CurrencyForm";

const schema = Yup.object().shape({
  name: Yup.string().required(),
  status: Yup.string().oneOf(["online", "offline"]).required(),
  type: Yup.string().oneOf(["digital", "fiat"]).required(),
  description: Yup.string().when("type", {
    is: "digital",
    then: Yup.string().required(),
  }),
  code: Yup.string().required(),
  explorer: Yup.string(),
  website: Yup.string().when("type", {
    is: "digital",
    then: Yup.string().required(),
  }),
  vip_payout_status: Yup.boolean(),
  payout: Yup.boolean(),
  deposit: Yup.boolean(),
  unit_price_scale: Yup.number().required(),
  exponent: Yup.number().required(),
  whitepaper: Yup.string().when("type", {
    is: "digital",
    then: Yup.string().required(),
  }),
  address_regex: Yup.string().required(),
  color: Yup.string().when("type", {
    is: "digital",
    then: Yup.string().required(),
  }),
});

export default function CurrencyDetails() {
  const goBack = useGoBack();
  const params = useParams();
  const isAddMode = params.code === "add";

  const { isLoading, currency, error, refetch } = useGetCurrency(!isAddMode);

  const { onSubmit, formIsLoading } = useCurrencyAction({ isAddMode });

  const formMethods = useForm<AddCurrencyPayload>({
    defaultValues: currency ?? {
      status: "offline",
      type: "digital",
      name: "",
      description: "",
      code: "",
      explorer: "",
      website: "",
      vip_payout_status: false,
      payout: false,
      deposit: false,
      unit_price_scale: 0,
      exponent: 0,
      whitepaper: "",
      address_regex: "",
      color: "000000",
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { reset, formState, handleSubmit } = formMethods;

  const onFormSubmit = (data: AddCurrencyPayload) => {
    onSubmit(data);
  };

  React.useEffect(() => {
    if (currency) {
      reset(currency);
    }
  }, [currency, reset]);

  return (
    <Page
      breadcrumbs={[{ onAction: goBack }]}
      title={isAddMode ? "Add Currency" : `Edit ${currency?.code || "..."}`}
    >
      {formState.isDirty && (
        <>
          <BaseStyles />
          <ContextualSaveBar
            message="Unsaved Currency"
            saveAction={{
              content: isAddMode ? "Add" : "Save",
              onAction: handleSubmit(onFormSubmit),
              loading: formIsLoading,
            }}
            discardAction={{
              onAction: () => reset(isAddMode ? undefined : currency),
            }}
          />
        </>
      )}

      <FormProvider {...formMethods}>
        {isAddMode ? (
          <CurrencyForm currency={currency} isAddMode={isAddMode} />
        ) : (
          <LoaderContainer loading={isLoading} error={error} onRetryClicked={refetch}>
            <CurrencyForm currency={currency} isAddMode={isAddMode} />
          </LoaderContainer>
        )}
      </FormProvider>
    </Page>
  );
}

const BaseStyles = () => {
  return (
    <Global
      styles={css`
        .Polaris-Frame__ContextualSaveBar {
          z-index: 1000;
        }
      `}
    />
  );
};
