import { useParams } from "react-router-dom";
import { Badge, Loading, Page } from "@shopify/polaris";

import LoaderContainer from "app/components/LoaderContainer";
import useGoBack from "app/hooks/useGoBack";

import Details from "./components/Details";
import useGetPaymentLink from "../PaymentLinkDetails/hooks/useGetPaymentLink";
import { formatDateStr } from "app/utils/helpers";
import useModalState from "app/hooks/useModalState";
import usePaymentLinkActions from "../PaymentLinkDetails/hooks/usePaymentLinkActions";
import ToggleStatusModal from "app/components/ToggleStatusModal";

export default function DonationDetails() {
  const goBack = useGoBack();

  const { isOpen, closeModal, openModal } = useModalState(false);
  const { id } = useParams();

  const { isLoading, isFetching, data, error, refetch } = useGetPaymentLink(String(id));

  const {
    deletingPaymentLink,
    deletePaymentLink,
    restoringPaymentLink,
    restorePaymentLink,
  } = usePaymentLinkActions(String(id));

  const onDelete = () => {
    deletePaymentLink(undefined, {
      onSuccess() {
        closeModal();
      },
    });
  };

  const onRestore = () => {
    restorePaymentLink(undefined, {
      onSuccess() {
        closeModal();
      },
    });
  };

  const isEnabled = !Boolean(data?.data.deleted_at);

  return (
    <>
      {isFetching && <Loading />}

      <Page
        breadcrumbs={[{ onAction: goBack }]}
        title={isLoading ? "..." : `DON-${data?.data.id.slice(0, 6)}`}
        subtitle={
          isLoading
            ? "..."
            : formatDateStr(String(data?.data.created_at), "MMMM D, YYYY [at] hh:mma")
        }
        primaryAction={{
          loading: deletingPaymentLink || restoringPaymentLink,
          content: isEnabled ? "Disable Donation" : "Enable Donation",
          disabled: deletingPaymentLink || restoringPaymentLink,
          onAction: openModal,
          destructive: isEnabled,
        }}
        titleMetadata={
          <>
            <Badge status={data?.data.deleted_at ? "critical" : "success"}>
              {data?.data.deleted_at ? "Inactive" : "Active"}
            </Badge>
          </>
        }
      >
        <LoaderContainer modal loading={isLoading} error={error} onRetryClicked={refetch}>
          {data && <Details paymentLink={data.data} />}
        </LoaderContainer>

        {data && (
          <ToggleStatusModal
            title={isEnabled ? "Disable Donation" : "Enable Donation"}
            open={isOpen}
            onClose={closeModal}
            loading={deletingPaymentLink || restoringPaymentLink}
            onConfirm={isEnabled ? onDelete : onRestore}
            destructive={isEnabled ? true : false}
            confirmText={isEnabled ? "Disable Donation" : "Enable Donation"}
            text={`Are you sure you want to ${
              isEnabled ? "disable" : "enable"
            } this donation?`}
          />
        )}
      </Page>
    </>
  );
}
