import { useParams } from "react-router-dom";
import { Badge, BadgeProps, IndexTable, Link } from "@shopify/polaris";

import TableWrapper, { CellPadding } from "app/components/TableWrapper";
import { capitalizeStr, formatDateStr } from "app/utils/helpers";
import Business from "app/models/business";
import useGetBusinessTypes from "app/hooks/useGetBusinessTypes";

interface Props {
  businesses: Business[];
}
export default function BusinessesList(props: Props) {
  const { businesses } = props;

  const params = useParams();
  const { businessTypes } = useGetBusinessTypes();

  return (
    <TableWrapper>
      <IndexTable
        selectable={false}
        itemCount={businesses.length}
        headings={[
          { title: "Business name" },
          { title: "Country" },
          { title: "Business Type" },
          { title: "Date" },
          { title: "Status" },
        ]}
      >
        {businessTypes &&
          businesses.map((business, i) => {
            const businessTypeName =
              businessTypes[business.business_type_id as keyof typeof businessTypes];

            return (
              <IndexTable.Row id={business.id} key={business.id} position={i}>
                <IndexTable.Cell>
                  <CellPadding>
                    <Link
                      removeUnderline
                      url={`/compliance/${params.type}/${business.id}`}
                    >
                      {business.name}
                    </Link>
                  </CellPadding>
                </IndexTable.Cell>
                <IndexTable.Cell>{business.country_id}</IndexTable.Cell>
                <IndexTable.Cell>{businessTypeName.name}</IndexTable.Cell>
                <IndexTable.Cell>
                  {formatDateStr(business.created_at, "MMMM D, YYYY [at] hh:mma")}
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <Badge status={badgeStatus[business.compliance_status]}>
                    {capitalizeStr(business.compliance_status)}
                  </Badge>
                </IndexTable.Cell>
              </IndexTable.Row>
            );
          })}
      </IndexTable>
    </TableWrapper>
  );
}

const badgeStatus: Record<Business["compliance_status"], BadgeProps["status"]> = {
  pending: "warning",
  verified: "success",
  rejected: "critical",
  unverified: "info",
};
