import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import MerchantService from "app/api/merchants";
import { useToast } from "app/providers/ToastProvider";

export default function useEnableBusinesses(merchantId: string) {
  const queryClient = useQueryClient();
  const showToast = useToast();

  const { mutate, isLoading, data, error } = useMutation(
    MerchantService.enableMerchantsBusinesses,
    {
      onSuccess: res => {
        showToast(res.data.message || "", "success");

        return queryClient.invalidateQueries(["merchant"]);
      },
      onError: (error: AxiosError<{}>) => {
        const errorMessage = parseError(error) as string;

        showToast(errorMessage || "", "error");
      },
    }
  );

  return {
    enableBusinesses: () => mutate(merchantId),
    isEnablingBusinesses: isLoading,
    response: data?.data.message,
    error: error ? (parseError(error as AxiosError<{}>) as string) : null,
  };
}
