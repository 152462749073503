import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { Badge, Card, Layout, Page, Text, Link, Loading, Avatar } from "@shopify/polaris";

import useGoBack from "app/hooks/useGoBack";
import TransactionDetails from "./components/TransactionDetails";
import BalanceChanges from "./components/BalanceChanges";
import BalanceDetails from "./components/BalanceDetails";
import useGetTransaction from "./hook/useGetTransaction";
import LoaderContainer from "app/components/LoaderContainer";
import { capitalizeStr, formatDateStr, mapStatusToBadge } from "app/utils/helpers";

export default function TransactionsDetails() {
  const goBack = useGoBack();
  const { id } = useParams();

  const { isLoading, isFetching, data, error, refetch } = useGetTransaction(String(id));

  const isQueryResolved = data && !isLoading && !error;

  return (
    <>
      {isFetching && <Loading />}

      <Page
        breadcrumbs={[{ onAction: goBack }]}
        title={isQueryResolved ? data.reference : "..."}
        subtitle={
          isQueryResolved
            ? formatDateStr(data.created_at, "MMMM DD, YYYY [at] hh:mm a")
            : "..."
        }
        titleMetadata={
          isQueryResolved ? (
            <Badge status={mapStatusToBadge(data.status)}>
              {capitalizeStr(data.status)}
            </Badge>
          ) : (
            "..."
          )
        }
      >
        <LoaderContainer loading={isLoading} onRetryClicked={refetch} error={error}>
          {data && (
            <Layout>
              <Layout.Section>
                <TransactionDetails transaction={data} />
                <BalanceChanges transaction={data} />
                <BalanceDetails transaction={data} />
              </Layout.Section>
              <Layout.Section secondary>
                <Card>
                  <Card.Section
                    title={
                      <TitleEl>
                        <Text as="h3" variant="headingXs">
                          MERCHANT
                        </Text>
                        <Avatar initials="DA" size="small" />
                      </TitleEl>
                    }
                  >
                    <Link url={`/merchants/${data.user_id}`} removeUnderline>
                      <p style={{ marginTop: 16 }}>{data.merchant_name}</p>
                    </Link>
                  </Card.Section>
                </Card>
                <Card>
                  <Card.Section
                    title={
                      <Text as="h3" variant="headingXs">
                        BUSINESS
                      </Text>
                    }
                  >
                    <Link url={`/businesses/${data.business_id}`} removeUnderline>
                      <p style={{ marginTop: 16 }}>{data.business_name}</p>
                    </Link>
                  </Card.Section>
                </Card>
                <Card>
                  <Card.Section
                    title={
                      <TitleEl>
                        <Text as="h3" variant="headingXs">
                          HASH
                        </Text>
                      </TitleEl>
                    }
                  >
                    {data.data.meta.hash ? (
                      <Text as="p" variant="bodyMd" breakWord>
                        <HashLink
                          href={data.data.meta.blockchain_url}
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                        >
                          {data.data.meta.hash}
                        </HashLink>
                      </Text>
                    ) : (
                      <Text as="p" variant="bodyMd">
                        N/A
                      </Text>
                    )}
                  </Card.Section>
                </Card>
              </Layout.Section>
            </Layout>
          )}
        </LoaderContainer>
      </Page>
    </>
  );
}

const TitleEl = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HashLink = styled.a`
  text-decoration: none;
  color: var(--p-interactive);
`;
