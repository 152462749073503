import { useLocation } from "react-router-dom";
import { IconSource } from "@shopify/polaris";
import {
  AnalyticsMajor,
  ChecklistAlternateMajor,
  CustomersMajor,
  GiftCardMajor,
  TeamMajor,
  ListMajor,
  PaymentsMajor,
  CheckoutMajor,
  StoreMajor,
  AppsMajor,
  SettingsMajor,
  BillingStatementDollarMajor,
  ConnectMinor,
  TransactionFeeDollarMajor,
} from "@shopify/polaris-icons";
import { ItemProps } from "@shopify/polaris/build/ts/latest/src/components/Navigation/components";

const links: ItemProps[][] = [
  [
    {
      url: "/businesses",
      label: "Businesses",
      icon: StoreMajor as IconSource,
    },
    {
      url: "/payment-links",
      label: "Payment Links",
      icon: CheckoutMajor as IconSource,
    },
    {
      url: "/donations",
      label: "Donations",
      icon: GiftCardMajor as IconSource,
    },
    {
      url: "/invoices",
      label: "Invoices",
      icon: ChecklistAlternateMajor as IconSource,
    },
    {
      url: "/payments",
      label: "Payments",
      icon: PaymentsMajor as IconSource,
    },
    {
      url: "/withdrawals",
      label: "Withdrawals",
      icon: BillingStatementDollarMajor as IconSource,
    },
    {
      url: "/transactions",
      label: "Transactions",
      icon: TransactionFeeDollarMajor as IconSource,
    },
    {
      url: "/connect",
      label: "Connect",
      icon: ConnectMinor as IconSource,
      subNavigationItems: [
        {
          url: "/connect",
          label: "Trades",
          disabled: false,
        },
      ],
    },
    {
      url: "/merchants",
      label: "Merchants",
      icon: CustomersMajor as IconSource,
    },
    {
      url: "/compliance/documents",
      label: "Compliance",
      icon: ListMajor as IconSource,
      matchPaths: ["/compliance"],
    },
    {
      url: "/analytics",
      label: "Analytics",
      icon: AnalyticsMajor as IconSource,
      subNavigationItems: [
        {
          url: "/feedbacks",
          label: "Feedbacks",
          disabled: false,
        },
      ],
    },
  ],
];

export const inHouseLinks: ItemProps[][] = [
  [
    {
      url: "/users",
      label: "Users",
      icon: TeamMajor as IconSource,
    },
    {
      url: "/audit",
      label: "Audit Logs",
      icon: AppsMajor as IconSource,
    },
  ],
];

export function useSettingsLinks(): ItemProps[] {
  const { pathname, search } = useLocation();

  return [
    {
      url: `/settings?backUrl=${encodeURIComponent(pathname + search)}`,
      label: "Settings",
      icon: SettingsMajor as IconSource,
    },
  ];
}

export default links;
