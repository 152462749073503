import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import ComplianceService from "app/api/compliance";

export default function useGetDocument(id: string) {
  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["document", id],
    () => ComplianceService.getDocument(id),
    {
      enabled: !!id,
    }
  );

  return {
    isLoading,
    isFetching,
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
  };
}
