import { BadgeProps } from "@shopify/polaris";

export function mapCountryOnlineStatusToBadge(status: "true" | "false") {
  const statusMap: Record<"true" | "false", BadgeProps["status"]> = {
    true: "success",
    false: undefined,
  };

  return statusMap[status];
}
