import styled from "@emotion/styled";

import Timeline from "app/components/Timeline";
import { Trade } from "app/models/connect";
import { capitalizeStr } from "app/utils/helpers";

interface Props {
  event: Trade;
}

export default function ConnectTimeline(props: Props) {
  const { event } = props;

  const events = event.trade_events.slice().reverse();

  return (
    <Container>
      <h2 className="header">Timeline</h2>

      <div className="timeline-container">
        <Timeline
          data={events.map(i => {
            return {
              event: `${capitalizeStr(i.description)}`,
              date: `${i.created_at}`,
            };
          })}
        />
      </div>
    </Container>
  );
}

const Container = styled.section`
  margin-top: 16px;

  .header {
    padding: 20px;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid #dfe3e8;
  }

  .timeline-container {
    margin-top: 26px;
    padding: 20px;
  }
`;
