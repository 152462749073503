import * as React from "react";
import { Card, Tabs } from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { PaymentsSection } from "./PaymentsSection";
import { WithdrawalsSection } from "./WithdrawalsSection";

const tabsMap: Record<number, React.ReactNode> = {
  0: <PaymentsSection />,
  1: <WithdrawalsSection />,
};

const routesMap: Record<number, string> = {
  0: "payments",
  1: "withdrawals",
};

export default function PaymentWithdrawals() {
  const [selected, setSelected] = React.useState(0);

  const { id } = useParams();

  const onSelectTab = (n: number) => {
    setSelected(n);
  };

  return (
    <Card
      actions={[
        {
          content: `View all ${routesMap[selected]}`,
          url: `/${routesMap[selected]}?business_id=${id}`,
        },
      ]}
    >
      <Tabs
        selected={selected}
        onSelect={onSelectTab}
        tabs={[
          {
            id: "payments",
            content: "Payments",
          },
          {
            id: "withdrawals",
            content: "Withdrawals",
          },
        ]}
      >
        {tabsMap[selected]}
      </Tabs>
    </Card>
  );
}
