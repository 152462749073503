import Business from "app/models/business";

import request from "../request";
import { BaseResponse, GenericPaginatedResponse, GenericResponse } from "../types";
import {
  AddBusinessTypePayload,
  BusinessDocumentsResponse,
  BusinessLimitsResponse,
  BusinessTypeResponse,
  ChangeEmailPayload,
  ListBusinessesQuery,
  ListBusinessTypesQuery,
} from "./types";

const getBusinesses = async (params?: ListBusinessesQuery) => {
  return request
    .get<GenericPaginatedResponse<Business>>(`/v1/businesses`, {
      params,
    })
    .then(res => res.data);
};

const getBusiness = async (businessId: string) => {
  return request.get<GenericResponse<Business>>(`/v1/businesses/${businessId}`);
};

const listBusinessTypes = (params?: ListBusinessTypesQuery) => {
  return request.get<BusinessTypeResponse>("/v1/businesses/types", {
    params,
  });
};

const getBusinessLimits = (businessId: string) => {
  return request.get<BusinessLimitsResponse>(`/v1/businesses/${businessId}/limits`);
};

const addBusinessType = (payload: AddBusinessTypePayload) => {
  return request.post("/v1/businesses/types", [payload]);
};

const listBusinessDocuments = (businessId: string) => {
  return request.get<BusinessDocumentsResponse>(`/v1/documents`, {
    params: { q: businessId },
  });
};

const changeEmail = ({ businessId, payload }: ChangeEmailPayload) => {
  return request.put(`/v1/businesses/${businessId}`, payload);
};

const recoverBusiness = (businessId: string) => {
  return request.put<BaseResponse>(`/v1/businesses/${businessId}/recover`);
};

const togglePaymentsStatus = (businessId: string) => {
  return request.patch(`/v1/businesses/${businessId}/payments`);
};

const toggleWithdrawalsStatus = (businessId: string) => {
  return request.patch(`/v1/businesses/${businessId}/withdrawals`);
};

const toggleSuspension = (businessId: string) => {
  return request.patch(`/v1/businesses/${businessId}/suspended`);
};

const BusinessesService = {
  addBusinessType,
  getBusinesses,
  getBusiness,
  listBusinessTypes,
  listBusinessDocuments,
  toggleSuspension,
  changeEmail,
  recoverBusiness,
  togglePaymentsStatus,
  toggleWithdrawalsStatus,
  getBusinessLimits,
};

export default BusinessesService;
