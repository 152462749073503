import Feedback from "app/models/feedback";
import request from "../request";
import { GenericPaginatedResponse } from "../types";
import { GetFeedbacksQuery } from "./types";

const getFeedbacks = async (params?: GetFeedbacksQuery) => {
  return request.get<GenericPaginatedResponse<Feedback>>(
    `/v1/businesses/analytics/feedbacks`,
    {
      params,
    }
  );
};

const FeedbackService = {
  getFeedbacks,
};

export default FeedbackService;
