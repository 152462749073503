import styled from "@emotion/styled";
import { IndexTable, Link } from "@shopify/polaris";
import { formatMoney } from "app/lib/money";

import { BookItem } from "app/models/book";

import { formatDateStr } from "app/utils/helpers";

interface Props {
  books: BookItem[];
}
export default function BookList(props: Props) {
  const { books } = props;

  return (
    <>
      {books.map((book, i) => {
        return (
          <IndexTable.Row id={book.id} key={book.id} position={i}>
            <IndexTable.Cell>
              <CellPadding>
                <Link removeUnderline url={`/books/${book.id}`}>
                  {book.product}
                </Link>
              </CellPadding>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <CellPadding>{book.exchange}</CellPadding>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <CellPadding>
                {formatDateStr(book.updated_at, "MMMM D, YYYY [at] hh:mma")}
              </CellPadding>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <CellPadding>
                {formatMoney(book.buy_price, "digital", book.counter_currency)}
              </CellPadding>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <CellPadding>{book.spread}</CellPadding>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <CellPadding>
                {formatMoney(book.sell_price, "digital", book.counter_currency)}
              </CellPadding>
            </IndexTable.Cell>
          </IndexTable.Row>
        );
      })}
    </>
  );
}

const CellPadding = styled.div`
  padding: 13px 0px;
`;
