import { BadgeProps } from "@shopify/polaris";
import { BusinessStatus } from "app/models/business";

export function mapBusinessStatusToBadge(status: BusinessStatus) {
  const statusMap: Record<BusinessStatus, BadgeProps["status"]> = {
    verified: "success",
    unverified: "warning",
    closed: "critical",
  };

  return statusMap[status];
}
