import styled from "@emotion/styled";
import {
  Card,
  EmptySearchResult,
  IndexTable,
  Page,
  Pagination,
  Stack,
} from "@shopify/polaris";
import { ListInvoicesQuery } from "app/api/invoices/types";
import LoaderContainer from "app/components/LoaderContainer";
import useQueryParams from "app/hooks/useQueryParams";
import FeedbackFilter from "./components/FeedbackFilter";
import FeedbacksList from "./components/FeedbacksList";
import useGetFeedbacks from "./hooks/useGetFeedbacks";

export default function Feedbacks() {
  const { queryParams, updateQueryParams } = useQueryParams<ListInvoicesQuery>({
    page: 1,
    sort: "desc",
    limit: 10,
  });

  const { feedbacks, isLoading, error, refetch } = useGetFeedbacks({
    params: queryParams,
  });

  const feedbacksData = feedbacks?.data ?? [];

  return (
    <Page title="Feedbacks" fullWidth>
      <Card>
        <FeedbackFilter
          query={queryParams}
          setQuery={updateQueryParams}
          numOfFeedbacks={feedbacksData.length}
        />
        <Card.Section flush>
          <LoaderContainer
            style={{ minHeight: "unset" }}
            loading={isLoading}
            error={error}
            onRetryClicked={refetch}
          >
            <TableWrapper>
              <IndexTable
                selectable={false}
                resourceName={{ plural: "feedbacks", singular: "feedback" }}
                headings={[
                  { title: "Ref Number" },
                  { title: "Feedback" },
                  { title: "Rating" },
                  { title: "Type" },
                  { title: "Date" },
                ]}
                itemCount={feedbacksData.length}
                emptyState={
                  <EmptySearchResult
                    title="No feedback yet"
                    description="Try changing the filters or search term"
                    withIllustration
                  />
                }
              >
                <FeedbacksList feedbacks={feedbacksData} />
              </IndexTable>
            </TableWrapper>
          </LoaderContainer>
        </Card.Section>
        <Card.Section>
          <Stack distribution="center">
            {feedbacks && feedbacksData.length > 0 && (
              <Pagination
                label={
                  <p>
                    {feedbacks.pagination.page} /{feedbacks.pagination.total_pages}
                  </p>
                }
                onPrevious={() =>
                  updateQueryParams("page", (Number(queryParams.page) ?? 1) - 1)
                }
                onNext={() =>
                  updateQueryParams("page", (Number(queryParams.page) ?? 1) + 1)
                }
                hasNext={feedbacks.pagination.total_pages > feedbacks.pagination.page}
                hasPrevious={Boolean(feedbacks.pagination.page > 1)}
              />
            )}
          </Stack>
        </Card.Section>
      </Card>
    </Page>
  );
}

const TableWrapper = styled.div`
  .Polaris-IndexTable__TableHeading {
    color: #000000;
    padding-block: 20px;
  }

  tr {
    border-top: 1px solid #e1e3e5;
  }
`;
