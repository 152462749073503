import styled from "@emotion/styled";
import { Card, IndexTable } from "@shopify/polaris";

import TableWrapper from "app/components/TableWrapper";
import { TeamActivity } from "app/models/team";
import { capitalizeStr, formatDateStr } from "app/utils/helpers";

interface Props {
  activities: TeamActivity[];
}

export default function ActivitiesList({ activities }: Props) {
  return (
    <Card.Section fullWidth>
      <TableWrapper>
        <IndexTable
          resourceName={{ singular: "merchant", plural: "merchants" }}
          headings={[
            { title: "Team Member" },
            { title: "Date" },
            { title: "Role" },
            { title: "Action" },
          ]}
          itemCount={activities.length}
          selectable={false}
        >
          {activities.map((activity, index) => {
            return (
              <IndexTable.Row key={index} id={index.toString()} position={index}>
                <CellPadding>
                  <IndexTable.Cell>{activity.name}</IndexTable.Cell>
                </CellPadding>

                <IndexTable.Cell>
                  {formatDateStr(activity.created_at, "MMMM D, YYYY [at] hh:mma")}
                </IndexTable.Cell>

                <IndexTable.Cell>{capitalizeStr(activity.role_name)}</IndexTable.Cell>

                <IndexTable.Cell>{activity.description}</IndexTable.Cell>
              </IndexTable.Row>
            );
          })}
        </IndexTable>
      </TableWrapper>
    </Card.Section>
  );
}

const CellPadding = styled.div`
  padding: 13px 0px;
`;
