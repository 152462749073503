import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import InvoiceService from "app/api/invoices";
import { ListInvoicesQuery } from "app/api/invoices/types";
import parseError from "app/lib/parseError";

interface Props {
  params?: ListInvoicesQuery;
}
export default function useListInvoices({ params }: Props) {
  const { data, isLoading, error, refetch, isFetching } = useQuery(
    ["invoices", params],
    () => InvoiceService.listInvoices(params)
  );

  return {
    invoices: data?.data,
    isLoading,
    isFetching,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
  };
}
