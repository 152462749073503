export const searchOptions = [
  {
    label: "Merchant Id",
    value: "reference",
  },
  {
    label: "Business Id",
    value: "business_id",
  },
];

export const ratingType = [
  { label: "1 Star", value: "2" },
  { label: "2 Star", value: "4" },
  { label: "3 Star", value: "6" },
  { label: "4 Star", value: "8" },
  { label: "5 Star", value: "10" },
];

export const customerType = [
  { label: "Merchant", value: "true" },
  { label: "Customer", value: "false" },
];
