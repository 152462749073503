import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import RolesService from "app/api/roles";
import parseError from "app/lib/parseError";

export default function useGetPermission(permissionId: string) {
  const { isLoading, error, refetch, data } = useQuery(
    ["permission", permissionId],
    () => RolesService.getPermission(permissionId),
    {
      enabled: Boolean(permissionId),
    }
  );

  return {
    permission: data?.data.data,
    isLoading,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
  };
}
