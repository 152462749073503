import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import BusinessesService from "app/api/businesses";

export default function useGetBusiness(businessId: string) {
  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["business", businessId],
    () => BusinessesService.getBusiness(businessId)
  );

  return {
    isLoading,
    isFetching,
    business: data?.data.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
  };
}
