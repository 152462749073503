import styled from "@emotion/styled";
import { Card, EmptySearchResult, IndexTable, Link } from "@shopify/polaris";
import TableWrapper from "app/components/TableWrapper";
import { BusinessType } from "app/models/business";
import { DocType } from "app/models/compliance";
import { formatDateStr } from "app/utils/helpers";

interface Props {
  documentTypes: DocType[];
  businessTypes: Record<keyof BusinessType, BusinessType>;
}
export default function DocumentsList(props: Props) {
  const { businessTypes, documentTypes } = props;

  return (
    <Card.Section fullWidth>
      <TableWrapper>
        <IndexTable
          headings={[
            { title: "Name" },
            { title: "Business Type" },
            { title: "Date Created" },
          ]}
          emptyState={
            <EmptySearchResult
              title="No documents found"
              description="Try changing the filters or search term"
              withIllustration
            />
          }
          itemCount={documentTypes.length}
          selectable={false}
        >
          {documentTypes.map((documentType, index) => {
            return (
              <IndexTable.Row key={index} id={index.toString()} position={index}>
                <CellPadding>
                  <IndexTable.Cell>
                    <Link url="/settings/documents/form" removeUnderline>
                      {documentType.name}
                    </Link>
                  </IndexTable.Cell>
                </CellPadding>

                <IndexTable.Cell>
                  {
                    businessTypes[documentType.business_type_id as keyof BusinessType]
                      .name
                  }
                </IndexTable.Cell>
                <IndexTable.Cell>
                  {formatDateStr(new Date().toLocaleDateString(), "MMMM DD, YYYY")}
                </IndexTable.Cell>
              </IndexTable.Row>
            );
          })}
        </IndexTable>
      </TableWrapper>
    </Card.Section>
  );
}

const CellPadding = styled.div`
  padding: 13px 0px;
`;
