import { useParams } from "react-router-dom";
import { LegacyCard, Icon, LegacyStack, Text } from "@shopify/polaris";
import { FavoriteMajor } from "@shopify/polaris-icons";

import { formatDateStr } from "app/utils/helpers";
import useGetFeedbacks from "app/views/Feedbacks/hooks/useGetFeedbacks";

export default function Feedback() {
  const { id } = useParams();
  const { feedbacks } = useGetFeedbacks({
    params: { business_id: id },
  });

  const feedbackData = feedbacks?.data.slice(0, 3);
  const starArray = new Array(5).fill(0).map((_, i) => i + 1);

  return (
    <LegacyCard
      title={
        <Text variant="headingXs" as="h3">
          RATINGS & FEEDBACKS
        </Text>
      }
      actions={
        feedbackData?.length
          ? [
              {
                content: "View all",
                url: "/feedbacks",
              },
            ]
          : []
      }
    >
      {feedbackData?.length ? (
        feedbackData?.map((feedback, index) => (
          <LegacyCard.Section subdued key={index}>
            <LegacyStack spacing="extraTight" wrap={false}>
              {starArray.map(i => (
                <Icon
                  key={i}
                  source={FavoriteMajor}
                  color={Math.ceil(Number(feedback.rating) / 2) >= i ? "success" : "base"}
                />
              ))}
            </LegacyStack>
            <div style={{ marginTop: "12px" }}>
              <Text as="p" variant="bodyLg" alignment="start">
                {feedback.comment ? feedback.comment : "--No comment--"}
              </Text>
              <Text variant="bodySm" as="span" color="subdued">
                {formatDateStr(feedback.created_at, "MMM DD, YYYY [at] hh:mm a")}
              </Text>
            </div>
          </LegacyCard.Section>
        ))
      ) : (
        <LegacyCard.Section>
          <Text as="p" variant="bodyLg" alignment="start">
            N/A
          </Text>
        </LegacyCard.Section>
      )}
    </LegacyCard>
  );
}
