import request from "../request";
import {
  GetPaymentAccountsResponse,
  GetPaymentsAccountsQuery,
  GetPaymentsQuery,
  GetPaymentsResponse,
  GetMissingPayment,
} from "./types";
import { SinglePayment } from "app/models/payment";
import { BaseResponse } from "../types";

const getPayments = async (params?: GetPaymentsQuery) => {
  return request.get<GetPaymentsResponse>("/v1/businesses/payments", {
    params,
  });
};

const getPaymentDetails = async (reference: string) => {
  return request.get<GetPaymentsResponse>(
    `/v1/businesses/payments?reference=${reference}`
  );
};

const getPayment = async (paymentId: string) => {
  return request.get<SinglePayment>(`/v1/businesses/payments/${paymentId}`);
};

const getPaymentAccounts = async (
  customerId: string,
  params?: GetPaymentsAccountsQuery
) => {
  return request.get<GetPaymentAccountsResponse>(
    `/v1/customers/${customerId}/payment-accounts`,
    {
      params,
    }
  );
};

const getMissingPaymentsCount = async () => {
  return request.get<GetMissingPayment>(`/v1/businesses/payments/missing-deposits`);
};

const findMissingPayments = async () => {
  return request.post<BaseResponse>(`/v1/businesses/payments/find`);
};

const resyncPayment = async () => {
  return request.post<BaseResponse>(`/v1/businesses/payments/resync`);
};

const completePayment = async (paymentId: string) => {
  return request.put(`/v1/businesses/payments/${paymentId}/fulfill`);
};

const updateConfirmation = async (payload: { count: string; paymentId: string }) => {
  return request.put(`/v1/businesses/payments/${payload.paymentId}/confirmation-count`, {
    count: payload.count,
  });
};

const PaymentsService = {
  getPayments,
  getPayment,
  getPaymentAccounts,
  completePayment,
  getMissingPaymentsCount,
  findMissingPayments,
  resyncPayment,
  updateConfirmation,
  getPaymentDetails,
};

export default PaymentsService;
