import { Card, Loading, Page, Pagination, Stack } from "@shopify/polaris";
import LoaderContainer from "app/components/LoaderContainer";
import PaymentsList from "./components/PaymentsList";
import PaymentsFilter from "./components/PaymentsFilter";
import useGetPayments from "app/hooks/useGetPayments";
import useQueryParams from "app/hooks/useQueryParams";
import { GetPaymentsQuery } from "app/api/payments/types";
import PaymentActionButtons from "./components/PaymentButton";

export default function Payments() {
  const { queryParams, updateQueryParams } = useQueryParams<GetPaymentsQuery>({
    limit: 10,
    page: 1,
  });

  const { payments, isLoading, error, refetch, isFetching } = useGetPayments({
    params: queryParams,
  });

  return (
    <>
      {isFetching && <Loading />}

      <Page title="Payments" primaryAction={<PaymentActionButtons />}>
        <Card>
          <PaymentsFilter
            query={queryParams}
            setQuery={updateQueryParams}
            paymentsOnDisplay={payments?.data.length ?? 0}
          />

          <LoaderContainer
            style={{ minHeight: "unset" }}
            loading={isLoading}
            error={error}
            onRetryClicked={refetch}
          >
            <PaymentsList payments={payments?.data ?? []} />
          </LoaderContainer>

          <Card.Section>
            <Stack distribution="center">
              {payments && payments.data.length > 0 && (
                <Pagination
                  label={`${queryParams.page ?? 1} / ${payments.pagination.total_pages}`}
                  hasNext={payments.pagination.total_pages > payments.pagination.page}
                  onNext={() => updateQueryParams("page", payments.pagination.page + 1)}
                  hasPrevious={payments.pagination.page > 1}
                  onPrevious={() =>
                    updateQueryParams("page", payments.pagination.page - 1)
                  }
                />
              )}
            </Stack>
          </Card.Section>
        </Card>
      </Page>
    </>
  );
}
