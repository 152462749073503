import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import RolesService from "app/api/roles";
import parseError from "app/lib/parseError";
import { capitalizeStr } from "app/utils/helpers";
import { makeOtherCategoriesEmpty, Permission, RoleEnum } from "../utils";

export default function useRolePermissions() {
  const { data, isLoading, error, refetch, isFetching } = useQuery(
    ["role_permissions"],
    async () => {
      const [rolesResponse, permissionsResponse] = await Promise.all([
        RolesService.listRoles(),
        RolesService.listPermissions(),
      ]);

      const roles = rolesResponse.data.data;

      const permissionsData = permissionsResponse.data.data;
      const sortedCategories = new Set(permissionsData.map(item => item.category));
      // @ts-expect-error
      const categories = [...sortedCategories].map(item => ({
        label: capitalizeStr(item),
        value: item,
      }));

      const checkPermmissionByRole = (roleName: string, action: string) => {
        if (roles) {
          const role = roles.find(item => item.name === roleName);
          return role ? role.permissions.includes(action) : false;
        }
        return false;
      };

      const permissions =
        permissionsData && roles
          ? permissionsData.map(item => ({
              id: item.id,
              category: capitalizeStr(item.category),
              description: capitalizeStr(item.description),
              owner: checkPermmissionByRole(RoleEnum.owner, item.id),
              admin: checkPermmissionByRole(RoleEnum.admin, item.id),
              support: checkPermmissionByRole(RoleEnum.support, item.id),
              developer: checkPermmissionByRole(RoleEnum.dev, item.id),
            }))
          : ([] as Permission[]);

      return {
        permissions: makeOtherCategoriesEmpty(permissions),
        roles,
        categories,
      };
    },
    { keepPreviousData: true }
  );

  return {
    permissions: data?.permissions,
    roles: data?.roles,
    categories: data?.categories,
    isFetching,
    isLoading,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
  };
}
