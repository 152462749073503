import { Card, Grid, Text } from "@shopify/polaris";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";

import useGetPaymentsAnalytics from "app/hooks/useGetPaymentAnalytics";
import { formatMoney } from "app/lib/money";

export default function Stats(props: {
  store_currency: string;
  total_balance: string;
  escrow_balance: string;
}) {
  const { store_currency, total_balance, escrow_balance } = props;

  const { id } = useParams<{ id: string }>();

  const { paymentAnalytics } = useGetPaymentsAnalytics({
    business_id: String(id),
  });

  const getFormat = ["NGN", "USD"].includes(store_currency) ? "fiat" : "digital";

  return (
    <GridWrapper>
      <Grid>
        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 3, xl: 3 }}>
          <Card
            title={
              <Text as="p" variant="bodySm">
                Transactions
              </Text>
            }
            sectioned
          >
            <Text variant="headingXl" as="h4">
              {paymentAnalytics?.total_count || 0}
            </Text>
          </Card>
        </Grid.Cell>

        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 4, xl: 4 }}>
          <Card
            title={
              <Text as="p" variant="bodySm">
                Total Pending Balance
              </Text>
            }
            sectioned
          >
            <Text variant="headingXl" as="h4">
              {formatMoney(Number(escrow_balance), getFormat, store_currency)}
            </Text>
          </Card>
        </Grid.Cell>

        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 5, xl: 5 }}>
          <Card
            title={
              <Text as="p" variant="bodySm">
                Total Available Balance
              </Text>
            }
            sectioned
          >
            <Text variant="headingXl" as="h4">
              {formatMoney(Number(total_balance), getFormat, store_currency)}
            </Text>
          </Card>
        </Grid.Cell>
      </Grid>
    </GridWrapper>
  );
}

export const GridWrapper = styled.div`
  margin-bottom: 14px;
`;
