import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import ComplianceService from "app/api/compliance";
import parseError from "app/lib/parseError";
import { GetDocumentTypesQuery } from "app/api/compliance/types";

export default function useGetDocumentTypes(params: GetDocumentTypesQuery = {}) {
  const { data, isLoading, error, refetch } = useQuery(["document-types", params], () =>
    ComplianceService.getDocumentTypes(params)
  );

  return {
    documentTypes: data?.data.data,
    isLoading,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
  };
}
