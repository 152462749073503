import request from "../request";
import { BaseResponse } from "../types";
import {
  GetWithdrawalsResponse,
  GetWithdrawalsQuery,
  GetWithdrawalResponse,
  GetMissingWithdrawalsResponse,
  UpdateWithdrawalPayload,
} from "./types";

const getWithdrawals = async (params?: GetWithdrawalsQuery) => {
  return request.get<GetWithdrawalsResponse>("/v1/businesses/payouts", { params });
};

const getWithdrawal = async (id: string) => {
  return request.get<GetWithdrawalResponse>(`/v1/businesses/payouts/${id}`);
};

const updateWithdrawalsStatus = async (id: string, payload: UpdateWithdrawalPayload) => {
  return request.put<BaseResponse>(`/v1/businesses/payouts/${id}/status`, payload);
};

const retryWithdrawals = async (id: string) => {
  return request.post<BaseResponse>(`/v1/businesses/payouts/groot/${id}/retry`);
};

const fulfillWithdrawals = async (id: string) => {
  return request.post<BaseResponse>(`/v1/businesses/payouts/groot/${id}/fulfill`);
};

const getMissingWithdrawals = async () => {
  return request.get<GetMissingWithdrawalsResponse>(
    `/v1/businesses/payouts/groot/missing-payouts`
  );
};

const resyncWithdrawals = async () => {
  return request.post<BaseResponse>(`/v1/businesses/payouts/groot/resync`);
};

const WithdrawalsService = {
  getWithdrawals,
  getWithdrawal,
  updateWithdrawalsStatus,
  retryWithdrawals,
  fulfillWithdrawals,
  getMissingWithdrawals,
  resyncWithdrawals,
};

export default WithdrawalsService;
