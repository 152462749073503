import styled from "@emotion/styled";
import { Stack, Card, Text } from "@shopify/polaris";
import { TransactionsItem } from "app/models/transaction";

interface Props {
  transaction: TransactionsItem;
}

export default function BalanceDetails({ transaction }: Props) {
  return (
    <Card>
      <Card.Section title="BALANCE DETAILS">
        <BalanceDetailsWrapper>
          <Stack alignment="center" distribution="equalSpacing">
            <Text as="p" variant="bodyMd">
              In hold
            </Text>
            <Text as="p" variant="bodyMd">
              {transaction.data.hold ?? 0} {transaction.data.currency}
            </Text>
          </Stack>

          <Stack alignment="center" distribution="equalSpacing">
            <Text as="p" variant="bodyMd">
              Pending
            </Text>
            <Text as="p" variant="bodyMd">
              {transaction.data.pending_balance ?? 0} {transaction.data.currency}
            </Text>
          </Stack>

          <Stack alignment="center" distribution="equalSpacing">
            <Text as="p" variant="bodyMd">
              Available
            </Text>

            <Text as="p" variant="bodyMd">
              {transaction.data.available_balance} {transaction.data.currency}
            </Text>
          </Stack>

          <Stack alignment="center" distribution="equalSpacing">
            <Text as="p" variant="bodyMd">
              Escrow
            </Text>

            <Text as="p" variant="bodyMd">
              {transaction.data.escrow_balance ?? 0} {transaction.data.currency}
            </Text>
          </Stack>

          <Stack alignment="center" distribution="equalSpacing">
            <Text as="p" variant="bodyMd">
              Savings
            </Text>

            <Text as="p" variant="bodyMd">
              {transaction.data.total_balance} {transaction.data.currency}
            </Text>
          </Stack>
        </BalanceDetailsWrapper>
      </Card.Section>
    </Card>
  );
}

const BalanceDetailsWrapper = styled.div`
  margin-top: 16px;
  display: grid;
  gap: 16px;
`;
