import AuthPageWrapper from "app/components/AuthPageWrapper";
import ForgotPasswordForm from "./components/ForgotPasswordForm";

export default function ForgotPassword() {
  return (
    <AuthPageWrapper>
      <ForgotPasswordForm onSubmit={() => {}} />
    </AuthPageWrapper>
  );
}
