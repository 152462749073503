import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import UserService from "app/api/users";
import Profile from "app/models/profile";

export default function useUpdateUser(id: string) {
  const queryClient = useQueryClient();

  const { isLoading, data, error, mutate, variables } = useMutation(
    ["user", id],
    (data: Partial<Profile>) => UserService.updateUser(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user", id]);
      },
    }
  );

  const updateUser = (data: Partial<Profile>) => {
    mutate(data);
  };

  const {
    isLoading: updatingPassword,
    data: updatePasswordResponse,
    error: updatePasswordError,
    mutate: updatePasswordMutate,
  } = useMutation(["user", id], (data: string) =>
    UserService.updateUserPassword(id ?? "", data)
  );

  const updateUserPassword = (data: string) => {
    updatePasswordMutate(data);
  };

  return {
    isLoading,
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    updateUser,
    updateUserVariables: variables,
    //
    updateUserPassword,
    updatingPassword,
    updatePasswordResponse: updatePasswordResponse?.data,
    updatePasswordError: updatePasswordError
      ? (parseError(updatePasswordError as AxiosError<{}>) as string)
      : undefined,
  };
}
