import styled from "@emotion/styled";
import { Button, OptionList } from "@shopify/polaris";
import { PaymentLinkType } from "app/hooks/useGetAnalytics";
import { capitalizeStr } from "app/utils/helpers";
import * as React from "react";
import ClickAway from "./ClickAway";

export interface OptionDescriptor {
  value: string;
  label: string;
}

interface Props {
  options: OptionDescriptor[];
  selected?: string[];
  onChange(selected: PaymentLinkType[]): void;
  size?: "medium" | "slim" | "large";
}

export default function GenericDropdownFilter(props: Props) {
  const { options, selected, onChange, size } = props;
  const [popoverActive, setPopoverActive] = React.useState(false);

  const togglePopoverActive = () => {
    setPopoverActive(value => !value);
  };

  const closePopover = () => {
    setPopoverActive(false);
  };

  return (
    <ClickAway onClickAway={closePopover}>
      <Container>
        <Button size={size ? size : "medium"} disclosure onClick={togglePopoverActive}>
          {selected
            ? selected[0].length > 3
              ? capitalizeStr(selected[0])
              : selected[0].toLocaleUpperCase()
            : "select"}
        </Button>
        {popoverActive ? (
          <>
            <Popover>
              <OptionList
                onChange={onChange}
                selected={selected ?? []}
                options={options}
              ></OptionList>
            </Popover>
          </>
        ) : (
          <></>
        )}
      </Container>
    </ClickAway>
  );
}

const Container = styled.div`
  display: flex;
  position: relative;
`;

const Popover = styled.div<{ small?: boolean }>`
  position: absolute;
  left: 0;
  top: 50px;
  border-radius: 8px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  z-index: 99;
  width: 200px;

  .options {
    padding: 5px;
    display: grid;
    row-gap: 10px;
  }
`;
