import * as React from "react";
import { Button, Card, Checkbox, Select, Spinner, Stack, Text } from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import { DataRow } from "./Details";
import Business from "app/models/business";
import useGetPaymentsAnalytics from "app/hooks/useGetPaymentAnalytics";
import useGetWithdrawalsAnalytics from "app/hooks/useGetWithdrawalAnalytics";
import { formatMoney } from "app/lib/money";
import { MerchantDetails } from "app/models/merchant";
import { GetAnalyticsParams } from "app/api/analytics/types";
import usePaymentLinkAnalytics from "app/hooks/useGetPaymentLinkAnalytics";
import useGetInvoiceAnalytics from "app/hooks/useGetInvoiceAnalytics";
import useListMerchantTiers from "../hooks/useListMerchantTiers";
import { capitalizeStr } from "app/utils/helpers";
import useUpdateMerchantLevel from "../hooks/useUpdateMerchantLevel";
import useModalState from "app/hooks/useModalState";
import ToggleLevelModal from "./ToggleLevelModal";

interface Props {
  businesses: Business[];
  merchant: MerchantDetails;
}

const allPaymentTypes = [
  {
    label: "All Types",
    value: "",
  },
  {
    label: "Payment Links",
    value: "payment_links",
  },
  {
    label: "Invoices",
    value: "invoices",
  },
];

const allWithdrawalTypes = [
  {
    label: "All Types",
    value: "",
  },
  {
    label: "Busha",
    value: "busha",
  },
  {
    label: "External",
    value: "external",
  },
];

export default function BusinessDetails(props: Props) {
  const { businesses, merchant } = props;

  const navigate = useNavigate();

  const { merchantId: id } = useParams<{ merchantId: string }>();

  const [selectedPaymentType, setSelectedPaymentType] = React.useState<
    "payment_link" | "invoices" | ""
  >("");

  const [selectedWithdrawalType, setSelectedWithdrawalType] = React.useState<
    "busha" | "external" | ""
  >("");

  const [selectedBusinessPayment, setSelectedBusinessPayment] = React.useState("");
  const [selectedBusinessWithdrawals, setSelectedBusinessWithdrawals] =
    React.useState("");

  const [paymentParams, setPaymentParams] = React.useState<GetAnalyticsParams>({
    merchant_id: String(id),
  });

  const [withdrawalParams, setWithdrawalParams] = React.useState<GetAnalyticsParams>({
    merchant_id: String(id),
  });

  const [paymentLinkParams, setPaymentLinkParams] = React.useState<GetAnalyticsParams>();
  const [invoiceParams, setInvoiceParams] = React.useState<GetAnalyticsParams>();

  const viewActivities = () => navigate(`/merchants/${id}/activities`);
  const viewDevices = () => navigate(`/merchants/${id}/devices`);

  const {
    isOpen: isToggleLevelOpen,
    openModal: openToggleLevelModal,
    closeModal: closeToggleLevelModal,
  } = useModalState(false);

  const businessesOption = businesses.map(business => ({
    label: business.name,
    value: business.id,
  }));

  const { tiers, isLoadingTiers } = useListMerchantTiers();
  const { updateLevel, loading } = useUpdateMerchantLevel();

  const { paymentAnalytics, isLoading: isLoadingPaymentAnalytics } =
    useGetPaymentsAnalytics(paymentParams);

  const { withdrawalAnalytics, isLoading: isLoadingWithdrawalAnalytics } =
    useGetWithdrawalsAnalytics(withdrawalParams);

  const { data: paymentLinkAnalytics, isLoading: isLoadingPaymentLinkAnalytics } =
    usePaymentLinkAnalytics(paymentLinkParams);

  const { invoiceAnalytics, isLoading: isLoadingInvoiceAnalytics } =
    useGetInvoiceAnalytics(invoiceParams);

  const handleChangePaymentAnalyticsQuery = (payload: string) => {
    if (!payload.length) {
      setSelectedBusinessPayment("");
      return setPaymentParams({ merchant_id: String(id) });
    }
    setSelectedBusinessPayment(payload);
    setPaymentParams({
      business_id: payload,
    });
  };

  const handleChangeWithdrawalAnalyticsQuery = (payload: string) => {
    if (!payload.length) {
      setSelectedBusinessWithdrawals("");
      return setWithdrawalParams({ merchant_id: String(id) });
    }
    setSelectedBusinessWithdrawals(payload);
    setWithdrawalParams({
      business_id: payload,
    });
  };

  const handleGetPaymentTypeAnalytics = (payload: "payment_link" | "invoices" | "") => {
    setSelectedPaymentType(payload);

    if (!payload.length) {
      setInvoiceParams(undefined);
      setPaymentLinkParams(undefined);
      setSelectedPaymentType("");
      setPaymentParams({
        merchant_id: id,
        business_id: selectedBusinessPayment,
      });
    }

    if (payload === "payment_link") {
      setPaymentLinkParams({
        business_id: selectedBusinessPayment,
        merchant_id: id,
      });
      setInvoiceParams(undefined);
    }

    if (payload === "invoices") {
      setInvoiceParams({
        business_id: selectedBusinessPayment,
        merchant_id: id,
      });
      setPaymentLinkParams(undefined);
    }
  };

  const handleGetWithdrawalTypeAnalytics = (payload: "busha" | "external" | "") => {
    setSelectedWithdrawalType(payload);

    if (!payload.length) {
      setWithdrawalParams({
        merchant_id: id,
        business_id: selectedBusinessWithdrawals,
      });
    }

    setWithdrawalParams({
      business_id: selectedBusinessWithdrawals,
      merchant_id: id,
      type: payload,
    });
  };

  const { control, setValue, reset, getValues } = useForm({
    defaultValues: {
      level: merchant.merchant_tier,
    },
  });

  const changeLevel = () => {
    const level = getValues("level");
    updateLevel(
      {
        merchantId: String(id),
        tierId: level,
      },
      {
        onSuccess: () => {
          closeToggleLevelModal();
        },
      }
    );
  };

  const onCancel = () => {
    closeToggleLevelModal();
    reset();
  };

  const loadingPaymentAnalytics =
    isLoadingPaymentAnalytics ||
    isLoadingPaymentLinkAnalytics ||
    isLoadingInvoiceAnalytics;

  return (
    <>
      <Card>
        <Card.Section title="MERCHANT LEVEL">
          <Stack vertical spacing="tight">
            {isLoadingTiers ? (
              <Spinner />
            ) : (
              tiers?.data.map((tier, idx) => (
                <Controller
                  key={idx.toString()}
                  control={control}
                  name="level"
                  render={({ field }) => (
                    <Checkbox
                      label={capitalizeStr(tier.name)}
                      name="level"
                      disabled={
                        merchant.merchant_tier === "enterprise" &&
                        tier.name === "standard"
                      }
                      checked={field.value === tier.name}
                      onChange={v => {
                        setValue("level", tier.name);
                        if (tier.name !== merchant.merchant_tier) {
                          openToggleLevelModal();
                        }
                      }}
                    />
                  )}
                />
              ))
            )}
          </Stack>
        </Card.Section>

        <Card.Section>
          <DataRow>
            <Text as="p" variant="bodySm">
              Payments
            </Text>

            <Stack alignment="center">
              <Select
                labelHidden
                label="All"
                name="sort"
                value={selectedBusinessPayment}
                options={[{ label: "All", value: "" }, ...businessesOption]}
                onChange={handleChangePaymentAnalyticsQuery}
              />
              <Select
                labelHidden
                label="All Types"
                name="sort"
                options={allPaymentTypes}
                value={selectedPaymentType}
                onChange={handleGetPaymentTypeAnalytics}
              />
            </Stack>
          </DataRow>

          {loadingPaymentAnalytics ? (
            <Spinner />
          ) : (
            <Text as="h2" variant="heading2xl" fontWeight="regular">
              {invoiceParams
                ? invoiceAnalytics?.total_count ?? 0
                : paymentLinkParams
                ? paymentLinkAnalytics?.data.total_count ?? 0
                : paymentAnalytics?.total_count ?? 0}
            </Text>
          )}

          <DataRow>
            <Text as="p" variant="bodySm">
              Payments processed
            </Text>
          </DataRow>

          {loadingPaymentAnalytics ? (
            <Spinner />
          ) : (
            <Text as="h2" variant="heading2xl" fontWeight="regular">
              {invoiceParams
                ? invoiceAnalytics?.display_currency
                : paymentLinkParams
                ? paymentLinkAnalytics?.data.display_currency
                : paymentAnalytics?.display_currency}
              {formatMoney(
                Number(
                  invoiceParams
                    ? invoiceAnalytics?.total_amount ?? 0
                    : paymentLinkParams
                    ? paymentLinkAnalytics?.data.total_amount ?? 0
                    : paymentAnalytics?.total_amount ?? 0
                )
              )}
            </Text>
          )}
        </Card.Section>
      </Card>

      <Card>
        <Card.Section>
          <DataRow>
            <Text as="p" variant="bodySm">
              Withdrawals
            </Text>

            <Stack alignment="center">
              <Select
                labelHidden
                label="All"
                name="sort"
                value={selectedBusinessWithdrawals}
                options={[{ label: "All", value: "" }, ...businessesOption]}
                onChange={handleChangeWithdrawalAnalyticsQuery}
              />
              <Select
                labelHidden
                label="All Types"
                name="sort"
                value={selectedWithdrawalType}
                onChange={handleGetWithdrawalTypeAnalytics}
                options={allWithdrawalTypes}
              />
            </Stack>
          </DataRow>

          {isLoadingWithdrawalAnalytics ? (
            <Spinner />
          ) : (
            <Text as="h2" variant="heading2xl" fontWeight="regular">
              {withdrawalAnalytics?.total_count ?? 0}
            </Text>
          )}

          <DataRow>
            <Text as="p" variant="bodySm">
              Withdrawals processed
            </Text>
          </DataRow>

          <Text as="h2" variant="heading2xl" fontWeight="regular">
            {withdrawalAnalytics?.display_currency}{" "}
            {formatMoney(Number(withdrawalAnalytics?.total_amount ?? 0))}
          </Text>
        </Card.Section>
      </Card>

      <Card>
        <Card.Section title="Activities">
          <Text as="p" variant="bodySm">
            See all {merchant.profile.first_name}'s account actions such as login,
            password change e.t.c
          </Text>

          <br />
          <Button onClick={viewActivities}>View Activities</Button>
        </Card.Section>

        <Card.Section title="Devices">
          <Text as="p" variant="bodySm">
            See all {merchant.profile.first_name}'s devices including web sessions &
            mobile applications.
          </Text>

          <br />
          <Button onClick={viewDevices}>View Devices</Button>
        </Card.Section>
      </Card>

      <ToggleLevelModal
        open={isToggleLevelOpen}
        title="Change Merchant Level"
        onClose={closeToggleLevelModal}
        onCancel={onCancel}
        loading={loading}
        onConfirm={changeLevel}
        confirmText="Change Level"
        text="This increases the business limit of this merchant to Six(6). Do you want to continue?"
      />
    </>
  );
}
