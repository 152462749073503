import * as React from "react";
import {
  AppliedFilterInterface,
  ChoiceList,
  FilterInterface,
  Filters,
  Stack,
} from "@shopify/polaris";

import { GetDonationsQuery } from "app/api/paymentLink/types";
import SearchFilter from "app/components/SearchFilter";

const searchOptions = [
  {
    label: "Donation Name",
    value: "name",
  },
  {
    label: "Business Id",
    value: "business_id",
  },
];

interface Props {
  query: GetDonationsQuery;
  setQuery: (q: GetDonationsQuery) => void;
}

export default function DonationsFilter(props: Props) {
  const { query, setQuery } = props;

  const [queryString, setQueryString] = React.useState("");

  const filters: FilterInterface[] = [
    {
      label: "Status",
      key: "active",
      filter: (
        <ChoiceList
          title="Status"
          titleHidden
          choices={[
            { label: "Active", value: "true" },
            { label: "Inactive", value: "false" },
          ]}
          selected={[query.active || ""]}
          onChange={t => {
            setQuery({ active: t[0] });
          }}
        />
      ),
      shortcut: true,
    },
  ];

  const removeFilter = (filter: keyof GetDonationsQuery) => {
    setQuery({ ...query, [filter]: undefined });
  };

  const appliedFilters: AppliedFilterInterface[] = Object.keys(query)
    .filter(
      key =>
        ["active"].includes(key) && query[key as keyof GetDonationsQuery] !== undefined
    )
    .map(key => {
      return {
        key,
        label: `${key}: ${query[key as keyof GetDonationsQuery]}`,
        onRemove: removeFilter,
      };
    });

  return (
    <Stack spacing="tight">
      <Stack.Item fill>
        <SearchFilter
          options={searchOptions}
          placeholder="Search by business ID or donation name"
        />
      </Stack.Item>

      <Filters
        hideQueryField
        hideTags
        filters={filters}
        appliedFilters={appliedFilters}
        queryValue={queryString}
        onQueryChange={setQueryString}
        onQueryClear={() => setQueryString("")}
        onClearAll={() => {
          setQuery({
            q: undefined,
            sort: undefined,
            page: 1,
            limit: 10,
            active: undefined,
          });
        }}
      />
    </Stack>
  );
}
