import * as React from "react";
import { Button, Modal, TextContainer } from "@shopify/polaris";
import useRecoverBusiness from "../hooks/useRecoverBusiness";

interface Props {
  businessId: string;
}
export default function RecoverBusinessModal(props: Props) {
  const { businessId } = props;

  const [active, setActive] = React.useState(true);

  const { recoverBusiness, isRecoveringBusiness } = useRecoverBusiness();

  return (
    <Modal
      title="Recover Business"
      activator={
        <Button onClick={() => setActive(true)} primary>
          Recover Business
        </Button>
      }
      onClose={() => setActive(false)}
      primaryAction={{
        content: "Recover Business",
        loading: isRecoveringBusiness,
        onAction: () => {
          recoverBusiness(businessId, {
            onSuccess() {
              setActive(false);
            },
          });
        },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => setActive(false),
        },
      ]}
      open={active}
    >
      <Modal.Section>
        <TextContainer>
          <p>
            Your are about to recover this closed business. Are you sure you want to
            continue?
          </p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
}
