import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import CountryService from "app/api/countries";
import { AddCountryPayload } from "app/api/countries/type";
import { useToast } from "app/providers/ToastProvider";
import parseError from "app/lib/parseError";

export default function useAddCountry() {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (payload: AddCountryPayload) => CountryService.addCountry(payload),
    {
      onError: (error: AxiosError<{}>) => {
        const errorMessage = parseError(error) as string;
        showToast(errorMessage || "", "error");
      },
      onSuccess: res => {
        queryClient.invalidateQueries(["countries"]);
        const message = res.data.message || "";
        showToast(message, "success");
      },
    }
  );

  return {
    addCountry: mutate,
    addingCountryInProgress: isLoading,
  };
}
