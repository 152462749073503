import styled from "@emotion/styled";

import { formatDateStr } from "app/utils/helpers";

type Event = {
  event: string;
  date: string;
};

interface Props {
  data: Event[];
}
export default function Timeline(props: Props) {
  const { data } = props;

  const list = groupBy(data);

  return (
    <Container>
      {Object.keys(list).map(key => {
        const rows = list[key];
        return (
          <div key={key}>
            <h3 className="date-header">{key}</h3>

            {rows.map((row, index) => {
              return (
                <div key={index} className="row">
                  <div className="dot-container">
                    <div className="dot"></div>
                  </div>
                  <div className="event">{row.event}</div>
                  <div className="time">{formatDateStr(row.date, "h:mm a")}</div>
                </div>
              );
            })}
          </div>
        );
      })}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .date-header {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: #637381;
    margin-bottom: 22px;
  }

  .row {
    display: flex;
    gap: 14px;

    .event {
      color: #212b36;
      font-size: 14px;
    }

    .time {
      margin-left: auto;
      font-size: 14px;
      color: #637381;
      white-space: nowrap;
    }

    .event,
    .time {
      line-height: 1;
    }

    .dot-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .dot {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        background: #c4cdd5;
        border: 2px solid #f4f6f8;
      }

      &::after {
        content: "";
        border-width: 1px;
        border-color: rgb(90, 92, 94);
        display: block;
        width: 0px;
        height: 40px;
        margin-top: 2px;
        margin-bottom: 2px;
        border-style: solid;
      }
    }

    &:first-of-type {
      .dot {
        background: #007ace;
      }
    }

    &:last-of-type {
      .dot-container::after {
        height: 20px;
      }
    }
  }
`;

function groupBy(list: Event[]) {
  return list.reduce((group, item) => {
    const key = formatDateStr(item.date, "MMMM D");

    if (!group[key]) {
      group[key] = [];
    }

    group[key].push(item);

    return group;
  }, {} as Record<string, Event[]>);
}
