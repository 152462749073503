import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import BusinessesService from "app/api/businesses";
import parseError from "app/lib/parseError";
import { useToast } from "app/providers/ToastProvider";

export default function useRecoverBusiness() {
  const queryClient = useQueryClient();
  const showToast = useToast();

  const { mutate, isLoading } = useMutation(BusinessesService.recoverBusiness, {
    onError: (error: AxiosError<{}>) => {
      const errorMessage = parseError(error) as string;

      showToast(errorMessage || "", "error");
    },
    onSuccess(response, businessId) {
      queryClient.invalidateQueries(["business", businessId]);
      showToast(response.data.message || "", "success");
    },
  });

  return { recoverBusiness: mutate, isRecoveringBusiness: isLoading };
}
