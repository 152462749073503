import * as Yup from "yup";
import { FormLayout, Modal, TextField } from "@shopify/polaris";
import useUpdateGrootPayoutStatus from "../hooks/useUpdateWIthdrawalsStatus";
import { UpdateWithdrawalPayload } from "app/api/withdrawals/types";
import { useForm, Controller } from "react-hook-form";
import { capitalize } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  action?: PayoutModalAction;
}

export type PayoutModalAction =
  | "cancel"
  | "unqueue"
  | "complete"
  | "fulfill"
  | "retry";

export default function UpdatePayoutStatusModal(props: Props) {
  const { isOpen, closeModal, action } = props;

  const {
    isUpdating,
    updatePayoutStatus,
    isRetrying,
    retryPayout,
    isFulfilling,
    fulfillPayout,
  } = useUpdateGrootPayoutStatus();

  const handleOnPrimaryAction = () => {
    if (action === "cancel") {
      updatePayoutStatus({ status: "cancelled" });
    } else if (action === "unqueue") {
      updatePayoutStatus({ status: "pending" });
    } else if (action === "fulfill") {
      fulfillPayout();
    } else {
      retryPayout();
    }
  };

  const schema = Yup.object().shape({
    fee: Yup.string().required("Fee is required"),
  });

  const {
    setValue,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<UpdateWithdrawalPayload>({
    resolver: yupResolver(schema),
  });

  const handleCloseModal = () => {
    if (isUpdating || isFulfilling || isRetrying) return;

    closeModal();
  };

  const onSubmit = (v: any) => {
    if (v.hash) {
      updatePayoutStatus({ status: "completed", ...v });
    } else {
      updatePayoutStatus({ status: "completed", fee: v.fee });
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      title="withdrawal"
      primaryAction={{
        content: action === "cancel" ? "Yes" : capitalize(action),
        onAction:
          action === "complete"
            ? handleSubmit(onSubmit)
            : handleOnPrimaryAction,

        loading: isUpdating || isFulfilling || isRetrying,
        destructive: action === "cancel",
      }}
      secondaryActions={[
        {
          content: action === "cancel" ? "No" : "Cancel",
          onAction: handleCloseModal,
        },
      ]}
    >
      <Modal.Section>
        {action === "complete" ? (
          <FormLayout>
            <Controller
              name="hash"
              control={control}
              render={({ field }) => (
                <TextField
                  value={field.value}
                  label="Payout Hash"
                  name="hash"
                  onChange={(v: string) => {
                    setValue("hash", v);
                  }}
                  autoComplete="off"
                  error={errors.hash?.message}
                />
              )}
            />
            <Controller
              name="fee"
              control={control}
              render={({ field }) => (
                <TextField
                  autoComplete="off"
                  label="Fee"
                  name="fee"
                  onChange={(v) => {
                    if (!isNaN(Number(v))) {
                      setValue("fee", v);
                    }
                  }}
                  suffix="NGN"
                  error={errors.fee?.message}
                  value={field.value}
                  helpText="Enter the cost of manually processing this payout"
                />
              )}
            />
          </FormLayout>
        ) : (
          <p>You are about to {action} this payout. Do you wish to continue?</p>
        )}
      </Modal.Section>
    </Modal>
  );
}
