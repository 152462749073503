import * as React from "react";
import {
  Card,
  ChoiceList,
  FilterInterface,
  Filters,
  Select,
  Stack,
  Text,
} from "@shopify/polaris";
import { GetFeedbacksQuery } from "app/api/feedbacks/types";
import SearchFilter from "app/components/SearchFilter";
import { customerType, ratingType, searchOptions } from "./utils";

interface Props {
  query: GetFeedbacksQuery;
  setQuery: (q: GetFeedbacksQuery) => void;
  numOfFeedbacks: number;
}

export default function FeedbackFilter(props: Props) {
  const { numOfFeedbacks, query, setQuery } = props;

  const [queryString, setQueryString] = React.useState("");

  const filters: FilterInterface[] = [
    {
      label: "Rating",
      key: "rating_below",
      filter: (
        <ChoiceList
          title="Rating"
          name="rating_below"
          selected={[query.rating_below || ""]}
          choices={ratingType}
          onChange={t => setQuery({ rating_below: t[0] })}
          titleHidden
        />
      ),
      shortcut: true,
    },
    {
      label: "Type",
      key: "is_merchant",
      filter: (
        <ChoiceList
          title="Type"
          name="is_merchant"
          selected={[query.is_merchant || ""]}
          choices={customerType}
          onChange={t => setQuery({ is_merchant: t[0] })}
          titleHidden
        />
      ),
      shortcut: true,
    },
  ];

  const removeFilter = React.useCallback(
    (filter: string) => {
      setQuery({ ...query, [filter]: undefined });
    },
    [setQuery, query]
  );

  const appliedFilters = Object.keys(query)
    .filter(
      key =>
        ["rating_below", "is_merchant"].includes(key) &&
        query[key as keyof GetFeedbacksQuery] !== undefined
    )
    .map(key => ({
      key,
      label: `${key}: ${query[key as keyof GetFeedbacksQuery]}`,
      onRemove: removeFilter,
    }));

  return (
    <>
      <Card.Section>
        <Stack vertical>
          <Text as="p" variant="bodySm">
            Enter search text, then press{" "}
            <span style={{ fontWeight: "bold" }}>Enter/Command</span>
          </Text>
        </Stack>
      </Card.Section>

      <Card.Section>
        <Stack spacing="tight">
          <Stack.Item fill>
            <SearchFilter options={searchOptions} />
          </Stack.Item>

          <Filters
            filters={filters}
            appliedFilters={appliedFilters}
            queryValue={queryString}
            onQueryChange={setQueryString}
            onQueryClear={() => {
              setQueryString("");
            }}
            onClearAll={() =>
              setQuery({
                sort: undefined,
                rating_below: undefined,
                is_merchant: undefined,
              })
            }
            hideQueryField
            hideTags
          />
        </Stack>
      </Card.Section>

      <Card.Section>
        <Stack distribution="equalSpacing" alignment="center">
          <Stack spacing="extraLoose" alignment="center">
            <p>
              Showing {numOfFeedbacks} feedback
              {Number(numOfFeedbacks) > 1 ? "s" : ""}{" "}
            </p>
          </Stack>

          <Select
            labelInline
            label="Sort by"
            name="sort"
            value={query.sort}
            options={[
              { label: "Newest", value: "desc" },
              { label: "Oldest", value: "asc" },
            ]}
            onChange={(s: "asc" | "desc") => {
              setQuery({ sort: s });
            }}
          />
        </Stack>
      </Card.Section>
    </>
  );
}
