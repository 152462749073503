import request from "../request";
import {
  GetConnectTradeResponse,
  GetConnectTradesQuery,
  GetConnectTradesResponse,
} from "./type";

const getConnectTrades = (params?: GetConnectTradesQuery) => {
  return request.get<GetConnectTradesResponse>("/v1/businesses/connect/trades", {
    params,
  });
};

const getConnectTrade = (tradeId: string) => {
  return request.get<GetConnectTradeResponse>(`/v1/businesses/connect/trades/${tradeId}`);
};

const ConnectTradeService = {
  getConnectTrade,
  getConnectTrades,
};

export default ConnectTradeService;
