import request from "../request";
import {
  Activate2FAPayload,
  Activate2FAResponse,
  DeActivate2FAResponse,
  GetProfileResponse,
  Initiate2FAResponse,
  UpdatePasswordPayload,
  UpdatePasswordResponse,
  UpdateProfilePayload,
  UpdateProfileResponse,
} from "./types";

const getProfile = async () => {
  return await request.get<GetProfileResponse>("/v1/me");
};

const initiate2FA = async () => {
  return await request.get<Initiate2FAResponse>("/v1/me/two-step");
};

const activate2FA = async (payload: Activate2FAPayload) => {
  return await request.post<Activate2FAResponse>("/v1/me/two-step", payload);
};

const deActivate2FA = async () => {
  return await request.delete<DeActivate2FAResponse>("/v1/me/two-step");
};

const updateProfile = async (payload: UpdateProfilePayload) => {
  return await request.put<UpdateProfileResponse>("/v1/me", payload);
};

const updatePassword = async (payload: UpdatePasswordPayload) => {
  return await request.put<UpdatePasswordResponse>("/v1/me/password", payload);
};

const ProfileServices = {
  getProfile,
  initiate2FA,
  activate2FA,
  deActivate2FA,
  updateProfile,
  updatePassword,
};

export default ProfileServices;
