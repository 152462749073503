import * as React from "react";
import { Navigate, RouteObject } from "react-router-dom";

import Login from "app/views/Login";
import DashboardWrapper from "app/components/DashboardWrapper";
import NotFound from "app/views/NotFound";
import Invite from "app/views/Invite";
import ForgotPassword from "app/views/ForgotPassword";
import ProfileProvider from "app/providers/ProfileProvider";
import DashboardTopBar from "app/components/TopBar";
import SignOut from "app/views/SignOut";
import { SIGN_OUT_PATH } from "app/constants/variables";
import Profile from "app/views/Profile";
import Users from "app/views/Users";
import UserDetails from "app/views/UserDetails";
import Currencies from "app/views/Currencies";
import CurrencyDetails from "app/views/CurrencyDetails";
import Books from "app/views/Books";
import BookDetails from "app/views/BookDetails";
import AuditLogs from "app/views/AuditLogs";
import Invoices from "app/views/Invoices";
import InvoiceDetails from "app/views/InvoiceDetails";
import Compliance from "app/views/Compliance";
import PaymentLinks from "app/views/PaymentLinks";
import PaymentsDetails from "app/views/PaymentsDetails";
import PaymentLinksDetails from "app/views/PaymentLinkDetails";
import PaymentLinkTransactions from "app/views/PaymentLinkTransactions";
import PaymentLinkTransactionDetails from "app/views/PaymentLinkTransactionDetails";
import Payments from "app/views/Payments";
import Businesses from "app/views/Businesses";
import BusinessDetails from "app/views/BusinessDetails";
import Merchants from "app/views/Merchants";
import MerchantsDetails from "app/views/MerchantsDetails.tsx";
import ComplianceDetails from "app/views/ComplianceDetails";
import Withdrawals from "app/views/Withdrawals";
import WithdrawalsDetails from "app/views/WithdrawalsDetails";
import Donations from "app/views/Donations";
import DonationDetails from "app/views/DonationsDetails";
import CountrySettings from "app/views/CountrySettings";
import CountrySettingsDetails from "app/views/CountrySettingsDetails";
import DocumentSettings from "app/views/DocumentsSettings";
import DocumentsSettingsDetails from "app/views/DocumentsSettingsDetails";
import BusinessSettings from "app/views/BusinessSettings";
import BusinessSettingsDetails from "app/views/BusinessSettingsDetails";
import MerchantActivities from "app/views/MerchantActivities";
import MerchantDevices from "app/views/MerchantDevices";
import Analytics from "app/views/Analytics";
import Feedbacks from "app/views/Feedbacks";
import TeamActivities from "app/views/TeamActivities";
import TeamMembersDetails from "app/views/BusinessDetails/components/TeamMembersDetails";
import RoleManagement from "app/views/RoleManagement";
import RolePermissionsEdit from "app/views/RolePermissionsEdit";
import Connect from "app/views/Connect";
import ConnectDetails from "app/views/ConnectDetails";
import TransactionsDetails from "app/views/TransactionsDetails";
import Transactions from "app/views/Transactions";
import Atlas from "app/views/Atlas";

const SettingsWrapper = React.lazy(() => import("app/components/SettingsWrapper"));

const routes: RouteObject[] = [
  {
    path: "/invite/:token",
    element: <Invite />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: `/${SIGN_OUT_PATH}`,
    element: <SignOut />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/atlas",
    element: <Atlas />,
  },
  {
    element: (
      <ProfileProvider>
        <DashboardWrapper topBar={<DashboardTopBar />} />
      </ProfileProvider>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="/businesses" replace={true} />,
      },
      {
        path: "/invoices",
        element: <Invoices />,
      },
      {
        path: "/invoices/:invoiceId",
        element: <InvoiceDetails />,
      },
      {
        path: "/withdrawals",
        element: <Withdrawals />,
      },
      {
        path: "/withdrawals/:id",
        element: <WithdrawalsDetails />,
      },
      {
        path: "/transactions/:id",
        element: <TransactionsDetails />,
      },
      {
        path: "/transactions",
        element: <Transactions />,
      },
      {
        path: "/merchants",
        children: [
          {
            index: true,
            element: <Merchants />,
          },
          {
            path: ":merchantId",
            element: <MerchantsDetails />,
          },
          {
            path: ":merchantId/activities",
            element: <MerchantActivities />,
          },
          {
            path: ":merchantId/devices",
            element: <MerchantDevices />,
          },
        ],
      },
      {
        path: "/payments",
        element: <Payments />,
      },
      {
        path: "/connect",
        children: [
          {
            index: true,
            element: <Connect />,
          },
          {
            path: ":id",
            element: <ConnectDetails />,
          },
        ],
      },
      {
        path: "/payments/:id",
        element: <PaymentsDetails />,
      },
      {
        path: "/payment-links",
        element: <PaymentLinks />,
      },
      {
        path: "/payment-links/:id",
        element: <PaymentLinksDetails />,
      },
      {
        path: "/donations",
        element: <Donations />,
      },
      {
        path: "/donations/:id",
        element: <DonationDetails />,
      },
      {
        path: "/payment-link/transactions",
        element: <PaymentLinkTransactions />,
      },
      {
        path: "/payment-link/transactions/:id",
        element: <PaymentLinkTransactionDetails />,
      },
      {
        path: "/businesses",
        element: <Businesses />,
      },
      {
        path: "/businesses/:businessId",
        element: <BusinessDetails />,
      },
      {
        path: "/businesses/:businessId/team",
        element: <TeamMembersDetails />,
      },
      {
        path: "/businesses/:id/team-activities",
        element: <TeamActivities />,
      },
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/users/:id",
        element: <UserDetails />,
      },
      {
        path: "/compliance/:type",
        element: <Compliance />,
      },
      {
        path: "/compliance/:type/:businessId",
        element: <ComplianceDetails />,
      },
      {
        path: "/analytics",
        element: <Analytics />,
      },
      {
        path: "feedbacks",
        element: <Feedbacks />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/books",
        element: <Books />,
      },
      {
        path: "/books/:id",
        element: <BookDetails />,
      },
      {
        path: "/audit",
        element: <AuditLogs />,
      },
      {
        path: "/settings",
        element: (
          <React.Suspense fallback="loading">
            <SettingsWrapper />
          </React.Suspense>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="/settings/currencies" replace={true} />,
          },
          {
            path: "/settings/currencies",
            element: <Currencies />,
          },
          {
            path: "/settings/currencies/:code",
            element: <CurrencyDetails />,
          },
          {
            path: "countries",
            element: <CountrySettings />,
          },
          {
            path: "countries/:code",
            element: <CountrySettingsDetails />,
          },
          {
            path: "documents",
            element: <DocumentSettings />,
          },
          {
            path: "documents/:action",
            element: <DocumentsSettingsDetails />,
          },
          {
            path: "businesses",
            element: <BusinessSettings />,
          },
          {
            path: "businesses/:action",
            element: <BusinessSettingsDetails />,
          },
          {
            path: "roles",
            element: <RoleManagement />,
          },
          {
            path: "roles/:category",
            element: <RolePermissionsEdit />,
          },
        ],
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
