import * as React from "react";
import { useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { TokenActions, useAuthTokensDispatch } from "app/providers/TokenProvider";
import AuthServices from "app/api/auth";
import { LoginPayload } from "app/api/auth/types";
import useAuth from "app/hooks/useAuth";
import parseError from "app/lib/parseError";
import { AxiosError } from "axios";
import atlasActions from "app/constants/atlasAction";
import { ATLAS_URL } from "app/constants/variables";

export default function useLogin() {
  const navigate = useNavigate();
  const location = useLocation();

  const isLoggedIn = useAuth();
  const dispatch = useAuthTokensDispatch();

  React.useEffect(() => {
    if (isLoggedIn) navigate(location.state ? location.state.from || "/" : "/");
  }, [navigate, isLoggedIn, location.state]);

  const [activeView, setActiveView] = React.useState<"login" | "twoFA">("login");
  const goBack = () => setActiveView("login");

  const logUserIn = (token: string) => {
    dispatch({
      type: TokenActions.setTokens,
      payload: { access_token: token },
    });

    // check for atlas and send token
    const hasAtlasWrapper = window !== window.parent;
    if (hasAtlasWrapper)
      window.parent.postMessage(
        {
          action: atlasActions.SET_TOKEN,
          data: { access_token: token },
        },
        { targetOrigin: ATLAS_URL }
      );
  };

  const loginMutation = useMutation(AuthServices.login, {
    onSuccess: () => {
      setActiveView("twoFA");
    },
  });

  const loginConfirmationMutation = useMutation(AuthServices.confirmLogin, {
    onSuccess: d => {
      logUserIn(d.data.access_token);
    },
  });

  const resendConfirmationCodeMutation = useMutation(
    AuthServices.resendLoginConfirmationCode,
    {
      onSuccess: d => {
        //
      },
    }
  );

  const handleLoginSubmit = (values: LoginPayload) => {
    loginMutation.mutate(values);
  };

  const handleLoginConfirmationSubmit = (values: { code: string }) => {
    if (loginMutation.data)
      loginConfirmationMutation.mutate({
        confirmation_token: loginMutation.data.data.confirmation_token,
        ...values,
      });
  };

  const handleResendConfirmationCode = () => {
    if (loginMutation.data)
      resendConfirmationCodeMutation.mutate(loginMutation.data.data.confirmation_token);
  };

  return {
    activeView,
    goBack,
    //
    handleLoginSubmit,
    loginPending: loginMutation.isLoading,
    loginError: loginMutation.error
      ? (parseError(loginMutation.error as AxiosError<{}>) as string)
      : null,
    loginMessage: loginMutation.data?.data.message,
    //
    handleLoginConfirmationSubmit,
    loginConfirmationPending: loginConfirmationMutation.isLoading,
    loginConfirmationError: loginConfirmationMutation.error
      ? (parseError(loginMutation.error as AxiosError<{}>) as string)
      : null,
    //
    handleResendConfirmationCode,
    resendConfirmationPending: resendConfirmationCodeMutation.isLoading,
    resendConfirmationError: resendConfirmationCodeMutation.error
      ? (parseError(loginMutation.error as AxiosError<{}>) as string)
      : null,
    resendConfirmationMessage: resendConfirmationCodeMutation.data?.data.message,
  };
}
