import AnalyticsCard from "./AnalyticsCard";
import useGetPaymentLinks from "app/hooks/useGetPaymentLinks";

export default function Donations() {
  const { data, isLoading, error, refetch } = useGetPaymentLinks("donation");

  const donationsData = data?.pagination.total_entries_size ?? "";

  return (
    <AnalyticsCard
      title="Donations"
      viewLink="/donations"
      amount={donationsData}
      loading={isLoading}
      error={error}
      refetch={refetch}
    />
  );
}
