import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import PaymentsService from "app/api/payments";
import { useToast } from "app/providers/ToastProvider";

export default function useUpdateConfirmationCount() {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const { data, error, isLoading, mutate } = useMutation(
    PaymentsService.updateConfirmation,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["payments"]);
        showToast("Confirmation count updated successfully", "success");
      },

      onError: (error) => {
        if (!error) return;
        const errorMessage = parseError(error as AxiosError<{}>) as string;
        showToast(errorMessage || "", "error");
      },
    }
  );

  return {
    updateConfirmationCount: mutate,
    isLoading,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    data,
  };
}
