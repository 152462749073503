import * as React from "react";
import {
  Badge,
  Button,
  Card,
  Heading,
  Icon,
  IconSource,
  Layout,
  Modal,
  Stack,
} from "@shopify/polaris";
import { DesktopMajor } from "@shopify/polaris-icons";

export default function DevicesSection() {
  const [confirmIsOpen, setConfirmIsOpen] = React.useState(false);
  const toggleConfirmDialog = () => setConfirmIsOpen(o => !o);

  return (
    <Layout.AnnotatedSection
      title="Devices"
      description="You're currently logged in to Grey on these devices. If you don't recognize a device, log out to keep your account secure."
    >
      <Card>
        <Card.Section>
          <Stack distribution="equalSpacing">
            <Heading>Logged in</Heading>
            <Button plain onClick={toggleConfirmDialog}>
              Log out all devices
            </Button>
          </Stack>
        </Card.Section>
        <Card.Section>
          <Stack distribution="equalSpacing">
            <Stack vertical spacing="tight">
              <Stack>
                <Icon source={DesktopMajor as IconSource} />
                <Button plain>Firefox on Windows</Button>
                <Badge status="info">This Device</Badge>
              </Stack>
              <p>Today at 6:54am</p>
              <p>Lagos (Lagos), Nigeria</p>
            </Stack>
            <Button onClick={toggleConfirmDialog}>Log out</Button>
          </Stack>
        </Card.Section>
        <Card.Section>
          <Stack distribution="equalSpacing">
            <Stack vertical spacing="tight">
              <Stack>
                <Icon source={DesktopMajor as IconSource} />
                <Button plain>Chrome on Mac OS X</Button>
              </Stack>
              <p>Monday at 2:41 pm</p>
              <p>Poplar (Tower Hamlets), United Kingdom</p>
            </Stack>
            <Button onClick={toggleConfirmDialog}>Log out</Button>
          </Stack>
        </Card.Section>
      </Card>
      <Modal
        title="Logout of Current Device?"
        open={confirmIsOpen}
        onClose={toggleConfirmDialog}
        primaryAction={{
          content: "Log out",
          destructive: true,
          onAction: toggleConfirmDialog,
        }}
        secondaryActions={[{ content: "Cancel", onAction: toggleConfirmDialog }]}
      >
        <Modal.Section>
          <p>
            To continue using Wanda, you'll have to log in again with a valid email
            address and password.
          </p>
        </Modal.Section>
      </Modal>
    </Layout.AnnotatedSection>
  );
}
