import * as React from "react";
import { Card, Form, FormLayout, Modal, Select } from "@shopify/polaris";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { CreateUserPayload } from "app/api/users/types";
import roles from "app/constants/roles";
import useCreateUser from "app/views/Users/hooks/useCreateUser";
import { useToast } from "app/providers/ToastProvider";

interface Props {
  open: boolean;
  onClose: () => void;
}
export default function EditRoleModal(props: Props) {
  const { open, onClose } = props;

  const { createUser, error, loading, response } = useCreateUser();

  const showToast = useToast();

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<CreateUserPayload>({
    defaultValues: {
      role: roles[0].value,
    },

    resolver: yupResolver(
      Yup.object().shape({
        role: Yup.string().required("Role is required"),
      })
    ),
  });

  const onCancel = () => {
    reset();
    onClose();
  };

  React.useEffect(() => {
    if (response) {
      showToast(response, "success");
      onClose();
      reset();
    }
  }, [response, showToast, onClose, reset]);

  React.useEffect(() => {
    if (error) {
      showToast(error, "error");
    }
  }, [error, showToast]);

  return (
    <Modal
      title="Edit Merchant Role"
      open={open}
      onClose={onCancel}
      primaryAction={{
        content: "Save changes",
        onAction: handleSubmit(createUser),
        loading,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onCancel,
        },
      ]}
    >
      <Modal.Section>
        <Card subdued>
          <Card.Section>
            <Form onSubmit={handleSubmit(createUser)}>
              <FormLayout>
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <Select
                      label="Role"
                      options={roles}
                      name="role"
                      value={field.value}
                      onChange={(v) => setValue("role", v)}
                      error={errors.role?.message}
                    />
                  )}
                />
              </FormLayout>
            </Form>
          </Card.Section>
        </Card>
      </Modal.Section>
    </Modal>
  );
}
