import styled from "@emotion/styled";
import { IndexTable, Link } from "@shopify/polaris";
import TableWrapper from "app/components/TableWrapper";

import { CurrencyItem } from "app/models/currency";
import { formatDateStr } from "app/utils/helpers";

interface Props {
  currencies: CurrencyItem[];
}
export default function CurrencyList(props: Props) {
  const { currencies } = props;

  return (
    <TableWrapper>
      <IndexTable
        selectable={false}
        resourceName={{ plural: "currencies", singular: "currency" }}
        itemCount={currencies.length}
        headings={[
          { title: "Name" },
          { title: "Symbol" },
          { title: "Type" },
          { title: "Date" },
        ]}
      >
        {currencies.map((currency, i) => {
          return (
            <IndexTable.Row id={currency.code} key={currency.code} position={i}>
              <IndexTable.Cell>
                <CellPadding>
                  <Link
                    removeUnderline
                    url={`/settings/currencies/${currency.code}`}
                  >
                    {currency.name}
                  </Link>
                </CellPadding>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <CellPadding>{currency.code}</CellPadding>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <CellPadding>{currency.type}</CellPadding>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <CellPadding>
                  {formatDateStr(
                    currency.created_at,
                    "MMMM D, YYYY [at] hh:mma"
                  )}
                </CellPadding>
              </IndexTable.Cell>
            </IndexTable.Row>
          );
        })}
      </IndexTable>
    </TableWrapper>
  );
}

const CellPadding = styled.div`
  padding: 13px 0px;
`;
