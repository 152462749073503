import styled from "@emotion/styled";
import { Badge, Card, Divider, Link, Stack, Text } from "@shopify/polaris";

import { Owner } from "app/models/compliance";
import { capitalizeStr } from "app/utils/helpers";

interface Props {
  owner: Owner;
}
export default function OwnerDetails(props: Props) {
  const { owner } = props;

  return (
    <Card
      title={
        <Stack>
          <Text as="h2" variant="headingMd">
            {capitalizeStr(owner.first_name)} {capitalizeStr(owner.last_name)}
          </Text>
          <Badge>{capitalizeStr(owner.role)}</Badge>
        </Stack>
      }
      sectioned
    >
      <Stack vertical>
        <Stack distribution="fillEvenly">
          <InfoDetail>
            <Text as="p" variant="bodySm" fontWeight="semibold">
              PHONE NUMBER
            </Text>

            <Text as="p" variant="bodyMd" color="subdued">
              {owner.phone_number}
            </Text>
            <Divider />
          </InfoDetail>

          <InfoDetail>
            <Text as="p" variant="bodySm" fontWeight="semibold">
              NATIONALITY
            </Text>
            <Text as="p" variant="bodyMd" color="subdued">
              {owner.nationality}
            </Text>
            <Divider />
          </InfoDetail>
        </Stack>

        <Stack distribution="fillEvenly">
          <InfoDetail>
            <Text as="p" variant="bodySm" fontWeight="semibold">
              DATE OF BIRTH
            </Text>

            <Text as="p" variant="bodyMd" color="subdued">
              {owner.date_of_birth}
            </Text>
            <Divider />
          </InfoDetail>

          <InfoDetail>
            <Text as="p" variant="bodySm" fontWeight="semibold">
              GENDER
            </Text>
            <Text as="p" variant="bodyMd" color="subdued">
              {capitalizeStr(owner.gender)}
            </Text>
            <Divider />
          </InfoDetail>
        </Stack>

        <Stack distribution="fillEvenly">
          <InfoDetail>
            <Text as="p" variant="bodySm" fontWeight="semibold">
              RESIDENTIAL ADDRESS
            </Text>

            <Text as="p" variant="bodyMd" color="subdued">
              {owner.address}
            </Text>
            <Divider />
          </InfoDetail>

          <InfoDetail>
            <Text as="p" variant="bodySm" fontWeight="semibold">
              CITY
            </Text>
            <Text as="p" variant="bodyMd" color="subdued">
              {owner.city}
            </Text>
            <Divider />
          </InfoDetail>
        </Stack>

        <Stack distribution="fillEvenly">
          <InfoDetail>
            <Text as="p" variant="bodySm" fontWeight="semibold">
              STATE OR REGION
            </Text>

            <Text as="p" variant="bodyMd" color="subdued">
              {owner.state}
            </Text>
            <Divider />
          </InfoDetail>

          <InfoDetail>
            <Text as="p" variant="bodySm" fontWeight="semibold">
              COUNTRY
            </Text>
            <Text as="p" variant="bodyMd" color="subdued">
              {owner.country}
            </Text>
            <Divider />
          </InfoDetail>
        </Stack>

        <Stack distribution="fillEvenly">
          <InfoDetail>
            <Text as="p" variant="bodySm" fontWeight="semibold">
              IDENTIFICATION DOCUMENT TYPE
            </Text>

            <Text as="p" variant="bodyMd" color="subdued">
              {owner.document.type}
            </Text>
            <Divider />
          </InfoDetail>

          <InfoDetail>
            <Text as="p" variant="bodySm" fontWeight="semibold">
              IDENTIFICATION DOCUMENT NUMBER
            </Text>
            <Text as="p" variant="bodyMd" color="subdued">
              {owner.document.number}
            </Text>
            <Divider />
          </InfoDetail>
        </Stack>

        <Stack distribution="fillEvenly">
          <InfoDetail>
            <Text as="p" variant="bodySm" fontWeight="semibold">
              IDENTIFICATION DOCUMENT
            </Text>

            <Text as="p" variant="bodyMd" color="subdued">
              <Link url={owner.document.file} external removeUnderline>
                <Text as="p" variant="bodyMd" fontWeight="medium">
                  {owner.document.file || "N/A"}
                </Text>
              </Link>
            </Text>
          </InfoDetail>

          <InfoDetail>
            <Text as="p" variant="bodySm" fontWeight="semibold">
              POLITICAL EXPOSURE
            </Text>
            <Text as="p" variant="bodyMd" color="subdued">
              {owner.political_exposure || "N/A"}
            </Text>
          </InfoDetail>
        </Stack>
      </Stack>
    </Card>
  );
}

const InfoDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 8px;
`;
