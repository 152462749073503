import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import CurrencyService from "app/api/currencies";
import { useToast } from "app/providers/ToastProvider";
import parseError from "app/lib/parseError";
import { GenericResponse } from "app/api/types";

export default function useToggleCurrencyStatus() {
  const params = useParams<{ code: string }>();

  const showToast = useToast();
  const queryClient = useQueryClient();

  const handleError = (error: AxiosError<{}>) => {
    const errorMessage = parseError(error) as string;
    showToast(errorMessage || "", "error");
    queryClient.invalidateQueries(["currency", params.code]);
  };

  const mutationOptions = {
    onSuccess: (res: AxiosResponse<GenericResponse<{}>>) => {
      queryClient.invalidateQueries(["currency", params.code]);
      const message = res.data.message;
      showToast(message || "", "success");
    },
    onError: handleError,
  };

  const { isLoading, mutate } = useMutation(
    (status: string) =>
      CurrencyService.toggleCurrencyStatus(params.code as string, status),
    mutationOptions
  );

  const { isLoading: isTogglingDepositStatus, mutate: toggleDepositStatus } =
    useMutation(
      () => CurrencyService.toggleCurrencyDepositStatus(params.code as string),
      mutationOptions
    );

  const { isLoading: isTogglingPayoutStatus, mutate: togglePayoutStatus } =
    useMutation(
      () => CurrencyService.toggleCurrencyPayoutStatus(params.code as string),
      mutationOptions
    );

  const { isLoading: isTogglingVIPStatus, mutate: toggleVIPStatus } =
    useMutation(
      () => CurrencyService.toggleCurrencyVIPStatus(params.code as string),
      mutationOptions
    );

  const onToggleStatus = (d: string) => {
    mutate(d);
  };

  return {
    isLoading,
    onToggleStatus,
    isTogglingDepositStatus,
    toggleDepositStatus,
    isTogglingPayoutStatus,
    togglePayoutStatus,
    isTogglingVIPStatus,
    toggleVIPStatus,
  };
}
