import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import PaymentsService from "app/api/payments";
import parseError from "app/lib/parseError";
import { useToast } from "app/providers/ToastProvider";

const useResyncPayment = () => {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const { isLoading, mutate: resyncPayouts } = useMutation(
    () => PaymentsService.resyncPayment(),
    {
      onSuccess: res => {
        queryClient.invalidateQueries(["payments"]);
        showToast(res.data.message || "", "success");
      },
      onError: error => {
        const errorMessage = parseError(error as AxiosError<{}>);
        showToast(errorMessage as string, "error");
      },
    }
  );

  return {
    isLoading,
    resyncPayouts,
  };
};

export default useResyncPayment;
