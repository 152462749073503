import { Card, IndexTable, Page, Pagination, Select, Stack } from "@shopify/polaris";
import { useParams } from "react-router-dom";

import useGoBack from "app/hooks/useGoBack";
import LoaderContainer from "app/components/LoaderContainer";
import useGetMerchantActivities from "./hooks/useGetMerchantActivities";
import ActivitiesList from "./components/ActivitiesList";

export default function MerchantActivities() {
  const params = useParams<{ merchantId: string }>();

  const {
    data,
    isLoading,
    error,
    refetch,
    isFetching,
    gotoNextPage,
    gotoPrevPage,
    updateQuery,
    query,
  } = useGetMerchantActivities(String(params.merchantId));

  const goBack = useGoBack();

  return (
    <Page
      title={isLoading ? "..." : "Account Activities"}
      breadcrumbs={[{ onAction: goBack }]}
    >
      <Card>
        <>
          <Card.Section>
            <Stack vertical>
              <Stack distribution="equalSpacing" alignment="center">
                <Stack spacing="extraLoose" alignment="center">
                  <p>Showing {data?.paginator.current_entries_size} Activities</p>
                </Stack>

                <Select
                  labelInline
                  label="Sort by"
                  name="sort"
                  value={query.sort}
                  options={[
                    { label: "Newest", value: "desc" },
                    { label: "Oldest", value: "asc" },
                  ]}
                  onChange={s => updateQuery({ ...query, sort: s[0] })}
                />
              </Stack>
            </Stack>
          </Card.Section>

          <LoaderContainer
            page
            loading={isLoading && !data?.data}
            error={error}
            onRetryClicked={refetch}
          >
            {data && (
              <IndexTable
                selectable={false}
                loading={isFetching}
                itemCount={data.paginator.total_entries_size || 0}
                headings={[
                  { title: "Date" },
                  { title: "Action" },
                  { title: "Device" },
                  { title: "Location" },
                ]}
              >
                <ActivitiesList activities={data.data} />
              </IndexTable>
            )}
          </LoaderContainer>

          {data && (
            <Card.Section>
              <Stack distribution="center">
                <Pagination
                  label={
                    <p>
                      {data.paginator.page} /{data.paginator.total_pages}
                    </p>
                  }
                  hasNext={data.paginator.total_pages > data.paginator.page}
                  onNext={gotoNextPage}
                  hasPrevious={data.paginator.page > 1}
                  onPrevious={gotoPrevPage}
                />
              </Stack>
            </Card.Section>
          )}
        </>
      </Card>
    </Page>
  );
}
