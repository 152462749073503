import { Button, Modal, TextContainer } from "@shopify/polaris";
import useModalState from "app/hooks/useModalState";
import { Invoice } from "app/models/invoice";
import useVoidInvoice from "../hooks/useVoidInvoice";

interface Props {
  invoice: Invoice;
}
export default function VoidInvoiceModal(props: Props) {
  const { invoice } = props;
  const { isOpen, openModal, closeModal } = useModalState();

  const { voidInvoice, isLoading } = useVoidInvoice();

  const handleVoidInvoice = () => {
    voidInvoice(invoice.id, {
      onSuccess() {
        closeModal();
      },
    });
  };

  return (
    <Modal
      activator={
        <Button
          onClick={openModal}
          disabled={["EXPIRED", "COMPLETED", "VOID", "DELETED"].includes(
            String(invoice?.status)
          )}
          outline
        >
          Disable invoice
        </Button>
      }
      open={isOpen}
      onClose={closeModal}
      title="Disable Invoice?"
      primaryAction={{
        content: "Disable Invoice",
        onAction: handleVoidInvoice,
        destructive: true,
        loading: isLoading,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: closeModal,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>You are about to disable this invoice. Do you want to continue?</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
}
