import * as React from "react";
import {
  Avatar,
  Badge,
  Card,
  IndexTable,
  Link,
  Page,
  Pagination,
  Stack,
} from "@shopify/polaris";

import UsersFilter from "./components/UsersFilter";
import AddUserModal from "./components/AddUserModal";
import useGetUsers from "./hooks/useGetUsers";
import LoaderContainer from "app/components/LoaderContainer";
import TableWrapper from "app/components/TableWrapper";
import { getRelativeTime } from "app/utils/helpers";
import { CellPadding } from "app/styles";

export default function Users() {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const {
    isLoading,
    isFetching,
    data,
    error,
    refetch,
    gotoNextPage,
    gotoPrevPage,
    query,
    setQuery,
  } = useGetUsers();

  const closeAddUserModal = React.useCallback(() => setModalIsOpen(false), []);

  return (
    <Page
      title="Users"
      primaryAction={{
        content: "Add User",
        onAction: () => setModalIsOpen(true),
      }}
    >
      <LoaderContainer page loading={isLoading} error={error} onRetryClicked={refetch}>
        {data && (
          <Card>
            <Card.Section fullWidth>
              <Card.Section>
                <UsersFilter query={query} setQuery={setQuery} />

                <br />
                <p>Showing {data.pagination.current_entries_size} Users</p>
              </Card.Section>

              <TableWrapper>
                <IndexTable
                  loading={isFetching}
                  resourceName={{ singular: "activity", plural: "activities" }}
                  headings={[
                    { title: "Name/Email" },
                    { title: "Date" },
                    { title: "Role" },
                  ]}
                  itemCount={data.pagination.total_entries_size || 0}
                  selectable={false}
                >
                  {data?.staffs.map((user, index) => {
                    return (
                      <IndexTable.Row key={index} id={index.toString()} position={index}>
                        <IndexTable.Cell>
                          <CellPadding>
                            <div style={{ display: "flex", gap: 16 }}>
                              <Link removeUnderline url={`/users/${user.id}`}>
                                <Avatar
                                  initials={`${user.first_name[0]}${user.last_name[0]}`}
                                />
                              </Link>
                              <Stack vertical spacing="extraTight">
                                <p>{`${user.first_name} ${user.last_name}`}</p>
                                <p>{user.email}</p>
                              </Stack>
                            </div>
                          </CellPadding>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <Badge status="new">
                            {getRelativeTime(new Date(user.last_login))}
                          </Badge>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <p>{user.role}</p>
                        </IndexTable.Cell>
                      </IndexTable.Row>
                    );
                  })}
                </IndexTable>
              </TableWrapper>
            </Card.Section>

            <Card.Section>
              <Stack distribution="center">
                <Pagination
                  label={
                    <p>
                      {data.pagination.page} /{data.pagination.total_pages}
                    </p>
                  }
                  hasNext={data.pagination.total_pages > data.pagination.page}
                  onNext={gotoNextPage}
                  hasPrevious={data.pagination.page > 1}
                  onPrevious={gotoPrevPage}
                />
              </Stack>
            </Card.Section>
          </Card>
        )}
      </LoaderContainer>
      <AddUserModal open={modalIsOpen} onClose={closeAddUserModal} />
    </Page>
  );
}
