import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import { useToast } from "app/providers/ToastProvider";
import PaymentLinksServices from "app/api/paymentLink";

export default function usePaymentLinkActions(paymentLinkId: string) {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const { isLoading: deletingPaymentLink, mutate: deletePaymentLinkMutate } = useMutation(
    () => PaymentLinksServices.deletePaymentLink(paymentLinkId),
    {
      onSuccess: res => {
        queryClient.invalidateQueries(["payment-link", paymentLinkId]);
        showToast(res.data.message || "", "success");
      },
      onError: (error: AxiosError<{}>) => {
        const errorMessage = parseError(error) as string;

        showToast(errorMessage || "", "error");
      },
    }
  );

  const { isLoading: restoringPaymentLink, mutate: restorePaymentLinkMutate } =
    useMutation(() => PaymentLinksServices.restorePaymentLink(paymentLinkId), {
      onSuccess: res => {
        queryClient.invalidateQueries(["payment-link", paymentLinkId]);
        showToast(res.data.message || "", "success");
      },
      onError: (error: AxiosError<{}>) => {
        const errorMessage = parseError(error) as string;

        showToast(errorMessage || "", "error");
      },
    });

  return {
    deletePaymentLink: deletePaymentLinkMutate,
    deletingPaymentLink,
    restoringPaymentLink,
    restorePaymentLink: restorePaymentLinkMutate,
  };
}
