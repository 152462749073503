import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import CurrencyService from "app/api/currencies";
import { GetCurrenciesQuery } from "app/api/currencies/types";

export default function useGetCurrencies(query: GetCurrenciesQuery) {
  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["currencies", query],
    () => CurrencyService.getCurrencies(query)
  );

  return {
    isLoading,
    isFetching,
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
  };
}
