import {
  Badge,
  BadgeProps,
  Card,
  IndexTable,
  Link,
  Text,
} from "@shopify/polaris";
import styled from "@emotion/styled";
import useGetWithdrawals from "../hooks/useGetWithdrawals";
import { formatDateStr } from "app/utils/helpers";
import { formatMoney } from "app/lib/money";
import { useParams } from "react-router-dom";
import LoaderContainer from "app/components/LoaderContainer";

const WithdrawalsStatusMap: Record<string, BadgeProps["status"]> = {
  completed: "success",
  failed: "critical",
  pending: "warning",
};

export function WithdrawalsSection() {
  const { id } = useParams();
  const { data, isLoading, refetch, error } = useGetWithdrawals(String(id));

  return (
    <Card>
      <LoaderContainer
        modal
        loading={isLoading}
        onRetryClicked={refetch}
        error={error}
      >
        <Card.Section fullWidth>
          <TableWrapper>
            <IndexTable
              headings={[
                { title: "Ref. Number" },
                { title: "Date" },
                { title: "Status" },
                { title: "Channel" },
                { title: "Amount" },
              ]}
              itemCount={data?.pagination.total_entries_size || 0}
              selectable={false}
              emptyState={
                <Text as="p" alignment="center" variant="bodySm">
                  This business is yet to make a withdrawal
                </Text>
              }
            >
              {data?.payouts.map((payout, index) => {
                return (
                  <IndexTable.Row
                    key={index}
                    id={index.toString()}
                    position={index}
                  >
                    <IndexTable.Cell>
                      <Link removeUnderline url={`/withdrawals/${payout.id}`}>
                        <CellPadding>{payout.reference}</CellPadding>
                      </Link>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      {formatDateStr(payout.created_at)}
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Badge status={WithdrawalsStatusMap[payout.status]}>
                        {payout.status}
                      </Badge>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Badge>Busha</Badge>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      {`${formatMoney(Number(payout.amount))} ${
                        payout.currency
                      }`}
                    </IndexTable.Cell>
                  </IndexTable.Row>
                );
              })}
            </IndexTable>
          </TableWrapper>
        </Card.Section>
      </LoaderContainer>
    </Card>
  );
}

const CellPadding = styled.div`
  padding: 13px 0px;
`;

const TableWrapper = styled.div`
  .Polaris-IndexTable__TableHeading {
    color: #000000;
    padding-block: 20px;
  }

  tr {
    border-top: 1px solid #e1e3e5;
  }
`;
