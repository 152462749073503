import styled from "@emotion/styled";
import {
  DisplayText,
  FormLayout,
  Stack,
  TextStyle,
  TextField,
  Button,
  Form,
  InlineError,
} from "@shopify/polaris";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LoginPayload } from "app/api/auth/types";

interface Props {
  onSubmit: (v: LoginPayload) => void;
  loading: boolean;
  error: string | null;
}

const schema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is not valid"),
  password: Yup.string().required("Password is required"),
});

export default function LoginForm(props: Props) {
  const { onSubmit, loading, error } = props;

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm<LoginPayload>({
    resolver: yupResolver(schema),
  });

  return (
    <Stack vertical spacing="loose">
      <Stack vertical spacing="tight">
        <DisplayText element="h2" size="large">
          <TextStyle variation="strong">Log in</TextStyle>
        </DisplayText>
        <p>
          <TextStyle variation="subdued">Continue to Busha Admin.</TextStyle>
        </p>
      </Stack>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormLayout>
          {error && <InlineError message={error} fieldID="form" />}

          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                autoComplete=""
                labelHidden
                label="Email address"
                placeholder="Enter email"
                name="email"
                value={field.value}
                onChange={(v: string) => setValue("email", v)}
                error={errors.email?.message}
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                autoComplete=""
                labelHidden
                label="Password"
                placeholder="Enter password"
                type="password"
                name="password"
                value={field.value}
                onChange={(v: string) => setValue("password", v)}
                error={errors.password?.message}
              />
            )}
          />

          <Button submit primary loading={loading}>
            Log in
          </Button>
          <ForgotLink to="/forgot-password">
            <TextStyle variation="subdued">Forgot Password?</TextStyle>
          </ForgotLink>
        </FormLayout>
      </Form>
    </Stack>
  );
}

const ForgotLink = styled(Link)`
  text-decoration: none;
`;
