import * as React from "react";
import { Page, Card, Stack, Pagination, IndexTable } from "@shopify/polaris";
import WithdrawalsList from "./components/WithdrawalsList";
import WithdrawalActionButtons from "./components/WithdrawalButtons";
import LoaderContainer from "app/components/LoaderContainer";
import useGetWithdrawals from "./hooks/useGetWithdrawals";
import styled from "@emotion/styled";
import WithdrawalsFilter from "./components/WithdrawalsFilter";

export default function Withdrawals() {
  const {
    isLoading,
    isFetching,
    error,
    refetch,
    data,
    queryParams,
    updateQueryParams,
    gotoNextPage,
    gotoPreviousPage,
  } = useGetWithdrawals();

  const withdrawalsData = React.useMemo(() => {
    return data?.payouts ?? [];
  }, [data?.payouts]);

  return (
    <Page
      title="Withdrawals"
      primaryAction={
        data && (
          <Stack alignment="center">
            <WithdrawalActionButtons />
          </Stack>
        )
      }
    >
      <Card>
        <WithdrawalsFilter
          query={queryParams}
          withdrawalOnDisplay={withdrawalsData.length}
          setQuery={updateQueryParams}
        />

        <Card.Section flush>
          <LoaderContainer
            style={{ minHeight: "unset" }}
            loading={isLoading}
            error={error}
            onRetryClicked={refetch}
          >
            <TableWrapper>
              <IndexTable
                itemCount={withdrawalsData.length}
                selectable={false}
                headings={[
                  { title: "Reference" },
                  { title: "Date" },
                  { title: "Customer" },
                  { title: "Status" },
                  { title: "Channel" },
                  { title: "Network" },
                  { title: "Amount" },
                ]}
                loading={isFetching}
                resourceName={{
                  singular: "Groot payout",
                  plural: "Groot payouts",
                }}
              >
                <WithdrawalsList withdrawals={withdrawalsData} />
              </IndexTable>
            </TableWrapper>
          </LoaderContainer>
        </Card.Section>
        <Card.Section>
          <Stack distribution="center">
            {data && (
              <Pagination
                label={
                  <p>
                    {data.pagination.page} /{data.pagination.total_pages}
                  </p>
                }
                hasNext={data.pagination.total_pages > data.pagination.page}
                onNext={gotoNextPage}
                hasPrevious={data.pagination.page > 1}
                onPrevious={gotoPreviousPage}
              />
            )}
          </Stack>
        </Card.Section>
      </Card>
    </Page>
  );
}

const TableWrapper = styled.div`
  .Polaris-IndexTable__TableHeading {
    color: #000000;
    padding-block: 20px;
  }

  tr {
    border-top: 1px solid #e1e3e5;
  }
`;
