import styled from "@emotion/styled";
import {
  DisplayText,
  FormLayout,
  Stack,
  TextStyle,
  TextField,
  Button,
  Form,
  InlineError,
} from "@shopify/polaris";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  AcceptUserInvitePayload,
  GetUserInviteResponse,
} from "app/api/users/types";

interface Props {
  onSubmit: (v: AcceptUserInvitePayload) => void;
  invite: GetUserInviteResponse;
  loading: boolean;
  error: string | null;
}
export default function InviteForm(props: Props) {
  const { onSubmit, loading, error, invite } = props;

  const form = useForm<AcceptUserInvitePayload>({
    resolver: yupResolver(
      Yup.object().shape({
        password: Yup.string()
          .required("Password is required")
          .min(8, "Password must be at least 8 characters")
          .max(30, "Password must be at most 30 characters"),
      })
    ),
  });

  return (
    <Stack vertical spacing="loose">
      <Stack vertical spacing="tight">
        <DisplayText element="h2" size="large">
          <TextStyle variation="strong">Hi, {invite.first_name}</TextStyle>
        </DisplayText>
        <DisplayText size="small">Create a staff account</DisplayText>

        <p>
          <TextStyle variation="subdued">
            Finalize your account details to gain access to Wanda
          </TextStyle>
        </p>
      </Stack>

      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <FormLayout>
          {error && <InlineError message={error} fieldID="form" />}

          <TextField
            autoComplete=""
            // labelHidden
            label="First name"
            placeholder="Enter first name"
            name="first_name"
            value={invite.first_name}
            onChange={() => {}}
          />
          <TextField
            autoComplete=""
            // labelHidden
            label="Last name"
            placeholder="Enter last name"
            name="last_name"
            value={invite.last_name}
            onChange={() => {}}
          />
          <TextField
            autoComplete=""
            // labelHidden
            label="Email address"
            placeholder="Enter email"
            name="email"
            value={invite.email}
            onChange={() => {}}
          />

          <Controller
            name="password"
            control={form.control}
            render={({ field }) => (
              <TextField
                autoComplete=""
                // labelHidden
                autoFocus
                label="Password"
                placeholder="Enter password"
                type="password"
                name="password"
                value={field.value}
                onChange={(v: string) => form.setValue("password", v)}
                error={form.formState.errors.password?.message}
              />
            )}
          />

          <Button submit primary loading={loading}>
            Confirm
          </Button>

          <HomeLink to="/login">
            <TextStyle variation="subdued">Go Home</TextStyle>
          </HomeLink>
        </FormLayout>
      </Form>
    </Stack>
  );
}

const HomeLink = styled(Link)`
  text-decoration: none;
`;
