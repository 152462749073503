import * as React from "react";
import {
  Avatar,
  Button,
  Card,
  ContextualSaveBar,
  Form,
  FormLayout,
  Layout,
  Stack,
  TextField,
} from "@shopify/polaris";
import Profile from "app/models/profile";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useToast } from "app/providers/ToastProvider";
import useUpdateUser from "../hooks/useUpdateUser";
import { useParams } from "react-router-dom";

interface Props {
  profile: Profile;
}
export default function AccountInfoSection(props: Props) {
  const { profile } = props;
  const { id } = useParams<{ id: string }>();

  const {
    updateUser,
    updateUserVariables,
    isLoading,
    error,
    data,
    //
    updateUserPassword,
    updatingPassword,
    updatePasswordResponse,
    updatePasswordError,
  } = useUpdateUser(String(id));

  const {
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    setValue,
    control,
  } = useForm<Partial<Profile>>({
    defaultValues: {
      first_name: profile.first_name,
      last_name: profile.last_name,
      email: profile.email,
      phone: profile.phone ?? "",
      role: profile.role,
    },
    resolver: yupResolver(
      Yup.object().shape({
        first_name: Yup.string().required("First name is required"),
        last_name: Yup.string().required("Last name is required"),
        email: Yup.string().required().email(),
      })
    ),
  });

  const passwordForm = useForm({
    defaultValues: {
      password: "",
    },
    resolver: yupResolver(
      Yup.object().shape({
        password: Yup.string()
          .required("Password is required")
          .min(8, "Password must be at least 8 characters")
          .max(30, "Password must be at most 30 characters"),
      })
    ),
  });

  const showToast = useToast();

  const memoizedPasswordFormReset = React.useMemo(
    () => passwordForm.reset,
    [passwordForm.reset]
  );

  React.useEffect(() => {
    if (error) {
      showToast(error, "error");
    }
    if (data && data.message) {
      showToast(data.message, "success");
      reset(updateUserVariables);
    }
  }, [error, data, showToast, reset, updateUserVariables]);

  React.useEffect(() => {
    if (updatePasswordError) {
      showToast(updatePasswordError, "error");
    }
    if (updatePasswordResponse && updatePasswordResponse.message) {
      showToast(updatePasswordResponse.message, "success");
      memoizedPasswordFormReset();
    }
  }, [updatePasswordResponse, updatePasswordError, showToast, memoizedPasswordFormReset]);

  return (
    <>
      {isDirty && (
        <ContextualSaveBar
          message="Unsaved changes (Account Information)"
          saveAction={{
            onAction: handleSubmit(updateUser),
            loading: isLoading,
          }}
          discardAction={{
            onAction: () => reset(),
          }}
        />
      )}

      <Layout.AnnotatedSection title="Account Information">
        <Card>
          <Card.Section title="User Profile">
            <Stack vertical>
              <Avatar
                initials={`${profile.first_name[0]}${profile.last_name[0]}`}
                size="small"
              />
              <FormLayout>
                <FormLayout.Group>
                  <Controller
                    name="first_name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete=""
                        label="First name"
                        name="first_name"
                        value={field.value}
                        onChange={v => setValue("first_name", v, { shouldDirty: true })}
                        error={errors.first_name?.message}
                      />
                    )}
                  />

                  <Controller
                    name="last_name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete=""
                        label="Last name"
                        name="last_name"
                        value={field.value}
                        onChange={v => setValue("last_name", v, { shouldDirty: true })}
                        error={errors.last_name?.message}
                      />
                    )}
                  />
                </FormLayout.Group>

                <FormLayout.Group>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete=""
                        label="Email address"
                        name="email"
                        value={field.value}
                        onChange={v => setValue("email", v, { shouldDirty: true })}
                        error={errors.email?.message}
                      />
                    )}
                  />
                  {/* <TextField autoComplete=""
                    label="Phone number"
                    name="phone"
                    value={form.values.phone}
                    onChange={(v) => form.setFieldValue("phone", v)}
                    error={form.errors.phone}
                  /> */}
                </FormLayout.Group>
              </FormLayout>
            </Stack>
          </Card.Section>

          <Card.Section title="Password Reset">
            <Stack vertical>
              <p>
                Change {profile.first_name} {profile.last_name}'s password
              </p>
              <Form
                onSubmit={passwordForm.handleSubmit(v => updateUserPassword(v.password))}
              >
                <FormLayout>
                  <Controller
                    name="password"
                    control={passwordForm.control}
                    render={({ field }) => (
                      <TextField
                        autoComplete=""
                        label="New password"
                        type="password"
                        name="password"
                        value={field.value}
                        onChange={v => passwordForm.setValue("password", v)}
                        error={passwordForm.formState.errors.password?.message}
                      />
                    )}
                  />

                  <Button submit loading={updatingPassword}>
                    Change password
                  </Button>
                </FormLayout>
              </Form>
            </Stack>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </>
  );
}
