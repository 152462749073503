import * as React from "react";
import { ContextualSaveBar, Loading, Page } from "@shopify/polaris";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import LoaderContainer from "app/components/LoaderContainer";
import useFormMode from "app/hooks/useFormMode";
import useGoBack from "app/hooks/useGoBack";
import BookForm from "./components/BookForm";
import useBookAction from "./hooks/useBookAction";
import useGetBook from "./hooks/useGetBook";
import useGetBookExchanges from "app/hooks/useGetBookExchanges";
import useGetBookOrderExchanges from "./hooks/useGetBookOrderExchanges";
import { useForm } from "react-hook-form";
import { AddBookPayload } from "app/api/books/types";
import bookTypes from "app/constants/bookTypes";

const schema = Yup.object().shape({
  exchange: Yup.string().required(),
  product: Yup.string().required(),
  base: Yup.string().required(),
  counter: Yup.string().required(),
  category: Yup.string().required(),
  buy_price: Yup.number().required(),
  maximum_purchase: Yup.number().required(),
  minimum_purchase: Yup.number().required(),
  sell_price: Yup.number().required(),
  maximum_sale: Yup.number().required(),
  minimum_sale: Yup.number().required(),
  buy_mark_up: Yup.number().required(),
  sell_mark_down: Yup.number().required(),
  unliquidated_buy_allowance: Yup.number().required(),
  unliquidated_sell_allowance: Yup.number().required(),
  buy: Yup.boolean(),
  sell: Yup.boolean(),
  base_decimals: Yup.number().required(),
  counter_decimals: Yup.number().required(),
  provider_base_decimals: Yup.number().required(),
  provider_counter_decimals: Yup.number().required(),
  market_order_exchange: Yup.string().required(),
  market_order_threshold: Yup.number().required(),
  limit_order_exchange: Yup.string().required(),
});

export default function BookDetails() {
  const goBack = useGoBack();
  const formMode = useFormMode();

  const {
    isLoading,
    data: book,
    error,
    isFetching,
    refetch,
  } = useGetBook(formMode);

  const {
    data: exchanges,
    isLoading: loadingExchnages,
    error: exchangesError,
    refetch: refetchExchanges,
  } = useGetBookExchanges();

  const {
    data: orderExchanges,
    isLoading: loadingOrderExchnages,
    error: orderExchangesError,
    refetch: refetchOrderExchanges,
  } = useGetBookOrderExchanges();

  const refetchData = () => {
    if (error) {
      refetch();
    }
    if (exchangesError) {
      refetchExchanges();
    }
    if (orderExchangesError) {
      refetchOrderExchanges();
    }
  };

  const { formIsLoading, onSubmit } = useBookAction(formMode);

  const initialValues =
    formMode === "add" || !book
      ? {
          exchange: exchanges?.exchanges[0].value || "",
          product: "",
          base: "",
          counter: "",
          category: bookTypes[0].value,
          buy_price: 0,
          maximum_purchase: 0,
          minimum_purchase: 0,
          sell_price: 0,
          maximum_sale: 0,
          minimum_sale: 0,
          buy_mark_up: 0,
          sell_mark_down: 0,
          unliquidated_buy_allowance: 0,
          unliquidated_sell_allowance: 0,
          buy: false,
          sell: false,
          base_decimals: 0,
          counter_decimals: 0,
          provider_base_decimals: 0,
          provider_counter_decimals: 0,
          market_order_exchange: orderExchanges?.exchanges[0].value || "",
          market_order_threshold: 0,
          limit_order_exchange: orderExchanges?.exchanges[0].value || "",
        }
      : book;

  const form = useForm<AddBookPayload>({
    defaultValues: initialValues,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const resetForm = React.useMemo(() => form.reset, [form.reset]);

  React.useEffect(() => {
    if (book) {
      resetForm(book);
    }
  }, [book, resetForm]);

  const setFieldValue = React.useMemo(() => form.setValue, [form.setValue]);

  React.useEffect(() => {
    if (exchanges && formMode === "add") {
      setFieldValue("exchange", exchanges.exchanges[0].value);
    }
  }, [exchanges, setFieldValue, formMode]);

  return (
    <Page
      breadcrumbs={[{ onAction: goBack }]}
      title={
        formMode === "add"
          ? "Add Book"
          : isLoading
          ? "..."
          : `Edit ${book?.product || ""}`
      }
    >
      {isFetching && <Loading />}

      <LoaderContainer
        loading={loadingExchnages || loadingOrderExchnages}
        error={error || exchangesError || orderExchangesError}
        onRetryClicked={refetchData}
      >
        {(formMode === "add" ||
          (formMode === "edit" && form.formState.isDirty)) && (
          <ContextualSaveBar
            message="Unsaved Book"
            saveAction={{
              content: formMode === "add" ? "Add" : "Save",
              onAction: form.handleSubmit(onSubmit),
              loading: formIsLoading,
            }}
            discardAction={{
              onAction: () =>
                form.reset(formMode === "edit" ? book : undefined),
            }}
          />
        )}

        <BookForm
          form={form}
          formMode={formMode}
          exchanges={exchanges?.exchanges || []}
          orderExchanges={orderExchanges?.exchanges || []}
        />
      </LoaderContainer>
    </Page>
  );
}
