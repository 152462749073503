import * as React from "react";
import {
  Card,
  ChoiceList,
  FilterInterface,
  Filters,
  Select,
  Stack,
} from "@shopify/polaris";
import debounce from "lodash.debounce";
import useGetRoles from "app/views/BusinessDetails/hooks/useGetRoles";
import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import { GetTeamsQuery } from "app/api/teams/types";
import { capitalizeStr } from "app/utils/helpers";

interface Props {
  query: GetTeamsQuery;
  updateQuery: (q: GetTeamsQuery) => void;
}

export default function ActivitiesFilter(props: Props) {
  const { query, updateQuery } = props;

  const [queryValue, setQueryValue] = React.useState(query.search);

  const { roles } = useGetRoles();

  const rolesOptions = React.useMemo(
    () =>
      roles?.data.map(role => ({
        label: capitalizeStr(role.description),
        value: role.id,
      })),
    [roles?.data]
  );

  const debouncedSearch = React.useMemo(
    () =>
      debounce(
        searchString =>
          updateQuery({
            search: searchString.length ? searchString : undefined,
            page: 1,
          }),
        1000
      ),
    [updateQuery]
  );

  useUpdatedEffect(() => {
    debouncedSearch(queryValue);
  }, [queryValue]);

  const filters: FilterInterface[] = [
    {
      key: "role",
      label: "Role",
      filter: (
        <ChoiceList
          choices={rolesOptions!}
          onChange={t => updateQuery({ role: t[0] })}
          selected={[query.role || ""]}
          title="Role"
          titleHidden
        />
      ),
      shortcut: true,
    },
  ];

  const removeFilter = React.useCallback(
    (filter: string) => {
      updateQuery({ ...query, [filter]: undefined });
    },
    [updateQuery, query]
  );

  const appliedFilters = Object.keys(query)
    .filter(
      key =>
        !["search", "limit", "sort"].includes(key) &&
        query[key as keyof GetTeamsQuery] !== undefined
    )
    .map(key => ({
      key,
      label: `${key === "role" ? "Role" : key}: ${
        key === "role"
          ? roles!.data.find(role => role.id === query.role_id)?.name
          : query[key as string as keyof GetTeamsQuery]
      }`,
      onRemove: removeFilter,
    }));

  return (
    <>
      <Card.Section>
        <Filters
          queryValue={queryValue}
          filters={filters}
          appliedFilters={appliedFilters}
          queryPlaceholder="Search"
          onQueryChange={setQueryValue}
          focused
          onQueryClear={() => {
            setQueryValue("");
            updateQuery({ ...query, search: undefined });
          }}
          onClearAll={() => {
            updateQuery({
              limit: 10,
              page: 1,
              search: undefined,
              sort: undefined,
              role: undefined,
            });
          }}
          hideTags
        />

        <Stack distribution="equalSpacing" alignment="center">
          <p style={{ marginTop: 16 }}>Showing team activities</p>

          <div style={{ marginTop: 16 }}>
            <Select
              labelInline
              label="Sort by"
              name="sort"
              value={query.sort}
              options={[
                { label: "Newest", value: "desc" },
                { label: "Oldest", value: "asc" },
              ]}
              onChange={s => updateQuery({ sort: s })}
            />
          </div>
        </Stack>
      </Card.Section>
    </>
  );
}
