import * as React from "react";
import { useParams } from "react-router-dom";
import { Badge, Card, IndexTable, Link, Text } from "@shopify/polaris";

import { formatMoney } from "app/lib/money";
import LoaderContainer from "app/components/LoaderContainer";
import useGetPaymentLinks from "app/hooks/useGetPaymentLinks";
import { formatDateStr } from "app/utils/helpers";
import { CellPadding } from "app/styles";
import TableWrapper from "app/components/TableWrapper";

export function PaymentLinks() {
  const { id } = useParams();

  const { data, isLoading, refetch, error, setQuery } = useGetPaymentLinks();

  React.useEffect(() => {
    if (id) {
      setQuery({ business_id: id, type: "fixed_price", limit: 5 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Card>
      <LoaderContainer modal loading={isLoading} onRetryClicked={refetch} error={error}>
        <Card.Section fullWidth>
          <TableWrapper>
            <IndexTable
              headings={[
                { title: "Product name" },
                { title: "Date" },
                { title: "Status" },
                { title: "Amount" },
              ]}
              itemCount={data?.pagination?.current_entries_size || 0}
              selectable={false}
              emptyState={
                <Text as="p" alignment="center" variant="bodySm">
                  This business is yet to create a payment link
                </Text>
              }
            >
              {data?.data.map((paymentLink, index) => {
                return (
                  <IndexTable.Row key={index} id={index.toString()} position={index}>
                    <IndexTable.Cell>
                      <CellPadding>
                        <Link removeUnderline url={`/payment-links/${paymentLink.id}`}>
                          {paymentLink.name}
                        </Link>
                      </CellPadding>
                    </IndexTable.Cell>

                    <IndexTable.Cell>
                      <CellPadding>
                        {formatDateStr(
                          paymentLink.created_at,
                          "MMMM D, YYYY [at] hh:mma"
                        )}
                      </CellPadding>
                    </IndexTable.Cell>

                    <IndexTable.Cell>
                      <CellPadding>
                        <Badge status={paymentLink.deleted_at ? "critical" : "success"}>
                          {paymentLink.deleted_at ? "Inactive" : "Active"}
                        </Badge>
                      </CellPadding>
                    </IndexTable.Cell>

                    <IndexTable.Cell>
                      <CellPadding>
                        {`${formatMoney(Number(paymentLink.local_amount))} ${
                          paymentLink.local_currency
                        }`}
                      </CellPadding>
                    </IndexTable.Cell>
                  </IndexTable.Row>
                );
              })}
            </IndexTable>
          </TableWrapper>
        </Card.Section>
      </LoaderContainer>
    </Card>
  );
}
