import { Badge, Card, Link, Stack, Text } from "@shopify/polaris";
import LoaderContainer from "app/components/LoaderContainer";
import useListBusinessDocuments from "app/hooks/useListBusinessDocuments";

interface Props {
  businessId: string;
}
export default function Documents(props: Props) {
  const { businessId } = props;

  const { documentsData, error, refetch } = useListBusinessDocuments(businessId);

  const fileDocuments = documentsData?.documents.filter(doc => doc.type === "image");
  const textDocuments = documentsData?.documents.filter(doc => doc.type === "text");

  const hasBusinessDoneKYB = (documentsData?.documents ?? []).length > 0;

  return (
    <LoaderContainer modal loading={false} onRetryClicked={refetch} error={error}>
      <Card
        title={
          <Stack distribution="equalSpacing" alignment="center">
            <Text as="p" variant="bodySm" fontWeight="semibold">
              COMPLIANCE
            </Text>

            {hasBusinessDoneKYB && (
              <Link url={`/compliance/documents/${businessId}`} removeUnderline>
                View all
              </Link>
            )}
          </Stack>
        }
        sectioned
      >
        {hasBusinessDoneKYB && (
          <Stack vertical>
            {textDocuments?.map(doc => (
              <Stack key={doc.id} spacing="extraTight" vertical>
                <Text as="p" variant="bodyMd">
                  {doc.document_name}
                </Text>
                <Text as="p" variant="bodyMd" color="subdued">
                  {doc.reference}
                </Text>
              </Stack>
            ))}

            <div style={{ height: 4 }}></div>

            {fileDocuments?.map(doc => (
              <Stack key={doc.id} distribution="equalSpacing">
                <Link removeUnderline url={`/compliance/documents/${businessId}`}>
                  <Text as="p" variant="bodyMd">
                    {doc.document_name}
                  </Text>
                </Link>
                <Badge
                  progress="complete"
                  status={doc.status === "unverified" ? "warning" : "success"}
                >
                  {doc.status}
                </Badge>
              </Stack>
            ))}
          </Stack>
        )}

        {!hasBusinessDoneKYB && (
          <Text as="p" variant="bodyMd">
            Business is unverified
          </Text>
        )}
      </Card>
    </LoaderContainer>
  );
}
