import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import ComplianceService from "app/api/compliance";
import { useToast } from "app/providers/ToastProvider";
import parseError from "app/lib/parseError";
import { UpdateDocPayload } from "app/api/compliance/types";

export default function useUpdateDocStatus(id: string) {
  const showToast = useToast();

  const handleError = (error: AxiosError<{}>) => {
    const errorMessage = parseError(error) as string;

    showToast(errorMessage || "", "error");
  };

  const queryClient = useQueryClient();
  const { isLoading: isUpdatingBook, mutate: updateDocMutate } = useMutation(
    (p: UpdateDocPayload) => ComplianceService.updateDocumentStatus(id as string, p),
    {
      onSuccess: res => {
        queryClient.invalidateQueries(["document", id]);
        const message = res.data.message;
        showToast(message || "", "success");
      },
      onError: handleError,
    }
  );

  return {
    formIsLoading: isUpdatingBook,
    onUpdateDoc: updateDocMutate,
  };
}
