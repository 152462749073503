import AnalyticsCard from "./AnalyticsCard";
import useGetPaymentLinks from "app/hooks/useGetPaymentLinks";

export default function PaymentLinks() {
  const { data, isLoading, error, refetch } = useGetPaymentLinks("fixed_price");

  const paymentLinksData = data?.pagination.total_entries_size ?? "";

  return (
    <AnalyticsCard
      title="Payment Links"
      viewLink="/payment-links"
      amount={paymentLinksData}
      loading={isLoading}
      error={error}
      refetch={refetch}
    />
  );
}
