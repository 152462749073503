import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import BookService from "app/api/books";
import parseError from "app/lib/parseError";

export default function useGetBookExchanges() {
  const { data, status, error, refetch, isFetching, isLoading } = useQuery(
    ["exchanges"],
    BookService.getExchanges
  );

  return {
    data: data?.data,
    status,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
    isFetching,
    isLoading,
  };
}
