import styled from "@emotion/styled";
import { Badge, Card, IndexTable, Link } from "@shopify/polaris";

import TableWrapper from "app/components/TableWrapper";
import { formatMoney } from "app/lib/money";
import { GrootDeposit } from "app/models/payment";
import { capitalizeStr, formatDateStr, mapStatusToBadge } from "app/utils/helpers";

interface Props {
  payments: GrootDeposit[];
}

export default function PaymentsList(props: Props) {
  const { payments } = props;

  return (
    <Card.Section fullWidth>
      <TableWrapper>
        <IndexTable
          selectable={false}
          resourceName={{ plural: "payments", singular: "payment" }}
          itemCount={payments.length}
          headings={[
            { title: "Ref Number" },
            { title: "Date" },
            { title: "Business name" },
            { title: "Status" },
            { title: "Type" },
            { title: "Network" },
            { title: "Amount" },
          ]}
        >
          {payments.map((payment, i) => {
            return (
              <IndexTable.Row id={payment.id} key={payment.id} position={i}>
                <IndexTable.Cell>
                  <CellPadding>
                    <Link removeUnderline url={`/payments/${payment.id}`}>
                      {payment.reference}
                    </Link>
                  </CellPadding>
                </IndexTable.Cell>

                <IndexTable.Cell>
                  {formatDateStr(payment.created_at, "MMMM D, YYYY [at] hh:mma")}
                </IndexTable.Cell>

                <IndexTable.Cell>
                  <CellPadding>{payment.business_name}</CellPadding>
                </IndexTable.Cell>

                <IndexTable.Cell>
                  <Badge status={mapStatusToBadge(payment.status)}>
                    {capitalizeStr(payment.status)}
                  </Badge>
                </IndexTable.Cell>

                <IndexTable.Cell>
                  <Badge>{capitalizeStr(payment.type.split("_").join(" "))}</Badge>
                </IndexTable.Cell>

                <IndexTable.Cell>{payment.network}</IndexTable.Cell>

                <IndexTable.Cell>
                  <CellPadding>
                    {formatMoney(Number(payment.amount), "", payment.currency)}
                  </CellPadding>
                </IndexTable.Cell>
              </IndexTable.Row>
            );
          })}
        </IndexTable>
      </TableWrapper>
    </Card.Section>
  );
}

const CellPadding = styled.div`
  padding: 13px 0px;
`;
