import styled from "@emotion/styled";
import { Card, EmptySearchResult, IndexTable, Link } from "@shopify/polaris";
import TableWrapper from "app/components/TableWrapper";
import { BusinessType } from "app/models/business";
import { formatDateStr } from "app/utils/helpers";

interface Props {
  businessTypes: BusinessType[];
}
export default function BusinessTypesList(props: Props) {
  const { businessTypes } = props;

  return (
    <Card.Section fullWidth>
      <TableWrapper>
        <IndexTable
          headings={[
            { title: "Name" },
            { title: "Country" },
            { title: "Date Created" },
          ]}
          emptyState={
            <EmptySearchResult
              title="No business types found"
              description="Try changing the filters or search term"
              withIllustration
            />
          }
          itemCount={businessTypes.length}
          selectable={false}
        >
          {businessTypes.map((businessType, index) => {
            return (
              <IndexTable.Row
                key={index}
                id={index.toString()}
                position={index}
              >
                <CellPadding>
                  <IndexTable.Cell>
                    <Link url="/settings/businesses/edit" removeUnderline>
                      {businessType.name}
                    </Link>
                  </IndexTable.Cell>
                </CellPadding>

                <IndexTable.Cell>{businessType.country_id}</IndexTable.Cell>
                <IndexTable.Cell>
                  {formatDateStr(businessType.created_at, "MMMM DD, YYYY")}
                </IndexTable.Cell>
              </IndexTable.Row>
            );
          })}
        </IndexTable>
      </TableWrapper>
    </Card.Section>
  );
}

const CellPadding = styled.div`
  padding: 13px 0px;
`;
