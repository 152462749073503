import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import PaymentsService from "app/api/payments";
import { useToast } from "app/providers/ToastProvider";

export default function useCompletePayment(paymentId: string) {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const { error, isLoading, mutate } = useMutation(
    () => PaymentsService.completePayment(paymentId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["payment", { paymentId }]);
        showToast("Payment updated successfully", "success");
      },
      onError: error => {
        if (!error) return;
        const errorMessage = parseError(error as AxiosError<{}>) as string;
        showToast(errorMessage || "", "error");
      },
    }
  );

  return {
    completePayment: mutate,
    completingPayment: isLoading,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
  };
}
