import styled from "@emotion/styled";
import { Card, Subheading, TextStyle, Badge } from "@shopify/polaris";
import { Withdrawal } from "app/models/withdrawal";
import {
  capitalizeStr,
  mapStatusToBadge,
  mapStatusToBadgeProgress,
} from "app/utils/helpers";

interface Props {
  withdrawal: Withdrawal;
}

export default function WithdrawalStatus(props: Props) {
  const { withdrawal } = props;

  return (
    <Card>
      <Card.Header title={<Subheading>withdrawal status</Subheading>} />
      <WithdrawalStatusEL>
        <Card.Section>
          <div>
            <p>Busha</p>
            <p>
              <Badge
                status={mapStatusToBadge(withdrawal.status)}
                progress={mapStatusToBadgeProgress(withdrawal.status)}
              >
                {capitalizeStr(withdrawal.status)}
              </Badge>
            </p>
          </div>
        </Card.Section>
        <Card.Section>
          <div>
            <p>HASH</p>
            <p>
              <Badge
                status={mapStatusToBadge(withdrawal.fulfilment_status)}
                progress={mapStatusToBadgeProgress(
                  withdrawal.fulfilment_status
                )}
              >
                {capitalizeStr(withdrawal.fulfilment_status)}
              </Badge>
            </p>
          </div>
          {withdrawal.hash && withdrawal.transaction.meta.blockchain_url ? (
            <HashLink
              href={`${withdrawal.transaction.meta.blockchain_url}`}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <LongTexts> {withdrawal.hash} </LongTexts>
            </HashLink>
          ) : (
            <LongTexts>
              <TextStyle variation="subdued"> No hash</TextStyle>
            </LongTexts>
          )}
        </Card.Section>
      </WithdrawalStatusEL>
    </Card>
  );
}

const WithdrawalStatusEL = styled.div`
  margin-top: 8px;

  .Polaris-Card__Section {
    div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }
    div:last-child {
      margin-bottom: 0px;
    }
  }
`;

const LongTexts = styled.p`
  word-break: break-all;
`;

const HashLink = styled.a`
  text-decoration: none;
  color: var(--p-interactive);
`;
