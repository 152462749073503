import { Card, Link, Stack, TextStyle } from "@shopify/polaris";

import useModalState from "app/hooks/useModalState";
import Currency from "app/models/currency";
import AddBookModal from "./AddBookModal";

interface Props {
  currency: Currency;
}

export default function BooksSection(props: Props) {
  const { currency } = props;

  const { isOpen, closeModal, openModal } = useModalState();

  return (
    <>
      <Card>
        <Card.Section
          title="Books"
          actions={[{ content: "Add", onAction: openModal }]}
        >
          {currency.books && currency.books.length > 0 ? (
            <Stack vertical>
              {currency.books.map((book) => (
                <Link key={book.id} url={`/books/${book.id}`}>
                  {book.product}
                </Link>
              ))}
            </Stack>
          ) : (
            <p>
              <TextStyle variation="subdued">No books</TextStyle>
            </p>
          )}
        </Card.Section>
      </Card>
      <AddBookModal open={isOpen} onClose={closeModal} />
    </>
  );
}
