import * as React from "react";
import {
  AppliedFilterInterface,
  Card,
  ChoiceList,
  FilterInterface,
  Filters,
  Select,
  Stack,
} from "@shopify/polaris";
import debounce from "lodash.debounce";

import { ListMerchantsQuery } from "app/api/merchants/type";
import useUpdatedEffect from "app/hooks/useUpdatedEffect";

interface Props {
  query: ListMerchantsQuery;
  merchantsOnDisplay: number;
  totalMerchants: number;
  updateQuery: (q: ListMerchantsQuery) => void;
}
export default function MerchantsFilters(props: Props) {
  const { query, merchantsOnDisplay, totalMerchants, updateQuery } = props;

  const [queryValue, setQueryValue] = React.useState(query.q);

  const debouncedSearch = React.useMemo(
    () => debounce(searchString => updateQuery({ q: searchString }), 1000),
    [updateQuery]
  );

  useUpdatedEffect(() => {
    debouncedSearch(queryValue);
  }, [queryValue]);

  const filters: FilterInterface[] = [
    {
      key: "active",
      label: "Status",
      filter: (
        <ChoiceList
          choices={[
            { label: "Active", value: "active" },
            { label: "Inactive", value: "inactive" },
            { label: "Pending deletion", value: "pending_deletion" },
            { label: "Deleted", value: "deleted" },
          ]}
          onChange={options => {
            updateQuery({ active: options[0] });
          }}
          selected={[query.active || ""]}
          title="Active"
          titleHidden
        />
      ),
      shortcut: true,
    },
  ];

  const removeFilter = (filter: keyof ListMerchantsQuery) => {
    updateQuery({ ...query, [filter]: undefined });
  };

  const appliedFilters: AppliedFilterInterface[] = Object.keys(query)
    .filter(
      key =>
        ["active"].includes(key) && query[key as keyof ListMerchantsQuery] !== undefined
    )
    .map(key => {
      return {
        key,
        label: `${key}: ${query[key as keyof ListMerchantsQuery]}`,
        onRemove: removeFilter,
      };
    });

  return (
    <Card.Section>
      <Filters
        queryValue={queryValue}
        appliedFilters={appliedFilters}
        filters={filters}
        queryPlaceholder="Search by merchant email"
        onQueryChange={setQueryValue}
        focused
        hideTags
        onQueryClear={() => {
          setQueryValue("");
          updateQuery({ ...query, q: undefined });
        }}
        onClearAll={() => {
          updateQuery({ limit: 10, page: 1, q: undefined, sort: undefined });
        }}
      />

      <Stack distribution="equalSpacing" alignment="center">
        <p style={{ marginTop: 16 }}>
          Showing {merchantsOnDisplay} merchant of {totalMerchants}
        </p>

        <div style={{ marginTop: 16 }}>
          <Select
            labelInline
            label="Sort by"
            name="sort"
            value={query.sort}
            options={[
              { label: "Date signed (Newest first)", value: "desc" },
              { label: "Date signed (Oldest first)", value: "asc" },
            ]}
            onChange={(s: any) => updateQuery({ sort: s })}
          />
        </div>
      </Stack>
    </Card.Section>
  );
}
