import styled from "@emotion/styled";
import { Card, Grid, Layout, Text } from "@shopify/polaris";

import BusinessDetails from "./BusinessDetails";
import MerchantBusinesses from "./Businesses";
import { MerchantDetails } from "app/models/merchant";
import { formatDateStr } from "app/utils/helpers";
import useGetMerchantBusiness from "../hooks/useGetMerchantBusiness";
import EditMerchantModal from "./EditMerchantModal";
import useModalState from "app/hooks/useModalState";
import EditMerchantAddressModal from "./EditMerchantAddressModal";
import EditRoleModal from "./EditRoleModal";
import LoaderContainer from "app/components/LoaderContainer";

interface Props {
  merchant: MerchantDetails;
}

export default function Details({ merchant }: Props) {
  const {
    isOpen: isEditProfileOpen,
    openModal: openEditProfileModal,
    closeModal: closeEditProfileModal,
  } = useModalState(false);

  const {
    isOpen: isEditAddressOpen,
    openModal: openEditAddressModal,
    closeModal: closeEditAddressModal,
  } = useModalState(false);

  const {
    isOpen: isEditRoleOpen,
    openModal: openEditRoleModal,
    closeModal: closeEditRoleModal,
  } = useModalState(false);

  const businessResponse = useGetMerchantBusiness(merchant.id ?? "", {});

  const businesses = businessResponse.businesses;

  return (
    <>
      <Layout>
        <Layout.Section oneHalf>
          <GridWrapper>
            <Grid>
              <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 12, xl: 12 }}>
                <Card
                  title={
                    <Text as="p" variant="bodyMd">
                      Number of Businesses
                    </Text>
                  }
                  sectioned
                >
                  <Text variant="headingXl" as="h4">
                    {merchant.num_business}
                  </Text>
                </Card>
              </Grid.Cell>
            </Grid>
          </GridWrapper>

          <Card>
            <Card.Section
              title={`${merchant?.profile.first_name} ${merchant?.profile.last_name}`}
              actions={[{ content: "Edit Profile", onAction: openEditProfileModal }]}
            >
              <Text as="p" variant="bodySm">
                {merchant.email}
              </Text>
              <Text as="p" variant="bodySm">
                {formatDateStr(String(merchant?.created_at), "MMMM D, YYYY [at] hh:mma")}
              </Text>
            </Card.Section>

            <Card.Section
              title="Address"
              actions={[{ content: "Edit", onAction: openEditAddressModal }]}
            >
              <Text as="p" variant="bodySm">
                {merchant.profile.street_address_1}
              </Text>
              <Text as="p" variant="bodySm">
                {merchant.profile.street_address_2}
              </Text>
              <Text as="p" variant="bodySm">
                {merchant.profile.country}
              </Text>
            </Card.Section>

            <Card.Section
              title="Role"
              actions={[
                {
                  content: "Edit",
                  onAction: openEditRoleModal,
                  disabled: true,
                },
              ]}
            >
              <Text as="p" variant="bodySm">
                Owner
              </Text>
            </Card.Section>
          </Card>

          <br />

          <MerchantBusinesses />
        </Layout.Section>

        <Layout.Section secondary oneThird>
          <LoaderContainer
            loading={businessResponse.isLoading}
            onRetryClicked={businessResponse.refetch}
            error={businessResponse.error}
            modal
          >
            {businesses?.data && (
              <BusinessDetails merchant={merchant} businesses={businesses.data} />
            )}
          </LoaderContainer>
        </Layout.Section>
      </Layout>

      <LayoutMargin>
        <Layout>
          <Layout.Section></Layout.Section>
          <Layout.Section secondary></Layout.Section>
        </Layout>
      </LayoutMargin>

      <EditMerchantModal
        formValues={{
          dob: merchant.profile.dob,
          firstname: merchant.profile.first_name,
          lastname: merchant.profile.last_name,
          gender: merchant.profile.gender,
        }}
        onClose={closeEditProfileModal}
        open={isEditProfileOpen}
      />

      <EditMerchantAddressModal
        formValues={{
          street_address_1: merchant.profile.street_address_1,
          street_address_2: merchant.profile.street_address_2,
          city: merchant.profile.city,
          country_code: merchant.profile.country_code,
          postal_code: merchant.profile.postal_code,
          state: merchant.profile.state,
        }}
        onClose={closeEditAddressModal}
        open={isEditAddressOpen}
      />

      <EditRoleModal onClose={closeEditRoleModal} open={isEditRoleOpen} />
    </>
  );
}

export const LayoutMargin = styled.section`
  margin-top: 13px;
`;

export const DataRow = styled.div`
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: space-between;
`;

export const TransactionsWrapper = styled.div`
  margin-bottom: 64px;
`;

export const GridWrapper = styled.div`
  margin-bottom: 16px;
`;
