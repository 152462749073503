import * as React from "react";
import {
  Card,
  ChoiceList,
  FilterInterface,
  Filters,
  Select,
  Stack,
} from "@shopify/polaris";
import debounce from "lodash.debounce";

import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import { GetCurrenciesQuery } from "app/api/currencies/types";
import { GetPaymentsQuery } from "app/api/payments/types";
import GenericDate from "app/components/GenericDate";
import SearchFilter from "app/components/SearchFilter";

interface Props {
  query: GetPaymentsQuery;
  setQuery: (q: GetPaymentsQuery) => void;
  paymentsOnDisplay: number;
}

const statusOptions = [
  {
    label: "Completed",
    value: "completed",
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Failed",
    value: "failed",
  },
];

const searchOptions = [
  {
    label: "Reference",
    value: "reference",
  },
  {
    label: "Business Name",
    value: "business_name",
  },
];

const typeOptions = [
  {
    label: "Payment Link",
    value: "payment_link",
  },
  {
    label: "Invoice",
    value: "invoice",
  },
  {
    label: "Donation",
    value: "donation",
  },
];

const networkOptions = [
  {
    label: "BTC",
    value: "BTC",
  },
  {
    label: "ETH",
    value: "ETH",
  },
  {
    label: "XLM",
    value: "XLM",
  },
  {
    label: "TRX",
    value: "TRX",
  },
  {
    label: "BNB",
    value: "BNB",
  },
  {
    label: "BSC",
    value: "BSC",
  },
];

const fulfilmentStatusOption = [
  {
    label: "Fulfilled",
    value: "fulfilled",
  },
  {
    label: "Success",
    value: "success",
  },
  {
    label: "Unfulfilled",
    value: "unfulfilled",
  },
];

export default function PaymentsFilter(props: Props) {
  const { query, setQuery, paymentsOnDisplay } = props;

  const [queryString, setQueryString] = React.useState(query.q);

  const debouncedQueryUpdate = React.useMemo(
    () => debounce(searchString => setQuery({ q: searchString, page: 1 }), 1000),
    [setQuery]
  );

  useUpdatedEffect(() => {
    debouncedQueryUpdate(queryString);
  }, [queryString]);

  const filters: FilterInterface[] = [
    {
      label: "Status",
      key: "status",
      filter: (
        <ChoiceList
          title="Status"
          titleHidden
          choices={statusOptions}
          selected={[query.status || ""]}
          onChange={t => setQuery({ status: t[0] })}
        />
      ),
      shortcut: true,
    },
    {
      label: "Type",
      key: "type",
      filter: (
        <ChoiceList
          title="Type"
          titleHidden
          choices={typeOptions}
          selected={[query.type || ""]}
          onChange={t => setQuery({ type: t[0] })}
        />
      ),
      shortcut: true,
    },
    {
      label: "Network",
      key: "network",
      shortcut: true,
      filter: (
        <ChoiceList
          title="Network"
          titleHidden
          choices={networkOptions}
          selected={[query.network || ""]}
          onChange={t => setQuery({ network: t[0] })}
        />
      ),
    },
    {
      label: "Fulfilment status",
      key: "fulfilment_status",
      shortcut: true,
      filter: (
        <ChoiceList
          title="Fulfilment status"
          titleHidden
          choices={fulfilmentStatusOption}
          selected={[query.fulfilment_status || ""]}
          onChange={t => setQuery({ fulfilment_status: t[0] })}
        />
      ),
    },
  ];

  const removeFilter = React.useCallback(
    (filter: string) => {
      setQuery({ ...query, [filter]: undefined });
    },
    [setQuery, query]
  );

  const appliedFilters = filters
    .filter(filter => query[filter.key as keyof GetCurrenciesQuery] !== undefined)
    .map(filter => ({
      ...filter,
      label: `${filter.label}: ${query[filter.key as keyof GetCurrenciesQuery]}`,
      onRemove: removeFilter,
    }));

  return (
    <>
      <Card.Section>
        <Stack spacing="tight">
          <Stack.Item fill>
            <SearchFilter options={searchOptions} />
          </Stack.Item>

          <Filters
            filters={filters}
            appliedFilters={appliedFilters}
            focused
            queryValue={queryString}
            onQueryChange={setQueryString}
            onQueryClear={() => setQueryString("")}
            queryPlaceholder="Search ref number"
            onClearAll={() =>
              setQuery({
                type: undefined,
                q: undefined,
                sort: undefined,
                status: undefined,
                page: 1,
                limit: undefined,
                network: undefined,
                fulfilment_status: undefined,
              })
            }
            hideQueryField
          />
        </Stack>
      </Card.Section>

      <Card.Section>
        <Stack distribution="equalSpacing" alignment="center">
          <Stack alignment="center">
            <GenericDate
              onDateChange={value => {
                setQuery({ date: value });
              }}
              onReset={() => setQuery({ date: undefined })}
              value={query.date}
            />

            <p>Showing {paymentsOnDisplay} payments</p>
          </Stack>

          <Select
            labelInline
            label="Sort by"
            name="sort"
            value={query.sort}
            options={[
              { label: "Newest", value: "desc" },
              { label: "Oldest", value: "asc" },
            ]}
            onChange={s => setQuery({ sort: s })}
          />
        </Stack>
      </Card.Section>
    </>
  );
}
