import * as React from "react";
import { Card, Loading, Page, Pagination, Select, Stack, Tabs } from "@shopify/polaris";

import LoaderContainer from "app/components/LoaderContainer";
import CurrencyList from "./components/CurrencyList";
import CurrenciesFilter from "./components/CurrenciesFilter";
import useGetCurrencies from "app/hooks/useGetCurrencies";
import useQueryParams from "app/hooks/useQueryParams";
import { GetCurrenciesQuery } from "app/api/currencies/types";

export default function Currencies() {
  const { queryParams, updateQueryParams } = useQueryParams<GetCurrenciesQuery>({});

  const status = queryParams.status || "online";
  const [selected, setSelected] = React.useState(status === "online" ? 0 : 1);

  const { data, isLoading, error, refetch, isFetching } = useGetCurrencies(queryParams);

  const onSelectTab = (n: number) => {
    setSelected(n);
    updateQueryParams("status", n === 0 ? "online" : "offline");
  };

  return (
    <Page
      title="Currencies"
      primaryAction={{
        content: "Add Currency",
        url: "/settings/currencies/add",
      }}
    >
      {isFetching && <Loading />}

      <Card>
        <Tabs
          selected={selected}
          onSelect={onSelectTab}
          tabs={[
            {
              id: "online",
              content: "Online",
            },
            {
              id: "offline",
              content: "Offline",
            },
          ]}
        >
          <Card.Section>
            <Stack vertical>
              <CurrenciesFilter query={queryParams} setQuery={updateQueryParams} />
              <Stack distribution="equalSpacing" alignment="center">
                <p>Showing {data?.pagination.current_entries_size ?? 0} currencies</p>
                <Select
                  labelInline
                  label="Sort by"
                  name="sort"
                  value={queryParams.sort}
                  options={[
                    { label: "Newest", value: "desc" },
                    { label: "Oldest", value: "asc" },
                  ]}
                  onChange={s => updateQueryParams("sort", s)}
                />
              </Stack>
            </Stack>
          </Card.Section>

          <LoaderContainer
            style={{ minHeight: "unset" }}
            loading={isLoading}
            error={error}
            onRetryClicked={refetch}
          >
            {data && <CurrencyList currencies={data.currency} />}
          </LoaderContainer>

          <Card.Section>
            {data && (
              <Stack distribution="center">
                <Pagination
                  label={`${queryParams.page ?? 1} / ${data.pagination.total_pages}`}
                  hasNext={data.pagination.total_pages > data.pagination.page}
                  onNext={() => {
                    updateQueryParams("page", (Number(queryParams.page) ?? 1) + 1);
                  }}
                  hasPrevious={data.pagination.page > 1}
                  onPrevious={() => {
                    updateQueryParams("page", Number(queryParams.page ?? 1) - 1);
                  }}
                />
              </Stack>
            )}
          </Card.Section>
        </Tabs>
      </Card>
    </Page>
  );
}
