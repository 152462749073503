import AnalyticsCard from "./AnalyticsCard";
import useListMerchants from "app/views/Merchants/hooks/useListMerchants";

export default function TotalMerchants() {
  const { merchants, isLoading, error, refetch } = useListMerchants();

  const merchantsData = merchants?.pagination.total_entries_size ?? "";

  return (
    <AnalyticsCard
      title="Total Merchants"
      viewLink="/merchants"
      amount={merchantsData}
      loading={isLoading}
      error={error}
      refetch={refetch}
    />
  );
}
