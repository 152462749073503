export interface Permission {
  id: string;
  category: string;
  description: string;
  owner: boolean;
  admin: boolean;
  support: boolean;
  developer: boolean;
}

export enum RoleEnum {
  owner = "owner",
  support = "support",
  dev = "software developer",
  admin = "admin",
}

export function makeOtherCategoriesEmpty(arr: Permission[]) {
  const encounterdCategories = new Set();

  for (let i = 0; i < arr.length; i++) {
    const category = arr[i].category;

    if (encounterdCategories.has(category)) {
      arr[i].category = "";
    } else {
      encounterdCategories.add(category);
    }
  }

  return arr;
}
