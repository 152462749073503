import {
  Card,
  Checkbox,
  FormLayout,
  Layout,
  Select,
  Stack,
  TextField,
} from "@shopify/polaris";
import { AddDocTypePayload } from "app/api/compliance/types";
import { BusinessType } from "app/models/business";
import { Controller, useFormContext } from "react-hook-form";

interface Props {
  businessTypes: BusinessType[];
}
export default function DocumentTypeForm(props: Props) {
  const { businessTypes } = props;
  const { control, setValue } = useFormContext<AddDocTypePayload>();

  const businessTypesOptions = businessTypes.map(businessType => {
    return {
      value: businessType.id,
      label: businessType.name,
    };
  });

  return (
    <Layout>
      <Layout.Section>
        <Card sectioned>
          <FormLayout>
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  label="Name"
                  onChange={val => field.onChange(val)}
                  value={field.value}
                  autoComplete="off"
                  placeholder="eg. Proof of Address"
                />
              )}
            />
          </FormLayout>
        </Card>

        <Card sectioned>
          <FormLayout>
            <Controller
              control={control}
              name="warning"
              render={({ field }) => (
                <TextField
                  label="Warning"
                  onChange={val => field.onChange(val)}
                  value={field.value}
                  autoComplete="off"
                  multiline={2}
                  placeholder="Screenshots are not allowed. JPG, PNG or PDF format only."
                />
              )}
            />

            <Controller
              control={control}
              name="requirement"
              render={({ field }) => (
                <TextField
                  label="Requirement"
                  onChange={val => field.onChange(val)}
                  value={field.value}
                  autoComplete="off"
                  multiline={2}
                  placeholder="Upload a colour photo of the data page of your international, drivers licence, NIMC slip or voters card. Make sure the picture is clear, readable and not blurry"
                />
              )}
            />
          </FormLayout>
        </Card>
      </Layout.Section>

      <Layout.Section secondary>
        <Card>
          <Card.Section title="DOCUMENT TYPE">
            <div style={{ marginTop: 16 }}>
              <Controller
                control={control}
                name="business_type_id"
                render={({ field }) => (
                  <Select
                    label=""
                    labelHidden
                    options={businessTypesOptions}
                    onChange={val => field.onChange(val)}
                    value={field.value}
                  />
                )}
              />
            </div>
          </Card.Section>
        </Card>

        <Card>
          <Card.Section title="ID NUMBER">
            <div style={{ marginTop: 16 }}>
              <Stack vertical>
                <Controller
                  control={control}
                  name="id_number_required"
                  render={({ field }) => (
                    <Checkbox
                      label="Required"
                      checked={field.value === true}
                      onChange={() => {
                        setValue("id_number_required", true, {
                          shouldDirty: true,
                        });
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="id_number_required"
                  render={({ field }) => (
                    <Checkbox
                      label="Not required"
                      checked={field.value === false}
                      onChange={() => {
                        setValue("id_number_required", false, {
                          shouldDirty: true,
                        });
                      }}
                    />
                  )}
                />
              </Stack>
            </div>
          </Card.Section>
        </Card>
        <Card>
          <Card.Section title="VERIFICATION">
            <div style={{ marginTop: 16 }}>
              <Stack vertical>
                <Controller
                  control={control}
                  name="verify_with_text"
                  render={({ field }) => (
                    <Checkbox
                      label="Verify with Text"
                      checked={field.value}
                      onChange={val => field.onChange(val)}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="verify_with_selfie"
                  render={({ field }) => (
                    <Checkbox
                      label="Verify with Selfie"
                      checked={field.value}
                      onChange={val => field.onChange(val)}
                    />
                  )}
                />
              </Stack>
            </div>
          </Card.Section>
        </Card>
      </Layout.Section>
    </Layout>
  );
}
