import * as React from "react";
import styled from "@emotion/styled";
import { useSearchParams } from "react-router-dom";
import { Badge, ButtonGroup, Card, Layout, Link, Stack, Text } from "@shopify/polaris";

import RejectDocumentModal from "./RejectDocumentModal";
import ApproveDocumentModal from "./ApproveDocumentModal";
import Business from "app/models/business";
import useGetDocument from "../../hooks/useGetDocument";
import ComplianceDocument from "app/models/compliance";
import LoaderContainer from "app/components/LoaderContainer";
import useGetMerchant from "app/hooks/useGetMerchant";
import { capitalizeStr, mapStatusToBadge } from "app/utils/helpers";

interface Props {
  business: Business;
  documents: ComplianceDocument[];
}
export default function DocumentsSection(props: Props) {
  const { business, documents } = props;

  const { merchant, ...merchantQuery } = useGetMerchant(business.user_id);

  const fileDocuments = documents.filter(item => item.type === "image");
  const textDocuments = documents.filter(item => item.type === "text");

  const [searchParams, setSearchParams] = useSearchParams();

  const activeDocID = (searchParams.get("docId") || fileDocuments[0]?.id) ?? "";

  const { data: document, ...documentQuery } = useGetDocument(activeDocID);

  return (
    <Layout>
      <Layout.Section secondary>
        <Stack vertical>
          <Card
            title={
              <Text as="h3" variant="headingXs">
                DOCUMENTS
              </Text>
            }
          >
            <ListWrapper>
              {fileDocuments.length > 0 ? (
                fileDocuments.map(document => {
                  return (
                    <DocumentItem
                      key={document.id}
                      active={document.id === activeDocID}
                      onClick={() => {
                        searchParams.set("docId", document.id);
                        setSearchParams(searchParams, { replace: true });
                      }}
                    >
                      <Stack vertical>
                        <Text as="p" variant="bodyMd">
                          {capitalizeStr(document.document_name)}
                        </Text>

                        <Stack distribution="equalSpacing">
                          <Link removeUnderline url="">
                            <Text as="p" variant="bodyMd">
                              {document.reference}
                            </Text>
                          </Link>
                          <Badge
                            progress={
                              document.status === "pending"
                                ? "partiallyComplete"
                                : "complete"
                            }
                            status={mapStatusToBadge(document.status)}
                          >
                            {capitalizeStr(document.status)}
                          </Badge>
                        </Stack>
                      </Stack>
                    </DocumentItem>
                  );
                })
              ) : (
                <Card.Section>
                  <Text as="p" variant="bodyMd">
                    No documents uploaded
                  </Text>
                </Card.Section>
              )}
            </ListWrapper>
          </Card>

          {textDocuments.length > 0 && (
            <Card>
              {textDocuments.map(document => {
                return (
                  <Card.Section key={document.id} title={document.document_name}>
                    <Text as="p" variant="bodyMd" fontWeight="regular">
                      {document.reference}
                    </Text>
                  </Card.Section>
                );
              })}
            </Card>
          )}

          <Card
            title={
              <Text as="h3" variant="headingXs">
                BUSINESS
              </Text>
            }
            sectioned
          >
            <Link removeUnderline url={`/businesses/${business.id}`}>
              <Text as="p" variant="bodyMd" fontWeight="medium">
                {business.name}
              </Text>
            </Link>
          </Card>

          <LoaderContainer
            error={merchantQuery.error}
            loading={merchantQuery.isLoading}
            onRetryClicked={merchantQuery.refetch}
            small
          >
            {merchant && (
              <Card
                title={
                  <Text as="h3" variant="headingXs">
                    MERCHANT
                  </Text>
                }
                sectioned
              >
                <Link url={`/merchants/${merchant.id}`} removeUnderline>
                  <Text as="p" variant="bodyMd" fontWeight="medium">
                    {merchant.profile.first_name} {merchant.profile.last_name}
                  </Text>
                </Link>
              </Card>
            )}
          </LoaderContainer>
        </Stack>
      </Layout.Section>

      <Layout.Section>
        <Card>
          <LoaderContainer
            loading={documentQuery.isLoading && activeDocID !== ""}
            error={documentQuery.error}
            onRetryClicked={documentQuery.refetch}
            small
          >
            {document ? (
              <Card.Section
                title={
                  <Stack alignment="center" distribution="equalSpacing">
                    <Text variant="headingSm" as="p">
                      {document?.document}
                    </Text>

                    {document?.status === "pending" && (
                      <ButtonGroup>
                        <RejectDocumentModal documentId={document.id} />

                        <ApproveDocumentModal documentId={document.id} />
                      </ButtonGroup>
                    )}
                  </Stack>
                }
              >
                <div style={{ height: "16px" }}></div>
                <FilePreviewer
                  title={"PDF-Viewer"}
                  src={document?.file}
                  frameBorder={0}
                />
              </Card.Section>
            ) : (
              <Card.Section>
                <Text as="p" variant="bodyMd">
                  No document to preview
                </Text>
              </Card.Section>
            )}
          </LoaderContainer>
        </Card>
      </Layout.Section>
    </Layout>
  );
}

const FilePreviewer = styled.iframe`
  height: 592px;
  width: 100%;
`;

const DocumentItem = styled.div<{ active: boolean }>`
  cursor: pointer;
  padding: 20px;
  transition: 0.3s background-color ease;

  ${props => props.active && `background-color: #f6f6f7;`}

  &:hover {
    background-color: #f6f6f7;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid #e1e3e5;
  }

  &:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const ListWrapper = styled.div`
  padding-top: 16px;
`;
