import * as React from "react";
import { useParams } from "react-router-dom";

export type FormMode = "add" | "edit";

export default function useFormMode() {
  const params = useParams<{ code: string; id: string }>();

  const formMode: FormMode = React.useMemo(
    () =>
      params.code?.toLowerCase() === "create" ||
      params.id?.toLowerCase() === "create"
        ? "add"
        : "edit",
    [params.code, params.id]
  );

  return formMode;
}
