import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";

import QueryProvider from "./providers/QueryProvider";
import TokenProvider from "./providers/TokenProvider";
import history from "./lib/history";
import Routes from "./routes";

function App() {
  return (
    <TokenProvider>
      <QueryProvider>
        <HistoryRouter history={history}>
          <Routes />
        </HistoryRouter>
      </QueryProvider>
    </TokenProvider>
  );
}

export default App;
