// owner,admin,support,analyst, & view
const roles: { label: string; value: string }[] = [
  {
    label: "Owner",
    value: "owner",
  },
  {
    label: "Administrator",
    value: "admin",
  },
  {
    label: "Support",
    value: "support",
  },
  {
    label: "Analyst",
    value: "analyst",
  },
  {
    label: "View",
    value: "view",
  },
];

export default roles;
