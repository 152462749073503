import {
  Badge,
  Card,
  EmptySearchResult,
  IndexTable,
  Link,
  Text,
} from "@shopify/polaris";
import styled from "@emotion/styled";

import { Invoice } from "app/models/invoice";
import {
  capitalizeStr,
  formatDateStr,
  mapInvoiceStatusToBadge,
} from "app/utils/helpers";
import { formatMoney } from "app/lib/money";
import TableWrapper from "app/components/TableWrapper";

interface Props {
  invoices: Invoice[];
}
export default function InvoicesList({ invoices }: Props) {
  return (
    <Card.Section fullWidth>
      <TableWrapper>
        <IndexTable
          resourceName={{ singular: "invoice", plural: "invoices" }}
          headings={[
            { title: "Invoice Ref" },
            { title: "Date Created" },
            { title: "Business" },
            { title: "Customer" },
            { title: "Status" },
            { title: "Amount" },
          ]}
          itemCount={invoices.length}
          emptyState={
            <EmptySearchResult
              title="No invoices yet"
              description="Try changing the filters or search term"
              withIllustration
            />
          }
          selectable={false}
        >
          {invoices.map((invoice, index) => {
            return (
              <IndexTable.Row key={invoice.id} id={invoice.id} position={index}>
                <IndexTable.Cell>
                  <CellPadding>
                    <Link url={`/invoices/${invoice.id}`} removeUnderline>
                      {invoice.reference}
                    </Link>
                  </CellPadding>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  {formatDateStr(
                    invoice.created_at!,
                    "MMMM DD, YYYY [at] hh:mm a"
                  )}
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <Link
                    url={`/businesses/${invoice.business_id}`}
                    removeUnderline
                  >
                    {invoice.business_name}
                  </Link>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <Text as="p" variant="bodyMd">
                    {invoice.customer_name}
                  </Text>
                  <Text as="p" variant="bodyMd" color="subdued">
                    {invoice.customer_email}
                  </Text>
                </IndexTable.Cell>

                <IndexTable.Cell>
                  <Badge status={mapInvoiceStatusToBadge(invoice.status)}>
                    {capitalizeStr(invoice.status)}
                  </Badge>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  {formatMoney(
                    Number(invoice.local_amount),
                    "",
                    invoice.local_currency
                  )}
                </IndexTable.Cell>
              </IndexTable.Row>
            );
          })}
        </IndexTable>
      </TableWrapper>
    </Card.Section>
  );
}

const CellPadding = styled.div`
  padding: 13px 0px;
`;
