import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import FeedbackService from "app/api/feedbacks";
import { GetFeedbacksQuery } from "app/api/feedbacks/types";
import parseError from "app/lib/parseError";

interface Props {
  params?: GetFeedbacksQuery;
}

export default function useGetFeedbacks(props: Props) {
  const { params } = props;
  const { data, isLoading, error, refetch, isFetching } = useQuery(
    ["feedbacks", params],
    () => FeedbackService.getFeedbacks(params)
  );

  return {
    feedbacks: data?.data,
    isFetching,
    isLoading,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
  };
}
