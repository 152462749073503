import AnalyticsCard from "./AnalyticsCard";
import useListInvoices from "app/views/Invoices/hooks/useListInvoices";

export default function Invoices() {
  const { invoices, isLoading, error, refetch } = useListInvoices({});

  const invoicesData = invoices?.pagination.total_entries_size ?? "";

  return (
    <AnalyticsCard
      title="Invoices"
      viewLink="/invoices"
      amount={invoicesData}
      loading={isLoading}
      error={error}
      refetch={refetch}
    />
  );
}
