import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import AuditLogService from "app/api/auditLog";
import { GetAuditLogsQuery } from "app/api/auditLog/types";
import useQueryParams from "app/hooks/useQueryParams";

export default function useGetAuditLogs() {
  const { queryParams, updateQueryParams } = useQueryParams<GetAuditLogsQuery>({});

  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["auditLogs", queryParams],
    () => AuditLogService.getLogs(queryParams)
  );

  return {
    isLoading,
    isFetching,
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
    query: queryParams,
    setQuery: updateQueryParams,
  };
}
