import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import { useToast } from "app/providers/ToastProvider";
import RolesService from "app/api/roles";

export default function useTogglePermission() {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const { isLoading: isToggling, mutate: togglePermission } = useMutation(
    RolesService.updateRole,
    {
      onSuccess: res => {
        showToast(res.data.message || "", "success");

        queryClient.invalidateQueries(["role_permissions"]);
      },
      onError: (error: AxiosError<{}>) => {
        const errorMessage = parseError(error) as string;

        showToast(errorMessage || "", "error");
      },
    }
  );

  return {
    isToggling,
    togglePermission,
  };
}
