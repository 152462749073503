import { Card, Page, Pagination, Stack } from "@shopify/polaris";
import LoaderContainer from "app/components/LoaderContainer";
import DocumentsFilters from "./components/DocumentsFilters";
import DocumentsList from "./components/DocumentsList";
import useGetDocumentTypes from "./hooks/useGetDocumentTypes";
import useGetBusinessTypes from "app/hooks/useGetBusinessTypes";
import useQueryParams from "app/hooks/useQueryParams";
import { GetDocumentTypesQuery } from "app/api/compliance/types";

export default function DocumentSettings() {
  const { queryParams, updateQueryParams } = useQueryParams<GetDocumentTypesQuery>({});

  const documentTypesQuery = useGetDocumentTypes(queryParams);
  const businessTypesQuery = useGetBusinessTypes();

  const businessTypes = Object.values(businessTypesQuery.businessTypes ?? {});

  const handleRefetch = () => {
    if (documentTypesQuery.error) {
      documentTypesQuery.refetch();
    }

    if (businessTypesQuery.error) {
      businessTypesQuery.refetch();
    }
  };

  return (
    <Page
      title="Documents Types"
      primaryAction={{
        content: "Add Document Type",
        url: "/settings/documents/create",
      }}
    >
      <Card>
        <DocumentsFilters
          query={queryParams}
          updateQuery={updateQueryParams}
          businessTypes={businessTypes}
        />

        <LoaderContainer
          style={{ minHeight: "unset" }}
          loading={documentTypesQuery.isLoading || businessTypesQuery.isLoading}
          error={documentTypesQuery.error || businessTypesQuery.error}
          onRetryClicked={handleRefetch}
        >
          {documentTypesQuery.documentTypes && businessTypesQuery.businessTypes && (
            <DocumentsList
              businessTypes={businessTypesQuery.businessTypes}
              documentTypes={documentTypesQuery.documentTypes}
            />
          )}
        </LoaderContainer>

        <Card.Section>
          <Stack distribution="center">
            <Pagination />
          </Stack>
        </Card.Section>
      </Card>
    </Page>
  );
}
