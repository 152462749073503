import { useCallback } from "react";
import { Select } from "@shopify/polaris";

interface Props {
  categories: { label: string; value: any }[];
  setQuery: (s: string) => void;
  query?: string;
}

export default function PermissionsFilter({ categories, query, setQuery }: Props) {
  const handleSelectChange = useCallback((value: string) => setQuery(value), [setQuery]);

  const options = [{ label: "Select", value: "" }, ...categories];

  return (
    <Select
      label="Categories"
      options={options}
      onChange={handleSelectChange}
      value={query}
      labelHidden
    />
  );
}
