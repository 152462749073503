import styled from "@emotion/styled";
import { Card, IndexTable } from "@shopify/polaris";
import { PaymentLinkAnalytics } from "app/models/analytics";
import { formatDateStr } from "app/utils/helpers";

import { CellPadding, TableWrapper } from "./styles";

interface PaymentLink {
  paymentLink: PaymentLinkAnalytics;
}

export function DonationAccounts(props: PaymentLink) {
  const { paymentLink } = props;

  const numberOfDonation = paymentLink.total_count;

  return (
    <Card title={numberOfDonation + " Donations"}>
      <Card.Section fullWidth>
        <TableWrapper>
          <IndexTable
            headings={[
              { title: "Currency" },
              { title: "Transactions" },
              { title: "Last Received" },
              { title: "Available Balance" },
            ]}
            itemCount={paymentLink.balances?.length ?? 0}
            selectable={false}
          >
            {paymentLink.balances?.map((paymentLink, i) => {
              return (
                <IndexTable.Row id="" key={paymentLink.last_received} position={i}>
                  <IndexTable.Cell>
                    <CellPadding>
                      <Info>
                        <img
                          src={`https://res.cloudinary.com/busha-inc/image/upload/v1628857343/App/Currencies/${paymentLink.currency}.svg`}
                          alt={paymentLink.currency}
                        />
                        {paymentLink.currency}
                      </Info>
                    </CellPadding>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <CellPadding>{paymentLink.count}</CellPadding>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <CellPadding>
                      {paymentLink.last_received &&
                        formatDateStr(
                          paymentLink.last_received,
                          "MMMM D, YYYY [at] hh:mma"
                        )}
                    </CellPadding>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <CellPadding>{paymentLink.amount_equivalent}</CellPadding>
                  </IndexTable.Cell>
                </IndexTable.Row>
              );
            })}
          </IndexTable>
        </TableWrapper>
      </Card.Section>
    </Card>
  );
}

export const Info = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 16px;
    width: 32px;
    height: 32px;
    border-radius: 30px;
  }
`;
