import request from "../request";
import { BaseResponse } from "../types";
import {
  LoginConfirmationPayload,
  LoginConfirmationResponse,
  LoginPayload,
  LoginResponse,
} from "./types";

const login = async (data: LoginPayload) => {
  return request.post<LoginResponse>("/v1/auth/login", data, {
    headers: {
      noToken: true,
    },
  });
};

const confirmLogin = async (data: LoginConfirmationPayload) => {
  const { code, confirmation_token } = data;
  return request.post<LoginConfirmationResponse>(
    "/v1/auth/confirm",
    { code },
    {
      headers: {
        noToken: true,
        Authorization: `Bearer ${confirmation_token}`,
      },
    }
  );
};

const resendLoginConfirmationCode = async (confirmation_token: string) => {
  return request.get<BaseResponse>("/v1/auth/resend/code", {
    headers: {
      noToken: true,
      Authorization: `Bearer ${confirmation_token}`,
    },
  });
};

const authService = { login, confirmLogin, resendLoginConfirmationCode };

export default authService;
