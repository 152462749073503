import * as React from "react";
import styled from "@emotion/styled";
import { Button, Layout, Page, Stack } from "@shopify/polaris";
import GenericDate from "app/components/GenericDate";
import PaymentLinks from "./components/PaymentLinks";
import Donations from "./components/Donations";
import Invoices from "./components/Invoices";
import PaymentsAsset from "./components/PaymentsAsset";
import PaymentsProcessed from "./components/PaymentsProcessed";
import PaymentsProcessedOn from "./components/PaymentsProcessedOn";
import WithdrawalsAsset from "./components/WithdrawalsAsset";
import WithdrawalsProcessed from "./components/WithdrawalsProcessed";
import WithdrawalsType from "./components/WithdrawalsType";
import TotalPayments from "./components/TotalPayments";
import TotalBusinesses from "./components/TotalBusinesses";
import TotalMerchants from "./components/TotalMerchants";

export default function Analytics() {
  return (
    <Page title="Analytics" fullWidth>
      <Stack distribution="equalSpacing" alignment="center">
        <Stack spacing="tight" alignment="center">
          <GenericDate
            onDateChange={function (dateString: string): void {
              throw new Error("Function not implemented.");
            }}
            onReset={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
          <Button>Compare</Button>
        </Stack>
        <Button>Re-sync</Button>
      </Stack>
      <SectionWrapper>
        <Layout>
          <Layout.Section oneThird>
            <TotalMerchants />
            <PaymentLinks />
            <PaymentsProcessed />
            <WithdrawalsProcessed />
          </Layout.Section>
          <Layout.Section oneThird>
            <TotalBusinesses />
            <Donations />
            <PaymentsProcessedOn />
            <WithdrawalsType />
          </Layout.Section>
          <Layout.Section oneThird>
            <TotalPayments />
            <Invoices />
            <PaymentsAsset />
            <WithdrawalsAsset />
          </Layout.Section>
        </Layout>
      </SectionWrapper>
    </Page>
  );
}

export const SectionWrapper = styled.div`
  margin-top: 17px;
`;
