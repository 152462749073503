import parseError from "app/lib/parseError";
import { AxiosError } from "axios";

import { useQuery } from "@tanstack/react-query";
import PayoutsService from "app/api/withdrawals";
import { useParams } from "react-router";

export default function useWithdrawalDetails() {
  const params = useParams<{ id: string }>();

  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["withdrawal", params.id],
    () => PayoutsService.getWithdrawal(params.id ?? "")
  );

  return {
    isLoading,
    isFetching,
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
  };
}
