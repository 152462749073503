import * as React from "react";
import styled from "@emotion/styled";
import {
  DisplayText,
  FormLayout,
  Stack,
  TextStyle,
  TextField,
  Button,
  InlineError,
} from "@shopify/polaris";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LoginConfirmationPayload } from "app/api/auth/types";
import { useToast } from "app/providers/ToastProvider";

const schema = Yup.object().shape({
  code: Yup.string().required("Authentication code is required"),
});

type ConfirmationPayload = Omit<LoginConfirmationPayload, "confirmation_token">;
interface Props {
  onSubmit: (v: ConfirmationPayload) => void;
  onGoBack: () => void;
  loading: boolean;
  error: string | null;
  handleResendConfirmationCode: () => void;
  resendConfirmationPending: boolean;
  resendConfirmationError: string | null;
  resendConfirmationMessage?: string;
  loginMessage: string;
}
export default function TwoFAForm(props: Props) {
  const {
    loading,
    error,
    onGoBack,
    handleResendConfirmationCode,
    resendConfirmationPending,
    resendConfirmationMessage,
    resendConfirmationError,
    loginMessage,
    onSubmit,
  } = props;

  const {
    formState: { errors },
    control,
    setValue,
    handleSubmit,
  } = useForm<ConfirmationPayload>({
    resolver: yupResolver(schema),
  });

  const toast = useToast();

  React.useEffect(() => {
    if (resendConfirmationMessage) {
      toast(resendConfirmationMessage, "success");
    }
    if (resendConfirmationError) {
      toast(resendConfirmationError, "error");
    }
  }, [toast, resendConfirmationMessage, resendConfirmationError]);

  return (
    <>
      <Stack vertical spacing="loose">
        <Stack vertical spacing="tight">
          <DisplayText element="h2" size="medium">
            <TextStyle variation="strong">Confirm Login</TextStyle>
          </DisplayText>
          <p>
            <TextStyle variation="subdued">{loginMessage}</TextStyle>
          </p>
        </Stack>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormLayout>
            {error && <InlineError message={error} fieldID="form" />}

            <Controller
              name="code"
              control={control}
              render={({ field }) => (
                <TextField
                  autoComplete=""
                  autoFocus
                  labelHidden
                  label="Code"
                  placeholder="000000"
                  name="code"
                  value={field.value}
                  onChange={(v) => setValue("code", v)}
                  error={errors.code?.message}
                />
              )}
            />

            <Button submit primary loading={loading}>
              Confirm
            </Button>

            <Button
              plain
              removeUnderline
              onClick={handleResendConfirmationCode}
              loading={resendConfirmationPending}
            >
              Resend code
            </Button>
          </FormLayout>

          <Footer>
            <p>
              Have trouble Verifying?{" "}
              <Button plain removeUnderline onClick={onGoBack}>
                Go Home
              </Button>
            </p>
          </Footer>
        </form>
      </Stack>
    </>
  );
}

const Footer = styled.div`
  margin-top: 45px;
`;
