import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import MerchantService from "app/api/merchants";
import { useToast } from "app/providers/ToastProvider";

export default function useHaltDeletion() {
  const queryClient = useQueryClient();
  const showToast = useToast();

  const { mutate, isLoading, data, error } = useMutation(MerchantService.haltDeletion, {
    onError: (error: AxiosError<{}>) => {
      const errorMessage = parseError(error) as string;

      showToast(errorMessage || "", "error");
    },
    onSuccess: response => {
      showToast(response.data.message || "", "success");
      queryClient.invalidateQueries(["merchant"]);
    },
  });

  return {
    haltDeletion: mutate,
    loading: isLoading,
    response: data?.data.message,
    error: error ? (parseError(error as AxiosError<{}>) as string) : null,
  };
}
