import * as React from "react";
import {
  Autocomplete,
  Icon,
  IconSource,
  Stack,
  TextContainer,
  TextFieldProps,
} from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import { useQuery } from "@tanstack/react-query";
import debounce from "lodash.debounce";

import CurrencyService from "app/api/currencies";
import { CurrencyItem } from "app/models/currency";

interface Props {
  value: string;
  onChange: (v: string, metaData?: CurrencyItem) => void;
}
export default function CurrencyField(props: Props & TextFieldProps) {
  const { value, onChange, ...rest } = props;

  const [search, setSearch] = React.useState(value || "");
  const [deboucedSearchVal, setDebouncedSearch] = React.useState(value || "");

  React.useEffect(() => {
    setSearch(value);
  }, [value]);

  const { data, isLoading } = useQuery(["currencies", deboucedSearchVal], () =>
    CurrencyService.getCurrencies({
      q: deboucedSearchVal,
    })
  );

  const [isFocused, setIsFocused] = React.useState(false);

  const debouncedQueryUpdate = React.useMemo(
    () => debounce((s) => setDebouncedSearch(s), 500),
    [setDebouncedSearch]
  );

  const onSearchChange = React.useCallback(
    (s: string) => {
      debouncedQueryUpdate(s);
      setSearch(s);
    },
    [debouncedQueryUpdate]
  );

  const getCorrespondingCurrency = (value: string) => {
    const currency = data?.data.currency || [];
    const correspondingCurrency = currency.find((item) => item.code === value);
    return correspondingCurrency;
  };

  const handleSelect = (s: string[]) => {
    setIsFocused(false);
    const correspondingCurrency = getCorrespondingCurrency(s[0]);
    onChange(s[0], correspondingCurrency);
  };

  const emptyState = (
    <>
      <Icon source={SearchMinor as IconSource} />
      <Stack distribution="center">
        <TextContainer>Could not find any results</TextContainer>
      </Stack>
    </>
  );

  return (
    <Autocomplete
      emptyState={emptyState}
      loading={isLoading}
      options={
        data?.data?.currency.map((c) => ({ value: c.code, label: c.code })) ||
        []
      }
      selected={[value]}
      onSelect={handleSelect}
      textField={
        <Autocomplete.TextField
          {...rest}
          autoComplete="off"
          placeholder="Search e.g BTC"
          value={search}
          onChange={onSearchChange}
          onBlur={() => {
            setIsFocused(false);
            if (search !== value) setSearch(value);
          }}
          onFocus={() => setIsFocused(true)}
          focused={isFocused}
        />
      }
    />
  );
}
