import * as React from "react";
import {
  Card,
  ChoiceList,
  FilterInterface,
  Filters,
  Select,
  Stack,
} from "@shopify/polaris";
import debounce from "lodash.debounce";

import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import { GetTransactionsQuery } from "app/api/transactions/types";
import GenericDate from "app/components/GenericDate";

const statusOptions = [
  {
    label: "Completed",
    value: "completed",
  },
  {
    label: "Pending",
    value: "PENDING",
  },
  {
    label: "Failed",
    value: "failed",
  },
];

const categoryOptions = [
  {
    label: "Crypto Withdrawal",
    value: "crypto_withdrawal",
  },
  {
    label: "Buy",
    value: "buy",
  },
  {
    label: "Sell",
    value: "sell",
  },
  {
    label: "Crypto deposit",
    value: "crypto_deposit",
  },
  {
    label: "Connect cash deposit",
    value: "connect_cash_deposit",
  },
  {
    label: "Connect cash withdrawal",
    value: "connect_cash_withdrawal",
  },
  {
    label: "Connect cash withdrawal reversal",
    value: "connect_cash_withdrawal_reversal",
  },
];

interface Props {
  query: GetTransactionsQuery;
  setQuery: (q: GetTransactionsQuery) => void;
  transactionsOnDisplay: number;
}

export default function TransactionFilter(props: Props) {
  const { query, setQuery, transactionsOnDisplay } = props;

  const [queryString, setQueryString] = React.useState(() => query.reference ?? "");

  const debouncedQueryUpdate = React.useMemo(
    () =>
      debounce(
        searchString =>
          setQuery({
            reference: searchString.length ? searchString : undefined,
            page: 1,
          }),
        1000
      ),
    [setQuery]
  );

  useUpdatedEffect(() => {
    debouncedQueryUpdate(queryString);
  }, [queryString]);

  const filters: FilterInterface[] = [
    {
      label: "Category",
      key: "category",
      filter: (
        <ChoiceList
          title="Category"
          selected={[query.category || ""]}
          choices={categoryOptions}
          onChange={t => setQuery({ category: t[0] })}
          titleHidden
        />
      ),
      shortcut: true,
    },
    {
      label: "Status",
      key: "status",
      filter: (
        <ChoiceList
          title="Status"
          titleHidden
          selected={[query.status || ""]}
          choices={statusOptions}
          onChange={t => setQuery({ status: t[0] })}
        />
      ),
      shortcut: true,
    },
  ];

  const removeFilter = React.useCallback(
    (filter: string) => {
      setQuery({ ...query, [filter]: undefined });
    },
    [setQuery, query]
  );

  const appliedFilters = Object.keys(query)
    .filter(
      key =>
        !["q", "limit", "page", "sort"].includes(key) &&
        query[key as keyof GetTransactionsQuery] !== undefined
    )
    .map(key => {
      return {
        key,
        label: `${key}: ${query[key as keyof GetTransactionsQuery]}`,
        onRemove: removeFilter,
      };
    });

  return (
    <>
      <Card.Section>
        <Stack vertical>
          <Filters
            filters={filters}
            appliedFilters={appliedFilters}
            focused
            queryValue={queryString}
            onQueryChange={setQueryString}
            onQueryClear={() => {
              setQueryString("");
              setQuery({ ...query, category: undefined });
            }}
            queryPlaceholder="Search by transaction reference"
            onClearAll={() =>
              setQuery({
                category: undefined,
                sort: undefined,
                page: 1,
                limit: undefined,
              })
            }
            hideTags
          />
        </Stack>
      </Card.Section>

      <Card.Section>
        <Stack distribution="equalSpacing" alignment="center">
          <Stack alignment="center">
            <GenericDate
              onDateChange={value => {
                setQuery({ date: value });
              }}
              onReset={() => setQuery({ date: undefined })}
              value={query.date}
            />

            <p>Showing {transactionsOnDisplay} transactions</p>
          </Stack>

          <Select
            labelInline
            label="Sort by"
            name="sort"
            value={query.sort}
            options={[
              { label: "Newest", value: "desc" },
              { label: "Oldest", value: "asc" },
            ]}
            onChange={s => setQuery({ sort: s })}
          />
        </Stack>
      </Card.Section>
    </>
  );
}
