import styled from "@emotion/styled";
import { Badge, IndexTable, Link } from "@shopify/polaris";

import TableWrapper from "app/components/TableWrapper";
import ConnectTrade from "app/models/connect";
import { formatMoney } from "app/lib/money";
import { capitalizeStr, formatDateStr, mapConnectStatusToBadge } from "app/utils/helpers";

interface Props {
  trades: ConnectTrade[];
}

export default function ConnectList(props: Props) {
  const { trades } = props;

  return (
    <TableWrapper>
      <IndexTable
        selectable={false}
        resourceName={{ plural: "connects", singular: "conects" }}
        itemCount={trades.length}
        headings={[
          { title: "Trade" },
          { title: "Date" },
          { title: "Business" },
          { title: "Offer" },
          { title: "Amount" },
          { title: "Status" },
          { title: "Merchant" },
        ]}
      >
        {trades.map((trade, i) => {
          return (
            <IndexTable.Row id={trade.id} key={trade.id} position={i}>
              <IndexTable.Cell>
                <CellPadding>
                  {trade.status === "initialized" ? (
                    trade.reference
                  ) : (
                    <Link removeUnderline url={`/connect/${trade.id}`}>
                      {trade.reference}
                    </Link>
                  )}
                </CellPadding>
              </IndexTable.Cell>
              <IndexTable.Cell>
                {formatDateStr(trade.created_at, "MMMM D, YYYY [at] hh:mma")}
              </IndexTable.Cell>
              <IndexTable.Cell>
                <Link removeUnderline url={`/businesses/${trade.business_id}`}>
                  {trade.business_name}
                </Link>
              </IndexTable.Cell>
              <IndexTable.Cell>{capitalizeStr(trade.side)}</IndexTable.Cell>
              <IndexTable.Cell>
                {formatMoney(Number(trade.amount), "", trade.currency)}
              </IndexTable.Cell>
              <IndexTable.Cell>
                <CellPadding>
                  <Badge status={mapConnectStatusToBadge(trade.status)}>
                    {capitalizeStr(trade.status)}
                  </Badge>
                </CellPadding>
              </IndexTable.Cell>
              <IndexTable.Cell>{trade.merchant_username}</IndexTable.Cell>
            </IndexTable.Row>
          );
        })}
      </IndexTable>
    </TableWrapper>
  );
}

const CellPadding = styled.div`
  padding: 13px 0px;
`;
