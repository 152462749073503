import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import TeamsService from "app/api/teams";
import { GetTeamsQuery } from "app/api/teams/types";

export default function useGetTeamMembers(businessId: string, params?: GetTeamsQuery) {
  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["teamMembers", params, businessId],
    () => TeamsService.getTeamMembers(businessId, params)
  );

  return {
    isLoading,
    isFetching,
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
  };
}
