import * as React from "react";
import { Card, Page, Pagination, Stack, Tabs } from "@shopify/polaris";

import ConnectList from "./components/ConnectList";
import ConnectFilter from "./components/ConnectFilter";
import { GetConnectTradesQuery } from "app/api/connect/type";
import useQueryParams from "app/hooks/useQueryParams";
import useGetConnectTrades from "./hooks/useGetConnectTrades";
import LoaderContainer from "app/components/LoaderContainer";

const LIMIT = 10;

export default function Connect() {
  const [currentPage, setCurrentPage] = React.useState(0);
  const { queryParams, updateQueryParams } = useQueryParams<GetConnectTradesQuery>({
    currency: "NGN",
  });

  const {
    hasNextPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    connectTrades,
    isLoading,
    error,
    refetch,
  } = useGetConnectTrades({
    params: queryParams,
  });

  const allConnectTrades = React.useMemo(
    () => (connectTrades ? connectTrades.flatMap(page => page.data.data) : []),
    [connectTrades]
  );

  const hasNextPageInState = Math.round(allConnectTrades.length / 10) > currentPage;
  const hasNoNextPage = !hasNextPageInState && !hasNextPage;

  // corresponding data based on the current page
  const connectTradesData = allConnectTrades.slice(
    currentPage * LIMIT,
    (currentPage + 1) * LIMIT
  );

  const handleNextPage = () => {
    if (hasNextPage) {
      setCurrentPage(prev => prev + 1);
      fetchNextPage();
    } else if (hasNextPageInState) {
      setCurrentPage(prev => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(prev => prev - 1);
    }
  };

  const [selected, setSelected] = React.useState(0);

  const tabs = [
    { id: "1", content: "All" },
    { id: "2", content: "Pending" },
    { id: "3", content: "Paid" },
    { id: "4", content: "Completed" },
    { id: "5", content: "Disputed" },
    { id: "6", content: "Expired" },
  ];

  const handleTabChange = (selectedTabIndex: number) => {
    setSelected(selectedTabIndex);
    let query = tabs[selectedTabIndex].content.toLowerCase();
    updateQueryParams(
      "status",
      tabs[selectedTabIndex].content === "All" ? undefined : query
    );
  };

  return (
    <Page title="Trades">
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          <Card>
            <Card.Section>
              <ConnectFilter
                query={queryParams}
                setQuery={updateQueryParams}
                tradesOnDisplay={connectTradesData?.length}
              />
            </Card.Section>
            <Card.Section flush>
              <LoaderContainer
                style={{ minHeight: "unset" }}
                loading={isLoading || isFetchingNextPage}
                error={error}
                onRetryClicked={refetch}
              >
                <ConnectList trades={connectTradesData} />
              </LoaderContainer>
            </Card.Section>
            <Card.Section>
              <Stack distribution="center">
                <Pagination
                  hasNext={currentPage === 0 || isFetchingPreviousPage}
                  onNext={handleNextPage}
                  hasPrevious={hasNoNextPage}
                  onPrevious={handlePrevPage}
                />
              </Stack>
            </Card.Section>
          </Card>
        </Tabs>
      </Card>
    </Page>
  );
}
