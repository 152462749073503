import Profile from "app/models/profile";
import request from "../request";
import { BaseResponse } from "../types";
import {
  AcceptUserInvitePayload,
  CreateUserPayload,
  GetUserInvitePayload,
  GetUserInviteResponse,
  GetUsersQuery,
  GetUsersResponse,
} from "./types";

const getUsers = async (params?: GetUsersQuery) => {
  return request.get<GetUsersResponse>("/v1/users", { params });
};

const getUser = async (id: string) => {
  return request.get<Profile>(`/v1/users/${id}`);
};

const createUser = async (data: CreateUserPayload) => {
  return request.post<BaseResponse>("/v1/users", data);
};

const updateUser = async (id: string, data: Partial<Profile>) => {
  return request.put<BaseResponse>(`/v1/users/${id}`, data);
};

const updateUserPassword = async (id: string, new_password: string) => {
  return request.put<BaseResponse>(`/v1/users/${id}/password`, {
    new_password,
  });
};

const deactivateUser = async (id: string) => {
  return request.put<BaseResponse>(`/v1/users/${id}/deactivate`);
};

const activateUser = async (id: string) => {
  return request.put<BaseResponse>(`/v1/users/${id}/activate`);
};

const deleteUser = async (id: string) => {
  return request.delete<BaseResponse>(`/v1/users/${id}`);
};

const sendUserInvite = async (id: string) => {
  return request.post<BaseResponse>(`/v1/users/${id}/invite`);
};

const getUserInvite = async (payload: GetUserInvitePayload) => {
  return request.get<GetUserInviteResponse>(`/v1/invite?jwt=${payload.jwt}`, {
    headers: { noToken: true },
  });
};

const acceptUserInvite = async ({
  payload,
  token,
}: {
  payload: AcceptUserInvitePayload;
  token: string;
}) => {
  return await request.post<BaseResponse>("/v1/invite/accept", payload, {
    headers: {
      noToken: true,
      Authorization: `Bearer ${token}`,
    },
  });
};

const UserService = {
  getUsers,
  getUser,
  createUser,
  updateUser,
  updateUserPassword,
  deactivateUser,
  activateUser,
  deleteUser,
  getUserInvite,
  acceptUserInvite,
  sendUserInvite,
};

export default UserService;
