import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Page, Pagination, Stack, Tabs } from "@shopify/polaris";

import LoaderContainer from "app/components/LoaderContainer";
import BusinessesFilter from "./components/BusinessesFilter";
import BusinessesList from "./components/BusinessesList";
import useListBusinesses from "app/hooks/useListBusinesses";
import useQueryParams from "app/hooks/useQueryParams";
import { ListBusinessesQuery } from "app/api/businesses/types";

const tabs = [
  { id: "documents", content: "Documents" },
  { id: "owners", content: "Owners" },
];

export default function Compliance() {
  const { type } = useParams<{ type: "owners" | "documents" }>();
  const [selected, setSelected] = React.useState(type === "documents" ? 0 : 1);
  const navigate = useNavigate();

  const { queryParams, updateQueryParams } = useQueryParams<ListBusinessesQuery>({
    page: 1,
    limit: 10,
  });

  const { businessesData, ...listBusinessesQuery } = useListBusinesses(queryParams);

  const businesses = businessesData?.data || [];

  const handleTabChange = (selectedTabIndex: number) => {
    setSelected(selectedTabIndex);
    navigate(`/compliance/${tabs[selectedTabIndex].id}`);
  };

  return (
    <Page title="Compliance">
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          <Card>
            <Card.Section>
              <BusinessesFilter
                query={queryParams}
                setQuery={updateQueryParams}
                businessesOnDisplay={businessesData?.pagination.current_entries_size}
              />
            </Card.Section>

            <LoaderContainer
              loading={listBusinessesQuery.isLoading}
              error={listBusinessesQuery.error}
              onRetryClicked={listBusinessesQuery.refetch}
            >
              {businessesData && (
                <>
                  <Card.Section flush>
                    <BusinessesList businesses={businesses} />
                  </Card.Section>

                  <Card.Section>
                    <Stack distribution="center">
                      <Pagination
                        label={`${queryParams.page ?? 1} / ${
                          businessesData.pagination.total_pages
                        }`}
                        hasNext={
                          businessesData.pagination.total_pages >
                          businessesData.pagination.page
                        }
                        onNext={() => {
                          updateQueryParams("page", businessesData.pagination.page + 1);
                        }}
                        hasPrevious={businessesData.pagination.page > 1}
                        onPrevious={() => {
                          updateQueryParams("page", businessesData.pagination.page - 1);
                        }}
                      />
                    </Stack>
                  </Card.Section>
                </>
              )}
            </LoaderContainer>
          </Card>
        </Tabs>
      </Card>
    </Page>
  );
}
