import { Button, Popover, ActionList } from "@shopify/polaris";
import useModalState from "app/hooks/useModalState";
import { MobileHorizontalDotsMajor } from "@shopify/polaris-icons";

interface Props {
  onRemove: (callbackFn: () => void) => void;
  onSetPrimary: (callbackFn: () => void) => void;
  active: boolean;
}

export default function ManageBankAccountDropdown(props: Props) {
  const { onRemove, onSetPrimary, active } = props;
  const { isOpen, toggleIsOpen } = useModalState();

  // This function closes the popover after removing a Bank Account
  const removeHandler = () => {
    onRemove(toggleIsOpen);
  };

  // This function closes the popover after setting a Bank Account as primary
  const primaryHandler = () => {
    onSetPrimary(toggleIsOpen);
  };

  const activator = (
    <Button onClick={toggleIsOpen} icon={MobileHorizontalDotsMajor} plain />
  );

  const withPrimary = [
    { content: "Set as primary", onAction: primaryHandler },
    { content: "Delete", onAction: removeHandler },
  ];

  const withOutPrimary = [{ content: "Delete", onAction: removeHandler }];

  const items = active ? withOutPrimary : withPrimary;

  return (
    <Popover
      active={isOpen}
      activator={activator}
      onClose={toggleIsOpen}
      preferredAlignment="right"
    >
      <ActionList actionRole="menuitem" items={items} />
    </Popover>
  );
}
