import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import profileService from "app/api/profile";
import parseError from "app/lib/parseError";
import { useSetProfile } from "app/providers/ProfileProvider";

const useGetProfile = () => {
  const setProfile = useSetProfile();

  const { data, status, error, refetch, isFetching, isLoading } = useQuery(
    ["profile"],
    profileService.getProfile,
    {
      onSuccess: (data) => {
        if (data) setProfile(data.data);
      },
    }
  );

  return {
    profile: data,
    status,
    error: error ? (parseError(error as AxiosError<{}>) as string) : null,
    refetch,
    isFetching,
    isLoading,
  };
};

export default useGetProfile;
