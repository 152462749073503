import {
  Card,
  IndexTable,
  Page,
  Pagination,
  Select,
  Stack,
  Text,
} from "@shopify/polaris";
import styled from "@emotion/styled";

import LoaderContainer from "app/components/LoaderContainer";
import GenericDate from "app/components/GenericDate";
import DonationsList from "./components/DonationsList";
import useGetPaymentLinks from "app/hooks/useGetPaymentLinks";
import DonationsFilter from "./components/DonationsFilter";

export default function Donations() {
  const {
    data,
    isLoading,
    error,
    refetch,
    isFetching,
    gotoNextPage,
    gotoPrevPage,
    query,
    setQuery,
  } = useGetPaymentLinks("donation");

  return (
    <Page title={isLoading ? "..." : "Donations"}>
      <Card>
        <>
          <Card.Section>
            <Stack vertical>
              <Text as="p" variant="bodySm">
                Enter search text, then press Enter/Command
              </Text>
              {/* @ts-expect-error Will fix when payment Links and donations are separated*/}
              <DonationsFilter query={query} setQuery={setQuery} />

              <Stack distribution="equalSpacing" alignment="center">
                <Stack spacing="extraLoose" alignment="center">
                  <GenericDate
                    onDateChange={value => {
                      setQuery({ date: value });
                    }}
                    onReset={() => setQuery({ date: "" })}
                    value={query.date}
                  />

                  <p>Showing {data?.pagination.current_entries_size} donations</p>
                </Stack>

                <Select
                  labelInline
                  label="Sort by"
                  name="sort"
                  value={query.sort}
                  options={[
                    { label: "Newest", value: "desc" },
                    { label: "Oldest", value: "asc" },
                  ]}
                  onChange={s => setQuery("sort", s)}
                />
              </Stack>
            </Stack>
          </Card.Section>

          <LoaderContainer
            page
            loading={isLoading && !data?.data}
            error={error}
            onRetryClicked={refetch}
          >
            {data && (
              <TableWrapper>
                <IndexTable
                  selectable={false}
                  resourceName={{ plural: "Payment Links", singular: "PaymentLink" }}
                  loading={isFetching}
                  itemCount={data.pagination.total_entries_size || 0}
                  headings={[
                    { title: "Ref Number" },
                    { title: "Product Name" },
                    { title: "Creation Date" },
                    { title: "Business" },
                    { title: "Status" },
                  ]}
                >
                  <DonationsList paymentLinks={data.data} />
                </IndexTable>
              </TableWrapper>
            )}
          </LoaderContainer>

          {data && (
            <Card.Section>
              <Stack distribution="center">
                <Pagination
                  label={
                    <p>
                      {data.pagination.page} /{data.pagination.total_pages}
                    </p>
                  }
                  hasNext={data.pagination.total_pages > data.pagination.page}
                  onNext={gotoNextPage}
                  hasPrevious={data.pagination.page > 1}
                  onPrevious={gotoPrevPage}
                />
              </Stack>
            </Card.Section>
          )}
        </>
      </Card>
    </Page>
  );
}

const TableWrapper = styled.div`
  .Polaris-IndexTable__TableHeading {
    color: #000000;
    padding-block: 20px;
  }

  tr {
    border-top: 1px solid #e1e3e5;
  }
`;
