import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import AnalyticsService from "app/api/analytics";

export type PaymentLinkType = "fixed_price" | "donation" | "invoice";

export default function useGetAnalytics(type: PaymentLinkType) {
  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["invoiceinfo", type],
    () => {
      if (type === "invoice") {
        return AnalyticsService.getInvoiceAnalytics();
      } else {
        return AnalyticsService.getPaymentLinkAnalyticsInfo(type);
      }
    }
  );

  return {
    isLoading,
    isFetching,
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
  };
}
