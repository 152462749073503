import { Global, css } from "@emotion/react";
import { ContextualSaveBar, Page } from "@shopify/polaris";
import { FormProvider, useForm } from "react-hook-form";

import useGoBack from "app/hooks/useGoBack";
import BusinessSettingsForm from "./components/BusinessSettingsForm";
import useGetCountries from "app/hooks/useGetCountries";
import LoaderContainer from "app/components/LoaderContainer";
import { AddBusinessTypePayload } from "app/api/businesses/types";
import useAddBusinessType from "./hooks/useAddBusinessType";

const defaultValues = { name: "", description: "" };

export default function BusinessSettingsDetails() {
  const goBack = useGoBack();

  const countriesQuery = useGetCountries();

  const formMethods = useForm<AddBusinessTypePayload>({
    defaultValues,
  });

  const { addBusinessType, isLoading } = useAddBusinessType();

  const { formState, handleSubmit, reset } = formMethods;
  const countries = countriesQuery.countries?.data ?? [];

  const handleBusinessTypeSubmit = (values: AddBusinessTypePayload) => {
    addBusinessType(values);
  };

  return (
    <Page title="Add Business Type" breadcrumbs={[{ onAction: goBack }]}>
      {formState.isDirty && (
        <>
          <BaseStyles />
          <ContextualSaveBar
            message="Unsaved Country"
            saveAction={{
              content: "Add",
              onAction: handleSubmit(handleBusinessTypeSubmit),
              loading: isLoading,
            }}
            discardAction={{
              onAction: () => reset(defaultValues),
            }}
          />
        </>
      )}

      <FormProvider {...formMethods}>
        <LoaderContainer
          loading={countriesQuery.isLoading}
          error={countriesQuery.error}
          onRetryClicked={countriesQuery.refetch}
        >
          <BusinessSettingsForm countries={countries} />
        </LoaderContainer>
      </FormProvider>
    </Page>
  );
}

const BaseStyles = () => {
  return (
    <Global
      styles={css`
        .Polaris-Frame__ContextualSaveBar {
          z-index: 1000;
        }
      `}
    />
  );
};
