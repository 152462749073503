import { Button, Modal, Text } from "@shopify/polaris";

import useUpdateDocStatus from "../../hooks/useUpdateDocStatus";
import useModalState from "app/hooks/useModalState";

interface Props {
  documentId: string;
}
export default function ApproveDocumentModal(props: Props) {
  const { documentId } = props;

  const { isOpen, closeModal, openModal } = useModalState();

  const { onUpdateDoc, formIsLoading: isLoading } = useUpdateDocStatus(documentId);

  const handleChange = () => {
    onUpdateDoc(
      {
        status: "accepted",
        comment: "Your document has been accepted",
      },
      {
        onSuccess: () => {
          closeModal();
        },
      }
    );
  };

  return (
    <>
      <Button primary onClick={openModal}>
        Approve
      </Button>

      <Modal
        title="Approve Document?"
        open={isOpen}
        onClose={closeModal}
        primaryAction={{
          content: "Approve",
          onAction: handleChange,
          loading: isLoading,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: closeModal,
          },
        ]}
      >
        <Modal.Section>
          <Text as="p" variant="bodyMd">
            You are about to approve this document. Do you want to continue?
          </Text>
        </Modal.Section>
      </Modal>
    </>
  );
}
