import { ContextualSaveBar, Page } from "@shopify/polaris";
import { FormProvider, useForm } from "react-hook-form";

import useGoBack from "app/hooks/useGoBack";
import DocumentTypeForm from "./components/DocumentTypeForm";
import { AddDocTypePayload } from "app/api/compliance/types";
import useGetBusinessTypes from "app/hooks/useGetBusinessTypes";
import LoaderContainer from "app/components/LoaderContainer";
import useAddDocumentType from "./hooks/useAddDocumentType";
import { Global, css } from "@emotion/react";

const defaultValues = {
  name: "",
  requirement: "",
  verify_with_selfie: false,
  verify_with_text: true,
  warning: "",
  example: "",
  id_number_required: true,
};

export default function DocumentsSettingsDetails() {
  const goBack = useGoBack();

  const { addDocumentType, isLoading } = useAddDocumentType();

  const businessTypesQuery = useGetBusinessTypes();
  const businessTypes = Object.values(businessTypesQuery.businessTypes ?? {});

  const formMethods = useForm<AddDocTypePayload>({
    defaultValues,
  });

  const { formState, reset } = formMethods;

  const submitDocumentType = (values: AddDocTypePayload) => {
    addDocumentType(values);
  };

  return (
    <Page title="Add Document" breadcrumbs={[{ onAction: goBack }]}>
      {formState.isDirty && (
        <>
          <BaseStyles />
          <ContextualSaveBar
            message="Unsaved Document Type"
            saveAction={{
              content: "Add",
              onAction: formMethods.handleSubmit(submitDocumentType),
              loading: isLoading,
            }}
            discardAction={{
              onAction: () => reset(defaultValues),
            }}
          />
        </>
      )}

      <FormProvider {...formMethods}>
        <LoaderContainer
          loading={businessTypesQuery.isLoading}
          error={businessTypesQuery.error}
          onRetryClicked={businessTypesQuery.refetch}
        >
          <DocumentTypeForm businessTypes={businessTypes} />
        </LoaderContainer>
      </FormProvider>
    </Page>
  );
}

const BaseStyles = () => {
  return (
    <Global
      styles={css`
        .Polaris-Frame__ContextualSaveBar {
          z-index: 1000;
        }
      `}
    />
  );
};
