import { Card, Text } from "@shopify/polaris";
import countries from "app/constants/countries";
import Business from "app/models/business";

interface Props {
  business: Business;
}
export default function BusinessInformation(props: Props) {
  const { business } = props;

  const country =
    countries.find(c => c.id === business.country_id)?.name || business.country_id;

  return (
    <div>
      <Card
        title={
          <Text as="h3" variant="headingXs">
            BUSINESS INFORMATION
          </Text>
        }
      >
        <Card.Section
          title={
            <Text as="p" variant="bodyMd" fontWeight="medium">
              Industry
            </Text>
          }
        >
          <Text as="p" variant="bodyMd" breakWord color="subdued">
            {business.industry ?? "N/A"}
          </Text>
        </Card.Section>

        <Card.Section
          title={
            <Text as="p" variant="bodyMd" fontWeight="medium">
              Description
            </Text>
          }
        >
          <Text as="p" variant="bodyMd" breakWord color="subdued">
            {business.description ?? "N/A"}
          </Text>
        </Card.Section>

        <Card.Section
          title={
            <Text as="p" variant="bodyMd" fontWeight="medium">
              Address
            </Text>
          }
        >
          {business.address && (
            <Text as="p" variant="bodyMd" breakWord color="subdued">
              {business.address ?? "N/A"}
            </Text>
          )}
          {business.state && (
            <Text as="p" variant="bodyMd" breakWord color="subdued">
              {business.state ?? "N/A"}
            </Text>
          )}
          <Text as="p" variant="bodyMd" breakWord color="subdued">
            {country}
          </Text>
        </Card.Section>
      </Card>
    </div>
  );
}
