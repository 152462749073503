import * as React from "react";
import styled from "@emotion/styled";

export default function TableWrapper(props: React.PropsWithChildren) {
  return <Wrapper>{props.children}</Wrapper>;
}

export const Wrapper = styled.div`
  .Polaris-IndexTable__TableHeading {
    color: #202223;
    padding-block: 20px;
    font-size: 14px;
  }

  thead > tr {
    border-top: 1px solid #e1e3e5;
  }

  tbody > tr:last-of-type {
    border-bottom: 1px solid #e1e3e5;
  }
`;

export const CellPadding = styled.div`
  padding: 13px 0px;
`;
