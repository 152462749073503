import * as React from "react";

import Profile from "app/models/profile";

export const ProfileContext = React.createContext<Profile | undefined>(
  undefined
);

export const ProfileSetterContext = React.createContext<
  React.Dispatch<React.SetStateAction<Profile | undefined>>
>((p) => {});

export default function ProfileProvider(props: React.PropsWithChildren<{}>) {
  const [profile, setProfile] = React.useState<Profile>();

  return (
    <ProfileContext.Provider value={profile}>
      <ProfileSetterContext.Provider value={setProfile}>
        {props.children}
      </ProfileSetterContext.Provider>
    </ProfileContext.Provider>
  );
}

export function useProfile() {
  const profile = React.useContext(ProfileContext);

  return profile;
}

export function useSetProfile() {
  const setProfile = React.useContext(ProfileSetterContext);

  return setProfile;
}
