import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import { GetTransactionsQuery } from "app/api/transactions/types";
import TransactionsServices from "app/api/transactions";

interface Props {
  params?: GetTransactionsQuery;
}

export default function useGetTransactions({ params }: Props) {
  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["transactions", params],
    () => TransactionsServices.getTransactions(params)
  );

  return {
    isLoading,
    isFetching,
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
  };
}
