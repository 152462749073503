import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import BusinessesService from "app/api/businesses";

export default function useGetBusinessLimits(businessId: string) {
  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["business_limits", businessId],
    () => BusinessesService.getBusinessLimits(businessId),
    {
      enabled: Boolean(businessId),
    }
  );
  return {
    isLoading,
    isFetching,
    businessLimits: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
  };
}
