import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import SettlementService from "app/api/settlements";
import parseError from "app/lib/parseError";

export default function useListSettlements(businessId: string) {
  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["settlements", businessId],
    () => SettlementService.listSettlements(businessId)
  );

  return {
    isLoading,
    isFetching,
    settlements: data?.data.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
  };
}
