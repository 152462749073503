import * as React from "react";
import {
  AppliedFilterInterface,
  Card,
  ChoiceList,
  FilterInterface,
  Filters,
  Select,
  Stack,
  Tag,
} from "@shopify/polaris";
import { ListInvoicesQuery } from "app/api/invoices/types";
import GenericDate from "app/components/GenericDate";
import SearchFilter from "app/components/SearchFilter";

const searchOptions = [
  {
    label: "Reference",
    value: "reference",
  },
  {
    label: "Business Name",
    value: "business_name",
  },
  {
    label: "Customer Name",
    value: "customer_name",
  },
  {
    label: "Customer Email",
    value: "customer_email",
  },
];

interface Props {
  query: ListInvoicesQuery;
  invoicesOnDisplay: number;
  updateQuery: (q: ListInvoicesQuery) => void;
}
export default function InvoicesFilter(props: Props) {
  const { query, invoicesOnDisplay, updateQuery } = props;

  const [queryValue, setQueryValue] = React.useState("");

  const filters: FilterInterface[] = [
    {
      label: "Status",
      key: "status",
      filter: (
        <ChoiceList
          title="Status"
          titleHidden
          choices={[
            { label: "Paid", value: "COMPLETED" },
            { label: "New", value: "NEW" },
            { label: "Expired", value: "EXPIRED" },
            { label: "Voided", value: "DELETED" },
          ]}
          selected={[query.status ?? ""]}
          onChange={s => {
            updateQuery({ status: s[0] });
          }}
        />
      ),
      shortcut: true,
    },
  ];

  const removeFilter = (filter: keyof ListInvoicesQuery) => {
    updateQuery({ ...query, [filter]: undefined });
  };

  const appliedFilters: AppliedFilterInterface[] = Object.keys(query)
    .filter(
      key =>
        ["status"].includes(key) &&
        query[key as keyof ListInvoicesQuery] !== undefined
    )
    .map(key => {
      return {
        key,
        label: `${key}: ${query[key as keyof ListInvoicesQuery]}`,
        onRemove: removeFilter,
      };
    });

  return (
    <>
      <Card.Section>
        <Stack spacing="tight">
          <Stack.Item fill>
            <SearchFilter options={searchOptions} />
          </Stack.Item>

          <Filters
            filters={filters}
            appliedFilters={appliedFilters}
            queryValue={queryValue}
            onQueryChange={setQueryValue}
            onQueryClear={() => {
              setQueryValue("");
            }}
            onClearAll={() => {
              updateQuery({
                limit: 10,
                page: 1,
                sort: undefined,
                status: undefined,
              });
            }}
            hideQueryField
            hideTags
          />
        </Stack>
      </Card.Section>

      {Boolean(query.business_name) && (
        <Card.Section>
          <Tag onRemove={() => updateQuery({ business_name: undefined })}>
            {query.business_name}
          </Tag>
        </Card.Section>
      )}

      <Card.Section>
        <Stack distribution="equalSpacing" alignment="center">
          <Stack alignment="center">
            <GenericDate
              onDateChange={value => {
                updateQuery({ date: value });
              }}
              onReset={() => updateQuery({ date: undefined })}
              value={query.date}
            />
            <p>Showing {invoicesOnDisplay} invoices</p>
          </Stack>
          <Select
            labelInline
            label="Sort by"
            name="sort"
            value={query.sort}
            options={[
              { label: "Newest", value: "desc" },
              { label: "Oldest", value: "asc" },
            ]}
            onChange={(s: "asc" | "desc") => {
              updateQuery({ sort: s });
            }}
          />
        </Stack>
      </Card.Section>
    </>
  );
}
