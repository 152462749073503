import * as React from "react";
import AnalyticsCard from "./AnalyticsCard";
import useGetWithdrawalsAnalytics from "app/hooks/useGetWithdrawalAnalytics";
import { formatMoney } from "app/lib/money";
import GenericDropdownFilter from "app/components/GenericDropdownFilter";

export default function WithdrawalsProcessed() {
  const [value, setValue] = React.useState<string>("NGN");

  function changehandler(selected: string[]) {
    setValue(selected[0]);
  }
  const { withdrawalAnalytics, isLoading, error, refetch } = useGetWithdrawalsAnalytics({
    display_currency: value,
  });

  return (
    <AnalyticsCard
      title="Withdrawals processed"
      amount={
        withdrawalAnalytics
          ? `${withdrawalAnalytics?.display_currency} ${formatMoney(
              withdrawalAnalytics?.total_amount
            )}`
          : ""
      }
      loading={isLoading}
      error={error}
      refetch={refetch}
      dropdown={
        <GenericDropdownFilter
          options={[
            { label: "Naira", value: "NGN" },
            { label: "USD", value: "USD" },
          ]}
          selected={[value]}
          onChange={changehandler}
          size="slim"
        />
      }
    />
  );
}
