import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import UserService from "app/api/users";
import { useToast } from "app/providers/ToastProvider";

export default function useSendUserInvite(id: string) {
  const showToast = useToast();

  const { isLoading: sendingUserInvite, mutate: sendUserInviteMutate } =
    useMutation(() => UserService.sendUserInvite(id), {
      onSuccess: (res) => {
        showToast(res.data.message || "", "success");
      },
      onError: (error: AxiosError<{}>) => {
        const errorMessage = parseError(error) as string;

        showToast(errorMessage || "", "error");
      },
    });

  const sendUserInvite = () => {
    sendUserInviteMutate();
  };

  return {
    sendUserInvite,
    sendingUserInvite,
  };
}
