import styled from "@emotion/styled";
import { IndexTable } from "@shopify/polaris";

import { Activity } from "app/models/merchant";
import { formatDateStr } from "app/utils/helpers";

interface Props {
  activities: Activity[];
}
export default function ActivitiesList(props: Props) {
  const { activities } = props;

  return (
    <>
      {activities.map((activity, idx) => {
        return (
          <IndexTable.Row
            id={idx.toString()}
            key={idx.toString()}
            position={idx}
          >
            <IndexTable.Cell>
              <CellPadding>
                {formatDateStr(activity.created_at, "MMMM D, YYYY [at] hh:mma")}
              </CellPadding>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <CellPadding>{activity.action}</CellPadding>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <CellPadding>{activity.agent}</CellPadding>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <CellPadding>{activity.location}</CellPadding>
            </IndexTable.Cell>
          </IndexTable.Row>
        );
      })}
    </>
  );
}

const CellPadding = styled.div`
  padding: 13px 0px;
`;
