import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import PaymentsService from "app/api/payments";
import { GetPaymentsQuery } from "app/api/payments/types";

export default function useGetTransactions(params: GetPaymentsQuery) {
  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["transactions", params],
    () => PaymentsService.getPayments(params)
  );

  return {
    isLoading,
    isFetching,
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
  };
}
