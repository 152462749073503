import styled from "@emotion/styled";
import { Stack, Card, Text } from "@shopify/polaris";
import { TransactionsItem } from "app/models/transaction";

interface Props {
  transaction: TransactionsItem;
}

export default function BalanceChanges({ transaction }: Props) {
  return (
    <Card>
      <Card.Section title="BALANCE CHANGES">
        <BalanceChangesWrapper>
          <Stack alignment="center" distribution="equalSpacing">
            <Text as="p" variant="bodyMd">
              Previous Balance
            </Text>

            <Text as="p" variant="bodyMd">
              {transaction.old_balance} {transaction.data.currency}
            </Text>
          </Stack>

          <Stack alignment="center" distribution="equalSpacing">
            <Text as="p" variant="bodyMd">
              Amount
            </Text>
            <Text
              as="p"
              variant="bodyMd"
              color={transaction.is_credit ? "success" : "critical"}
            >
              {transaction.is_credit ? "+" : "-"} {transaction.amount}{" "}
              {transaction.currency}
            </Text>
          </Stack>

          <Stack alignment="center" distribution="equalSpacing">
            <Text as="p" variant="bodyMd" fontWeight="medium">
              New Balance
            </Text>

            <Text as="p" variant="bodyMd" fontWeight="medium">
              {transaction.new_balance} {transaction.currency}
            </Text>
          </Stack>
        </BalanceChangesWrapper>
      </Card.Section>
    </Card>
  );
}

const BalanceChangesWrapper = styled.div`
  margin-top: 16px;
  display: grid;
  gap: 16px;
`;
