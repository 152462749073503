import * as React from "react";
import { Card, ChoiceList, Filters } from "@shopify/polaris";
import Country from "app/models/country";
import { ListBusinessTypesQuery } from "app/api/businesses/types";

interface Props {
  query: ListBusinessTypesQuery;
  countries: Country[];
  updateQuery: (q: ListBusinessTypesQuery) => void;
}
export default function BusinessTypesFilters(props: Props) {
  const { countries, query, updateQuery } = props;

  const [queryValue, setQueryValue] = React.useState("");

  const countriesOptions = countries.map(country => ({
    value: country.id,
    label: country.name,
  }));

  const filters = [
    {
      label: "Country",
      key: "country_id",
      filter: (
        <ChoiceList
          title="Country"
          titleHidden
          choices={countriesOptions}
          onChange={t => updateQuery({ country_id: t[0] })}
          selected={[query.country_id ?? ""]}
        />
      ),
      shortcut: true,
    },
  ];

  const removeFilter = React.useCallback(
    (filter: string) => {
      updateQuery({ ...query, [filter]: undefined });
    },
    [updateQuery, query]
  );

  const appliedFilters = Object.keys(query)
    .filter(
      key =>
        !["q", "limit", "page", "sort"].includes(key) &&
        query[key as keyof ListBusinessTypesQuery] !== undefined
    )
    .map(key => ({
      key,
      label: `${key}: ${query[key as keyof ListBusinessTypesQuery]}`,
      onRemove: removeFilter,
    }));

  return (
    <Card.Section>
      <Filters
        filters={filters}
        appliedFilters={appliedFilters}
        queryValue={queryValue}
        onQueryChange={setQueryValue}
        onQueryClear={() => setQueryValue("")}
        onClearAll={() =>
          updateQuery({
            country_id: undefined,
          })
        }
        hideTags
      />
    </Card.Section>
  );
}
