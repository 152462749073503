import { Button, Stack } from "@shopify/polaris";
import { Withdrawal } from "app/models/withdrawal";
import { PayoutModalAction } from "./UpdatePayoutStatusesModal";
import * as React from "react";

interface Props {
  data?: Withdrawal;
  onActionClicked: (x: PayoutModalAction) => void;
}

export default function WithdrawalActions(props: Props) {
  const { data, onActionClicked } = props;

  const cancelButton = React.useMemo(
    () => (
      <Button
        monochrome
        plain
        removeUnderline
        onClick={() => onActionClicked("cancel")}
      >
        Cancel
      </Button>
    ),
    [onActionClicked]
  );

  const retryButton = React.useMemo(
    () => (
      <Button removeUnderline onClick={() => onActionClicked("retry")}>
        Retry
      </Button>
    ),
    [onActionClicked]
  );

  const unqueueButton = React.useMemo(
    () => <Button onClick={() => onActionClicked("unqueue")}>Unqueue</Button>,
    [onActionClicked]
  );

  const completeButton = React.useMemo(
    () => (
      <Button primary onClick={() => onActionClicked("complete")}>
        Complete
      </Button>
    ),
    [onActionClicked]
  );

  const fulfilButton = React.useMemo(
    () => <Button onClick={() => onActionClicked("fulfill")}>Fulfil</Button>,
    [onActionClicked]
  );

  const fulfilmentStatus = String(data?.fulfilment_status).toLocaleLowerCase();

  if (data) {
    if (data.status === "completed" && data.fulfilment_status === "completed") {
      return null;
    }
    if (data.status === "cancelled" && data.fulfilment_status === "cancelled") {
      return null;
    }
    if (data.status === "cancelled" && data.fulfilment_status === "pending") {
      return null;
    }
    if (
      data.status === "completed" &&
      (data.fulfilment_status === "pending" ||
        data.fulfilment_status === "failed")
    ) {
      return (
        <Stack alignment="center" spacing="loose">
          {cancelButton}
          {retryButton}
          {completeButton}
        </Stack>
      );
    }
    if (
      data.status === "pending" &&
      (data.fulfilment_status === "pending" ||
        data.fulfilment_status === "failed")
    ) {
      return (
        <Stack alignment="center" spacing="loose">
          {cancelButton}
          {retryButton}
          {completeButton}
        </Stack>
      );
    }

    if (data.status === "completed" && fulfilmentStatus === "queued") {
      return (
        <Stack alignment="center" spacing="loose">
          {cancelButton}
          {unqueueButton}
          {completeButton}
        </Stack>
      );
    }

    if (data.status === "pending" && fulfilmentStatus === "not found") {
      return (
        <Stack alignment="center" spacing="loose">
          {cancelButton}
          {fulfilButton}
        </Stack>
      );
    }

    if (data.status === "processing" && fulfilmentStatus === "failed") {
      return (
        <Stack alignment="center" spacing="loose">
          {cancelButton}
          {completeButton}
        </Stack>
      );
    }

    if (fulfilmentStatus === "pending") {
      return (
        <Stack alignment="center" spacing="loose">
          {cancelButton}
        </Stack>
      );
    }

    if (fulfilmentStatus === "not found") {
      return <Stack alignment="center">{fulfilButton}</Stack>;
    }

    if (fulfilmentStatus === "processing") {
      return <Stack alignment="center">{completeButton}</Stack>;
    }

    return <></>;
  }
  return <></>;
}
