import { BadgeProps } from "@shopify/polaris";
import { StatusType } from "app/models/transaction";

export const statusMap: Record<StatusType, BadgeProps["status"]> = {
  NEW: "attention",
  CONFIRMED: "success",
  CANCELLED: "critical",
  COMPLETED: "success",
  DISABLED: "critical",
  PENDING: "warning",
  UNRESOLVED: "attention",
  UNDERPAID: "attention",
  OVERPAID: "info",
  RESOLVED: "info",
};

export const paymentStatusMap: Record<string, BadgeProps["status"]> = {
  completed: "success",
  failed: "critical",
  pending: "info",
};
