import { FormLayout, Modal, Select } from "@shopify/polaris";
import LoaderContainer from "app/components/LoaderContainer";

interface Props {
  open: boolean;
  onClose: () => void;
}
export default function AddBookModal(props: Props) {
  const { open, onClose } = props;

  return (
    <>
      <Modal
        title="Add Book"
        open={open}
        onClose={onClose}
        secondaryActions={[{ content: "Cancel", onAction: onClose }]}
        primaryAction={{
          content: "Add Book",
          loading: false,
          onAction: () => null,
        }}
      >
        <LoaderContainer small className="add-book-modal-container">
          <Modal.Section>
            <FormLayout>
              <Select label="Exchange" name="exchange" />
            </FormLayout>
          </Modal.Section>
        </LoaderContainer>
      </Modal>
    </>
  );
}
