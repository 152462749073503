import * as React from "react";
import styled from "@emotion/styled";
import { Card, Heading, Icon, IconSource, Layout, List, Stack } from "@shopify/polaris";
import { SecureMajor } from "@shopify/polaris-icons";

import TwoFAModal from "./TwoFAModal";
import useTwoFA from "../hooks/useTwoFA";

import { useProfile } from "app/providers/ProfileProvider";
import { useToast } from "app/providers/ToastProvider";

export default function TwoFASection() {
  const showToast = useToast();

  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const toggleModal = () => setModalIsOpen(o => !o);

  const profile = useProfile();
  const {
    activatingTwoFA,
    activateTwoFA,
    activateTwoFAError,
    activateTwoFAResponse,
    //
    deactivateTwoFA,
    deactivateTwoFAError,
    deactivateTwoFAResponse,
    deactivatingTwoFA,
  } = useTwoFA();

  React.useEffect(() => {
    if (activateTwoFAResponse) {
      showToast(activateTwoFAResponse, "success");
      toggleModal();
    }
    if (activateTwoFAError) {
      showToast(activateTwoFAError, "error");
    }
  }, [activateTwoFAResponse, activateTwoFAError, showToast]);

  React.useEffect(() => {
    if (deactivateTwoFAResponse) {
      showToast(deactivateTwoFAResponse, "success");
    }
    if (deactivateTwoFAError) {
      showToast(deactivateTwoFAError, "error");
    }
  }, [deactivateTwoFAResponse, deactivateTwoFAError, showToast]);

  if (!profile) return null;

  return (
    <Layout.AnnotatedSection
      title="Two-step authentication"
      description="After entering your password, verify your identity with a second authentication method.
    "
    >
      <Card
        primaryFooterAction={
          profile.two_fa_medium === "email"
            ? {
                content: "Turn on two-step",
                onAction: toggleModal,
              }
            : undefined
        }
        secondaryFooterActions={
          profile.two_fa_medium !== "email"
            ? [
                {
                  content: "Turn off two-step",
                  onAction: deactivateTwoFA,
                  loading: deactivatingTwoFA,
                },
              ]
            : undefined
        }
        footerActionAlignment="left"
      >
        <Card.Section>
          <Heading>
            <p>
              Two-step authentication is{" "}
              {profile.two_fa_medium === "email" ? "off" : "on"}
            </p>
          </Heading>
        </Card.Section>
        <Card.Section>
          <Stack vertical>
            <Stack wrap={false}>
              <IconWrapper>
                <Icon source={SecureMajor as IconSource} color="primary" />
              </IconWrapper>
              <Stack.Item fill>
                <p>
                  With two-step authentication, only you can access your account — even if
                  someone else has your password
                </p>
              </Stack.Item>
            </Stack>
            <Stack vertical>
              <p>HOW IT WORKS</p>
              <Stack spacing="tight">
                <p>When you log in to Wanda, you'll need to:</p>
                <List type="number">
                  <List.Item>Enter your password</List.Item>
                  <List.Item>Enter a code generated by your authenticator app</List.Item>
                </List>
              </Stack>
            </Stack>
          </Stack>
        </Card.Section>
      </Card>
      <TwoFAModal
        open={modalIsOpen}
        onClose={toggleModal}
        loading={activatingTwoFA}
        onSubmit={activateTwoFA}
      />
    </Layout.AnnotatedSection>
  );
}

const IconWrapper = styled.div`
  .Polaris-Icon {
    width: 32px;
    height: 32px;
  }
`;
