import {
  Card,
  IndexTable,
  Page,
  Pagination,
  Select,
  Stack,
  Text,
} from "@shopify/polaris";
import LoaderContainer from "app/components/LoaderContainer";
import useGoBack from "app/hooks/useGoBack";
import GenericDate from "app/components/GenericDate";
import TransactionsFilter from "./components/Transactions";
import TransactionsList from "./components/TransactionsList";
import useGetPaymentLinkTransactions from "./hooks/useGetPaymentLinkTransactions";

export default function PaymentLinkTransactions() {
  const goBack = useGoBack();

  const {
    data,
    isLoading,
    error,
    refetch,
    isFetching,
    gotoNextPage,
    gotoPrevPage,
    query,
    setQuery,
  } = useGetPaymentLinkTransactions();

  return (
    <Page breadcrumbs={[{ onAction: goBack }]} title={isLoading ? "..." : "Transactions"}>
      <Card>
        <LoaderContainer
          page
          loading={isLoading && !data?.data}
          error={error}
          onRetryClicked={refetch}
        >
          {data && (
            <>
              <Card.Section>
                <Stack vertical>
                  <Text as="p" variant="bodySm">
                    Enter search text, then press Enter/Command
                  </Text>
                  <TransactionsFilter query={query} setQuery={setQuery} />
                  <Stack distribution="equalSpacing" alignment="center">
                    <Stack spacing="extraLoose" alignment="center">
                      <GenericDate
                        onDateChange={value => {
                          setQuery({ date: value });
                        }}
                        onReset={() => setQuery({ date: "" })}
                        value={query.date}
                      />

                      <p>Showing {data.pagination.current_entries_size} transaction(s)</p>
                    </Stack>

                    <Select
                      labelInline
                      label="Sort by"
                      name="sort"
                      value={query.sort}
                      options={[
                        { label: "Newest", value: "desc" },
                        { label: "Oldest", value: "asc" },
                      ]}
                      onChange={s => setQuery("sort", s)}
                    />
                  </Stack>
                </Stack>
              </Card.Section>

              <IndexTable
                selectable={false}
                resourceName={{
                  plural: "transactions",
                  singular: "transaction",
                }}
                loading={isFetching}
                itemCount={data.pagination.total_entries_size || 0}
                headings={[
                  { title: "Ref Number" },
                  { title: "Date" },
                  { title: "Status" },
                  { title: "Amount" },
                ]}
              >
                <TransactionsList transactions={data.data} />
              </IndexTable>
              <Card.Section>
                <Stack distribution="center">
                  <Pagination
                    hasNext={data.pagination.total_pages > data.pagination.page}
                    onNext={gotoNextPage}
                    hasPrevious={data.pagination.page > 1}
                    onPrevious={gotoPrevPage}
                  />
                </Stack>
              </Card.Section>
            </>
          )}
        </LoaderContainer>
      </Card>
    </Page>
  );
}
