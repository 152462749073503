import { Card, Page, Pagination, Stack } from "@shopify/polaris";

import { ListInvoicesQuery } from "app/api/invoices/types";
import LoaderContainer from "app/components/LoaderContainer";
import useQueryParams from "app/hooks/useQueryParams";
import InvoicesFilter from "./components/InvoicesFilter";
import InvoicesList from "./components/InvoicesList";
import useListInvoices from "./hooks/useListInvoices";

export default function Invoices() {
  const { queryParams, updateQueryParams } = useQueryParams<ListInvoicesQuery>({
    page: 1,
    sort: "desc",
    limit: 10,
  });

  const { invoices, isLoading, error, refetch } = useListInvoices({
    params: queryParams,
  });

  const invoicesData = invoices?.data ?? [];

  return (
    <Page title="Invoices">
      <Card>
        <InvoicesFilter
          query={queryParams}
          invoicesOnDisplay={invoicesData.length}
          updateQuery={updateQueryParams}
        />

        <LoaderContainer
          style={{ minHeight: "unset" }}
          loading={isLoading}
          error={error}
          onRetryClicked={refetch}
        >
          <InvoicesList invoices={invoicesData} />
        </LoaderContainer>

        <Card.Section>
          <Stack distribution="center">
            {invoices && invoices.data.length > 0 && (
              <Pagination
                label={`${queryParams.page ?? 1} / ${
                  invoices.pagination.total_pages
                }`}
                onPrevious={() => {
                  updateQueryParams("page", Number(queryParams.page ?? 1) - 1);
                }}
                onNext={() => {
                  updateQueryParams(
                    "page",
                    (Number(queryParams.page) ?? 1) + 1
                  );
                }}
                hasNext={
                  invoices.pagination.total_pages > invoices.pagination.page
                }
                hasPrevious={Boolean(invoices.pagination.page > 1)}
              />
            )}
          </Stack>
        </Card.Section>
      </Card>
    </Page>
  );
}
