import styled from "@emotion/styled";

export const CellPadding = styled.div`
  padding: 13px 0px;
`;

export const TableWrapper = styled.div`
  .Polaris-IndexTable__TableHeading {
    color: #000000;
    padding-block: 20px;
  }

  tr {
    border-top: 1px solid #e1e3e5;
  }
`;
