function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement("textarea", {});
  textArea.style.display = "none";
  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
    const msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
    return msg === "successful" ? Promise.resolve() : Promise.reject();
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
    return Promise.reject(err);
  } finally {
    document.body.removeChild(textArea);
  }
}

export default function copyToClipboard(text: string) {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text);
  } else {
    return fallbackCopyTextToClipboard(text);
  }
}
