import * as React from "react";
import styled from "@emotion/styled";
import { Badge, Button, Icon, IndexTable, LegacyStack, Link } from "@shopify/polaris";
import { FavoriteMajor } from "@shopify/polaris-icons";
import Feedbacks from "app/models/feedback";
import { formatDateStr, truncateTextMidSection } from "app/utils/helpers";
import { useNavigate } from "react-router-dom";
import useGetPaymentDetails from "../hooks/useGetPaymentDetails";

interface Props {
  feedbacks: Feedbacks[];
}

export default function FeedbacksList(props: Props) {
  const { feedbacks } = props;
  const [ref, setRef] = React.useState<string>();
  const navigate = useNavigate();

  const { data } = useGetPaymentDetails(ref);

  React.useEffect(() => {
    if (data) {
      let paymentId = data?.groot_deposits[0].id;
      navigate(`/payments/${paymentId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const starArray = new Array(5).fill(0).map((_, i) => i + 1);
  return (
    <>
      {feedbacks.map((feedback, index) => (
        <IndexTable.Row key={index} id={index.toString()} position={index}>
          <IndexTable.Cell>
            <CellPadding>
              {feedback.is_merchant ? (
                <Link url={`/businesses/${feedback.business_id}`} removeUnderline>
                  {feedback.id}
                </Link>
              ) : (
                <Button plain onClick={() => setRef(feedback.reference)}>
                  {feedback.reference}
                </Button>
              )}
            </CellPadding>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CellPadding>
              {truncateTextMidSection({ text: feedback.comment })}
            </CellPadding>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CellPadding>
              <LegacyStack spacing="extraTight" wrap={false}>
                {starArray.map(i => (
                  <Icon
                    key={i}
                    source={FavoriteMajor}
                    color={
                      Math.ceil(Number(feedback.rating) / 2) >= i ? "success" : "base"
                    }
                  />
                ))}
              </LegacyStack>
            </CellPadding>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CellPadding>
              <Badge status="new">{feedback.is_merchant ? "Business" : "Merchant"}</Badge>
            </CellPadding>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CellPadding>
              {formatDateStr(feedback.created_at, "MMM DD, YYYY [at] hh:mm a")}
            </CellPadding>
          </IndexTable.Cell>
        </IndexTable.Row>
      ))}
    </>
  );
}

const CellPadding = styled.div`
  padding: 13px 0px;
`;
