import request from "../request";
import {
  AddCountryPayload,
  GetCountriesResponse,
  GetCountryResponse,
} from "./type";

async function getCountries() {
  return request.get<GetCountriesResponse>("/v1/businesses/countries");
}

async function getCountry(countryId: string) {
  return request.get<GetCountryResponse>(
    `/v1/businesses/countries/${countryId}`
  );
}

async function addCountry(data: AddCountryPayload) {
  return request.post("/v1/businesses/countries", data);
}

const CountryService = {
  addCountry,
  getCountries,
  getCountry,
};

export default CountryService;
