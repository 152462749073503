import { AxiosError } from "axios";
import { useInfiniteQuery } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import ConnectTradeService from "app/api/connect";
import { GetConnectTradesQuery } from "app/api/connect/type";

interface Props {
  params?: GetConnectTradesQuery;
}
export default function useGetConnectTrades({ params }: Props) {
  const fetchItemsWithCursor = ({ pageParam = undefined }) =>
    ConnectTradeService.getConnectTrades({
      ...params,
      cursor: pageParam,
    });

  const {
    isLoading,
    error,
    isFetching,
    data,
    hasNextPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    refetch,
  } = useInfiniteQuery(["connect-trade", params], fetchItemsWithCursor, {
    enabled: Boolean(params?.currency),
    keepPreviousData: true,
    getNextPageParam: lastPage =>
      lastPage.data.cursor !== "" ? lastPage.data.cursor : undefined,
    getPreviousPageParam: firstPage => firstPage.data.cursor,
  });

  return {
    connectTrades: data?.pages,
    hasNextPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    isLoading,
    isFetching,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
  };
}
