import { useMutation, useQueryClient } from "@tanstack/react-query";
import BankAccountService from "app/api/paymentAccount";

import parseError from "app/lib/parseError";
import { useToast } from "app/providers/ToastProvider";
import { AxiosError } from "axios";

export default function useRemoveBankAccount() {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(BankAccountService.removePaymentAccount, {
    onSuccess: res => {
      showToast(res.data.message || "Bank account removed", "success");
      return queryClient.invalidateQueries(["bank-accounts"]);
    },
    onError: (error: AxiosError<{}>) => {
      const errorMessage = parseError(error) as string;
      showToast(errorMessage, "error");
    },
  });

  return {
    isRemovingBankAccount: isLoading,
    removePaymentAccount: mutate,
  };
}
