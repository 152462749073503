import { useParams } from "react-router-dom";
import { Badge, Loading, Page } from "@shopify/polaris";

import LoaderContainer from "app/components/LoaderContainer";
import useGoBack from "app/hooks/useGoBack";
import Details from "./components/Details";
import useGetPaymentLink from "./hooks/useGetPaymentLink";
import { formatDateStr } from "app/utils/helpers";
import useModalState from "app/hooks/useModalState";
import ToggleStatusModal from "app/components/ToggleStatusModal";
import usePaymentLinkActions from "./hooks/usePaymentLinkActions";

export default function PaymentLinksDetails() {
  const goBack = useGoBack();

  const { id } = useParams();

  const { openModal, closeModal, isOpen } = useModalState(false);

  const { isLoading, isFetching, data, error, refetch } = useGetPaymentLink(String(id));

  const {
    deletePaymentLink,
    deletingPaymentLink,
    restorePaymentLink,
    restoringPaymentLink,
  } = usePaymentLinkActions(String(id));

  const onDelete = () => {
    deletePaymentLink(undefined, {
      onSuccess() {
        closeModal();
      },
    });
  };

  const onRestore = () => {
    restorePaymentLink(undefined, {
      onSuccess() {
        closeModal();
      },
    });
  };

  const paymentLink = data?.data;

  const isDeleted = !Boolean(paymentLink?.deleted_at);

  const isQueryResolved = paymentLink && !isLoading && !error;

  return (
    <>
      {isFetching && <Loading />}

      <Page
        breadcrumbs={[{ onAction: goBack }]}
        title={isQueryResolved ? `CHK-${paymentLink?.id.slice(0, 6)}` : "..."}
        subtitle={
          isQueryResolved
            ? formatDateStr(String(paymentLink?.created_at), "MMMM D, YYYY [at] hh:mma")
            : "..."
        }
        primaryAction={{
          loading: deletingPaymentLink || restoringPaymentLink,
          content: isDeleted ? "Delete Payment link" : "Restore Payment link",
          disabled: deletingPaymentLink || restoringPaymentLink,
          onAction: openModal,
          destructive: isDeleted,
        }}
        titleMetadata={
          isQueryResolved ? (
            <Badge status={paymentLink?.deleted_at ? "critical" : "success"}>
              {paymentLink?.deleted_at ? "Inactive" : "Active"}
            </Badge>
          ) : (
            "..."
          )
        }
      >
        <LoaderContainer modal loading={isLoading} error={error} onRetryClicked={refetch}>
          {paymentLink && <Details paymentLink={paymentLink} />}
        </LoaderContainer>

        <ToggleStatusModal
          title={isDeleted ? "Delete Payment link" : "Restore Payment link"}
          open={isOpen}
          onClose={closeModal}
          loading={deletingPaymentLink || restoringPaymentLink}
          onConfirm={isDeleted ? onDelete : onRestore}
          destructive={isDeleted ? true : false}
          confirmText={isDeleted ? "Delete Payment link" : "Restore Payment link"}
          text={`Are you sure you want to ${
            isDeleted ? "delete" : "restore"
          } this payment link?`}
        />
      </Page>
    </>
  );
}
