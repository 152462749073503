import { Badge, IndexTable, Link } from "@shopify/polaris";
import { Status } from "@shopify/polaris/build/ts/latest/src/components/Badge";

import { formatMoney } from "app/lib/money";
import { CellPadding } from "./styles";
import { formatDateStr } from "app/utils/helpers";
import { paymentStatusMap } from "app/lib/mappers";
import { GrootDeposit } from "app/models/payment";

interface Props {
  transactions: GrootDeposit[];
}

export default function TransactionsList(props: Props) {
  const { transactions } = props;

  return (
    <>
      {transactions.map((transaction, idx) => {
        return (
          <IndexTable.Row id={idx.toString()} key={idx.toString()} position={idx}>
            <IndexTable.Cell>
              <Link removeUnderline url={`/payments/${transaction.id}`}>
                <CellPadding>{transaction.reference}</CellPadding>
              </Link>
            </IndexTable.Cell>

            <IndexTable.Cell>
              <CellPadding>
                {formatDateStr(transaction.created_at, "MMMM D, YYYY [at] hh:mma")}
              </CellPadding>
            </IndexTable.Cell>

            <IndexTable.Cell>
              <Badge status={paymentStatusMap[transaction.status] as Status}>
                {transaction.status ?? "Cancelled"}
              </Badge>
            </IndexTable.Cell>

            <IndexTable.Cell>
              {transaction.amount
                ? formatMoney(Number(transaction.amount), "", transaction.currency)
                : "No Payment"}
            </IndexTable.Cell>
          </IndexTable.Row>
        );
      })}
    </>
  );
}
