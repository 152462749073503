import { Card, Form, Modal, TextField } from "@shopify/polaris";

interface Props {
  open: boolean;
  onClose: () => void;
  text: string;
  loading: boolean;
  onConfirm: () => void;
  confirmText: string;
}
export default function ConfirmModal(props: Props) {
  const { open, onClose, text, onConfirm, confirmText, loading } = props;
  return (
    <Modal
      title="Deactivate user account"
      open={open}
      onClose={onClose}
      primaryAction={{
        content: confirmText,
        onAction: onConfirm,
        destructive: true,
        loading,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <Card subdued>
          <Card.Section>
            <p>{text}</p>
          </Card.Section>
          <Card.Section>
            <Form onSubmit={() => {}}>
              <TextField
                autoComplete=""
                label="Enter password"
                onChange={() => {}}
              />
            </Form>
          </Card.Section>
        </Card>
      </Modal.Section>
    </Modal>
  );
}
