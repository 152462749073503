import * as React from "react";
import { FilterInterface, Filters, Select } from "@shopify/polaris";
import debounce from "lodash.debounce";

import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import { GetBookExchangesResponse, GetBooksQuery } from "app/api/books/types";

interface Props {
  query: GetBooksQuery;
  setQuery: (q: GetBooksQuery) => void;
  exchanges: GetBookExchangesResponse["exchanges"];
}

export default function BooksFilter(props: Props) {
  const { query, setQuery, exchanges } = props;

  const [queryString, setQueryString] = React.useState(query.q);

  const debouncedQueryUpdate = React.useMemo(
    () =>
      debounce((searchString) => setQuery({ q: searchString, page: 1 }), 1000),
    [setQuery]
  );

  useUpdatedEffect(() => {
    debouncedQueryUpdate(queryString);
  }, [queryString]);

  const exchangesOption = React.useMemo(
    () => exchanges.map((e) => ({ label: e.name, value: e.value })),
    [exchanges]
  );

  const filters: FilterInterface[] = [
    {
      label: "Exchange",
      key: "exchange",
      filter: (
        <Select
          labelHidden
          label="Exchange"
          value={query.exchange}
          options={exchangesOption}
          onChange={(s) => setQuery({ exchange: s })}
        />
      ),
      shortcut: true,
    },
  ];

  const removeFilter = React.useCallback(
    (filter: string) => {
      setQuery({ ...query, [filter]: undefined });
    },
    [setQuery, query]
  );

  const appliedFilters = filters
    .filter((filter) => query[filter.key as keyof GetBooksQuery] !== undefined)
    .map((filter) => ({
      ...filter,
      label: `${filter.label}: ${query[filter.key as keyof GetBooksQuery]}`,
      onRemove: removeFilter,
    }));

  return (
    <Filters
      filters={filters}
      appliedFilters={appliedFilters}
      focused
      queryValue={queryString}
      onQueryChange={setQueryString}
      onQueryClear={() => setQueryString("")}
      queryPlaceholder="Search books"
      onClearAll={() => {}}
    />
  );
}
