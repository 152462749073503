import { Card, Loading, Page, Pagination, Stack } from "@shopify/polaris";
import TransactionsList from "./components/TransactionList";
import LoaderContainer from "app/components/LoaderContainer";
import TransactionFilter from "./components/TransactionFilter";
import { GetTransactionsQuery } from "app/api/transactions/types";
import useQueryParams from "app/hooks/useQueryParams";
import useGetTransactions from "./hooks/useGetTransactions";

export default function Transactions() {
  const { queryParams, updateQueryParams } = useQueryParams<GetTransactionsQuery>({
    limit: 10,
    sort: "desc",
    page: 1,
  });

  const { data, isLoading, error, refetch, isFetching } = useGetTransactions({
    params: queryParams,
  });

  const transactionsData = data?.data ?? [];

  return (
    <>
      {isFetching && <Loading />}

      <Page title="Transactions">
        <Card>
          <TransactionFilter
            query={queryParams}
            setQuery={updateQueryParams}
            transactionsOnDisplay={transactionsData.length}
          />
          <LoaderContainer
            style={{ minHeight: "unset" }}
            loading={isLoading}
            error={error}
            onRetryClicked={refetch}
          >
            <TransactionsList transactions={transactionsData} />
          </LoaderContainer>

          <Card.Section>
            <Stack distribution="center">
              {data && data.data.length > 0 && (
                <Pagination
                  label={`${queryParams.page ?? 1} / ${data.pagination.total_pages}`}
                  onPrevious={() => {
                    updateQueryParams("page", Number(queryParams.page ?? 1) - 1);
                  }}
                  onNext={() => {
                    updateQueryParams("page", (Number(queryParams.page) ?? 1) + 1);
                  }}
                  hasNext={data.pagination.total_pages > data.pagination.page}
                  hasPrevious={Boolean(data.pagination.page > 1)}
                />
              )}
            </Stack>
          </Card.Section>
        </Card>
      </Page>
    </>
  );
}
