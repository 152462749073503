import {
  Card,
  Form,
  FormLayout,
  Layout,
  Select,
  TextField,
} from "@shopify/polaris";
import { Controller, useFormContext } from "react-hook-form";
import { AddCountryPayload } from "app/api/countries/type";
import { CurrencyItem } from "app/models/currency";

interface Props {
  isAddMode: boolean;
  currencies: CurrencyItem[];
}
export default function CountryForm(props: Props) {
  const { isAddMode, currencies } = props;
  const { control } = useFormContext<AddCountryPayload>();

  const currenciesOptions = currencies.map(currency => {
    return {
      value: currency.code,
      label: currency.name,
    };
  });

  return (
    <Form onSubmit={() => {}}>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <FormLayout>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextField
                    label="Name"
                    onChange={val => {
                      field.onChange(val);
                    }}
                    value={field.value}
                    autoComplete="off"
                    readOnly={!isAddMode}
                    placeholder="Eg. Nigeria"
                  />
                )}
              />

              <Controller
                control={control}
                name="code"
                render={({ field }) => (
                  <TextField
                    label="Code"
                    onChange={val => {
                      field.onChange(val);
                    }}
                    value={field.value}
                    autoComplete="off"
                    readOnly={!isAddMode}
                    placeholder="NG"
                  />
                )}
              />

              <Controller
                control={control}
                name="currency"
                render={({ field }) => (
                  <Select
                    label="Currency"
                    options={currenciesOptions}
                    onChange={val => field.onChange(val)}
                    value={field.value}
                  />
                )}
              />

              <Controller
                control={control}
                name="calling_code"
                render={({ field }) => (
                  <TextField
                    label="Calling Code"
                    onChange={val => {
                      field.onChange(val);
                    }}
                    value={field.value}
                    autoComplete="off"
                    readOnly={!isAddMode}
                    placeholder="Eg. 234. Do not add the + symbol"
                  />
                )}
              />
            </FormLayout>
          </Card>
        </Layout.Section>

        <Layout.Section secondary>
          {/* <Card>
            <Card.Section title="STATUS">
              <div style={{ marginTop: 16 }}>
                <Stack vertical>
                  <Controller
                    control={control}
                    name="online"
                    render={({ field }) => (
                      <Checkbox
                        label="Online"
                        checked={field.value === true}
                        onChange={() => {
                          setValue("online", true, { shouldDirty: true });
                        }}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="online"
                    render={({ field }) => (
                      <Checkbox
                        label="Offline"
                        checked={field.value === false}
                        onChange={() => {
                          setValue("online", false, { shouldDirty: true });
                        }}
                      />
                    )}
                  />
                </Stack>
              </div>
            </Card.Section>
          </Card> */}
        </Layout.Section>
      </Layout>
    </Form>
  );
}
