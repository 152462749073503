import * as React from "react";
import { Tabs } from "@shopify/polaris";

import { MobileAops } from "./MobileAppsList";
import { WebSessions } from "./WebSessions";
import { ComfirmedDevices } from "./ConfirmedDevices";

const tabsMap: Record<number, React.ReactNode> = {
  0: <MobileAops />,
  1: <WebSessions />,
  2: <ComfirmedDevices />,
};

export default function DevicesTabs() {
  const [selected, setSelected] = React.useState(0);

  const onSelectTab = (n: number) => {
    setSelected(n);
  };

  return (
    <Tabs
      selected={selected}
      onSelect={onSelectTab}
      tabs={[
        {
          id: "apps",
          content: "Mobile Applications",
        },
        {
          id: "sessions",
          content: "Web Sessions",
        },
        {
          id: "confirmed",
          content: "Confirmed Devices",
        },
      ]}
    >
      {tabsMap[selected]}
    </Tabs>
  );
}
