import { Card, Page } from "@shopify/polaris";
import LoaderContainer from "app/components/LoaderContainer";
import CountriesList from "./components/CountriesList";
import useGetCountries from "app/hooks/useGetCountries";

export default function CountrySettings() {
  const { countries, error, isLoading, refetch } = useGetCountries();

  return (
    <Page
      title="Countries"
      primaryAction={{
        content: "Add Country",
        url: "/settings/countries/add",
      }}
    >
      <Card>
        <LoaderContainer
          style={{ minHeight: "unset" }}
          loading={isLoading}
          error={error}
          onRetryClicked={refetch}
        >
          {countries?.data && <CountriesList countries={countries.data} />}
        </LoaderContainer>
      </Card>
    </Page>
  );
}
