import { Card, Stack, Text } from "@shopify/polaris";

import EditSettlementModal from "./EditSettlementModal";
import useListSettlements from "./hooks/useListSettlements";
import SettlementDetails from "./SettlementDetails";

interface Props {
  businessId: string;
}
export default function Settlement(props: Props) {
  const { businessId } = props;

  const { settlements } = useListSettlements(businessId);

  const settlement = settlements?.[0];

  return (
    <Card
      title={
        <Stack distribution="equalSpacing" alignment="center">
          <Text as="p" variant="bodySm" fontWeight="semibold">
            SETTLEMENT
          </Text>

          {settlement && <EditSettlementModal settlement={settlement} />}
        </Stack>
      }
    >
      {settlement ? (
        <SettlementDetails settlement={settlement} />
      ) : (
        <Card.Section>
          <Text as="p" variant="bodyMd" fontWeight="regular">
            No settlements enabled
          </Text>
        </Card.Section>
      )}
    </Card>
  );
}
