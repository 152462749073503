import * as React from "react";
import {
  Card,
  Checkbox,
  FormLayout,
  Layout,
  Select,
  Stack,
  TextField,
} from "@shopify/polaris";
import { css, Global } from "@emotion/react";

import { AddBookPayload, GetBookExchangesResponse } from "app/api/books/types";
import bookTypes from "app/constants/bookTypes";
import { FormMode } from "app/hooks/useFormMode";
import CurrencyField from "app/components/CurrencyField";
import { Controller, UseFormReturn } from "react-hook-form";
import { formatMoneyNoCurrency } from "app/lib/money";

interface Props {
  form: UseFormReturn<AddBookPayload>;
  formMode: FormMode;
  exchanges: GetBookExchangesResponse["exchanges"];
  orderExchanges: GetBookExchangesResponse["exchanges"];
}

export default function BookForm(props: Props) {
  const {
    form: {
      getValues,
      setValue,
      control,
      formState: { errors },
    },
    formMode,
    exchanges,
    orderExchanges,
  } = props;

  const exchangesOptions = React.useMemo(
    () => exchanges.map((e) => ({ label: e.name, value: e.value })),
    [exchanges]
  );

  const orderExchangesOptions = React.useMemo(
    () => orderExchanges.map((e) => ({ label: e.name, value: e.value })),
    [orderExchanges]
  );

  const values = React.useMemo(() => getValues(), [getValues]);

  React.useEffect(() => {
    setValue("product", `${values.base}${values.counter}`);
  }, [values.base, values.counter, setValue]);

  const priceCurrency =
    values.category.toLowerCase() === "fiat" ? values.counter : "";

  return (
    <>
      <Global
        styles={css`
          .Polaris-Layout {
            padding-bottom: 20px;
          }
        `}
      />
      <Layout>
        <Layout.AnnotatedSection
          title="EXCHANGE DETAILS"
          description={
            formMode === "edit" &&
            "Sadly you can’t edit any of this. Contact any of the devs in you need to change anything."
          }
        >
          <Card sectioned>
            <FormLayout>
              <Controller
                name="base"
                control={control}
                render={({ field }) => (
                  <CurrencyField
                    autoComplete=""
                    label="Base"
                    name="base"
                    value={field.value}
                    onChange={(v) => setValue("base", v, { shouldDirty: true })}
                    disabled={formMode === "edit"}
                    error={errors.base?.message}
                  />
                )}
              />

              <Controller
                name="counter"
                control={control}
                render={({ field }) => (
                  <CurrencyField
                    autoComplete=""
                    label="Counter"
                    name="counter"
                    value={field.value}
                    onChange={(v) =>
                      setValue("counter", v, { shouldDirty: true })
                    }
                    error={errors.counter?.message}
                    disabled={formMode === "edit"}
                  />
                )}
              />

              <FormLayout.Group>
                <Controller
                  name="base_decimals"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete=""
                      type="number"
                      label="Base Decimal"
                      name="base_decimals"
                      value={field.value.toString()}
                      onChange={(v) =>
                        setValue("base_decimals", Number(v), {
                          shouldDirty: true,
                        })
                      }
                      error={errors.base_decimals?.message}
                    />
                  )}
                />

                <Controller
                  name="counter_decimals"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete=""
                      type="number"
                      label="Counter Decimal"
                      name="counter_decimals"
                      value={field.value.toString()}
                      onChange={(v) =>
                        setValue("counter_decimals", Number(v), {
                          shouldDirty: true,
                        })
                      }
                      error={errors.counter_decimals?.message}
                    />
                  )}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card>

          <Card sectioned>
            <FormLayout>
              <Controller
                name="product"
                control={control}
                render={({ field }) => (
                  <TextField
                    autoComplete=""
                    label="Product"
                    name="product"
                    value={field.value}
                    onChange={(v) =>
                      setValue("product", v, { shouldDirty: true })
                    }
                    error={errors.product?.message}
                    disabled
                  />
                )}
              />

              <Controller
                name="exchange"
                control={control}
                render={({ field }) => (
                  <Select
                    label="Exchange"
                    name="exchange"
                    value={field.value}
                    options={exchangesOptions}
                    onChange={(v) =>
                      setValue("exchange", v, { shouldDirty: true })
                    }
                    error={errors.exchange?.message}
                    disabled={formMode === "edit"}
                  />
                )}
              />

              <FormLayout.Group>
                <Controller
                  name="provider_base_decimals"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete=""
                      type="number"
                      label="Provider Base Decimal"
                      name="provider_base_decimals"
                      value={field.value.toString()}
                      onChange={(v) =>
                        setValue("provider_base_decimals", Number(v), {
                          shouldDirty: true,
                        })
                      }
                      error={errors.provider_base_decimals?.message}
                    />
                  )}
                />

                <Controller
                  name="provider_counter_decimals"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete=""
                      type="number"
                      label="Provider Counter Decimal"
                      name="provider_counter_decimals"
                      value={field.value.toString()}
                      onChange={(v) =>
                        setValue("provider_counter_decimals", Number(v), {
                          shouldDirty: true,
                        })
                      }
                      error={errors.provider_counter_decimals?.message}
                    />
                  )}
                />
              </FormLayout.Group>

              <Controller
                name="category"
                control={control}
                render={({ field }) => (
                  <Select
                    label="Category"
                    name="category"
                    options={bookTypes}
                    value={field.value}
                    onChange={(v) =>
                      setValue("category", v, { shouldDirty: true })
                    }
                    error={errors.category?.message}
                    disabled={formMode === "edit"}
                  />
                )}
              />
            </FormLayout>
          </Card>

          {formMode === "edit" && (
            <Card sectioned>
              <FormLayout>
                <FormLayout.Group>
                  <Controller
                    name="buy_price"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete=""
                        label="Buy Price"
                        name="buy_price"
                        value={`${priceCurrency} ${formatMoneyNoCurrency(
                          Number(field.value),
                          "digital"
                        )}`}
                        onChange={(v) =>
                          setValue("buy_price", Number(v), {
                            shouldDirty: true,
                          })
                        }
                        disabled
                      />
                    )}
                  />

                  <Controller
                    name="buy_price"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete=""
                        label="Sell Price"
                        name="sell_price"
                        value={`${priceCurrency} ${formatMoneyNoCurrency(
                          Number(field.value),
                          "digital"
                        )}`}
                        onChange={(v) =>
                          setValue("sell_price", Number(v), {
                            shouldDirty: true,
                          })
                        }
                        disabled
                      />
                    )}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Card>
          )}
        </Layout.AnnotatedSection>

        <Layout.AnnotatedSection
          title="MARK UP & DOWN"
          description="Mark up buy prices and markdown sell price. The modified prices are the prices customers will buy or sell at."
        >
          <Card sectioned>
            <FormLayout>
              <FormLayout.Group>
                <Controller
                  name="buy_mark_up"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete=""
                      type="number"
                      label="Buy - Mark Up (%)"
                      name="buy_mark_up"
                      value={field.value.toString()}
                      onChange={(v) =>
                        setValue("buy_mark_up", Number(v), {
                          shouldDirty: true,
                        })
                      }
                      error={errors.buy_mark_up?.message}
                    />
                  )}
                />

                <Controller
                  name="buy_mark_up"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete=""
                      type="number"
                      label="Sell - Mark Down (%)"
                      name="sell_mark_down"
                      value={field.value.toString()}
                      onChange={(v) =>
                        setValue("sell_mark_down", Number(v), {
                          shouldDirty: true,
                        })
                      }
                      error={errors.sell_mark_down?.message}
                    />
                  )}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="BUY & SELL STATUS"
          description="Activate or deactive both or a single side of the book. Prices will still be updated but no orders will be taken."
        >
          <Card sectioned>
            <Stack>
              <Controller
                name="buy"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    label="Buy"
                    checked={field.value}
                    onChange={(v) => setValue("buy", v, { shouldDirty: true })}
                    error={errors.buy?.message}
                    helpText={`Allow customers buy ${
                      {} || "base"
                    } at the current set price`}
                  />
                )}
              />

              <Controller
                name="sell"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    label="Sell"
                    checked={field.value}
                    onChange={(v) => setValue("sell", v, { shouldDirty: true })}
                    error={errors.sell?.message}
                    helpText={`Allow customers sell ${
                      {} || "base"
                    } at the current set price`}
                  />
                )}
              />
            </Stack>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="PURCHASES"
          description="This is the minimum or maximun volume a customer can purchase at a time."
        >
          <Card sectioned>
            <FormLayout>
              <FormLayout.Group>
                <Controller
                  name="minimum_purchase"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete=""
                      type="number"
                      label="Minimum Purchases"
                      name="minimum_purchase"
                      value={field.value.toString()}
                      onChange={(v) =>
                        setValue("minimum_purchase", Number(v), {
                          shouldDirty: true,
                        })
                      }
                      error={errors.minimum_purchase?.message}
                    />
                  )}
                />

                <Controller
                  name="maximum_purchase"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete=""
                      type="number"
                      label="Maximum Purchases"
                      name="maximum_purchase"
                      value={field.value.toString()}
                      onChange={(v) =>
                        setValue("maximum_purchase", Number(v), {
                          shouldDirty: true,
                        })
                      }
                      error={errors.maximum_purchase?.message}
                    />
                  )}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="SELL"
          description="This is the minimum or maximun volume a customer can sell at a time."
        >
          <Card sectioned>
            <FormLayout>
              <FormLayout.Group>
                <Controller
                  name="minimum_sale"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete=""
                      type="number"
                      label="Minimum Sell"
                      name="minimum_sale"
                      value={field.value.toString()}
                      onChange={(v) =>
                        setValue("minimum_sale", Number(v), {
                          shouldDirty: true,
                        })
                      }
                      error={errors.minimum_sale?.message}
                    />
                  )}
                />

                <Controller
                  name="maximum_sale"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete=""
                      type="number"
                      label="Maximum Sell"
                      name="maximum_sale"
                      value={field.value.toString()}
                      onChange={(v) =>
                        setValue("maximum_sale", Number(v), {
                          shouldDirty: true,
                        })
                      }
                      error={errors.maximum_sale?.message}
                    />
                  )}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="ORDER EXCHANGE">
          <Card sectioned>
            <FormLayout>
              <FormLayout.Group>
                <Controller
                  name="limit_order_exchange"
                  control={control}
                  render={({ field }) => (
                    <Select
                      label="Limit Order Exhange"
                      name="limit_order_exchange"
                      options={orderExchangesOptions}
                      value={field.value}
                      onChange={(v) =>
                        setValue("limit_order_exchange", v, {
                          shouldDirty: true,
                        })
                      }
                      error={errors.limit_order_exchange?.message}
                    />
                  )}
                />

                <Controller
                  name="market_order_exchange"
                  control={control}
                  render={({ field }) => (
                    <Select
                      label="Market Order Exhange"
                      name="market_order_exchange"
                      options={orderExchangesOptions}
                      value={field.value}
                      onChange={(v) =>
                        setValue("market_order_exchange", v, {
                          shouldDirty: true,
                        })
                      }
                      error={errors.market_order_exchange?.message}
                    />
                  )}
                />
              </FormLayout.Group>

              <Controller
                name="market_order_threshold"
                control={control}
                render={({ field }) => (
                  <TextField
                    autoComplete=""
                    type="number"
                    label="Market Order Threshold"
                    name="market_order_threshold"
                    value={field.value.toString()}
                    onChange={(v) =>
                      setValue("market_order_threshold", Number(v), {
                        shouldDirty: true,
                      })
                    }
                    error={errors.market_order_threshold?.message}
                  />
                )}
              />
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </>
  );
}
