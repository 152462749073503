import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AlertMessage from "app/models/alertMessage";

const usePageAlert = () => {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  const [alert, setAlert] = React.useState<AlertMessage | null>(() => {
    if (state?.alert) {
      return state.alert;
    }
    return null;
  });

  const closeAlert = () => {
    setAlert(null);
  };

  // hack to remove alert from history state 💩
  React.useEffect(() => {
    if (state?.alert) {
      const { alert, ...restState } = state;
      navigate({ pathname }, { state: restState, replace: true });
    }
  }, [navigate, pathname, state]);

  return { alert, closeAlert };
};

export default usePageAlert;
