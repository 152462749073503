import { AppProvider } from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
import AppLink from "app/components/AppLink";
import { useRoutes } from "react-router-dom";

import routes from "./routes";

export default function Routes() {
  const routObjects = useRoutes(routes);

  return (
    // @ts-ignore
    <AppProvider i18n={enTranslations} linkComponent={AppLink}>
      {routObjects}
    </AppProvider>
  );
}
