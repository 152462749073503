import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import WIthdrawalsService from "app/api/withdrawals";

export default function useGetWithdrawals(businessId: string) {
  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["withdrawals", businessId],
    () => WIthdrawalsService.getWithdrawals({ limit: 5, business_id: businessId }),
    { staleTime: 0 }
  );

  return {
    isLoading,
    isFetching,
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
  };
}
