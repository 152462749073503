import * as React from "react";
import {
  AppliedFilterInterface,
  ChoiceList,
  FilterInterface,
  Filters,
  Stack,
} from "@shopify/polaris";
import debounce from "lodash.debounce";

import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import { GetPaymentLinksQuery } from "app/api/paymentLink/types";
import SearchFilter from "app/components/SearchFilter";

interface Props {
  query: GetPaymentLinksQuery;
  setQuery: (q: GetPaymentLinksQuery) => void;
}

const searchOptions = [
  {
    label: "Business Name",
    value: "business_name",
  },
  {
    label: "Product Name",
    value: "name",
  },
  {
    label: "Reference",
    value: "reference",
  },
];

export default function PaymentLinksFilter(props: Props) {
  const { query, setQuery } = props;

  const [queryString, setQueryString] = React.useState(query.q);

  const debouncedQueryUpdate = React.useMemo(
    () => debounce(searchString => setQuery({ q: searchString, page: 1 }), 1000),
    [setQuery]
  );

  useUpdatedEffect(() => {
    debouncedQueryUpdate(queryString);
  }, [queryString]);

  const statusTypes = [
    {
      label: "Active",
      value: "false",
    },
    {
      label: "Inactive",
      value: "true",
    },
  ];
  const filters: FilterInterface[] = [
    {
      label: "Status",
      key: "deleted",
      filter: (
        <ChoiceList
          title="Status"
          titleHidden
          choices={statusTypes}
          selected={[query.deleted || ""]}
          onChange={t => setQuery({ deleted: t[0] })}
        />
      ),
      shortcut: true,
    },
  ];

  const removeFilter = (filter: keyof GetPaymentLinksQuery) => {
    setQuery({ ...query, [filter]: undefined });
  };

  const appliedFilters: AppliedFilterInterface[] = Object.keys(query)
    .filter(
      key =>
        ["deleted"].includes(key) &&
        query[key as keyof GetPaymentLinksQuery] !== undefined
    )
    .map(key => {
      return {
        key,
        label: `${key}: ${query[key as keyof GetPaymentLinksQuery]}`,
        onRemove: removeFilter,
      };
    });

  return (
    <Stack spacing="tight">
      <Stack.Item fill>
        <SearchFilter options={searchOptions} />
      </Stack.Item>

      <Filters
        hideQueryField
        filters={filters}
        appliedFilters={appliedFilters}
        focused
        queryValue={queryString}
        onQueryChange={setQueryString}
        onQueryClear={() => setQueryString("")}
        queryPlaceholder="Search ref number"
        onClearAll={() =>
          setQuery({
            type: undefined,
            q: undefined,
            sort: undefined,
            page: 1,
            limit: undefined,
          })
        }
      />
    </Stack>
  );
}
