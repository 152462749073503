import styled from "@emotion/styled";
import { Avatar, Badge, ResourceItem, Stack } from "@shopify/polaris";
import AuditLogs from "app/models/auditLog";
import { formatDateStr } from "app/utils/helpers";

interface Props {
  auditlog: AuditLogs;
}
export default function AuditLog(props: Props) {
  const { auditlog } = props;

  return (
    <UserContainer>
      <Stack alignment="center" distribution="equalSpacing" spacing="loose">
        <Stack distribution="leading">
          <Avatar initials={`${auditlog.method_name[0]}`} />

          <Stack vertical spacing="extraTight">
            <p>{auditlog.activity}</p>
            <p>{auditlog.ipaddr}</p>
          </Stack>
        </Stack>
        <Stack.Item>
          <Badge status="new">{auditlog.object_type}</Badge>
        </Stack.Item>
        <Stack.Item>
          <p style={{ whiteSpace: "nowrap" }}>
            {formatDateStr(`${auditlog.timestamp}`, "DD MMM, YYYY [at] hh:mma")}
          </p>
        </Stack.Item>
      </Stack>
    </UserContainer>
  );
}

const UserContainer = styled.div`
  width: 100%;
  > div > .Polaris-Stack__Item:first-child {
    flex-basis: 40%;
  }
  > div > .Polaris-Stack__Item:nth-child(2) {
    margin-inline: auto;
  }
  > div > .Polaris-Stack__Item:last-child {
    flex-basis: auto;
    p {
      text-transform: capitalize;
    }
  }
`;

export function renderAuditLog(item: AuditLogs) {
  return (
    <ResourceItem key={item.object_id} id={item.object_id} url={""}>
      <AuditLog auditlog={item} />
    </ResourceItem>
  );
}
