import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import UserService from "app/api/users";
import { GetUsersQuery } from "app/api/users/types";
import useQueryParams from "app/hooks/useQueryParams";

export default function useGetUsers() {
  const { queryParams, updateQueryParams } = useQueryParams<GetUsersQuery>({
    page: 1,
    limit: 6,
  });

  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["users", queryParams],
    () => UserService.getUsers(queryParams)
  );

  const gotoNextPage = () => {
    if (data) {
      updateQueryParams("page", data.data.pagination.page + 1);
    }
  };

  const gotoPrevPage = () => {
    if (data) {
      updateQueryParams("page", data.data.pagination.page - 1);
    }
  };

  return {
    isLoading,
    isFetching,
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
    gotoNextPage,
    gotoPrevPage,
    query: queryParams,
    setQuery: updateQueryParams,
  };
}
