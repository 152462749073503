import * as React from "react";
import { ChoiceList, FilterInterface, Filters } from "@shopify/polaris";
import debounce from "lodash.debounce";

import roles from "app/constants/roles";
import { GetUsersQuery } from "app/api/users/types";
import useUpdatedEffect from "app/hooks/useUpdatedEffect";

interface Props {
  query: GetUsersQuery;
  setQuery: (q: GetUsersQuery) => void;
}
const filterOptions = [
  { key: "roles", label: "Roles" },
  { key: "date_created", label: "Date Created" },
];

export default function UsersFilter(props: Props) {
  const { query, setQuery } = props;

  const [queryString, setQueryString] = React.useState(query.q);

  const debouncedQueryUpdate = React.useMemo(
    () =>
      debounce(
        (searchString: string) => setQuery({ q: searchString, page: 1 }),
        1000
      ),
    [setQuery]
  );

  useUpdatedEffect(() => {
    if (!queryString) {
      setQuery({ q: undefined });
      return;
    }

    debouncedQueryUpdate(queryString);
  }, [queryString]);

  const filters: FilterInterface[] = [
    {
      label: "Date Created",
      key: "date_created",
      filter: (
        <ChoiceList
          title="Date Created"
          titleHidden
          choices={[
            { label: "Newest first", value: "desc" },
            { label: "Oldest first", value: "asc" },
          ]}
          selected={[query.date_created || ""]}
          onChange={(d) => {
            setQuery({ date_created: d[0] });
          }}
        />
      ),
      shortcut: true,
    },
    {
      label: "Roles",
      key: "roles",
      filter: (
        <ChoiceList
          title="Role"
          name="roles"
          allowMultiple
          titleHidden
          choices={roles}
          selected={query.roles ? query.roles.split(",") : []}
          onChange={(r) => {
            setQuery({ roles: r.join(",") });
          }}
        />
      ),
      shortcut: true,
    },
  ];

  const removeFilter = React.useCallback(
    (filter: string) => {
      setQuery({ ...query, [filter]: undefined });
    },
    [setQuery, query]
  );

  const appliedFilters = React.useMemo(
    () =>
      filterOptions
        .filter((filter) => !!query[filter.key as keyof GetUsersQuery])
        .map((filter) => ({
          ...filter,
          label: `${filter.label}: ${query[filter.key as keyof GetUsersQuery]}`,
          onRemove: removeFilter,
        })),
    [query, removeFilter]
  );

  return (
    <>
      <Filters
        filters={filters}
        appliedFilters={appliedFilters}
        queryValue={queryString}
        queryPlaceholder="Search by name"
        onQueryChange={setQueryString}
        onQueryClear={() => setQueryString("")}
        onClearAll={() =>
          setQuery({
            date_created: undefined,
            limit: undefined,
            q: undefined,
            roles: undefined,
            page: 1,
          })
        }
      />
    </>
  );
}
