import * as React from "react";
import { Card, Page, Pagination, Stack } from "@shopify/polaris";

import LoaderContainer from "app/components/LoaderContainer";
import ActivitiesFilter from "./components/ActivitiesFilter";
import ActivitiesList from "./components/ActivitiesList";
import useGoBack from "app/hooks/useGoBack";
import { useParams } from "react-router-dom";
import useGetTeamActivities from "./hooks/useGetTeamActivities";
import { GetTeamsQuery } from "app/api/teams/types";

const LIMIT = 10;

export default function TeamActivities() {
  const goBack = useGoBack();
  const { id } = useParams();
  const [query, setQuery] = React.useState<GetTeamsQuery>({ limit: LIMIT });

  const [currentPage, setCurrentPage] = React.useState(0);
  const {
    data,
    isLoading,
    error,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
  } = useGetTeamActivities(String(id), query);

  const currentLogs = React.useMemo(
    () => (data ? data.flatMap(page => page.data.data) : []),
    [data]
  );

  const logs = currentLogs.slice(currentPage * LIMIT, (currentPage + 1) * LIMIT);

  const handleNextPage = () => {
    fetchNextPage();
    setCurrentPage(prev => prev + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(prev => prev - 1);
    }
  };

  return (
    <Page breadcrumbs={[{ onAction: goBack }]} title="Team Activities">
      <Card>
        <ActivitiesFilter query={query} updateQuery={setQuery} />

        <LoaderContainer
          style={{ minHeight: "unset" }}
          loading={isLoading || isFetching}
          error={error}
          onRetryClicked={refetch}
        >
          {logs && <ActivitiesList activities={logs} />}
        </LoaderContainer>

        <Card.Section>
          <Stack distribution="center">
            <Pagination
              onPrevious={handlePrevPage}
              onNext={handleNextPage}
              hasNext={hasNextPage || isFetchingNextPage}
              hasPrevious={currentPage > 0 || isFetchingPreviousPage}
            />
          </Stack>
        </Card.Section>
      </Card>
    </Page>
  );
}
