import {
  Badge,
  BadgeProps,
  Card,
  IndexTable,
  Link,
  Text,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";

import { formatMoney } from "app/lib/money";
import LoaderContainer from "app/components/LoaderContainer";
import { formatDateStr } from "app/utils/helpers";
import useListInvoices from "app/views/Invoices/hooks/useListInvoices";
import { CellPadding, TableWrapper } from "app/styles";

const InvoiceStatusMap: Record<string, BadgeProps["status"]> = {
  OPEN: "new",
  VIEWED: "attention",
  VOIDED: "warning",
  PAID: "success",
};

export function Invoices() {
  const { id } = useParams();

  const { invoices, isLoading, refetch, error } = useListInvoices({
    params: { business_id: id, limit: 5 },
  });

  return (
    <Card>
      <LoaderContainer
        modal
        loading={isLoading}
        onRetryClicked={refetch}
        error={error}
      >
        <Card.Section fullWidth>
          <TableWrapper>
            <IndexTable
              headings={[
                { title: "Invoice Number" },
                { title: "Date created" },
                { title: "Customer" },
                { title: "Status" },
                { title: "Amount" },
              ]}
              itemCount={invoices?.pagination?.current_entries_size || 0}
              selectable={false}
              emptyState={
                <Text as="p" alignment="center" variant="bodySm">
                  This business is yet to create an invoice.
                </Text>
              }
            >
              {invoices?.data.map((invoice, index) => {
                return (
                  <IndexTable.Row
                    key={index}
                    id={index.toString()}
                    position={index}
                  >
                    <IndexTable.Cell>
                      <Link removeUnderline url={`/invoices/${invoice.id}`}>
                        <CellPadding>{invoice.reference}</CellPadding>
                      </Link>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      {formatDateStr(
                        invoice.created_at,
                        "MMMM D, YYYY [at] hh:mma"
                      )}
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Text as="p" variant="bodySm" fontWeight="medium">
                        {invoice.customer_name}
                      </Text>
                      <Text as="p" variant="bodySm" fontWeight="regular">
                        {invoice.customer_email}
                      </Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Badge status={InvoiceStatusMap[invoice.status]}>
                        {invoice.status}
                      </Badge>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      {invoice.local_amount
                        ? `${formatMoney(Number(invoice.local_amount))} ${
                            invoice.local_currency
                          }`
                        : "N/A"}
                    </IndexTable.Cell>
                  </IndexTable.Row>
                );
              })}
            </IndexTable>
          </TableWrapper>
        </Card.Section>
      </LoaderContainer>
    </Card>
  );
}
