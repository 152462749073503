import {
  Card,
  IndexTable,
  Page,
  Pagination,
  Select,
  Stack,
} from "@shopify/polaris";

import LoaderContainer from "app/components/LoaderContainer";
import useGetBookExchanges from "app/hooks/useGetBookExchanges";
import BookList from "./components/BookList";
import BooksFilter from "./components/BooksFilter";
import useGetBooks from "./hooks/useGetBooks";

export default function Books() {
  const {
    data,
    isLoading,
    error,
    refetch,
    isFetching,
    gotoNextPage,
    gotoPrevPage,
    query,
    setQuery,
  } = useGetBooks();

  const {
    data: exchanges,
    isLoading: loadingExchnages,
    error: exchangesError,
    refetch: refetchExchanges,
  } = useGetBookExchanges();

  const refetchData = () => {
    if (error) {
      refetch();
    }
    if (exchangesError) {
      refetchExchanges();
    }
  };

  return (
    <Page
      title="Books"
      primaryAction={{ content: "Add Book", url: "/books/create" }}
    >
      <LoaderContainer
        page
        loading={(isLoading && !data?.books) || loadingExchnages}
        error={error || exchangesError}
        onRetryClicked={refetchData}
      >
        {data && (
          <Card>
            <Card.Section>
              <Stack vertical>
                <BooksFilter
                  exchanges={exchanges?.exchanges || []}
                  query={query}
                  setQuery={setQuery}
                />
                <Stack distribution="equalSpacing" alignment="center">
                  <p>Showing {data.pagination.current_entries_size} books</p>
                  <Select
                    labelInline
                    label="Sort by"
                    name="sort"
                    value={query.sort}
                    options={[
                      { label: "Newest", value: "desc" },
                      { label: "Oldest", value: "asc" },
                    ]}
                    onChange={(s) => setQuery({ sort: s })}
                  />
                </Stack>
              </Stack>
            </Card.Section>

            <IndexTable
              selectable={false}
              resourceName={{ plural: "books", singular: "book" }}
              loading={isFetching}
              itemCount={data.pagination.total_entries_size || 0}
              headings={[
                { title: "Product" },
                { title: "Exchange" },
                { title: "Updated" },
                { title: "Buy Price" },
                { title: "Spread" },
                { title: "Sell Price" },
              ]}
            >
              <BookList books={data.books} />
            </IndexTable>

            <Card.Section>
              <Stack distribution="center">
                <Pagination
                  hasNext={data.pagination.total_pages > data.pagination.page}
                  onNext={gotoNextPage}
                  hasPrevious={data.pagination.page > 1}
                  onPrevious={gotoPrevPage}
                />
              </Stack>
            </Card.Section>
          </Card>
        )}
      </LoaderContainer>
    </Page>
  );
}
