import * as React from "react";
import styled from "@emotion/styled";
import { Button, Card, Checkbox, IndexTable } from "@shopify/polaris";

import LoaderContainer from "app/components/LoaderContainer";
import useModalState from "app/hooks/useModalState";
import useGetAccounts from "../hooks/useGetAccounts";
import useToggleAccountStatus from "../hooks/useToggleAccountStatus";
import Stats from "./Stats";
import ToggleStatusModal from "app/components/ToggleStatusModal";
import Business from "app/models/business";
import { formatMoney } from "app/lib/money";
import { CellPadding, TableWrapper } from "app/styles";
import AddressModal from "./AddressModal";

interface Props {
  business: Business;
}

export function AccountsSection(props: Props) {
  const { business } = props;

  const [isActive, setIsActive] = React.useState<boolean>(false);
  const [activeAccount, setActiveAccount] = React.useState<string>("");

  const { accounts, isLoading, error, refetch } = useGetAccounts(business.id);

  const { toggleAccountStatus, isToggling } = useToggleAccountStatus(
    business.id,
    String(activeAccount)
  );

  const { isOpen, openModal, closeModal } = useModalState(false);
  const {
    isOpen: isAddressModalOpen,
    openModal: openAddressModal,
    closeModal: closeAddressModal,
  } = useModalState(false);

  const onToggle = (newChecked: boolean, id: string) => {
    setIsActive(!newChecked);
    setActiveAccount(id);
    openModal();
  };

  const totalBalance =
    accounts?.reduce(
      (accumulator, { local: { available_balance } }) =>
        accumulator + Number(available_balance),
      0
    ) ?? 0;

  const totalEscrowBalance =
    accounts?.reduce(
      (accumulator, { local: { escrow_balance } }) =>
        accumulator + Number(escrow_balance),
      0
    ) ?? 0;

  const isBusinessClosed = business.status === "closed";
  const isBusinessSuspended = business.suspended;

  if (!accounts) return null;

  return (
    <LoaderContainer modal loading={isLoading} onRetryClicked={refetch} error={error}>
      <Stats
        store_currency={business.store_currency}
        total_balance={String(totalBalance)}
        escrow_balance={String(totalEscrowBalance)}
      />

      <Card title="Accounts">
        <Card.Section fullWidth>
          <TableWrapper>
            <IndexTable
              headings={[
                { title: "" },
                { title: "Asset" },
                { title: "Transactions" },
                { title: "Pending Balance" },
                { title: "Available Balance" },
              ]}
              itemCount={accounts.length || 0}
              selectable={false}
            >
              {accounts.map((account, index) => {
                return (
                  <IndexTable.Row key={index} id={index.toString()} position={index}>
                    <IndexTable.Cell>
                      <Checkbox
                        disabled={isBusinessSuspended || isBusinessClosed}
                        checked={account.supported}
                        onChange={onToggle}
                        label=""
                        id={account.id}
                      />
                    </IndexTable.Cell>

                    <IndexTable.Cell>
                      <CellPadding>
                        <Info>
                          <img
                            src={`https://res.cloudinary.com/busha-inc/image/upload/v1628857343/App/Currencies/${account.currency_id}.svg`}
                            alt={account.currency_name}
                          />
                          <Button onClick={openAddressModal} plain>
                            {account.currency_id}
                          </Button>
                        </Info>
                      </CellPadding>
                    </IndexTable.Cell>

                    <IndexTable.Cell>NIL</IndexTable.Cell>

                    <IndexTable.Cell>
                      <p style={{ color: "#202223" }}>
                        {formatMoney(
                          Number(account.escrow_balance),
                          "digital",
                          account.currency_id
                        )}
                      </p>
                      <p style={{ color: "#6D7175" }}>
                        {formatMoney(
                          Number(account.local.escrow_balance),
                          "digital",
                          account.local.currency_id
                        )}
                      </p>
                    </IndexTable.Cell>

                    <IndexTable.Cell>
                      <p style={{ color: "#202223" }}>
                        {formatMoney(
                          Number(account.available_balance),
                          "digital",
                          account.currency_id
                        )}
                      </p>
                      <p style={{ color: "#6D7175" }}>
                        {formatMoney(
                          Number(account.local.available_balance),
                          "digital",
                          account.local.currency_id
                        )}
                      </p>
                    </IndexTable.Cell>
                  </IndexTable.Row>
                );
              })}
            </IndexTable>
          </TableWrapper>
        </Card.Section>
      </Card>

      <ToggleStatusModal
        title={!isActive ? "Activate Cryptocurrency" : "Deactivate Cryptocurrency"}
        open={isOpen}
        onClose={closeModal}
        loading={isToggling}
        onConfirm={toggleAccountStatus}
        destructive={isActive}
        confirmText={!isActive ? "Activate" : "Deactivate"}
        text={
          isActive
            ? `You are about to deactivate this cryptocurrency. Are you sure you want to continue?`
            : `You are about to activate this cryptocurrency. Are you sure you want to continue?`
        }
      />

      <AddressModal
        open={isAddressModalOpen}
        currency="BTC"
        onClose={closeAddressModal}
      />
    </LoaderContainer>
  );
}

export const Info = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 16px;
    width: 32px;
    height: 32px;
    border-radius: 30px;
  }
`;
