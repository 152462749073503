import request from "../request";
import {
  GetPaymentLinksResponse,
  GetPaymentLinksQuery,
  GetPaymentLinkResponse,
} from "./types";

const getPaymentLinks = async (params?: GetPaymentLinksQuery) => {
  return request.get<GetPaymentLinksResponse>("/v1/businesses/payment_links/", {
    params,
  });
};

const getPaymentLink = async (paymentLinkId: string) => {
  return request.get<GetPaymentLinkResponse>(
    `/v1/businesses/payment_links/${paymentLinkId}`
  );
};

const deletePaymentLink = async (paymentLinkId: string) => {
  return request.delete(`/v1/businesses/payment_links/${paymentLinkId}`);
};

const restorePaymentLink = async (paymentLinkId: string) => {
  return request.put(`/v1/businesses/payment_links/${paymentLinkId}`);
};

const PaymentLinksServices = {
  getPaymentLink,
  getPaymentLinks,
  deletePaymentLink,
  restorePaymentLink,
};

export default PaymentLinksServices;
