import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import { useToast } from "app/providers/ToastProvider";
import SettlementService from "app/api/settlements";

export default function useUpdateSettlement() {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(SettlementService.updateSettlement, {
    onSuccess: res => {
      showToast(res.data.message || "Settlement updated", "success");
      return queryClient.invalidateQueries(["settlements"]);
    },
    onError: (error: AxiosError<{}>) => {
      const errorMessage = parseError(error) as string;
      showToast(errorMessage, "error");
    },
  });

  return {
    updateSettlement: mutate,
    updating: isLoading,
  };
}
