import request from "../request";
import { BaseResponse } from "../types";
import {
  GetPermissionResponse,
  ListPermissionsResponse,
  ListRolesQuery,
  ListRolesResponse,
  TogglePermissionPayload,
  UpdatePermission,
} from "./types";

const listRoles = async (params?: ListRolesQuery) => {
  return request.get<ListRolesResponse>("/v1/businesses/roles", {
    params,
  });
};

const listPermissions = async () => {
  return request.get<ListPermissionsResponse>("/v1/businesses/permissions");
};

const getPermission = async (permissionId: string) => {
  return request.get<GetPermissionResponse>(`/v1/businesses/permissions/${permissionId}`);
};

const updateRole = async (payload: TogglePermissionPayload) => {
  const { id, ...data } = payload;
  return request.put(`/v1/businesses/roles/${id}`, { ...data });
};

const updatePermission = async ({ permissionId, payload }: UpdatePermission) => {
  return request.put<BaseResponse>(`/v1/businesses/permissions/${permissionId}`, payload);
};

const RolesService = {
  listRoles,
  listPermissions,
  getPermission,
  updatePermission,
  updateRole,
};

export default RolesService;
