import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import { GetWithdrawalsQuery } from "app/api/withdrawals/types";
import useQueryParams from "app/hooks/useQueryParams";
import parseError from "app/lib/parseError";
import WithdrawalsService from "app/api/withdrawals";

export default function useGetWithdrawals() {
  const { queryParams, updateQueryParams } =
    useQueryParams<GetWithdrawalsQuery>({
      sort: "desc",
      limit: 10,
      page: 1,
    });

  const { isFetching, isLoading, error, refetch, data } = useQuery(
    ["withdrawals", ...Object.values(queryParams)],
    () => WithdrawalsService.getWithdrawals(queryParams)
  );

  const gotoNextPage = () => {
    if (data) {
      updateQueryParams("page", data.data.pagination.page + 1);
    }
  };

  const gotoPreviousPage = () => {
    if (data) {
      updateQueryParams("page", data.data.pagination.page - 1);
    }
  };

  return {
    isFetching,
    isLoading,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
    data: data?.data,
    queryParams,
    updateQueryParams,
    gotoNextPage,
    gotoPreviousPage,
  };
}
