import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import BusinessesService from "app/api/businesses";

export default function useListBusinessTypes() {
  const { data, status, error, refetch, isFetching, isLoading } = useQuery(
    ["businessTypes"],
    () => BusinessesService.listBusinessTypes({ country_id: "NG" })
  );

  return {
    businessTypes: data?.data,
    status,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
    isFetching,
    isLoading,
  };
}
