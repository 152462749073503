import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import ConnectTradeService from "app/api/connect";

export default function useGetConnectTrade(tradeId: string) {
  const { data, isLoading, isFetching, error, refetch } = useQuery(
    ["connect-trade", tradeId],
    () => ConnectTradeService.getConnectTrade(tradeId)
  );

  return {
    connectTrade: data?.data.data,
    isFetching,
    isLoading,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
  };
}
