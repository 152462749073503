import styled from "@emotion/styled";

export const TimelineContainer = styled.div`
  margin-top: 36px;

  .header {
    color: #212b36;
    font-weight: 600;
    font-size: 16px;
    padding-inline: 20px;
  }

  hr {
    margin-top: 18px;
    border-top: 1px solid #dfe3e8;
    margin-bottom: 0;
  }

  .timeline {
    margin-top: 43px;
    padding-inline: 14px;

    .date-header {
      padding-left: 29px;
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase;
      color: #637381;
    }

    .stepper {
      margin-top: 32px;

      .row {
        display: flex;
        align-items: flex-start;
        gap: 16px;

        .status {
          color: #212b36;
          font-size: 14px;
        }

        .time {
          margin-left: auto;
          font-size: 14px;
          color: #637381;
          white-space: nowrap;
        }

        .dot-container {
          display: flex;
          flex-direction: column;
          align-items: center;

          .dot {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            background: #c4cdd5;
            border: 2px solid #f4f6f8;
          }

          .dot.active {
            background: #007ace;
          }

          &::after {
            content: "";
            border-width: 1px;
            border-color: rgb(90, 92, 94);
            display: block;
            width: 0px;
            height: 40px;
            margin-top: 2px;
            margin-bottom: 2px;
            border-style: solid;
          }
        }

        &:last-of-type {
          .dot-container::after {
            height: 20px;
          }
        }
      }
    }
  }
`;
