/** @jsxImportSource @emotion/react */
import * as React from "react";
import { useLocation } from "react-router-dom";
import { Spinner } from "@shopify/polaris";
import { css } from "@emotion/react";

import { TokenActions, useAuthTokensDispatch } from "app/providers/TokenProvider";
import logout from "app/lib/logout";
import atlasActions from "app/constants/atlasAction";
import { ATLAS_URL } from "app/constants/variables";

export default function SignOut() {
  const dispatch = useAuthTokensDispatch();

  const location = useLocation();

  React.useEffect(() => {
    dispatch({
      type: TokenActions.setTokens,
      payload: { access_token: "" },
    });

    // check for atlas and send token
    const hasAtlasWrapper = window !== window.parent;
    if (hasAtlasWrapper)
      window.parent.postMessage(
        {
          action: atlasActions.LOGOUT,
        },
        { targetOrigin: ATLAS_URL }
      );

    logout(location.state ? location.state.from || "/" : "/");
  }, [dispatch, location.state]);

  return (
    <div
      css={css`
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <Spinner />
    </div>
  );
}
