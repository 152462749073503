import {
  Button,
  ChoiceList,
  FormLayout,
  Modal,
  RadioButton,
  Select,
  Stack,
} from "@shopify/polaris";
import { Controller, useForm } from "react-hook-form";

import Settlement from "app/models/settlement";
import useModalState from "app/hooks/useModalState";
import useListPaymentAccounts from "app/hooks/useListPaymentAccounts";
import LoaderContainer from "app/components/LoaderContainer";
import useUpdateSettlement from "./hooks/useUpdateSettlement";

interface FormData {
  payment_account_id: string;
  percentage: string;
  status: string;
}

interface Props {
  settlement: Settlement;
}
export default function EditSettlementModal(props: Props) {
  const { settlement } = props;

  const { isOpen, closeModal, openModal } = useModalState();

  const { updateSettlement, updating } = useUpdateSettlement();
  const { paymentAccounts, isLoading, error, refetch } = useListPaymentAccounts(
    settlement.business_id
  );

  const { control, handleSubmit, watch } = useForm<FormData>({
    defaultValues: {
      payment_account_id: settlement.payment_account.id,
      percentage: String(settlement.percentage),
      status: Boolean(settlement.disabled_at) ? "disabled" : "enabled",
    },
  });

  const settlementStatus = watch("status");

  const paymentAccountsOptions = (paymentAccounts ?? []).map(account => {
    const accountNumber = account.fields.find(
      b => b.display_name === "Account Number"
    )?.value;

    const bankName = account.fields.find(b => b.display_name === "Bank Name")?.value;

    return { label: `${accountNumber} ${bankName}`, value: account.id };
  });

  const submitForm = (values: FormData) => {
    if (values.status === "disabled") {
      updateSettlement(
        {
          ...values,
          status: false,
          businessId: settlement.business_id,
          settlementId: settlement.id,
        },
        {
          onSuccess: closeModal,
        }
      );
    } else {
      updateSettlement(
        {
          ...values,
          status: true,
          businessId: settlement.business_id,
          settlementId: settlement.id,
        },
        {
          onSuccess: closeModal,
        }
      );
    }
  };

  return (
    <LoaderContainer
      style={{ minHeight: "unset" }}
      loading={isLoading}
      error={error}
      onRetryClicked={refetch}
    >
      <Modal
        activator={
          <Button onClick={openModal} plain>
            Edit
          </Button>
        }
        open={isOpen}
        onClose={closeModal}
        title="Edit Settlement"
        primaryAction={{
          content: "Save changes",
          onAction: handleSubmit(submitForm),
          loading: updating,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: closeModal,
          },
        ]}
      >
        <Modal.Section>
          <Stack spacing="loose" vertical>
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <ChoiceList
                  title="Status"
                  choices={[
                    { label: "Enabled", value: "enabled" },
                    { label: "Disabled", value: "disabled" },
                  ]}
                  selected={[field.value]}
                  onChange={selected => {
                    field.onChange(selected[0]);
                  }}
                />
              )}
            />

            {settlementStatus === "enabled" && (
              <FormLayout>
                <Controller
                  control={control}
                  name="payment_account_id"
                  render={({ field }) => (
                    <Select
                      label="Payment account"
                      options={paymentAccountsOptions}
                      onChange={selected => field.onChange(selected)}
                      value={field.value}
                    />
                  )}
                />

                <Stack spacing="tight" vertical>
                  <p>Payout percentage</p>

                  <Controller
                    control={control}
                    name="percentage"
                    render={({ field }) => (
                      <Stack distribution="equalSpacing">
                        <RadioButton
                          label="25%"
                          checked={field.value === "25"}
                          onChange={() => field.onChange("25")}
                        />
                        <RadioButton
                          label="50%"
                          checked={field.value === "50"}
                          onChange={() => field.onChange("50")}
                        />
                        <RadioButton
                          label="75%"
                          checked={field.value === "75"}
                          onChange={() => field.onChange("75")}
                        />
                        <RadioButton
                          label="100%"
                          checked={field.value === "100"}
                          onChange={() => field.onChange("100")}
                        />
                      </Stack>
                    )}
                  />
                </Stack>
              </FormLayout>
            )}
          </Stack>
        </Modal.Section>
      </Modal>
    </LoaderContainer>
  );
}
