import styled from "@emotion/styled";
import { Card, Text, Badge } from "@shopify/polaris";
import { SinglePayment } from "app/models/payment";
import {
  capitalizeStr,
  mapStatusToBadge,
  mapStatusToBadgeProgress,
} from "app/utils/helpers";
import Logo from "app/assets/images/busha_logo.svg";

interface Props {
  payment: SinglePayment;
}

export default function PaymentStatus(props: Props) {
  const { payment } = props;

  return (
    <Card>
      <Card.Header
        title={
          <Text variant="headingXs" as="h3">
            Payment Status
          </Text>
        }
      />
      <PaymentStatusEL>
        <Card.Section>
          <div>
            <p>
              <img src={Logo} alt="busha logo" />
            </p>
            <p>
              <Badge
                status={mapStatusToBadge(payment.status)}
                progress={mapStatusToBadgeProgress(payment.status)}
              >
                {capitalizeStr(payment.status)}
              </Badge>
            </p>
          </div>
        </Card.Section>
        <Card.Section>
          <div>
            <Text variant="headingXs" as="h3">
              HASH
            </Text>
            <p>
              <Badge
                status={mapStatusToBadge(payment.fulfilment_status)}
                progress={mapStatusToBadgeProgress(payment.fulfilment_status)}
              >
                {capitalizeStr(payment.fulfilment_status)}
              </Badge>
            </p>
          </div>
          <LongTexts>
            <Text variant="bodyMd" as="p" truncate color="subdued">
              {payment.transaction_hash}
            </Text>
          </LongTexts>
        </Card.Section>
      </PaymentStatusEL>
    </Card>
  );
}

const PaymentStatusEL = styled.div`
  margin-top: 8px;

  .Polaris-Card__Section {
    div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }
    div:last-child {
      margin-bottom: 0px;
    }
  }
`;

const LongTexts = styled.p`
  word-break: break-all;
`;
