import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import CountryService from "app/api/countries";
import parseError from "app/lib/parseError";

export default function useGetCountryDetails(countryId: string) {
  const { data, isLoading, error, refetch } = useQuery(
    ["countries", countryId],
    () => CountryService.getCountry(countryId),
    {
      enabled: countryId !== "add",
    }
  );

  return {
    country: data?.data.data,
    isLoading,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
  };
}
