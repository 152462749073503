import { Avatar, Card, Inline, Link, Text } from "@shopify/polaris";
import LoaderContainer from "app/components/LoaderContainer";
import useGetMerchant from "app/hooks/useGetMerchant";
import { SinglePayment } from "app/models/payment";

interface Props {
  payment: SinglePayment;
}
export default function MerchantDetails(props: Props) {
  const { payment } = props;

  const merchantResponse = useGetMerchant(payment.user_id ?? "");

  const merchantData = merchantResponse.merchant;

  return (
    <Card>
      <LoaderContainer
        style={{ minHeight: "unset" }}
        loading={merchantResponse.isLoading}
        error={merchantResponse.error}
        onRetryClicked={merchantResponse.refetch}
      >
        {merchantData && (
          <Card.Section
            title={
              <Inline align="space-between">
                <Text as="h3" variant="headingXs">
                  MERCHANT
                </Text>
                <Avatar
                  initials={`${merchantData?.profile.first_name[0]}${merchantData?.profile.last_name[0]}`}
                />
              </Inline>
            }
          >
            <Link url={`/merchants/${merchantData.id}`} removeUnderline>
              <p style={{ marginTop: 16 }}>
                {merchantData.profile.first_name} {merchantData.profile.last_name}
              </p>
            </Link>
          </Card.Section>
        )}
      </LoaderContainer>
    </Card>
  );
}
