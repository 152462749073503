import * as React from "react";
import { ChoiceList, FilterInterface, Filters, Select, Stack } from "@shopify/polaris";
import debounce from "lodash.debounce";

import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import GenericDate from "app/components/GenericDate";
import { GetConnectTradesQuery } from "app/api/connect/type";

const offerType = [
  {
    label: "Deposit",
    value: "deposit",
  },
  {
    label: "Withdraw",
    value: "withdraw",
  },
];

interface Props {
  query: GetConnectTradesQuery;
  setQuery: (q: GetConnectTradesQuery) => void;
  tradesOnDisplay: number;
}

export default function ConnectFilter(props: Props) {
  const { query, setQuery, tradesOnDisplay } = props;
  const [queryString, setQueryString] = React.useState(query.search);

  const debouncedQueryUpdate = React.useMemo(
    () => debounce(searchString => setQuery({ search: searchString }), 1000),
    [setQuery]
  );

  useUpdatedEffect(() => {
    debouncedQueryUpdate(queryString);
  }, [queryString]);

  const filters: FilterInterface[] = [
    {
      label: "Offer",
      key: "side",
      filter: (
        <ChoiceList
          title="Offer"
          name="side"
          titleHidden
          choices={offerType}
          selected={[query.side || ""]}
          onChange={t => setQuery({ side: t[0] })}
        />
      ),
      shortcut: true,
    },
  ];

  const removeFilter = React.useCallback(
    (filter: string) => {
      setQuery({ ...query, [filter]: undefined });
    },
    [setQuery, query]
  );

  const appliedFilters = Object.keys(query)
    .filter(
      key =>
        ["side"].includes(key) && query[key as keyof GetConnectTradesQuery] !== undefined
    )
    .map(key => ({
      key,
      label: `${key}: ${query[key as keyof GetConnectTradesQuery]}`,
      onRemove: removeFilter,
    }));

  return (
    <Stack vertical>
      <Filters
        filters={filters}
        appliedFilters={appliedFilters}
        focused
        queryValue={queryString}
        onQueryChange={setQueryString}
        onQueryClear={() => setQueryString("")}
        queryPlaceholder="Search trades"
        onClearAll={() =>
          setQuery({
            search: undefined,
            sort: undefined,
            status: undefined,
            side: undefined,
          })
        }
      />

      <Stack distribution="equalSpacing" alignment="center">
        <Stack spacing="extraLoose" alignment="center">
          <GenericDate
            onDateChange={value => setQuery({ date: value })}
            onReset={() => setQuery({ date: undefined })}
            value={query.date}
          />

          <p>Showing {tradesOnDisplay} Trades</p>
        </Stack>
        <Select
          labelInline
          label="Sort by"
          name="sort"
          value={query.sort}
          options={[
            { label: "Newest", value: "desc" },
            { label: "Oldest", value: "asc" },
          ]}
          onChange={(s: "asc" | "desc") => {
            setQuery({ sort: s });
          }}
        />
      </Stack>
    </Stack>
  );
}
