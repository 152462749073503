import { Toast } from "@shopify/polaris";
import * as React from "react";

export type ToastCallback = (
  message: string,
  status: "success" | "error"
) => void;

export const ToastContext = React.createContext<ToastCallback>(() => {});
ToastContext.displayName = "ToastContext";

export default function ToastProvider(props: React.PropsWithChildren<any>) {
  const [toastMessage, setToastMessage] = React.useState("");
  const [successToastActive, setSuccessToastActive] = React.useState(false);
  const toggleSuccessToast = () => setSuccessToastActive((active) => !active);
  const [errorToastActive, setToastActive] = React.useState(false);
  const toggleErrorToast = () => setToastActive((active) => !active);

  const toast: ToastCallback = React.useCallback((message, status) => {
    setToastMessage(message);
    if (status === "success") {
      toggleSuccessToast();
    }
    if (status === "error") {
      toggleErrorToast();
    }
  }, []);

  const successToast = successToastActive ? (
    <Toast content={toastMessage} onDismiss={toggleSuccessToast} />
  ) : null;
  const errorToast = errorToastActive ? (
    <Toast content={toastMessage} onDismiss={toggleErrorToast} error />
  ) : null;

  return (
    <ToastContext.Provider value={toast}>
      {props.children}
      {successToast}
      {errorToast}
    </ToastContext.Provider>
  );
}

export function useToast() {
  return React.useContext(ToastContext);
}
