import request from "../request";
import { GetAccountsResponse } from "./types";

const getAccounts = async (businessId: string) => {
  return request.get<GetAccountsResponse>(
    `/v1/businesses/${businessId}/accounts`
  );
};

const toggleAccountStatus = (businessId: string, accountId: string) => {
  return request.patch(
    `/v1/businesses/${businessId}/accounts/${accountId}/supported`
  );
};

const AccountsService = {
  getAccounts,
  toggleAccountStatus,
};

export default AccountsService;
