import * as React from "react";
import GenericDropdownFilter from "app/components/GenericDropdownFilter";
import { LegacyCard, Stack, Text } from "@shopify/polaris";
import LoaderContainer from "app/components/LoaderContainer";
import useGetWithdrawalsAnalytics from "app/hooks/useGetWithdrawalAnalytics";
import { formatMoney } from "app/lib/money";

export default function WithdrawalsAsset() {
  const [value, setValue] = React.useState<string>("BTC");

  function changehandler(selected: string[]) {
    setValue(selected[0]);
  }

  const { withdrawalAnalytics, isLoading, error, refetch } = useGetWithdrawalsAnalytics({
    display_currency: value,
  });

  const withdrawalAssetsOptions = React.useMemo(
    () =>
      withdrawalAnalytics?.balances.map(withdrawal => ({
        label: withdrawal.currency,
        value: withdrawal.currency,
      })),
    [withdrawalAnalytics]
  );

  const amount = withdrawalAnalytics?.total_amount ?? "";
  const currency = withdrawalAnalytics?.display_currency;

  return (
    <LegacyCard
      title={
        isLoading ? (
          ""
        ) : (
          <Stack distribution="equalSpacing" alignment="center">
            <div style={{ position: "relative", borderBottom: "dashed 1px" }}>
              <Text as={"h4"} variant={"headingMd"}>
                Withdrawals asset
              </Text>
            </div>
            <GenericDropdownFilter
              options={withdrawalAssetsOptions!}
              selected={[value]}
              onChange={changehandler}
              size="slim"
            />
          </Stack>
        )
      }
    >
      <LoaderContainer
        small
        loading={isLoading}
        onRetryClicked={refetch}
        error={error}
        spinnerSize="small"
      >
        <LegacyCard.Section>
          <Text variant="headingXl" as="h2">
            {amount ? `${currency} ${formatMoney(amount)}` : ""}
          </Text>
        </LegacyCard.Section>
      </LoaderContainer>
    </LegacyCard>
  );
}
