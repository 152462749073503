import { useQuery } from "@tanstack/react-query";
import AnalyticsService from "app/api/analytics";
import { GetAnalyticsParams } from "app/api/analytics/types";
import parseError from "app/lib/parseError";
import { AxiosError } from "axios";

export default function usePaymentLinkAnalytics(params?: GetAnalyticsParams) {
  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ["paymentLink_analytics", params],
    () => AnalyticsService.getPaymentLinkAnalytics(params)
  );

  return {
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
    isFetching,
    isLoading,
  };
}
