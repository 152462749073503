import { Card, FormLayout, Select, TextField } from "@shopify/polaris";
import { AddBusinessTypePayload } from "app/api/businesses/types";
import Country from "app/models/country";
import { Controller, useFormContext } from "react-hook-form";

interface Props {
  countries: Country[];
}
export default function BusinessSettingsForm(props: Props) {
  const { countries } = props;

  const { control } = useFormContext<AddBusinessTypePayload>();

  const countriesOptions = countries.map(country => ({
    value: country.id,
    label: country.name,
  }));

  return (
    <>
      <Card sectioned>
        <FormLayout>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <TextField
                label="Name"
                onChange={val => field.onChange(val)}
                value={field.value}
                autoComplete="off"
                placeholder="Registered Business"
              />
            )}
          />
        </FormLayout>
      </Card>
      <Card sectioned>
        <FormLayout>
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <TextField
                label="Description"
                onChange={val => field.onChange(val)}
                value={field.value}
                autoComplete="off"
                multiline={2}
                placeholder="This is an example of a typical description."
              />
            )}
          />

          <Controller
            control={control}
            name="country_id"
            render={({ field }) => (
              <Select
                label="Country"
                options={countriesOptions}
                onChange={val => field.onChange(val)}
                value={field.value}
                placeholder="Nigeria"
              />
            )}
          />
        </FormLayout>
      </Card>
    </>
  );
}
