import {
  SkeletonPage,
  Layout,
  Card,
  SkeletonDisplayText,
  SkeletonBodyText,
} from "@shopify/polaris";

export default function SkeletonLoader() {
  return (
    <SkeletonPage title="Profile">
      <Layout>
        <Layout.AnnotatedSection title="Details">
          <Card>
            <Card.Section>
              <SkeletonDisplayText size="small" />
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Password">
          <Card>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </SkeletonPage>
  );
}
