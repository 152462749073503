import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import TransactionsServices from "app/api/transactions";

export default function useGetTransaction(transactionId: string) {
  const { data, isLoading, error, refetch, isFetching } = useQuery(
    ["transaction", transactionId],
    () => TransactionsServices.getTransaction(transactionId)
  );

  return {
    data: data?.data.data,
    isLoading,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
    isFetching,
  };
}
