import { Badge, Loading, Page } from "@shopify/polaris";
import { useParams } from "react-router-dom";

import LoaderContainer from "app/components/LoaderContainer";
import useGoBack from "app/hooks/useGoBack";
import { capitalizeStr, formatDateStr } from "app/utils/helpers";
import useGetBusiness from "../BusinessDetails/hooks/useGetBusiness";
import { mapStatusToBadge } from "app/utils/helpers";
import useGetBusinessOwners from "./hooks/useGetBusinessOwners";
import useListBusinessDocuments from "app/hooks/useListBusinessDocuments";
import OwnersSection from "./components/owners";
import DocumentsSection from "./components/documents";

interface Params {
  businessId: string;
  type: "owners" | "documents";
  [key: string]: string;
}

export default function ComplianceDetails() {
  const goBack = useGoBack();

  const { businessId, type } = useParams<Params>();

  const { business, ...businessQuery } = useGetBusiness(String(businessId));

  const { owners, ...ownersQuery } = useGetBusinessOwners(String(businessId), {
    enabled: type === "owners",
  });

  const { documentsData, ...documentsQuery } = useListBusinessDocuments(
    String(businessId),
    {
      enabled: type === "documents",
    }
  );

  const handleRefetch = () => {
    if (businessQuery.error) {
      businessQuery.refetch();
    }

    if (ownersQuery.error) {
      ownersQuery.refetch();
    }

    if (documentsQuery.error) {
      documentsQuery.refetch();
    }
  };

  return (
    <LoaderContainer
      loading={
        businessQuery.isLoading ||
        // Loading state is true for a disabled query => https://github.com/TanStack/query/issues/3584
        (ownersQuery.isLoading && type === "owners") ||
        (documentsQuery.isLoading && type === "documents")
      }
      error={businessQuery.error || ownersQuery.error || documentsQuery.error}
      onRetryClicked={handleRefetch}
      small
    >
      {(ownersQuery.isFetching || documentsQuery.isFetching) && <Loading />}

      {business && (
        <Page
          breadcrumbs={[{ onAction: goBack }]}
          title={business.name}
          subtitle={formatDateStr(
            String(business.created_at),
            "MMMM D, YYYY [at] hh:mma"
          )}
          titleMetadata={
            <Badge
              progress="complete"
              status={mapStatusToBadge(business.compliance_status)}
            >
              {capitalizeStr(business?.compliance_status)}
            </Badge>
          }
        >
          {type === "owners" && <OwnersSection business={business} owners={owners!} />}

          {type === "documents" && documentsData && (
            <DocumentsSection business={business} documents={documentsData.documents} />
          )}
        </Page>
      )}
    </LoaderContainer>
  );
}
