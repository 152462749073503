import * as React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Frame, Navigation } from "@shopify/polaris";
import styled from "@emotion/styled";
import { css, Global } from "@emotion/react";

import useAuth from "app/hooks/useAuth";
import links, { inHouseLinks, useSettingsLinks } from "./links";
import LoaderContainer from "../LoaderContainer";
import { useProfile } from "app/providers/ProfileProvider";
import useGetProfile from "app/hooks/useGetProfile";
import ToastProvider from "app/providers/ToastProvider";

type MobileNavContextType = React.Dispatch<React.SetStateAction<boolean>>;
export const MobileNavToggleContext = React.createContext<MobileNavContextType>(() => {});
MobileNavToggleContext.displayName = "MobileNavToggleContext";

interface Props {
  topBar: JSX.Element;
}
export default function DashboardWrapper(props: Props) {
  const { topBar } = props;

  const [isMobileNavVisibile, setMobileNavVisible] = React.useState(false);
  const toggleMobileNav = () => setMobileNavVisible(v => !v);

  const isLoggedIn = useAuth();
  const location = useLocation();

  const { isFetching, error, refetch } = useGetProfile();
  const profile = useProfile();

  const settingsLinks = useSettingsLinks();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location.pathname }} />;
  }

  const navigationMarkup = (
    <Navigation location={location.pathname}>
      {links.map((section, i) => (
        <NavSectionWrapper key={i}>
          <Navigation.Section items={section} />
        </NavSectionWrapper>
      ))}

      <NavSectionWrapper>
        <Navigation.Section title="IN HOUSE" items={inHouseLinks[0]} />
      </NavSectionWrapper>

      <SettingsWrapper>
        <NavSectionWrapper>
          <Navigation.Section items={settingsLinks} />
        </NavSectionWrapper>
      </SettingsWrapper>
    </Navigation>
  );

  return (
    <LoaderContainer
      loading={isFetching && !profile}
      error={error || undefined}
      onRetryClicked={refetch}
    >
      <MobileNavToggleContext.Provider value={setMobileNavVisible}>
        <Frame
          logo={{
            topBarSource:
              "https://res.cloudinary.com/dwoc5fknz/image/upload/v1632346575/Logos/busha-wanda-logo.svg",
            url: "/",
            contextualSaveBarSource:
              "https://res.cloudinary.com/dwoc5fknz/image/upload/v1637245529/Logos/busha-wanda-logo-white.svg",
          }}
          topBar={topBar}
          navigation={navigationMarkup}
          showMobileNavigation={isMobileNavVisibile}
          onNavigationDismiss={toggleMobileNav}
        >
          <Global
            styles={css`
              .Polaris-Frame__Main {
                margin-bottom: 64px;
              }

              .Polaris-Page-Header__Row {
                align-items: center;
              }
            `}
          />
          <ToastProvider>
            <Outlet />
          </ToastProvider>
        </Frame>
      </MobileNavToggleContext.Provider>
    </LoaderContainer>
  );
}

const NavSectionWrapper = styled.div`
  .Polaris-Navigation__Section {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .Polaris-Navigation__SectionHeading {
    margin-top: 38px;
  }

  .Polaris-Navigation__Icon {
    margin-right: 16px;
  }
`;

const SettingsWrapper = styled.div`
  position: absolute;
  bottom: 48px;
  width: 100%;
`;
