import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import { useToast } from "app/providers/ToastProvider";
import TeamsService from "app/api/teams";

export default function useSuspendTeamMembers() {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(TeamsService.suspendTeamMembers, {
    onSuccess: res => {
      showToast(res.data.message || "", "success");
      queryClient.invalidateQueries(["teamMembers"]);
    },
    onError: (error: AxiosError<{}>) => {
      const errorMessage = parseError(error) as string;
      showToast(errorMessage || "", "error");
    },
  });

  return {
    isLoading,
    suspendTeamMembers: mutate,
  };
}
