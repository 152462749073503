import axios, { AxiosError } from "axios";

import storage from "app/lib/storage";
import {
  BASE_API_URL,
  SIGN_OUT_PATH,
  TOKEN_STORAGE_KEY,
} from "app/constants/variables";
import history from "app/lib/history";

const request = axios.create({
  baseURL: BASE_API_URL,
});

request.interceptors.request.use((config) => {
  const { access_token } = storage.get(TOKEN_STORAGE_KEY) || {};

  const newConfig = { ...config };

  if (!newConfig.headers) return newConfig;

  const requiresNoToken = newConfig.headers["noToken"];
  delete newConfig.headers["noToken"];

  if (!access_token || requiresNoToken) return newConfig;

  newConfig.headers = {
    ...newConfig.headers,
    Authorization: `Bearer ${access_token}`,
  };

  return newConfig;
});

// Add a response interceptor
request.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error: AxiosError) {
    const { access_token } = storage.get(TOKEN_STORAGE_KEY) || {};

    // if user's token has expired or has been blacklisted
    if (error.response?.status === 401 && access_token) {
      // log user out
      history.push(`/${SIGN_OUT_PATH}`, { from: window.location.pathname });
    }

    return Promise.reject(error);
  }
);

export default request;
