import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import BusinessesService from "app/api/businesses";

interface Options {
  enabled: boolean;
}
export default function useListBusinessDocuments(businessId: string, options?: Options) {
  const { enabled = true } = options ?? {};

  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["business_documents", businessId],
    () => BusinessesService.listBusinessDocuments(businessId),
    {
      enabled,
    }
  );

  return {
    isLoading,
    isFetching,
    documentsData: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
  };
}
