import * as React from "react";
import {
  AppliedFilterInterface,
  Card,
  ChoiceList,
  FilterInterface,
  Filters,
  Select,
  Stack,
} from "@shopify/polaris";

import SearchFilter from "app/components/SearchFilter";
import { GetWithdrawalsQuery } from "app/api/withdrawals/types";
import GenericDate from "app/components/GenericDate";

const searchOptions = [
  {
    label: "Reference",
    value: "q",
  },
  {
    label: "Business",
    value: "business_name",
  },
];

interface Props {
  query: GetWithdrawalsQuery;
  withdrawalOnDisplay: number;
  setQuery: (q: GetWithdrawalsQuery) => void;
}

export default function WithdrawalsFilter(props: Props) {
  const { query, withdrawalOnDisplay, setQuery } = props;

  const [queryString, setQueryString] = React.useState("");

  const filters: FilterInterface[] = [
    {
      label: "Status",
      key: "status",
      filter: (
        <ChoiceList
          title="Status"
          name="status"
          titleHidden
          choices={[
            { label: "Completed", value: "completed" },
            { label: "Pending", value: "pending" },
          ]}
          selected={[query.status || ""]}
          onChange={t => setQuery({ status: t[0] })}
        />
      ),
      shortcut: true,
    },
    {
      label: "Type",
      key: "type",
      filter: (
        <ChoiceList
          title="Type"
          name="type"
          titleHidden
          choices={[
            { label: "Busha", value: "busha" },
            { label: "External", value: "external" },
          ]}
          selected={[query.type || ""]}
          onChange={t => setQuery({ type: t[0] })}
        />
      ),
      shortcut: true,
    },
  ];

  const removeFilter = (filter: keyof GetWithdrawalsQuery) => {
    setQuery({ ...query, [filter]: undefined });
  };

  const appliedFilters: AppliedFilterInterface[] = Object.keys(query)
    .filter(
      key =>
        ["type", "status"].includes(key) &&
        query[key as keyof GetWithdrawalsQuery] !== undefined
    )
    .map(key => {
      return {
        key,
        label: `${key}: ${query[key as keyof GetWithdrawalsQuery]}`,
        onRemove: removeFilter,
      };
    });

  return (
    <>
      <Card.Section>
        <Stack spacing="tight">
          <Stack.Item fill>
            <SearchFilter options={searchOptions} />
          </Stack.Item>

          <Filters
            filters={filters}
            appliedFilters={appliedFilters}
            queryValue={queryString}
            onQueryChange={setQueryString}
            onQueryClear={() => setQueryString("")}
            onClearAll={() => {
              setQuery({
                q: undefined,
                sort: undefined,
                page: 1,
                limit: undefined,
              });
            }}
            hideQueryField
            hideTags
          />
        </Stack>
      </Card.Section>

      <Card.Section>
        <Stack distribution="equalSpacing" alignment="center">
          <Stack spacing="extraLoose" alignment="center">
            <GenericDate
              onDateChange={value => {
                setQuery({ date: value });
              }}
              onReset={() => setQuery({ date: undefined })}
              value={query.date}
            />

            <p>
              Showing {withdrawalOnDisplay} Withdrawal
              {Number(withdrawalOnDisplay) > 1 ? "s" : ""}{" "}
            </p>
          </Stack>
          <Select
            labelInline
            label="Sort by"
            name="sort"
            value={query.sort}
            options={[
              { label: "Newest", value: "desc" },
              { label: "Oldest", value: "asc" },
            ]}
            onChange={(s: "asc" | "desc") => {
              setQuery({ sort: s });
            }}
          />
        </Stack>
      </Card.Section>
    </>
  );
}
