import styled from "@emotion/styled";
import { Badge, IndexTable, Link } from "@shopify/polaris";
import { formatMoney } from "app/lib/money";

import Transaction from "app/models/transaction";
import { formatDateStr } from "app/utils/helpers";

interface Props {
  transactions: Transaction[];
}

export default function TransactionsList(props: Props) {
  const { transactions } = props;

  return (
    <>
      {transactions.map((transaction, i) => {
        return (
          <IndexTable.Row id={transaction.id} key={transaction.id} position={i}>
            <IndexTable.Cell>
              <CellPadding>
                <Link
                  removeUnderline
                  url={`/payment-link/transactions/${transaction.id}`}
                >
                  {transaction.reference}
                </Link>
              </CellPadding>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <CellPadding>
                {formatDateStr(transaction.created_at, "MMMM D, YYYY [at] hh:mma")}
              </CellPadding>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Badge status="success">Enabled</Badge>
            </IndexTable.Cell>
            <IndexTable.Cell>{formatMoney(400, "fiat", "USD")}</IndexTable.Cell>
          </IndexTable.Row>
        );
      })}
    </>
  );
}

const CellPadding = styled.div`
  padding: 13px 0px;
`;
