import { Avatar, Card, Inline, Link, Stack, Text } from "@shopify/polaris";
import PaymentLink from "app/models/paymentLink";
import useGetMerchant from "app/hooks/useGetMerchant";

interface Props {
  paymentLink: PaymentLink;
}

export default function BusinessDetails(props: Props) {
  const { paymentLink } = props;

  const { merchant } = useGetMerchant(paymentLink.user_id);

  return (
    <>
      <Card>
        <Card.Section title="Donation description">
          <Text as="p" variant="bodyMd" breakWord>
            {paymentLink.description}
          </Text>
        </Card.Section>
      </Card>

      <Card>
        <Card.Section title="Business">
          <Link removeUnderline url={`/businesses/${paymentLink.business_id}`}>
            <Text as="p" variant="bodyMd" breakWord>
              {paymentLink.business_name}
            </Text>
          </Link>
        </Card.Section>
      </Card>

      <Card>
        <Card.Section
          title={
            <Inline align="space-between">
              <Text as="p" variant="bodyMd">
                Merchant
              </Text>
              <Avatar
                initials={`${merchant?.profile.first_name[0]}${merchant?.profile.last_name[0]}`}
                size="small"
              />
            </Inline>
          }
        >
          <Stack vertical>
            <Link removeUnderline url={`/merchants/${paymentLink.user_id}`}>
              <Text as="p" variant="bodyMd" breakWord>
                {merchant?.profile.first_name} {merchant?.profile.last_name}
              </Text>
            </Link>
          </Stack>
        </Card.Section>
      </Card>
    </>
  );
}
