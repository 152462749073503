import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import AccountsService from "app/api/accounts";

export default function useGetAccounts(businessId: string) {
  const { isLoading, data, error, refetch, isFetching } = useQuery(["accounts"], () =>
    AccountsService.getAccounts(businessId)
  );

  return {
    isLoading,
    isFetching,
    accounts: data?.data.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch: refetch,
  };
}
