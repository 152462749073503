import { Badge, Button, Page } from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";

import LoaderContainer from "app/components/LoaderContainer";

import Details from "./components/Details";
import {
  capitalizeStr,
  formatDateStr,
  mapMerchantStatusToBadge,
} from "app/utils/helpers";
import useModalState from "app/hooks/useModalState";
import useGetMerchant from "app/hooks/useGetMerchant";
import ToggleStatusModal from "app/components/ToggleStatusModal";
import useEnableBusinesses from "./hooks/useEnableBusinesses";
import useDisableBusinesses from "./hooks/useDisableBusinesses";
import HaltDeletionModal from "./components/HaltDeletionModal";

export default function MerchantsDetails() {
  const navigate = useNavigate();

  const goBack = () => navigate("/merchants");

  const { merchantId } = useParams<{ merchantId: string }>();

  const params = useParams<{ merchantId: string }>();
  const { merchant, isLoading, isFetching, refetch, error } = useGetMerchant(
    params.merchantId ?? ""
  );
  const { enableBusinesses, isEnablingBusinesses } = useEnableBusinesses(
    merchantId ?? ""
  );
  const { disableBusinesses, isDisablingBusinesses } = useDisableBusinesses(
    merchantId ?? ""
  );

  const mockBoolean = true;

  const {
    isOpen: isDisableWithdrawalOpen,
    openModal: openWithdrawalModal,
    closeModal: closeWithdrawalModal,
  } = useModalState(false);

  const isWithdrawalsEnabled = false;

  const {
    isOpen: isDisablePaymentOpen,
    openModal: openPaymentModal,
    closeModal: closePaymentModal,
  } = useModalState(false);

  const isPaymentEnabled = false;

  const {
    isOpen: isDisableBusinessesOpen,
    openModal: openBusinessesModal,
    closeModal: closeBusinessesModal,
  } = useModalState(false);

  const isBusinessesEnabled = false;

  const {
    isOpen: isDisableMerchantOpen,
    openModal: openMerchantModal,
    closeModal: closeMerchantModal,
  } = useModalState(false);

  const {
    isOpen: isHaltDeletionModalOpen,
    closeModal: closeHaltDeletionModal,
    openModal: openHaltDeletionModal,
  } = useModalState();

  const isMerchantEnabled = !!merchant?.active;

  return (
    <>
      <Page
        breadcrumbs={[{ onAction: goBack }]}
        title={
          isLoading
            ? "..."
            : `${merchant?.profile.first_name} ${merchant?.profile.last_name}`
        }
        subtitle={
          isLoading
            ? "..."
            : formatDateStr(String(merchant?.created_at), "MMMM D, YYYY [at] hh:mma")
        }
        titleMetadata={
          isLoading || isFetching ? (
            "..."
          ) : (
            <Badge status={mapMerchantStatusToBadge(merchant?.status!)}>
              {capitalizeStr(merchant?.status.split("_").join(" ")!)}
            </Badge>
          )
        }
        primaryAction={
          <div style={{ display: "flex", gap: 4 }}>
            {merchant?.status === "pending_deletion" && (
              <Button onClick={openHaltDeletionModal}>Halt Deletion</Button>
            )}

            <Button
              disabled
              destructive={isMerchantEnabled}
              outline
              onClick={openMerchantModal}
            >
              {isMerchantEnabled ? "Deactivate Merchant" : "Activate Merchant"}
            </Button>
          </div>
        }
        actionGroups={[
          {
            disabled: true,
            title: "More Actions",
            actions: [
              {
                onAction: openBusinessesModal,
                content: isBusinessesEnabled ? "Disable Businesses" : "Enable Businesses",
                accessibilityLabel: isBusinessesEnabled
                  ? "Disable Businesses"
                  : "Enable Businesses",
              },
              {
                onAction: openPaymentModal,
                content: mockBoolean ? "Disable Payments" : "Enable Payments",
                accessibilityLabel: mockBoolean ? "Disable Payments" : "Enable Payments",
              },
              {
                onAction: openWithdrawalModal,
                content: mockBoolean ? "Disable Withdrawals" : "Enable  Withdrawals",
                accessibilityLabel: mockBoolean
                  ? "Disable Withdrawals"
                  : "Enable  Withdrawals",
              },
            ],
          },
        ]}
      >
        <LoaderContainer
          loading={isLoading || isFetching}
          error={error}
          onRetryClicked={refetch}
        >
          <Details merchant={merchant!} />
        </LoaderContainer>

        <ToggleStatusModal
          title={isWithdrawalsEnabled ? "Disable Withdrawals" : "Enable Withdrawals"}
          open={isDisableWithdrawalOpen}
          onClose={closeWithdrawalModal}
          destructive={isWithdrawalsEnabled}
          loading={isWithdrawalsEnabled}
          onConfirm={() => null}
          confirmText={isWithdrawalsEnabled ? "Disable" : "Enable"}
          text={
            isWithdrawalsEnabled
              ? `Disabling withdrawals will deactivate withdrawals for all merchant’s businesses.`
              : `Enabling withdrawals will activate withdrawals for all merchant’s businesses.`
          }
        />

        <ToggleStatusModal
          destructive={isPaymentEnabled}
          open={isDisablePaymentOpen}
          title={isPaymentEnabled ? "Disable Payments" : "Enable Payments"}
          onClose={closePaymentModal}
          loading={isPaymentEnabled}
          onConfirm={() => null}
          confirmText={isPaymentEnabled ? "Disable" : "Enable"}
          text={
            isPaymentEnabled
              ? "Disabling payments will deactivate payments for this business"
              : "Enabling payments will activate payments for this business"
          }
        />

        <ToggleStatusModal
          destructive={isBusinessesEnabled}
          open={isDisableBusinessesOpen}
          title={isBusinessesEnabled ? "Disable Businesses" : "Enable Businesses"}
          onClose={closeBusinessesModal}
          loading={isEnablingBusinesses || isDisablingBusinesses}
          onConfirm={isBusinessesEnabled ? disableBusinesses : enableBusinesses}
          confirmText={isBusinessesEnabled ? "Disable" : "Enable"}
          text={
            isBusinessesEnabled
              ? "Disabling businesses will deactivate payments and withdrawals for all merchant’s businesses"
              : "Enabling businesses will activate payments and withdrawals for all merchant’s businesses"
          }
        />

        <ToggleStatusModal
          destructive={isMerchantEnabled}
          open={isDisableMerchantOpen}
          title={isMerchantEnabled ? "Deactivate Merchant" : "Activate Merchant"}
          onClose={closeMerchantModal}
          loading={isMerchantEnabled}
          onConfirm={() => null}
          confirmText={isMerchantEnabled ? "Deactivate" : "Activate"}
          text={
            isMerchantEnabled
              ? "Deactivating this merchant will deactivate payments and withdrawals for all merchant’s businesses"
              : "Activating this merchant will activate payments and withdrawals for all merchant’s businesses"
          }
        />

        <HaltDeletionModal
          open={isHaltDeletionModalOpen}
          onClose={closeHaltDeletionModal}
        />
      </Page>
    </>
  );
}
