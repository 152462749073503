import { useParams } from "react-router-dom";
import { Loading, Page } from "@shopify/polaris";

import LoaderContainer from "app/components/LoaderContainer";
import useGoBack from "app/hooks/useGoBack";
import useGetPayment from "./hooks/useGetPayment";
import Details from "./components/Details";
import { formatMoney } from "app/lib/money";
import useCompletePayment from "./hooks/useCompletePayment";

export default function PaymentsDetails() {
  const goBack = useGoBack();
  const { id } = useParams();

  const { isLoading, isFetching, data, error, refetch } = useGetPayment(String(id));

  const { completePayment, completingPayment } = useCompletePayment(id!);

  const isQueryResolved = data && !isLoading && !error;

  return (
    <>
      {isFetching && <Loading />}

      <Page
        breadcrumbs={[{ onAction: goBack }]}
        title={isQueryResolved ? data.reference : "..."}
        subtitle={
          isQueryResolved
            ? formatMoney(data.local_amount, "", data.local_currency)
            : "..."
        }
        primaryAction={{
          content: "Complete payment",
          loading: completingPayment,
          onAction: completePayment,
        }}
      >
        <LoaderContainer loading={isLoading} onRetryClicked={refetch} error={error}>
          {data && <Details payment={data} />}
        </LoaderContainer>
      </Page>
    </>
  );
}
