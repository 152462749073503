import * as React from "react";
import { ActionList, IconSource, TopBar } from "@shopify/polaris";
import { ProfileMinor, LogOutMinor } from "@shopify/polaris-icons";
import { MobileNavToggleContext } from "./DashboardWrapper";
import { useProfile } from "app/providers/ProfileProvider";
import { UserMenuProps } from "@shopify/polaris/build/ts/latest/src/components/TopBar";

const userMenuActions: UserMenuProps["actions"] = [
  {
    items: [
      {
        content: "Profile",
        icon: ProfileMinor as IconSource,
        url: "/profile",
      },
      {
        content: "Log out",
        icon: LogOutMinor as IconSource,
        url: "/signout",
      },
    ],
  },
];

export default function DashboardTopBar() {
  const [searchValue, setSearchValue] = React.useState("");
  const handleSearchFieldChange = (value: string) => {
    setSearchValue(value);
  };
  const [userMenuActive, setUserMenuActive] = React.useState(false);
  const toggleUserMenuActive = () => setUserMenuActive(userMenuActive => !userMenuActive);

  const setMobileNavVisible = React.useContext(MobileNavToggleContext);
  const toggleMobileNav = () => setMobileNavVisible(v => !v);

  const profile = useProfile();

  if (!profile) return null;

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={`${profile.first_name} ${profile.last_name}`}
      initials={`${profile.first_name[0]}${profile.last_name[0]}`}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  );

  const searchResultsMarkup = <ActionList items={[]} />;

  const searchFieldMarkup = (
    <TopBar.SearchField
      onChange={handleSearchFieldChange}
      value={searchValue}
      placeholder="Search"
    />
  );

  return (
    <TopBar
      showNavigationToggle
      searchField={searchFieldMarkup}
      searchResults={searchResultsMarkup}
      userMenu={userMenuMarkup}
      onNavigationToggle={toggleMobileNav}
    />
  );
}
