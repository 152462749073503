import * as React from "react";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  IndexTable,
  Page,
  Pagination,
  Select,
  Stack,
} from "@shopify/polaris";
import useGoBack from "app/hooks/useGoBack";
import TeamMembersFilter from "./TeamMemberfilter";
import { CellPadding, TableWrapper } from "app/styles";
import useGetTeamMembers from "../hooks/useGetTeamMembers";
import { useParams } from "react-router-dom";
import LoaderContainer from "app/components/LoaderContainer";
import useModalState from "app/hooks/useModalState";
import ToggleStatusModal from "app/components/ToggleStatusModal";
import useSuspendTeamMembers from "../hooks/useSuspendTeamMembers";
import useRemoveTeamMembers from "../hooks/useRemoveTeamMembers";
import { mapTeamStatus } from "../utils";
import useQueryParams from "app/hooks/useQueryParams";
import { GetTeamsQuery } from "app/api/teams/types";
import EditRoleModal from "./EditRoleModal";
import { capitalizeStr, formatDateStr } from "app/utils/helpers";

export default function TeamMembersDetails() {
  const { queryParams, updateQueryParams } = useQueryParams<GetTeamsQuery>({
    sort: "desc",
    limit: 50,
  });

  const goBack = useGoBack();

  const {
    isOpen: isSuspendOpen,
    openModal: openSuspendModal,
    closeModal: closeSuspendModal,
  } = useModalState(false);

  const {
    isOpen: isRemoveMemberOpen,
    openModal: openRemoveMemberModal,
    closeModal: closeRemoveMemberModal,
  } = useModalState(false);

  const {
    isOpen: isOpenEditRoleModal,
    openModal: openEditRoleModal,
    closeModal: closeEditRoleModal,
  } = useModalState(false);

  const { businessId } = useParams();
  const { data, isFetching, isLoading, error, refetch } = useGetTeamMembers(
    String(businessId),
    queryParams
  );

  const teamsData = data?.data;

  const { isLoading: suspendTeamMemberLoading, suspendTeamMembers } =
    useSuspendTeamMembers();

  const { isLoading: removeTeamMemberLoading, removeTeamMember } = useRemoveTeamMembers();

  // ⚙️ Only checkbox with same status can be selected at a given instance.

  const [checkedState, setCheckedState] = React.useState<{ [key: string]: boolean }>({});
  const [selectedStatus, setSelectedStatus] = React.useState<string>("");

  const handleChange = (id: string, status: string) => {
    if (id in checkedState) {
      let newCheckedState = { ...checkedState };
      delete newCheckedState[id];
      setCheckedState({ ...newCheckedState });
      setSelectedStatus(Object.keys(newCheckedState).length > 0 ? status : "");
    } else {
      let updatedCheckedState = { [id]: true };
      setCheckedState(prev => ({ ...prev, ...updatedCheckedState }));
      setSelectedStatus(status);
    }
  };

  const isSuspended = selectedStatus === "suspended";
  const isDisabled = Object.keys(checkedState).length > 0;

  const reset = () => {
    closeRemoveMemberModal();
    closeSuspendModal();
    setCheckedState({});
    setSelectedStatus("");
  };

  const handleSuspendTeamMember = () => {
    suspendTeamMembers(
      {
        businessId: String(businessId),
        payload: {
          member_ids: Object.keys(checkedState),
          suspend: !isSuspended,
        },
      },
      {
        onSuccess: reset,
      }
    );
  };

  const handleRemoveTeamMember = () => {
    removeTeamMember(
      {
        businessId: String(businessId),
        member_ids: Object.keys(checkedState),
      },
      {
        onSuccess: reset,
      }
    );
  };

  return (
    <Page
      breadcrumbs={[{ onAction: goBack }]}
      title="Team Members"
      primaryAction={
        <Stack>
          <Button onClick={openSuspendModal} disabled={!isDisabled} primary={isSuspended}>
            {isSuspended ? "Reactivate" : "Suspend"}
          </Button>
        </Stack>
      }
      actionGroups={[
        {
          title: "More Actions",
          disabled: false,
          actions: [
            {
              content: "Edit Roles",
              accessibilityLabel: "Edit Roles",
              onAction: openEditRoleModal,
              disabled: !isDisabled,
            },
            {
              content: "Remove Members",
              accessibilityLabel: "Remove Members",
              onAction: openRemoveMemberModal,
              disabled: !isDisabled,
            },
          ],
        },
      ]}
    >
      <Card>
        <Card.Section>
          <TeamMembersFilter query={queryParams} setQuery={updateQueryParams} />
        </Card.Section>

        <Card.Section>
          <Stack distribution="equalSpacing" alignment="center">
            <Stack spacing="extraLoose" alignment="center">
              {isLoading ? (
                <p>Loading Team members</p>
              ) : (
                <p>Showing {data?.data.length} Team members</p>
              )}
            </Stack>

            <Select
              labelInline
              label="Sort by"
              name="sort"
              value={queryParams.sort}
              options={[
                { label: "Newest", value: "desc" },
                { label: "Oldest", value: "asc" },
              ]}
              onChange={s => updateQueryParams("sort", s)}
            />
          </Stack>
        </Card.Section>

        <LoaderContainer
          style={{ minHeight: "unset" }}
          loading={isLoading || isFetching}
          error={error}
          onRetryClicked={refetch}
        >
          <Card.Section flush>
            <TableWrapper>
              <IndexTable
                headings={[
                  { title: "Name" },
                  { title: "Email" },
                  { title: "Role" },
                  { title: "Status" },
                  { title: "Date Added" },
                ]}
                itemCount={teamsData?.length || 0}
                selectable={false}
              >
                {teamsData &&
                  teamsData.map((team, index) => {
                    return (
                      <IndexTable.Row key={index} id={index.toString()} position={index}>
                        <IndexTable.Cell>
                          {team.role_name === "owner" ? (
                            team.name
                          ) : (
                            <Checkbox
                              disabled={
                                selectedStatus === ""
                                  ? false
                                  : selectedStatus === team.status
                                  ? false
                                  : true
                              }
                              label={team.name}
                              id={`tolu-checkbox-${index}`}
                              checked={checkedState[team.id]}
                              onChange={() => handleChange(team.id, team.status)}
                            />
                          )}
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <CellPadding>{team.email}</CellPadding>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <CellPadding>{capitalizeStr(team.role_name)}</CellPadding>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <Badge status={mapTeamStatus(team.status)}>
                            {capitalizeStr(team.status)}
                          </Badge>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          {formatDateStr(team.created_at, "MMMM D, YYYY [at] hh:mma")}
                        </IndexTable.Cell>
                      </IndexTable.Row>
                    );
                  })}
              </IndexTable>
            </TableWrapper>
          </Card.Section>
        </LoaderContainer>

        <Card.Section>
          <Stack distribution="center">
            <Pagination label={"1/1"} />
          </Stack>
        </Card.Section>
      </Card>

      <EditRoleModal
        open={isOpenEditRoleModal}
        onClose={closeEditRoleModal}
        businessId={String(businessId)}
        member_ids={Object.keys(checkedState)}
        reset={reset}
      />

      <ToggleStatusModal
        title={isSuspended ? "Activate Team Member(s)?" : "Suspend Team Member(s)?"}
        open={isSuspendOpen}
        onClose={closeSuspendModal}
        loading={suspendTeamMemberLoading}
        onConfirm={handleSuspendTeamMember}
        destructive={!isSuspended}
        confirmText={isSuspended ? "Activate" : "Suspend"}
        text={`You are about to ${
          isSuspended ? "activate" : "suspend"
        } the selected  Member(s). Do you want to continue?`}
      />

      <ToggleStatusModal
        title="Remove Team Member(s)?"
        open={isRemoveMemberOpen}
        onClose={closeRemoveMemberModal}
        loading={removeTeamMemberLoading}
        onConfirm={handleRemoveTeamMember}
        destructive={true}
        confirmText="Remove"
        text="You are about to remove the selected Member(s). Do you want to continue?"
      />
    </Page>
  );
}
