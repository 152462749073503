import request from "../request";
import { PaymentLinkType, GetAnalyticsParams, GetAnalyticsResponse } from "./types";

const getPaymentAnalytics = async (analyticsParams?: GetAnalyticsParams) => {
  return request.get<GetAnalyticsResponse>(`/v1/businesses/analytics/payments`, {
    params: analyticsParams,
  });
};

const getWithdrawalAnalytics = async (analyticsParams?: GetAnalyticsParams) => {
  return request.get<GetAnalyticsResponse>(`/v1/businesses/analytics/withdrawals`, {
    params: analyticsParams,
  });
};

const getPaymentLinkAnalytics = async (analyticsParams?: GetAnalyticsParams) => {
  return request.get<GetAnalyticsResponse>(`/v1/businesses/analytics/payment_links`, {
    params: analyticsParams,
  });
};

const getInvoiceAnalytics = async (analyticsParams?: GetAnalyticsParams) => {
  return request.get<GetAnalyticsResponse>(`/v1/businesses/analytics/invoices`, {
    params: analyticsParams,
  });
};

const getPaymentLinkAnalyticsInfo = async (type: PaymentLinkType) => {
  return request.get<GetAnalyticsResponse>(
    `/v1/businesses/analytics/payment_links?type=${type}`
  );
};

const AnalyticsService = {
  getPaymentAnalytics,
  getWithdrawalAnalytics,
  getPaymentLinkAnalytics,
  getInvoiceAnalytics,
  getPaymentLinkAnalyticsInfo,
};

export default AnalyticsService;
