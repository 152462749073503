import request from "../request";
import {
  GenericPaymentAccountPayload,
  GetPaymentAccountResponse,
  ListPaymentAccountsResponse,
} from "./type";

const ListPaymentAccounts = (businessId: string) => {
  return request.get<ListPaymentAccountsResponse>(
    `/v1/businesses/${businessId}/payment_accounts`
  );
};

const setPrimaryPaymentAccount = ({
  businessId,
  paymentAccountId,
}: GenericPaymentAccountPayload) => {
  return request.post(
    `/v1/businesses/${businessId}/payment_accounts/${paymentAccountId}/default`
  );
};

const getPaymentAccountDetails = (accountId: string) => {
  return request.get<GetPaymentAccountResponse>(`/v1/payment_account/${accountId}`);
};

const removePaymentAccount = ({
  businessId,
  paymentAccountId,
}: GenericPaymentAccountPayload) => {
  return request.delete(
    `/v1/businesses/${businessId}/payment_accounts/${paymentAccountId}`
  );
};

const PaymentAccountService = {
  ListPaymentAccounts,
  setPrimaryPaymentAccount,
  removePaymentAccount,
  getPaymentAccountDetails,
};

export default PaymentAccountService;
