import * as React from "react";
import { LegacyCard, Link, Stack, Text } from "@shopify/polaris";
import LoaderContainer from "app/components/LoaderContainer";

interface Props {
  viewLink?: string;
  actions?: React.ReactNode;
  title: string;
  amount?: React.ReactNode;
  dropdown?: React.ReactNode;
  loading?: boolean;
  refetch?: () => void;
  error?: string;
}

export default function AnalyticsCard(props: Props) {
  const { viewLink, title, actions, amount, loading, refetch, error, dropdown } = props;
  return (
    <LegacyCard
      title={
        loading ? (
          ""
        ) : (
          <Stack distribution="equalSpacing" alignment="center">
            <div style={{ position: "relative", borderBottom: "dashed 1px" }}>
              <Text as={"h4"} variant={"headingMd"}>
                {title}
              </Text>
            </div>

            {viewLink && (
              <Link url={viewLink} removeUnderline>
                View
              </Link>
            )}
            {dropdown && dropdown}
          </Stack>
        )
      }
    >
      <LoaderContainer
        small
        loading={loading}
        onRetryClicked={refetch}
        error={error}
        spinnerSize="small"
      >
        <LegacyCard.Section>
          <Text variant="headingXl" as="h2">
            {amount ?? "4,678"}
          </Text>
          {actions}
        </LegacyCard.Section>
      </LoaderContainer>
    </LegacyCard>
  );
}
