import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import ComplianceService from "app/api/compliance";

interface Options {
  enabled: boolean;
}
export default function useGetBusinessOwners(businessId: string, options?: Options) {
  const { enabled = true } = options ?? {};

  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["business_owners", businessId],
    () => ComplianceService.getBusinessOwners(businessId),
    {
      enabled,
    }
  );

  return {
    owners: data?.data.data,
    isLoading,
    isFetching,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
  };
}
