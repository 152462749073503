import React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

import UserService from "app/api/users";
import parseError from "app/lib/parseError";
import { AxiosError } from "axios";
import AlertMessage from "app/models/alertMessage";
import { AcceptUserInvitePayload } from "app/api/users/types";

export default function useInvite() {
  const { token } = useParams<{ token?: string }>();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!token) {
      navigate("/login", { replace: true });
    }
  }, [token, navigate]);

  const {
    isLoading,
    data,
    error: reqError,
    refetch,
  } = useQuery(["getStaffInvite"], () =>
    UserService.getUserInvite({ jwt: token || "" })
  );

  const {
    isLoading: isAccepting,
    error,
    mutate,
  } = useMutation(UserService.acceptUserInvite);

  const onSubmit = (v: AcceptUserInvitePayload) => {
    if (token)
      mutate(
        { payload: v, token },
        // { payload: { ...v, calling_code: 234, phone: "08141543654" }, token },
        {
          onSuccess: (res) => {
            const successAlert: AlertMessage = {
              variant: "success",
              message: res.data.message,
            };
            navigate(
              {
                pathname: "/login",
              },
              { state: { alert: successAlert }, replace: true }
            );
          },
        }
      );
  };

  return {
    isRequesting: isLoading,
    invite: data ? data.data : undefined,
    requestError: reqError
      ? (parseError(reqError as AxiosError<{}>) as string)
      : undefined,
    refetch,
    //
    isAccepting,
    acceptanceError: error
      ? (parseError(error as AxiosError<{}>) as string)
      : null,
    onSubmit,
  };
}
