import * as React from "react";
import styled from "@emotion/styled";
import { Card, Frame, Loading, Page, Stack } from "@shopify/polaris";

import LoaderContainer from "app/components/LoaderContainer";
import PermissionsFilter from "./components/PermissionsFilter";
import PermissionsList from "./components/PermissionsList";
import useRolePermissions from "./hooks/useRolePermissions";
import { Permission } from "./utils";

export default function RoleManagement() {
  const [query, setQuery] = React.useState<string>("");
  const [data, setData] = React.useState<Permission[]>();

  const { permissions, roles, categories, isLoading, error, isFetching, refetch } =
    useRolePermissions();

  React.useEffect(() => {
    if (query === "") setData(permissions);
    if (query && permissions) {
      setData(() => {
        const filteredData = permissions.filter(item => item.id.includes(query));
        return filteredData;
      });
    }
  }, [query, permissions]);

  return (
    <Wrapper>
      <Frame>
        {isFetching && <Loading />}

        <Page title="Role Management" subtitle="Manage Teams’ Roles">
          <Card>
            <Card.Section>
              <Stack distribution="trailing">
                {categories && (
                  <PermissionsFilter
                    setQuery={setQuery}
                    query={query}
                    categories={categories}
                  />
                )}
              </Stack>
            </Card.Section>

            <LoaderContainer loading={isLoading} error={error} onRetryClicked={refetch}>
              {data && roles && <PermissionsList permissions={data} roles={roles} />}
            </LoaderContainer>
          </Card>
        </Page>
      </Frame>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .Polaris-Frame__Main,
  .Polaris-Frame {
    background: #fff;
  }
`;
