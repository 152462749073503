import AuthPageWrapper from "app/components/AuthPageWrapper";

import useLogin from "./hooks/useLogin";
import LoginForm from "./components/LoginForm";
import TwoFAForm from "./components/TwoFAForm";
import usePageAlert from "app/hooks/usePageAlert";
import { Banner, Stack } from "@shopify/polaris";

export default function Login() {
  const {
    activeView,
    handleLoginSubmit,
    loginPending,
    loginError,
    loginMessage,
    handleLoginConfirmationSubmit,
    loginConfirmationPending,
    loginConfirmationError,
    goBack,
    handleResendConfirmationCode,
    resendConfirmationPending,
    resendConfirmationMessage,
    resendConfirmationError,
  } = useLogin();

  const { alert, closeAlert } = usePageAlert();

  return (
    <AuthPageWrapper>
      <Stack vertical>
        {alert && (
          <Banner onDismiss={closeAlert} status={alert.variant}>
            <p>{alert.message}</p>
          </Banner>
        )}

        {activeView === "login" && (
          <LoginForm
            onSubmit={handleLoginSubmit}
            loading={loginPending}
            error={loginError}
          />
        )}

        {activeView === "twoFA" && loginMessage && (
          <TwoFAForm
            loginMessage={loginMessage}
            onSubmit={handleLoginConfirmationSubmit}
            loading={loginConfirmationPending}
            error={loginConfirmationError}
            onGoBack={goBack}
            handleResendConfirmationCode={handleResendConfirmationCode}
            resendConfirmationPending={resendConfirmationPending}
            resendConfirmationMessage={resendConfirmationMessage}
            resendConfirmationError={resendConfirmationError}
          />
        )}
      </Stack>
    </AuthPageWrapper>
  );
}
