/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Spinner } from "@shopify/polaris";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { TokenActions, useAuthTokensDispatch } from "app/providers/TokenProvider";
import atlasActions from "app/constants/atlasAction";
import { ATLAS_URL, SIGN_OUT_PATH } from "app/constants/variables";

export default function Atlas() {
  const navigate = useNavigate();

  const dispatch = useAuthTokensDispatch();

  useEffect(() => {
    const hasAtlasWrapper = window !== window.parent;

    if (!hasAtlasWrapper) return;

    window.parent.postMessage(
      { action: atlasActions.AUTHENTICATE },
      { targetOrigin: ATLAS_URL }
    );

    const onMessage = (
      e: MessageEvent<{ action: atlasActions; data?: { access_token: string } }>
    ) => {
      if (e.origin !== ATLAS_URL) return;

      switch (e.data.action) {
        case atlasActions.REAUTHENTICATE:
          navigate(`/${SIGN_OUT_PATH}`);
          break;

        case atlasActions.LOGIN:
          const token = e.data.data?.access_token;
          dispatch({
            type: TokenActions.setTokens,
            payload: { access_token: token },
          });

          navigate("/");
          break;

        default:
          break;
      }
    };

    window.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [navigate, dispatch]);

  return (
    <div
      css={css`
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <Spinner />
    </div>
  );
}
