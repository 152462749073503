import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import UserService from "app/api/users";

export default function useGetUser(id: string) {
  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["user", id],
    () => UserService.getUser(id)
  );

  return {
    isLoading,
    isFetching,
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
  };
}
