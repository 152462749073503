import * as React from "react";
import PayoutsService from "app/api/withdrawals";
import { UpdateWithdrawalPayload } from "app/api/withdrawals/types";
import { BaseResponse } from "app/api/types";
import { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router";
import { ToastContext } from "app/providers/ToastProvider";
import parseError from "app/lib/parseError";

const useUpdateGrootPayoutStatus = () => {
  const params = useParams<{ id: string }>();

  const showToast = React.useContext(ToastContext);
  const query = useQueryClient();

  const handleSuccess = (res: AxiosResponse<BaseResponse>) => {
    query.invalidateQueries(["withdrawal", params.id]);
    showToast(res.data.message || "", "success");
  };

  const handleError = (err: AxiosError<{}>) => {
    const errorMessage = parseError(err);
    showToast(errorMessage as string, "error");
  };

  const {
    isLoading: isUpdating,
    mutate: updatePayoutStatus,
    variables: payloadVariables,
  } = useMutation(
    (payload: UpdateWithdrawalPayload) =>
      PayoutsService.updateWithdrawalsStatus(params.id ?? "", payload),
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );

  const { isLoading: isRetrying, mutate: retryPayout } = useMutation(
    () => PayoutsService.retryWithdrawals(params.id ?? ""),
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );

  const { isLoading: isFulfilling, mutate: fulfillPayout } = useMutation(
    () => PayoutsService.fulfillWithdrawals(params.id ?? ""),
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );

  return {
    isUpdating,
    updatePayoutStatus,
    payloadVariables,
    isRetrying,
    retryPayout,
    isFulfilling,
    fulfillPayout,
  };
};

export default useUpdateGrootPayoutStatus;
