import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import CurrencyService from "app/api/currencies";
import { useToast } from "app/providers/ToastProvider";

export default function useGenerateCurrencyDetails() {
  const showToast = useToast();

  const { isLoading, data, mutate } = useMutation(
    (data: string) => CurrencyService.generateCurrencyDetails(data),
    {
      onSuccess: () => {
        showToast("Currency data fetched successfully", "success");
      },
      onError: (error: AxiosError<{}>) => {
        const message = parseError(error) as string;
        showToast(message || "Couldn't fetch currency data", "error");
      },
    }
  );

  return {
    isLoading,
    currencyDetails: data?.data,
    generateCurrencyDetails: mutate,
  };
}
