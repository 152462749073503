import { Modal } from "@shopify/polaris";

interface Props {
  open: boolean;
  onClose: () => void;
  text: string;
  destructive?: boolean;
  loading: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  confirmText: string;
  cancelText?: string;
  title: string;
}

export default function ToggleLevelModal(props: Props) {
  const {
    open,
    onClose,
    title,
    text,
    onConfirm,
    onCancel,
    confirmText,
    cancelText,
    loading,
    destructive,
  } = props;
  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      primaryAction={{
        content: confirmText,
        onAction: onConfirm,
        destructive,
        loading,
      }}
      secondaryActions={[
        {
          content: cancelText || "Cancel",
          onAction: onCancel,
        },
      ]}
    >
      <Modal.Section>
        <p>{text}</p>
      </Modal.Section>
    </Modal>
  );
}
