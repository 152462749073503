import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import { useToast } from "app/providers/ToastProvider";
import AccountsService from "app/api/accounts";

export default function useToggleAccountStatus(
  businessId: string,
  accountId: string
) {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const { isLoading: isToggling, mutate: toggleAccountStatus } = useMutation(
    () => AccountsService.toggleAccountStatus(businessId, accountId),
    {
      onSuccess: (res) => {
        showToast(res.data.message || "", "success");

        return Promise.all([
          queryClient.invalidateQueries(["business"]),
          queryClient.invalidateQueries(["accounts"]),
        ]);
      },
      onError: (error: AxiosError<{}>) => {
        const errorMessage = parseError(error) as string;

        showToast(errorMessage || "", "error");
      },
    }
  );

  return {
    isToggling,
    toggleAccountStatus,
  };
}
