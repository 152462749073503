import { Badge, Card, IndexTable, Link, Text } from "@shopify/polaris";

import Merchant from "app/models/merchant";
import TableWrapper from "app/components/TableWrapper";
import {
  capitalizeStr,
  formatDateStr,
  mapMerchantStatusToBadge,
  prependZero,
} from "app/utils/helpers";

interface Props {
  data: Merchant[];
}

export function MerchantsList({ data }: Props) {
  return (
    <Card.Section fullWidth>
      <TableWrapper>
        <IndexTable
          resourceName={{ singular: "merchant", plural: "merchants" }}
          headings={[
            { title: "Merchant" },
            { title: "No of Businesses" },
            { title: "Status" },
            { title: "Creation Date" },
          ]}
          itemCount={data.length}
          selectable={false}
        >
          {data.map((merchant, index) => {
            return (
              <IndexTable.Row key={index} id={index.toString()} position={index}>
                <IndexTable.Cell>
                  <Text as="p" variant="bodyMd">
                    <Link url={`/merchants/${merchant.id}`} removeUnderline>
                      {merchant.first_name} {merchant.last_name}
                    </Link>
                  </Text>
                  <Text as="p" variant="bodyMd">
                    {merchant.email}
                  </Text>
                </IndexTable.Cell>

                <IndexTable.Cell>{prependZero(merchant.num_business)}</IndexTable.Cell>

                <IndexTable.Cell>
                  <Badge status={mapMerchantStatusToBadge(merchant.status)}>
                    {capitalizeStr(
                      merchant.status === "pending_deletion"
                        ? "Pending deletion"
                        : merchant.status
                    )}
                  </Badge>
                </IndexTable.Cell>

                <IndexTable.Cell>
                  {formatDateStr(merchant.created_at, "MMMM D, YYYY")}
                </IndexTable.Cell>
              </IndexTable.Row>
            );
          })}
        </IndexTable>
      </TableWrapper>
    </Card.Section>
  );
}
