import { Badge, Card, IndexTable, Link, Text } from "@shopify/polaris";
import { useParams } from "react-router-dom";

import { formatMoney } from "app/lib/money";
import LoaderContainer from "app/components/LoaderContainer";
import { CellPadding, TableWrapper } from "app/styles";
import useGetPayments from "app/hooks/useGetPayments";
import { capitalizeStr, formatDateStr, mapStatusToBadge } from "app/utils/helpers";

export function PaymentsSection() {
  const { id } = useParams();

  const { payments, isLoading, error, refetch } = useGetPayments({
    params: { business_id: String(id), limit: 5 },
  });

  return (
    <Card>
      <LoaderContainer modal loading={isLoading} onRetryClicked={refetch} error={error}>
        <Card.Section fullWidth>
          <TableWrapper>
            <IndexTable
              headings={[
                { title: "Ref. Number" },
                { title: "Date" },
                { title: "Status" },
                { title: "Type" },
                { title: "Amount" },
              ]}
              itemCount={payments?.pagination?.current_entries_size || 0}
              selectable={false}
              emptyState={
                <Text as="p" alignment="center" variant="bodySm">
                  This business is yet to make a payment.
                </Text>
              }
            >
              {payments?.data.map((payment, index) => {
                return (
                  <IndexTable.Row key={index} id={index.toString()} position={index}>
                    <IndexTable.Cell>
                      <Link removeUnderline url={`/payments/${payment.id}`}>
                        <CellPadding>{payment.reference}</CellPadding>
                      </Link>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      {formatDateStr(payment.created_at, "MMMM D, YYYY [at] hh:mma")}
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Badge status={mapStatusToBadge(payment.status)}>
                        {capitalizeStr(payment.status)}
                      </Badge>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Badge>
                        {capitalizeStr(
                          payment.type === "payment_link" ? "payment link" : payment.type
                        )}
                      </Badge>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      {formatMoney(Number(payment.amount), "digital", payment.currency)}
                    </IndexTable.Cell>
                  </IndexTable.Row>
                );
              })}
            </IndexTable>
          </TableWrapper>
        </Card.Section>
      </LoaderContainer>
    </Card>
  );
}
