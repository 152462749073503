import * as React from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  Form,
  FormLayout,
  Modal,
  TextField,
  Select,
} from "@shopify/polaris";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useToast } from "app/providers/ToastProvider";
import { UpdateMerchantAddressPayload } from "app/api/merchants/type";
import useUpdateMerchantAddress from "../hooks/useUpdateMerchantAddress";

interface Props {
  open: boolean;
  onClose: () => void;
  formValues: UpdateMerchantAddressPayload;
}

export default function EditMerchantAddressModal(props: Props) {
  const { open, onClose, formValues } = props;

  const { merchantId } = useParams<{ merchantId: string }>();

  const { error, loading, response, updateAddress } = useUpdateMerchantAddress(
    String(merchantId)
  );

  const showToast = useToast();

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<UpdateMerchantAddressPayload>({
    defaultValues: {
      street_address_1: "",
      street_address_2: "",
      city: "",
      postal_code: "",
      state: "",
      country_code: "NG",
    },

    resolver: yupResolver(
      Yup.object().shape({
        street_address_1: Yup.string().required(
          "Street Address Line 1 is required"
        ),
        city: Yup.string().required("City is required"),
        postal_code: Yup.string().required("Postal code is required"),
        state: Yup.string().required("State is required"),
        country_code: Yup.string().required("Country code is required"),
      })
    ),
  });

  const onCancel = () => {
    reset();
    setTimeout(() => {
      onClose();
    }, 300);
  };

  React.useEffect(() => {
    reset(formValues);
  }, [formValues, reset]);

  React.useEffect(() => {
    if (response) {
      showToast(response, "success");
      onClose();
      reset();
    }
  }, [response, showToast, onClose, reset]);

  React.useEffect(() => {
    if (error) {
      showToast(error, "error");
    }
  }, [error, showToast]);

  return (
    <Modal
      title="Edit Merchant Address"
      open={open}
      onClose={onCancel}
      primaryAction={{
        content: "Save changes",
        onAction: handleSubmit(updateAddress),
        loading,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onCancel,
        },
      ]}
    >
      <Modal.Section>
        <Card subdued>
          <Card.Section>
            <Form onSubmit={() => null}>
              <FormLayout>
                <Controller
                  name="street_address_1"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete=""
                      label="Street Address 1"
                      name="street_address_1"
                      value={field.value}
                      onChange={(v) => setValue("street_address_1", v)}
                      error={errors.street_address_1?.message}
                    />
                  )}
                />

                <Controller
                  name="street_address_2"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete=""
                      label="Street Address 2"
                      name="street_address_2"
                      value={field.value}
                      onChange={(v) => setValue("street_address_2", v)}
                      error={errors.street_address_2?.message}
                    />
                  )}
                />
                <FormLayout.Group>
                  <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete=""
                        label="City"
                        name="first_name"
                        value={field.value}
                        onChange={(v) => setValue("city", v)}
                        error={errors.city?.message}
                      />
                    )}
                  />
                  <Controller
                    name="postal_code"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete=""
                        label="Postal Code"
                        name="last_name"
                        value={field.value}
                        onChange={(v) => setValue("postal_code", v)}
                        error={errors.postal_code?.message}
                      />
                    )}
                  />
                </FormLayout.Group>

                <FormLayout.Group>
                  <Controller
                    name="country_code"
                    control={control}
                    render={({ field }) => (
                      <Select
                        label="Country"
                        options={[{ label: "Nigeria", value: "NG" }]}
                        name="country"
                        value="NGN"
                        onChange={(v) => setValue("country_code", v)}
                        error={errors.country_code?.message}
                      />
                    )}
                  />
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete=""
                        label="State/Province"
                        name="state"
                        value={field.value}
                        onChange={(v) => setValue("state", v)}
                        error={errors.postal_code?.message}
                      />
                    )}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Form>
          </Card.Section>
        </Card>
      </Modal.Section>
    </Modal>
  );
}
