import * as React from "react";
import { Card, ChoiceList, Filters } from "@shopify/polaris";
import { BusinessType } from "app/models/business";
import { GetDocumentTypesQuery } from "app/api/compliance/types";

interface Props {
  businessTypes: BusinessType[];
  query: GetDocumentTypesQuery;
  updateQuery: (q: GetDocumentTypesQuery) => void;
}
export default function DocumentsFilters(props: Props) {
  const { businessTypes, query, updateQuery } = props;

  const [queryValue, setQueryValue] = React.useState("");

  const businessTypesOptions = businessTypes.map(businessType => {
    return {
      value: businessType.id,
      label: businessType.name,
    };
  });

  const filters = [
    {
      key: "country",
      label: "Country",
      filter: (
        <ChoiceList
          title=""
          titleHidden
          choices={[
            { label: "Nigeria", value: "NG" },
            { label: "Kenya", value: "KE" },
          ]}
          selected={[]}
        />
      ),
      shortcut: true,
    },
    {
      label: "Type",
      key: "type_id",
      filter: (
        <ChoiceList
          title="Type"
          titleHidden
          choices={businessTypesOptions}
          onChange={t => {
            updateQuery({ type_id: t[0] });
          }}
          selected={[query.type_id ?? ""]}
        />
      ),
      shortcut: true,
    },
  ];

  return (
    <Card.Section>
      <Filters
        filters={filters}
        queryValue={queryValue}
        onQueryChange={setQueryValue}
        onQueryClear={() => {
          setQueryValue("");
        }}
        onClearAll={() => {}}
        hideTags
      />
    </Card.Section>
  );
}
