import * as React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Button, Card, FormLayout, Modal, Stack, TextField } from "@shopify/polaris";
import { Controller, useForm } from "react-hook-form";

import { UpdateDocPayload } from "app/api/compliance/types";
import useUpdateDocStatus from "../../hooks/useUpdateDocStatus";
import useModalState from "app/hooks/useModalState";

interface Props {
  documentId: string;
}

interface ValueProps {
  comment: string;
}

const reasons = ["Improper Document", "Upload of blurry document", "File size too large"];

export default function RejectDocumentModal(props: Props) {
  const { documentId } = props;

  const { isOpen, closeModal, openModal } = useModalState();

  const [reason, setReason] = React.useState("");

  const { onUpdateDoc, formIsLoading: isLoading } = useUpdateDocStatus(documentId);

  const {
    setValue,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<UpdateDocPayload>({
    defaultValues: {
      comment: "",
      status: "rejected",
    },
  });

  const onSubmit = (data: ValueProps) => {
    onUpdateDoc(
      {
        status: "rejected",
        comment: data.comment || reason,
      },
      {
        onSuccess: () => {
          closeModal();
        },
      }
    );
  };

  return (
    <>
      <Button onClick={openModal}>Reject</Button>
      <Modal
        title="Pick a reason for rejecting this document"
        open={isOpen}
        onClose={closeModal}
        primaryAction={{
          content: "Reject",
          onAction: handleSubmit(onSubmit),
          loading: isLoading,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: closeModal,
          },
        ]}
      >
        <Modal.Section>
          <Card.Section>
            <Stack vertical>
              {reasons.map((item, idx) => (
                <StyledTag
                  key={idx.toString()}
                  onClick={() => {
                    setReason(item);
                    setValue("comment", "");
                  }}
                  selected={reason === item}
                >
                  {item}
                </StyledTag>
              ))}
            </Stack>
          </Card.Section>

          <Card.Section>
            <FormLayout>
              <Controller
                name="comment"
                control={control}
                render={({ field }) => (
                  <TextField
                    autoComplete="on"
                    label="Enter Custom Reason"
                    name="reason"
                    type="text"
                    placeholder="Enter reason"
                    value={field.value}
                    onChange={v => {
                      field.onChange(v);
                      setReason("");
                    }}
                    error={errors.comment?.message}
                  />
                )}
              />
            </FormLayout>
          </Card.Section>
        </Modal.Section>
      </Modal>
    </>
  );
}

export const StyledTag = styled.div<{ selected: boolean }>`
  background: var(--p-surface-neutral);
  max-width: max-content;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;

  :hover {
    opacity: 0.9;
  }
  ${props =>
    props.selected &&
    css`
      background: #008060;
      color: #fff;
    `}
`;
